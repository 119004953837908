import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import qs from 'qs';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import api from '../../services/api';
import ConfirmationDialog from '../common/ConfirmationDialog';
import EditScheduledPickupModal from './EditScheduledPickupModal';
import ScheduledPickupsForceModal from './ScheduledPickupsForceModal';
import CertaintyIcon from '../common/CertaintyIcon';
import PickupTestIcon from '../common/PickupTestIcon';
import { getIsTestModalConfirmMessage, getPickupRowStyle, SCHEDULED_PICKUPS_FIELDS } from '../../services/helpers';
import PickupsUploadModal from './PickupsUploadModal';
import FieldsModal from '../common/FieldsModal';
import { errorToast } from '../../services/toast';
import { updateChangeRowsPerPage } from '../../helpers';
import { PaginationKeys } from '../../constants';

const ScheduledPickups = ({
  allRecordsCount, defaultLimit, newScheduledPickupPath, companyList, states, limits, timezoneFromServer, radiusFlexId,
  permissions, isAdmin, countryId, userId, preferences,
}) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const assembleFilter = () => {
    const obj = {};
    if (urlSearchParams.company_name) obj['company.name'] = urlSearchParams.company_name;
    if (urlSearchParams.carrier_name) obj['carrier.name'] = urlSearchParams.carrier_name;
    ['ready_time',
      'close_time',
      'origin_company_name',
      'origin_attention_to',
      'origin_street_address1',
      'origin_street_address2',
      'origin_city',
      'origin_state',
      'origin_zip',
      'origin_country',
      'origin_phone',
      'package_cluster',
      'airport',
      'courier_instructions',
      'pickup_certainty',
      'is_test',
      'schedule_days',
      'is_on_call',
      'origin_record_info'].forEach((key) => {
      if (urlSearchParams[key]) obj[key] = urlSearchParams[key];
    });

    return obj;
  };
  const [rows, setRows] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || parseInt(window.localStorage.getItem(PaginationKeys.scheduledPickups), 10) || preferences?.records_per_page || 10,
  });
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openForseSchedule, setOpenForceSchedule] = useState(false);
  const [openCertainty, setOpenCertainty] = useState(false);
  const [filter, setFilter] = useState(assembleFilter());
  const [openTest, setOpenTest] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [serverTimezone, setServerTimezone] = useState('America/Chicago');

  const disassembleFilter = () => {
    const obj = {};
    Object.keys(filter).forEach((key) => {
      obj[key.replaceAll('.', '_')] = filter[key];
    });
    return obj;
  };

  useEffect(() => {
    const tz = timezoneFromServer?.info?.identifier;
    if (tz) {
      setServerTimezone(tz);
    } else {
      setServerTimezone('America/Chicago');
    }
  }, [timezoneFromServer]);

  useEffect(() => {
    setPaginationOptions({
      ...paginationOptions,
      count: allRecordsCount,
      limit: defaultLimit,
    });
  }, [allRecordsCount, defaultLimit]);

  useEffect(() => {
    handleFilter({});
  }, [filter]);

  const handleFilter = useCallback(({ page, limit }) => {
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    api.pickupsDashboard.scheduledIndex({
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
      ...filter,
    })
      .then((res) => {
        setRows(res.records);
        setPaginationOptions({
          count: res.all_records,
          page: fixedPage || 0,
          limit: res.limit || limit || paginationOptions.limit,
        });
        const url = `/admin/scheduled_pickups?${qs.stringify({
          page: fixedPage + 1,
          limit: limit || paginationOptions.limit,
          ...disassembleFilter(),
        })}`;
        window.history.replaceState(null, '', url);
      })
      .catch(err => console.log(err));
  }, [paginationOptions, filter]);

  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };

  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, PaginationKeys.scheduledPickups, val => handleFilter({ page: 0, limit: val }));
  };

  const handleDelete = useCallback(() => {
    api.pickupsDashboard.destroyScheduledPickup(selectedRow.id).then(() => {
      setOpenDelete(false);
      handleFilter(paginationOptions);
    });
  }, [selectedRow]);

  const handleSetCertainty = useCallback(() => {
    api.pickupsDashboard.updateScheduledPickup(selectedRow.id, { pickup: { pickup_certainty: !selectedRow.pickup_certainty } }).then(() => {
      setOpenCertainty(false);
      setSelectedRow({});
      handleFilter(paginationOptions);
    });
  }, [selectedRow]);

  const handleForceSchedule = () => {
    setOpenForceSchedule(true);
  };

  const handleSetTest = useCallback(() => {
    api.pickupsDashboard.updateScheduledPickup(selectedRow.id, { pickup: { is_test: !selectedRow.is_test } }).then(() => {
      setOpenTest(false);
      setSelectedRow({});
      handleFilter(paginationOptions);
    });
  }, [selectedRow]);

  const handleSetCustomer = async () => {
    if (selectedRows.length === 0) {
      errorToast('Please select any record');
      return;
    }
    setLoading(true);
    const responses = [];
    for (const row of selectedRows) {
      let res;
      try {
        // eslint-disable-next-line no-await-in-loop
        res = await api.pickupsDashboard.setCustomer(row.id);
      } catch (error) {
        res = error;
      } finally {
        responses.push({ ...res.generated_value });
      }
    }
    await api.pickupsDashboard.convertToFile(responses);
    setLoading(false);
  };

  const getFields = () => {
    const defaultArray = [
      {
        header: 'Company', key: 'company.name', filtering: true, format: true,
      },
      { header: 'Pickup Id', key: 'id' },
      { header: 'Customer', key: 'customer', format: true },
      { header: 'Carrier', key: 'carrier.name', filtering: true },
      { header: 'Ready Time', key: 'ready_time', timeFilter: true },
      { header: 'Close Time', key: 'close_time', timeFilter: true },
      { header: 'Cutoff', key: 'actual_cutoff_time', filtering: true },
      {
        header: 'Origin Company Name', key: 'origin_company_name', filtering: true, format: true,
      },
      {
        header: 'Origin Attention To', key: 'origin_attention_to', filtering: true, format: true,
      },
      {
        header: 'Origin Street Address1', key: 'origin_street_address1', filtering: true, format: true,
      },
      {
        header: 'Origin Street Address2', key: 'origin_street_address2', filtering: true, format: true,
      },
      {
        header: 'Origin City', key: 'origin_city', filtering: true, format: true,
      },
      {
        header: 'Origin State', key: 'origin_state', filtering: true, format: true,
      },
      {
        header: 'Origin Zip', key: 'origin_zip', filtering: true, format: true,
      },
      {
        header: 'Origin Country', key: 'origin_country', filtering: true, format: true,
      },
      {
        header: 'Origin Phone', key: 'origin_phone', filtering: true, format: true,
      },
      { header: 'Owner', key: 'owner' },
      {
        header: 'Package Cluster', key: 'package_cluster', filtering: true, format: true,
      },
      {
        header: 'Airport', key: 'airport', filtering: true, format: true,
      },
      { header: 'Courier Instructions', key: 'courier_instructions', filtering: true },
      { header: 'Confirm', key: 'pickup_certainty', format: true },
      {
        header: 'Test', key: 'is_test', filtering: true, format: true,
      },
      { header: 'Schedule Days', key: 'schedule_days', filtering: true },
      { header: 'OnCall', key: 'is_on_call', filtering: true },
      { header: 'Record ID', key: 'origin_record_info' },
    ];
    if (permissions.scheduledPickup?.edit) defaultArray.push({ header: 'Actions', key: 'actions', format: true });

    return defaultArray;
  };

  const handleUpdateFields = (updatedFields, save = true) => {
    setSelectedFields(getFields().filter(f => updatedFields.includes(f.key)));
    setShowFieldsModal(false);
    if (save) window.localStorage.setItem(SCHEDULED_PICKUPS_FIELDS, updatedFields.join(','));
  };

  useEffect(() => {
    const savedFields = window.localStorage.getItem(SCHEDULED_PICKUPS_FIELDS);
    if (!savedFields) {
      setSelectedFields(getFields());
      return;
    }

    handleUpdateFields(savedFields.split(','), false);
  }, []);

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Scheduled Pickups</h1>
              <div className="pull-right">
                <button className="btn btn-primary" onClick={() => setShowFieldsModal(true)}>Change selected fields</button>
                <button className="btn btn-primary" onClick={handleForceSchedule} style={{ marginLeft: '5px' }}>Run Scheduled</button>
                {
                  permissions.scheduledPickup?.add && (
                    <>
                      <a href={newScheduledPickupPath} className="btn bg-olive margin add-btn" style={{ marginLeft: '5px' }}>Add Scheduled Pickup</a>
                      <button
                        className="btn add-btn"
                        style={{ marginLeft: '5px' }}
                        onClick={() => {
                          setOpenImport(true);
                        }}
                      >
                        Import From File
                      </button>
                    </>
                  )
                }
                <button className="btn btn-primary" onClick={handleSetCustomer} style={{ marginLeft: '5px' }}>Create and assign customers</button>
              </div>
            </div>
            <div className="box-body main_listing">
              {loading === true && (
                <Dialog
                  className="confirmation-dialog"
                  open
                  aria-labelledby="preview-modal-title"
                >
                  <DialogTitle id="preview-modal-title">
                    In progress
                  </DialogTitle>
                  <DialogContent className="preview-item-container" id="preview-modal-dialog">
                    <div
                      className="spinner-border"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </DialogContent>
                </Dialog>
              )}
              <CustomTable
                rows={rows}
                isFilteringActive
                setFilter={setFilter}
                filter={filter}
                formatters={{
                  schedule_days: value => (value ? value.map(item => item.slice(0, 3)).join(', ') : '-'),
                  close_time: value => moment(value).tz(serverTimezone).format('hh:mm a z'),
                  ready_time: value => moment(value).tz(serverTimezone).format('hh:mm a z'),
                  actual_cutoff_time: value => (value ? moment(value).tz(serverTimezone).format('hh:mm a z') : ''),
                  package_cluster: ({ package_cluster }) => (package_cluster ? `${package_cluster.airport_code}@${package_cluster.id}` : '-'),
                  pickup_certainty: value => (
                    <CertaintyIcon
                      certainty={value.pickup_certainty}
                      onClick={() => {
                        if (!permissions.scheduledPickup?.edit) return;

                        setSelectedRow(value);
                        setOpenCertainty(true);
                      }}
                    />
                  ),
                  is_test: value => (
                    <PickupTestIcon
                      test={value.is_test}
                      onClick={() => {
                        setSelectedRow(value);
                        setOpenTest(true);
                      }}
                    />
                  ),
                  customer: ({
                    customer,
                    origin_record_json: origin,
                  }) => <div className="text-center" style={{ minWidth: '70px' }}>{`${origin?.customer?.first_name || customer?.first_name || ''} ${origin?.customer?.last_name || customer?.last_name || ''}`}</div>,
                  is_on_call: value => <div className="text-center">{`${value ? '+' : '-'}`}</div>,
                  actions: value => (
                    <div className="text-center" style={{ minWidth: '70px' }}>
                      <button
                        className="fa fa-trash action-button"
                        onClick={() => {
                          setOpenDelete(true);
                          setSelectedRow(value);
                        }}
                      />
                      <button
                        className="fa fa-edit action-button"
                        onClick={() => {
                          setOpenEdit(true);
                          setSelectedRow(value);
                        }}
                      />
                    </div>
                  ),
                  airport: ({ package_cluster }) => (package_cluster && package_cluster.airport ? `${package_cluster.airport.name}` : '-'),
                  origin_record_info: value => <span>{value ? value.record_id : '-'}</span>,
                  'company.name': value => value.origin_record_json?.company?.name || value.company?.name,
                  origin_company_name: value => value.origin_record_json?.company_name || value.origin_company_name,
                  origin_attention_to: value => value.origin_record_json?.attention_to || value.origin_attention_to,
                  origin_street_address1: value => value.origin_record_json?.street_address1 || value.origin_street_address1,
                  origin_street_address2: value => value.origin_record_json?.street_address2 || value.origin_street_address2,
                  origin_city: value => value.origin_record_json?.city || value.origin_city,
                  origin_state: value => (value.origin_record_json ? states.find(state => state.id === parseInt(value.origin_record_json.state_id, 10))?.alpha2 : value.origin_state),
                  origin_zip: value => value.origin_record_json?.zip || value.origin_zip,
                  origin_country: value => value.origin_record_json?.country || value.origin_country,
                  origin_phone: value => value.origin_record_json?.phone || value.origin_phone,
                }}
                rowsProps={selectedFields}
                paginationOptions={{
                  ...paginationOptions,
                  handleChangePage,
                  handleChangeRowsPerPage,
                }}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                rowStyle={row => getPickupRowStyle(row, serverTimezone)}
                suffix="scheduled_pickup"
                blockRowPePage
              />
            </div>
            <ConfirmationDialog
              onConfirm={handleSetCertainty}
              onClose={() => setOpenCertainty(false)}
              message={selectedRow.pickup_certainty ? 'Are you sure you want to unconfirm this pickup?' : 'Are you sure you want to confirm this pickup?'}
              open={openCertainty}
            />
            <ConfirmationDialog
              onConfirm={handleDelete}
              onClose={() => setOpenDelete(false)}
              message="Are you sure you want to delete this scheduled pickup?"
              open={openDelete}
            />
            <ConfirmationDialog
              onConfirm={handleSetTest}
              onClose={() => setOpenTest(false)}
              message={getIsTestModalConfirmMessage(selectedRow.is_test)}
              open={openTest}
            />
            {openEdit && (
              <EditScheduledPickupModal
                update={() => handleFilter({ page: paginationOptions.page })}
                companyList={companyList}
                row={selectedRow}
                onClose={() => setOpenEdit(false)}
                serverTimezone={serverTimezone}
                open={openEdit}
                states={states}
                limits={limits}
                radiusFlexId={radiusFlexId}
                isAdmin={isAdmin}
                permission={permissions}
                countryId={countryId}
                userId={userId}
                preferences={preferences}
              />
            )}
            <ScheduledPickupsForceModal
              onClose={() => setOpenForceSchedule(false)}
              open={openForseSchedule}
            />
            <PickupsUploadModal
              mode="scheduledPickup"
              sampleHref="/scheduled_pickups_sample.csv"
              handleClose={() => setOpenImport(false)}
              companies={companyList}
              callback={() => handleFilter(paginationOptions)}
              open={openImport}
            />
            {
              showFieldsModal && <FieldsModal open={showFieldsModal} fields={getFields()} selectedFields={selectedFields} updateFields={handleUpdateFields} handleClose={() => setShowFieldsModal(false)} />
            }
          </div>
        </div>
      </div>
    </>
  );
};

ScheduledPickups.propTypes = {
  allRecordsCount: PropTypes.number.isRequired,
  defaultLimit: PropTypes.number.isRequired,
  newScheduledPickupPath: PropTypes.string.isRequired,
  timezoneFromServer: PropTypes.instanceOf(Object).isRequired,
  companyList: PropTypes.instanceOf(Array).isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  limits: PropTypes.instanceOf(Object).isRequired,
  permissions: PropTypes.instanceOf(Object).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  countryId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  radiusFlexId: PropTypes.number.isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

export default ScheduledPickups;
