import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import api from '../../services/api';
import { isAddressValid } from '../../services/helpers';

const SetCustomerPriceConstants = ({
  row, companies, onClose, open, update,
}) => {
  const [errors, setErrors] = useState({});
  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [carriers, setCarriers] = useState([]);

  const isEdit = !isEmpty(row);

  useEffect(() => {
    if (isEdit) setRecord(row);
    else {
      setRecord({
        company_id: '',
        carrier_id: '',
        customer_discount: '',
        customer_surcharge: '',
        customer_minimum: '',
        base_discount: '',
      });
    }
  }, [isEdit, row]);

  useEffect(() => {
    api.customerPriceConstants.getCarriers({ company: record.company_id }).then((data) => {
      setCarriers(data.carriers);
    });
  }, [record.company_id]);

  const validate = useCallback(() => {
    Object.keys(record).forEach((key) => {
      if (!record[key]) {
        errors[key] = `Please enter ${key.replace('_', ' ')}`;
      }
    });
    setErrors({ ...errors });
    return errors;
  }, [record]);

  const handleChange = (e) => {
    const { id: field, value } = e.target;

    setRecord({
      ...record,
      [field]: value,
    });
    setErrors({ ...errors, [field]: '' });
  };

  const handleSubmit = () => {
    const method = isEdit ? 'update' : 'create';
    if (isAddressValid(validate())) {
      setLoading(true);
      api.customerPriceConstants[`${method}Table`]({ fields: record }, record.id).then(() => {
        onClose();
        setLoading(false);
        update({});
      });
    }
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        {`${isEdit ? 'Edit' : 'Add New'} Record`}
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="customer_discount">
                Customer Discount
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                id="customer_discount"
                value={record.customer_discount || ''}
                onChange={e => handleChange(e)}
              />
              {errors.customer_discount && (
                <label className="error">
                  {errors.customer_discount}
                </label>
              )}
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="customer_surcharge">
                Customer Surcharge
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                id="customer_surcharge"
                value={record.customer_surcharge || ''}
                onChange={e => handleChange(e)}
              />
              {errors.customer_surcharge && (
                <label className="error">
                  {errors.customer_surcharge}
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="customer_minimum">
                Customer Minimum
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                id="customer_minimum"
                value={record.customer_minimum || ''}
                onChange={e => handleChange(e)}
              />
              {errors.customer_minimum && (
                <label className="error">
                  {errors.customer_minimum}
                </label>
              )}
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="base_discount">
                Base Discount
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                id="base_discount"
                value={record.base_discount || ''}
                onChange={e => handleChange(e)}
              />
              {errors.base_discount && (
                <label className="error">
                  {errors.base_discount}
                </label>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="company_id">
                Company
                <span className="required">*</span>
              </label>
              <select
                className="form-control"
                id="company_id"
                value={record.company_id || ''}
                onChange={e => handleChange(e)}
              >
                <option key="default" value="">Please Select</option>
                {companies.map(item => <option key={item[1]} value={item[1]}>{item[0]}</option>)}
              </select>
              {errors.company_id && (
                <label className="error">
                  {errors.company_id}
                </label>
              )}
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="carrier_id">
                Carrier
                <span className="required">*</span>
              </label>
              <select
                className="form-control"
                id="carrier_id"
                value={record.carrier_id || ''}
                onChange={e => handleChange(e)}
              >
                <option key="default" value="">Please Select</option>
                {carriers?.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
              </select>
              {errors.carrier_id && (
                <label className="error">
                  {errors.carrier_id}
                </label>
              )}
            </div>
          </div>
        </div>
        <div className="modal-bottom-btn text-right">
          <button disabled={loading} className="btn bg-olive" onClick={handleSubmit}>{`${isEdit ? 'Update' : 'Add'} Record`}</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={onClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

SetCustomerPriceConstants.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  companies: PropTypes.instanceOf(Array).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  update: PropTypes.func.isRequired,
};

SetCustomerPriceConstants.defaultProps = {
  open: false,
};

export default SetCustomerPriceConstants;
