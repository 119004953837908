import ActionType from '../action-types';

const initialState = [];

const reducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
  case ActionType.SET_SYSTEM_USERS:
    return action.payload;
  default:
    return state;
  }
};

export default reducer;
