import React, { useEffect, useState } from 'react';
import { DirectionsRenderer } from 'react-google-maps';
import PropTypes from 'prop-types';

const DirectionRenderComponent = ({
  directionsData, callback, optimizeWaypoints, directionsDetails,
}) => {
  const [directions, setDirections] = useState();

  if (!directionsDetails) return <></>;

  useEffect(() => {
    getDirections(directionsData);
  }, [directionsData]);
  /* eslint-disable no-undef */
  const getDirections = ({ start, end, waypoints }) => {
    const directionService = new window.google.maps.DirectionsService();
    directionService.route(
      {
        origin: start,
        destination: end,
        waypoints,
        optimizeWaypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(directionsDetails);
          callback(directionsDetails);
        }
      },
    );
  };
  /* eslint-enable no-undef */

  return (
    <div>
      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{
            markerOptions: { visible: false },
          }}
        />
      )}
    </div>
  );
};

DirectionRenderComponent.propTypes = {
  directionsData: PropTypes.instanceOf(Object).isRequired,
  callback: PropTypes.func.isRequired,
  optimizeWaypoints: PropTypes.bool.isRequired,
  directionsDetails: PropTypes.instanceOf(Object),
};

DirectionRenderComponent.defaultProps = {
  directionsDetails: {},
};

export default DirectionRenderComponent;
