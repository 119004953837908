import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ShipmentPackageDetails from './ShipmentPackageDetails';
import api from '../../services/api';

const ShipmentDetailsInner = ({
  data, prefix,
}) => (
  <div className="box-body">
    <table className="table table-bordered table-hover data-view-table">
      <tbody>
        <tr>
          <td>
            <label htmlFor="company_id">Company</label>
            <div>
              <span>{data.company?.name || ''}</span>
            </div>
          </td>
          <td>
            <label htmlFor="origin">Origin</label>
            <div>
              <span>{data.string_origin}</span>
            </div>
          </td>
          <td>
            <label htmlFor="destination">Destination</label>
            <div>
              <span>{data.string_destination}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <label htmlFor="ship_date">Ship Date</label>
            <div>
              <span>{data.ship_date ? moment(data.ship_date).format('DD-MM-YYYY') : '-'}</span>
            </div>
          </td>
          <td>
            <label htmlFor="carrier_id">Carrier</label>
            <div>
              <span>{data.carrier?.name || '-'}</span>
            </div>
          </td>
          <td>
            <label htmlFor="carrier_service_id">Carrier Service</label>
            <div>
              <span>{data.carrier_service?.name || '-'}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="3">
            <div className="row">
              <div className="col-xs-4 col-lg-2 full-width">
                <label className="radio-label">
                  <input type="radio" name={`is_return_${prefix}`} disabled checked={data.is_return === 1} />
                  Outbound
                  <span className="checkmark" />
                </label>
              </div>
              <div className="col-xs-4 col-lg-2 full-width">
                <label className="radio-label">
                  <input type="radio" name={`is_return_${prefix}`} disabled checked={data.is_return === 2} />
                  Outbound and Return
                  <span className="checkmark" />
                </label>
              </div>
              <div className="col-xs-4 col-lg-2 full-width">
                <label className="radio-label">
                  <input type="radio" name={`is_return_${prefix}`} disabled checked={data.is_return === 3} />
                  Return only
                  <span className="checkmark" />
                </label>
              </div>
              <label id={`shipment[is_return]-error-${prefix}`} className="error" htmlFor="shipment[is_return]" />
            </div>
          </td>
        </tr>
        {!data.is_parent && (
          <tr>
            <td>
              <label htmlFor="tracking_id"> Tracking ID</label>
              <div>
                <span>{data.tracking_id || '-'}</span>
              </div>
            </td>
            <td className="table-column">
              <div className="column-container">
                <div className="column-item">
                  <label htmlFor="pickup_id">Pickup ID</label>
                  <div>
                    <span>{data.pickup_id || '-'}</span>
                  </div>
                </div>
                <div className="column-item">
                  <label htmlFor="price">Price($)</label>
                  <div>
                    <span>{data.price || '-'}</span>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <label htmlFor="files">Files</label>
              <div>
                { data.html_file_attached || data.pdf_file_attached || (
                  <button className="btn btn-primary" onClick={() => api.shipmentDashboard.generateLabel({ id: data.id })}>Regenerate</button>
                )}
                {data.html_file_attached && (
                  <a className="fa fa-file-picture-o pdf-icon" target="_blank" rel="noreferrer" style={{ marginLeft: 0 }} href={`/admin/shipments/${data.id}/show_html_label`} title="View HTML Label" />
                )}
                {data.pdf_file_attached && (
                  <a className="fa fa-file-pdf-o pdf-icon" target="_blank" rel="noreferrer" style={{ marginLeft: 0 }} href={`/admin/shipments/${data.id}/show_pdf_label`} title="View PDF Label" />
                )}
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
    <ShipmentPackageDetails data={data.shipment_details} prefix={prefix} />
  </div>
);

ShipmentDetailsInner.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  prefix: PropTypes.string,
};

ShipmentDetailsInner.defaultProps = {
  prefix: '',
};

export default ShipmentDetailsInner;
