import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import twilioApi from '../../../services/twilioApi';
import MessageView from './MessageView';
import Horizon from './Horizon';
import {
  getSdkConversationObject,
} from '../../../conversationObject';

function getMessageTime(message) {
  const { dateCreated } = message;

  return moment(dateCreated).format('DD.MM.YYYY hh:mm a z') || '';
}

const MessageList = ({
  messages, conversation, lastReadIndex, participants, manifestMembers,
}) => {
  if (messages === undefined) {
    return <div className="empty" />;
  }
  const myRef = useRef(null);
  const email = useSelector(state => state.email);
  const [horizonMessageCount, setHorizonMessageCount] = useState(0);
  const [showHorizonIndex, setShowHorizonIndex] = useState(0);
  const [scrolledToHorizon, setScrollToHorizon] = useState(false);

  useEffect(() => {
    if (scrolledToHorizon || !myRef.current) {
      return;
    }
    myRef.current.scrollIntoView({
      behavior: 'smooth',
    });
    setScrollToHorizon(true);
  });

  useEffect(() => {
    if (lastReadIndex === -1 || horizonMessageCount) {
      return;
    }
    const showIndex = 0;
    getSdkConversationObject(conversation)
      .getUnreadMessagesCount()
      .then((count) => {
        setHorizonMessageCount(count ?? 0);
        setShowHorizonIndex(showIndex);
      });
  }, [messages, lastReadIndex]);

  return (
    <>
      {messages.map(message => (
        <div key={`${message.sid}message`}>
          {lastReadIndex !== -1
          && horizonMessageCount
          && showHorizonIndex === message.index
            ? (<Horizon ref={myRef} messageCount={horizonMessageCount} />)
            : null}
          <MessageView
            text={message.body ?? ''}
            author={message.author ?? ''}
            getStatus={twilioApi.getMessageStatus(message, participants, email)}
            messageTime={getMessageTime(message)}
            manifestMembers={manifestMembers}
          />
        </div>
      ))}
    </>
  );
};

MessageList.propTypes = {
  messages: PropTypes.instanceOf(Array),
  conversation: PropTypes.instanceOf(Object).isRequired,
  lastReadIndex: PropTypes.number.isRequired,
  participants: PropTypes.instanceOf(Array),
  manifestMembers: PropTypes.instanceOf(Array),
};

MessageList.defaultProps = {
  messages: [],
  participants: [],
  manifestMembers: [],
};

export default MessageList;
