import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import api from '../../services/api';

const ScheduledPickupsForceModal = ({
  onClose, open,
}) => {
  const [carriers, setCarriers] = useState([]);
  const [checked, setChecked] = useState();
  const [dayChecked, setDayChecked] = useState(0);
  // const checkList = ["RFL", "UPS", "Fedex"];

  useEffect(() => {
    if (open) {
      api.carriers.getAllCarriers().then((res) => {
        setCarriers(res.carriers);
        setChecked(res.carriers[0].id);
      });
    }
  }, [open]);

  const handleCheck = (event) => {
    setChecked(parseInt(event.target.value, 10));
  };

  const handleSubmit = () => {
    api.pickupsDashboard.forceSchedule({ id: checked, forward_day: dayChecked });
    onClose();
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Run Scheduled
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row">
          <div className="col-sm-6 form-group">
            <label>Carrier type</label>
            {carriers.map(item => (
              <div className="radio" key={item.id}>
                <label>
                  <input
                    type="radio"
                    value={item.id}
                    checked={checked === item.id}
                    onChange={handleCheck}
                  />
                  {item.name}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label>Days</label>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value={0}
                  checked={dayChecked === 0}
                  onChange={() => setDayChecked(0)}
                />
                Only today
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value={1}
                  checked={dayChecked === 1}
                  onChange={() => setDayChecked(1)}
                />
                + One day
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value={2}
                  checked={dayChecked === 2}
                  onChange={() => setDayChecked(2)}
                />
                + Two day
              </label>
            </div>
          </div>
        </div>
        <div className="modal-bottom-btn text-right">
          <button className="btn bg-olive" onClick={handleSubmit}>Confirm</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={onClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ScheduledPickupsForceModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

ScheduledPickupsForceModal.defaultProps = {
  open: false,
};

export default ScheduledPickupsForceModal;
