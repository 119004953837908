import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import api from '../../services/api';
import {
  isFormValid,
  setPackageErrors,
} from '../../services/helpers';
import AddressInputs from './AddressInputs';
import { errorToast } from '../../services/toast';
import Packages from './Packages';
import AddressModal from './AddressModal';

const EditParentShipmentModal = ({
  row, onClose, open, onUpdate, companyList, states, limits, defaultData, countryId, userId, permission, preferences,
}) => {
  // eslint-disable-next-line
  let [errors, setErrors] = useState({});
  const [originAddress, setOriginAddress] = useState({});
  const [destinationAddress, setDestinationAddress] = useState({});
  const [company, setCompany] = useState('');
  const [accountList, setAccountList] = useState([]);
  const [carrier, setCarrier] = useState({
    outbound: '',
    return: '',
  });
  const [carrierService, setCarrierService] = useState({
    outbound: '',
    return: '',
  });
  const [isReturn, setIsReturn] = useState(1);
  const [returnCarrierList, setReturnCarrierList] = useState([]);
  const [outboundCarrierList, setOutboundCarrierList] = useState([]);
  const [carrierServicesList, setCarrierServicesList] = useState({
    outbound: [],
    return: [],
  });
  const [packageTypesList, setPackageTypesList] = useState({
    outbound: [],
    return: [],
  });
  const [packages, setPackages] = useState({
    outbound: [],
    return: [],
  });

  const [finalPrice, setFinalPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [originAddressModalVisible, setOriginAddressModalVisible] = useState(false);
  const [destinationAddressModalVisible, setDestinationAddressModalVisible] = useState(false);
  const [fedexFormula, setFedexFormula] = useState(false);
  const [currentCarrier, setCurrentCarrier] = useState('');
  const [returnShipmentRateId, setReturnShipmentRateId] = useState('');
  const [outboundShipmentRateId, setOutboundShipmentRateId] = useState('');
  const [isParent, setIsParent] = useState(false);

  const defaultDimensions = {
    width: 1,
    height: 1,
    length: 1,
  };

  useEffect(() => {
    carrier.outbound && setCurrentCarrier(outboundCarrierList.find(item => item.id === parseInt(carrier.outbound, 10))?.name);
  }, [carrier.outbound, outboundCarrierList]);

  useEffect(() => {
    if (loading) {
      $('.overlay').show();
    } else {
      $('.overlay').hide();
    }
  }, [loading]);

  const transformOriginAddress = () => ({
    company_name: row.origin_company_name,
    attention_to: row.origin_attention_to,
    street_address1: row.origin_street_address1,
    street_address2: row.origin_street_address2,
    city: row.origin_city,
    state: states.find(state => state.alpha2 === row.origin_state)?.id,
    zip: row.origin_zip,
    phone: row.origin_phone,
    account_id: row.account_id, // TODO
  });

  const transformDestinationAddress = () => ({
    company_name: row.destination_company_name,
    attention_to: row.destination_attention_to,
    street_address1: row.destination_street_address1,
    street_address2: row.destination_street_address2,
    city: row.destination_city,
    state: states.find(state => state.alpha2 === row.destination_state)?.id,
    zip: row.destination_zip,
    phone: row.destination_phone,
    account_id: row.account_id, // TODO
  });

  const initData = (outboundData, returnData) => {
    setIsParent(row.is_parent);
    setIsReturn(row.is_return);
    setCompany(row.company_id || '');
    setOriginAddress(transformOriginAddress());
    setDestinationAddress(transformDestinationAddress());
    const outbound_carrier = outboundData ? JSON.stringify({ carrier_details: { carrier_type: outboundData.carrier_type, carrier_id: outboundData.carrier_id } }) : '';
    const return_carrier = returnData ? JSON.stringify({ carrier_details: { carrier_type: returnData.carrier_type, carrier_id: returnData.carrier_id } }) : '';
    setCarrier({
      outbound: outbound_carrier,
      return: return_carrier,
    });
    setCarrierService({
      outbound: outboundData?.carrier_service_id || '',
      return: returnData?.carrier_service_id || '',
    });
    const defaultLocalDimentions = {
      width: defaultData.width ? defaultData.width : defaultDimensions.width,
      height: defaultData.height ? defaultData.height : defaultDimensions.height,
      length: defaultData.length ? defaultData.length : defaultDimensions.length,
    };
    const defaultPackage = {
      width: defaultLocalDimentions.width,
      height: defaultLocalDimentions.height,
      length: defaultLocalDimentions.length,
      weight_calculated_in: 1,
      dimension_calculated_in: 1,
      quantity: 1,
      weight: 1,
    };
    setPackages({
      outbound: outboundData ? outboundData.shipment_details : [defaultPackage],
      return: returnData ? [returnData.shipment_details[0]] : [defaultPackage],
    });
  };

  useEffect(() => {
    setErrors({
      ...errors,
      carrier_service_id: {
        outbound: '',
        return: '',
      },
      carrier_id: {
        outbound: '',
        return: '',
      },
      packages: {
        outbound: { 0: {} },
        return: { 0: {} },
      },
    });

    const setData = async () => {
      switch (row.is_return) {
      case 1:
        initData(row, null);
        break;
      case 2:
        if (row.outbound_id) {
          const { shipment: sibling } = await api.shipmentDashboard.show(row.outbound_id);
          initData(sibling, row);
        } else {
          const { shipment: sibling } = await api.shipmentDashboard.show(row.return_id);
          initData(row, sibling);
        }
        break;
      case 3:
        initData(null, row);
        break;
      default:
        break;
      }
    };
    setData();
  }, [row]);

  useEffect(() => {
    if (!company) {
      setReturnCarrierList([]);
      setOutboundCarrierList([]);
    } else {
      api.shipmentDashboard.getCarriers({ company_id: company }).then((res) => {
        setReturnCarrierList(res.data.return_carriers_r);
        setOutboundCarrierList(res.data.outbound_carriers_r);
      });
      api.addressBook.getUserAccounts({ company_id: company }).then((res) => {
        setAccountList(res.accounts);
      });
    }

    setCarrierServicesList({
      outbound: [],
      return: [],
    });
    setPackageTypesList({
      outbound: [],
      return: [],
    });
  }, [company]);

  const setCarrierServicesAndPackageTypes = async (carrierObject, fields) => {
    let serviceList = {
      outbound: [],
      return: [],
    };
    let packageList = {
      outbound: [],
      return: [],
    };
    for (let i = 0; i < fields.length; i += 1) {
      try {
        const field = fields[i];
        // eslint-disable-next-line no-continue
        if (!carrierObject[field]) continue;

        // eslint-disable-next-line no-await-in-loop
        const res = await api.shipmentDashboard.getCarrierServicesAndPackageTypes({
          company_id: company,
          carrier_type: JSON.parse(carrierObject[field]).carrier_details.carrier_type,
        });
        serviceList = {
          ...serviceList,
          [field]: res[`${field}_carrier_services_r`],
        };
        packageList = {
          ...packageList,
          [field]: res.package_types_r,
        };
      } catch (e) {
        console.log(e);
      }
    }
    setCarrierServicesList(serviceList);
    setPackageTypesList(packageList);
  };

  useEffect(() => {
    switch (isReturn) {
    case 1: {
      setCarrierServicesAndPackageTypes(carrier, ['outbound']);
      return;
    }
    case 2: {
      setCarrierServicesAndPackageTypes(carrier, ['outbound', 'return']);
      return;
    }
    case 3: {
      setCarrierServicesAndPackageTypes(carrier, ['return']);
    }
    // eslint-disable-next-line
      default: return;
    }
  }, [carrier, isReturn]);

  const validate = useCallback(() => {
    if (!company) errors.company = 'Please select company';
    if (!carrier.return && isReturn !== 1) errors.carrier_id.return = 'Please select carrier';
    if (!carrier.outbound && isReturn !== 3) errors.carrier_id.outbound = 'Please select carrier';
    if (!carrierService.return && isReturn !== 1) errors.carrier_service_id.return = 'Please select carrier service';
    if (!carrierService.outbound && isReturn !== 3) errors.carrier_service_id.outbound = 'Please select carrier service';

    // origin
    errors.origin = {};
    Object.keys(originAddress).forEach((key) => {
      if (!['street_address2', 'attention_to'].includes(key) && !originAddress[key]) errors.origin[key] = 'Please enter value';
    });

    // destination
    errors.destination = {};
    Object.keys(destinationAddress).forEach((key) => {
      if (!['street_address2', 'attention_to'].includes(key) && !destinationAddress[key]) errors.destination[key] = 'Please enter value';
    });

    // outbound packages
    if (isReturn !== 3) {
      packages.outbound.forEach((pack, index) => {
        errors = setPackageErrors(errors, pack, index, 'outbound');
      });
    }

    // return packages
    if (isReturn !== 1) errors = setPackageErrors(errors, packages.return[0], 0, 'return');

    if (destinationAddress.street_address1 === originAddress.street_address1) errors.destination.street_address1 = 'Should not be equal to origin street address';

    setErrors({ ...errors });
    return errors;
  }, [originAddress, destinationAddress, carrier, carrierService, company, packages, isReturn, errors, isParent]);

  const handleChange = (e, prefix) => {
    const { id: field, value } = e.target;
    if (prefix === 'origin') {
      setOriginAddress({
        ...originAddress,
        [field]: value,
      });
      setErrors({ ...errors, origin: { ...errors.origin, [field]: '' } });
    } else {
      setDestinationAddress({
        ...destinationAddress,
        [field]: value,
      });
      setErrors({ ...errors, destination: { ...errors.destination, [field]: '' } });
    }
  };

  const handleSelectAddress = (type, address) => {
    const newAddress = {
      company_name: address.company_name || '',
      attention_to: address.attention_to || '',
      street_address1: address.street_address1 || '',
      street_address2: address.street_address2 || '',
      city: address.city || '',
      state: address.state.id || '',
      zip: address.zip || '',
      phone: address.phone || '',
      account_id: address.account_id || '',
    };

    if (type === 'origin') {
      setOriginAddress(newAddress);
      setOriginAddressModalVisible(false);
    } else {
      setDestinationAddress(newAddress);
      setDestinationAddressModalVisible(false);
    }
  };

  const getParams = ({ outboundQuantity, returnQuantity }) => ({
    shipment: {
      id: row?.id,
      origin_company_name: originAddress.company_name,
      origin_attention_to: originAddress.attention_to,
      origin_street_address1: originAddress.street_address1,
      origin_street_address2: originAddress.street_address2,
      origin_city: originAddress.city,
      origin_state: states.find(state => state.id === parseInt(originAddress.state, 10))?.alpha2,
      origin_zip: originAddress.zip.trim(),
      origin_country: 'US',
      origin_phone: originAddress.phone,
      account_id: originAddress.account_id,
      destination_company_name: destinationAddress.company_name,
      destination_attention_to: destinationAddress.attention_to,
      destination_street_address1: destinationAddress.street_address1,
      destination_street_address2: destinationAddress.street_address2,
      destination_city: destinationAddress.city,
      destination_state: states.find(state => state.id === parseInt(destinationAddress.state, 10))?.alpha2,
      destination_zip: destinationAddress.zip.trim(),
      destination_country: 'US',
      destination_phone: destinationAddress.phone,
      carrier_type: carrier.outbound !== '' ? JSON.parse(carrier.outbound).carrier_details.carrier_type : carrier.outbound,
      carrier_id: carrier.outbound !== '' ? JSON.parse(carrier.outbound).carrier_details.carrier_id : carrier.outbound,
      carrier_service_id: carrierService.outbound,
      is_return: isReturn,
      record_id: null,
      shipment_details_attributes: outboundQuantity ? packages.outbound.map(item => ({ ...item, quantity: outboundQuantity })) : packages.outbound,
      company_id: company,
      is_parent: isParent,
    },
    fedex_formula: fedexFormula,
    outbound_shipment_rate_id: outboundShipmentRateId,
    return_shipment_rate_id: returnShipmentRateId,
    shipment_return: {
      id: packages.return[0].shipment_id,
      carrier_type: carrier.return !== '' ? JSON.parse(carrier.return).carrier_details.carrier_type : carrier.return,
      carrier_id: carrier.return !== '' ? JSON.parse(carrier.return).carrier_details.carrier_id : carrier.return,
      carrier_service_id: carrierService.return,
      shipment_details_attributes: returnQuantity ? { ...packages.return[0], quantity: returnQuantity } : packages.return[0],
    },
  });

  /* eslint-disable camelcase */
  const handleRate = () => {
    if (isFormValid(validate())) {
      setLoading(true);
      api.shipmentDashboard.getRate(getParams({}))
        .then(({ final_price, return_shipment_rate_id, outbound_shipment_rate_id }) => {
          setFinalPrice(final_price);
          setOutboundShipmentRateId(outbound_shipment_rate_id);
          setReturnShipmentRateId(return_shipment_rate_id);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const currentQuantity = parseInt(packages.outbound[0].quantity, 10);
    const currentReturnQuantity = parseInt(packages.return[0].quantity, 10);
    try {
      await api.shipmentDashboard.updateParentShipment(getParams({ outboundQuantity: currentQuantity, returnQuantity: currentReturnQuantity }));
      setLoading(false);
      onUpdate();
    } catch (e) {
      setLoading(false);
    }
  };

  const handleChangePackage = async (e, id, radioValue, type) => {
    const field = e.target.name.split('-')[0];
    if (field === 'package_type_id') {
      packages[type][id][field] = e.target.value;
      if (e.target.value === '') {
        packages[type][id].width = '1';
        packages[type][id].height = '1';
        packages[type][id].length = '1';
      } else {
        const { data } = await api.shipmentDashboard.getPackageType({ package_type_id: e.target.value });
        packages[type][id].width = data.width;
        packages[type][id].height = data.height;
        packages[type][id].length = data.length;
      }
    } else if (field === 'is_special_day_delivery') {
      packages[type][id][field] = !packages[type][id][field];
    } else {
      packages[type][id][field] = radioValue || e.target.value;
    }

    setPackages(packages);
    setErrors({
      ...errors,
      packages: {
        ...errors.packages,
        [type]: {
          ...errors.packages[type],
          [id]: {
            ...errors.packages[type][id],
            [field]: '',
          },
        },
      },
    });
  };

  const setAddressModalOpen = useCallback((mode) => {
    if (!company) {
      errorToast('Please, choose a company first');
      return;
    }

    if (mode === 'origin') setOriginAddressModalVisible(true);
    else setDestinationAddressModalVisible(true);
  }, [company]);

  const handleChangeType = (type) => {
    setIsReturn(type);
    setErrors({
      ...errors,
      packages: {
        outbound: row.shipment_details.reduce((a, v, i) => ({ ...a, [i]: {} }), {}),
        return: { 0: {} },
      },
    });
  };

  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Edit Parent Shipment
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="popup_errors" />
        <input hidden className="shipment_origin_email" />
        <input hidden className="shipment_destination_email" />
        <div className="row">
          <div className="col-sm-4 form-group">
            <label className="checkbox-label">
              <input
                disabled
                type="checkbox"
                id="has_lockbox"
                style={{ marginLeft: '5px' }}
                checked={isParent}
                onChange={() => setIsParent(!isParent)}
              />
              Is Parent?
              <span className="checkmark" />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="company">
              Company
              <span className="required">*</span>
            </label>
            <select
              name="company"
              id="company"
              className="form-control get_company_id get_carriers"
              value={company}
              disabled={finalPrice}
              onChange={(e) => {
                setCompany(e.target.value);
                setErrors({ ...errors, company: '' });
              }}
            >
              <option value="">Select company</option>
              {companyList.map(item => <option key={item[1]} value={item[1]}>{item[0]}</option>)}
            </select>
            {errors?.company && (
              <label className="error">
                {errors.company}
              </label>
            )}
          </div>
        </div>

        <AddressInputs
          errors={errors?.origin || {}}
          buttonTitle="Choose Origin"
          onChange={handleChange}
          onButtonClick={() => setAddressModalOpen('origin')}
          prefix="origin"
          values={originAddress}
          states={states}
          accounts={accountList}
          embed={false}
          disabled={Boolean(finalPrice) || !permission.shipments.customAddresses}
          limits={limits}
        />
        <AddressInputs
          errors={errors?.destination || {}}
          buttonTitle="Choose Destination"
          onChange={handleChange}
          onButtonClick={() => setAddressModalOpen('destination')}
          prefix="destination"
          values={destinationAddress}
          states={states}
          accounts={accountList}
          embed={false}
          disabled={Boolean(finalPrice) || !permission.shipments.customAddresses}
          limits={limits}
        />
        <div className="row radio-mobile">
          <div className="col-xs-4 col-lg-2 form-group full-width">
            <label className="radio-label margin-top5">
              <input
                type="radio"
                name="is_return"
                id="is_return"
                className="is_return_class"
                value={1}
                checked={isReturn === 1}
                disabled={Boolean(finalPrice)}
                onChange={() => handleChangeType(1)}
              />
              Outbound
              <span className="checkmark" />
            </label>
          </div>
          <div className="col-xs-4 col-lg-2 form-group full-width">
            <label className="radio-label margin-top5">
              <input
                type="radio"
                name="is_return"
                id="is_return"
                className="is_return_class"
                value={2}
                checked={isReturn === 2}
                disabled={Boolean(finalPrice)}
                onChange={() => handleChangeType(2)}
              />
              Outbound and Return
              <span className="checkmark" />
            </label>
          </div>
          <div className="col-xs-4 col-lg-2 form-group full-width">
            <label className="radio-label margin-top5">
              <input
                type="radio"
                name="is_return"
                id="is_return"
                className="is_return_class"
                checked={isReturn === 3}
                disabled={Boolean(finalPrice)}
                onChange={() => handleChangeType(3)}
              />
              Return only
              <span className="checkmark" />
            </label>
          </div>
        </div>
        {
          isReturn !== 3 && (
            <Packages
              setCarrier={value => setCarrier({ ...carrier, outbound: value })}
              setErrors={e => setErrors({ ...errors, [e.target.id]: { ...errors[e.target.id], outbound: '' } })}
              packageTypesList={packageTypesList.outbound}
              errors={errors}
              packages={packages.outbound}
              handleChangePack={(e, id, radioValue) => handleChangePackage(e, id, radioValue, 'outbound')}
              setCarrierService={value => setCarrierService({ ...carrierService, outbound: value })}
              carrierServicesList={carrierServicesList.outbound}
              carriersList={outboundCarrierList}
              type="outbound"
              carrier={carrier.outbound}
              carrierService={carrierService.outbound}
              disabled={Boolean(finalPrice)}
            />
          )
        }
        {
          isReturn !== 1 && (
            <Packages
              setCarrier={value => setCarrier({ ...carrier, return: value })}
              setErrors={e => setErrors({ ...errors, [e.target.id]: { ...errors[e.target.id], return: '' } })}
              packageTypesList={packageTypesList.return}
              errors={errors}
              packages={packages.return}
              handleChangePack={(e, id, radioValue) => handleChangePackage(e, id, radioValue, 'return')}
              setCarrierService={value => setCarrierService({ ...carrierService, return: value })}
              carrierServicesList={carrierServicesList.return}
              carriersList={returnCarrierList}
              type="return"
              carrier={carrier.return}
              carrierService={carrierService.return}
              disabled={Boolean(finalPrice)}
            />
          )
        }
        <label className="api_errors error" />
        <div className="form-group final-price-col" style={{ marginTop: '20px' }}>
          {finalPrice && <div className="final_price">{finalPrice}</div>}
          {finalPrice && (
            <button type="button" className="btn mb-30 btn-primary edit_shipment" title="Edit Shipment" onClick={() => setFinalPrice(null)}>
              Edit Shipment
            </button>
          )}
          {finalPrice ? (
            <>
              <button disabled={loading} id="submit-button" type="button" className="btn bg-olive ship_submit_btn" title="Continue" style={{ marginRight: '10px' }} onClick={handleSubmit} data-testid="handle-save">Save</button>
              <button className="btn btn-primary ship_cancel" title="Cancel" onClick={onClose}>Cancel</button>
            </>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button disabled={loading} id="rate-button" type="button" className="btn bg-olive get_rate_btn" title="Continue" style={{ marginRight: '10px' }} onClick={handleRate} data-testid="handle-rate">Continue</button>
              <button className="btn btn-primary continue_cancel" title="Cancel" onClick={onClose}>Cancel</button>
              {currentCarrier === 'Fed-Ex' && (
                <div hidden className="col-sm-12">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="fedex_formula"
                      id="fedex_formula"
                      checked={fedexFormula}
                      disabled={Boolean(finalPrice)}
                      onChange={() => setFedexFormula(!fedexFormula)}
                    />
                    FedEx Formula Pricing
                    <span className="checkmark" />
                  </label>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="print-labels" />
        {originAddressModalVisible && (
          <AddressModal
            handleClose={() => setOriginAddressModalVisible(false)}
            open={originAddressModalVisible}
            onAddressSelect={(type, address) => handleSelectAddress('origin', address)}
            companies={companyList}
            states={states}
            companyId={company}
            countryId={countryId}
            userId={userId}
            defaultTab={0}
            permission={permission}
            limits={limits}
            preferences={preferences}
          />
        )}
        {destinationAddressModalVisible && (
          <AddressModal
            handleClose={() => setDestinationAddressModalVisible(false)}
            open={destinationAddressModalVisible}
            onAddressSelect={(type, address) => handleSelectAddress('destination', address)}
            companies={companyList}
            states={states}
            companyId={company}
            countryId={countryId}
            userId={userId}
            defaultTab={1}
            permission={permission}
            limits={limits}
            preferences={preferences}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

EditParentShipmentModal.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  companyList: PropTypes.instanceOf(Array).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  limits: PropTypes.instanceOf(Object).isRequired,
  defaultData: PropTypes.instanceOf(Object).isRequired,
  countryId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

EditParentShipmentModal.defaultProps = {
  open: false,
};

export default EditParentShipmentModal;
