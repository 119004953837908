import React, {
  useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const ShipmentImportModal = ({
  handleClose, open, handleSelectFile, fedexCarrierList,
}) => {
  const [carrier, setCarrier] = useState();
  const selectFileRef = useRef(null);

  const handleImport = () => {
    selectFileRef.current.click();
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Import Shipments
        <IconButton onClick={handleClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="company_id">
              Fedex Carrier
              <span className="required">*</span>
            </label>
            <select
              name="carrier_id"
              id="carrier_id"
              className="form-control"
              value={carrier}
              onChange={(e) => {
                setCarrier(e.target.value);
              }}
            >
              <option value="">Please select</option>
              {fedexCarrierList.map(item => <option key={item[1]} value={item[1]}>{item[0]}</option>)}
            </select>
          </div>
        </div>
        <div className="modal-bottom-btn text-right">
          <input ref={selectFileRef} type="file" onChange={e => handleSelectFile(e, carrier)} style={{ width: 0, height: 0 }} data-testid="shipment-import-file-input" />
          <button disabled={!carrier} className="btn bg-olive" onClick={handleImport}>Select file</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={handleClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ShipmentImportModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSelectFile: PropTypes.func.isRequired,
  fedexCarrierList: PropTypes.instanceOf(Object).isRequired,
  open: PropTypes.bool,
};

ShipmentImportModal.defaultProps = {
  open: false,
};

export default ShipmentImportModal;
