import React, {
  useCallback, useEffect, useState, useRef, useMemo,
} from 'react';
import { Client } from '@twilio/conversations';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ConversationsContainer from './ConversationsContainer';
import ConversationContainer from './ConversationContainer';
import { actionCreators } from '../../stores';
import { handlePromiseRejection } from '../../helpers';
import twilioApi from '../../services/twilioApi';
import { ConnectionStatus } from '../../constants';

async function handleParticipantsUpdate(
  participant,
  updateParticipants,
) {
  const result = await twilioApi.getConversationParticipants(participant.conversation);
  updateParticipants(result, participant.conversation.sid);
}

async function loadUnreadMessagesCount(
  convo,
  updateUnreadMessages,
) {
  let count = 0;

  try {
    count = (await convo.getUnreadMessagesCount())
      ?? (await convo.getMessagesCount());
  } catch (e) {
    console.error('getUnreadMessagesCount threw an error', e);
  }

  updateUnreadMessages(convo.sid, count);
}

const updateTypingIndicator = (
  participant,
  sid,
  email,
  callback,
) => {
  const {
    attributes: { friendlyName },
    identity,
  } = participant;
  if (identity === email) {
    return;
  }
  callback(sid, identity || friendlyName || '');
};

const TwilioChat = ({
  currentUserEmail, manifestData, roles, systemUsers,
}) => {
  const dispatch = useDispatch();
  const {
    upsertMessages,
    updateLoadingState,
    updateParticipants,
    updateUnreadMessages,
    startTyping,
    endTyping,
    upsertConversation,
    login,
    removeMessages,
    removeConversation,
    updateCurrentConversation,
    addNotifications,
    clearAttachments,
    setEmail,
    setSystemUsers,
  } = bindActionCreators(actionCreators, dispatch);

  const token = useSelector(state => state.token);
  const email = useSelector(state => state.email);
  const [client, setClient] = useState();
  const [clientIteration, setClientIteration] = useState(0);
  const [connectionState, setConnectionState] = useState(ConnectionStatus.Connecting);
  const [loading, setLoading] = useState(true);
  const conversations = useSelector(state => state.convos);
  const sid = useSelector(state => state.sid);
  const sidRef = useRef('');
  sidRef.current = sid;

  const openedConversation = useMemo(
    () => conversations.find(convo => convo.sid === sid),
    [sid, conversations],
  );

  const requestToken = useCallback(async () => {
    if (!currentUserEmail || token) return;

    const data = await twilioApi.getToken(currentUserEmail);

    login(data.token);
    setEmail(currentUserEmail);
  }, [token, currentUserEmail, login]);

  const initClient = useCallback(async () => {
    if (!token || !email) return Promise.resolve(() => {});

    const localClient = new Client(token);
    localClient.on('conversationJoined', (conversation) => {
      upsertConversation(conversation);

      conversation.on('typingStarted', (participant) => {
        handlePromiseRejection(
          () => updateTypingIndicator(participant, conversation.sid, email, startTyping),
          addNotifications,
        );
      });

      conversation.on('typingEnded', (participant) => {
        handlePromiseRejection(
          () => updateTypingIndicator(participant, conversation.sid, email, endTyping),
          addNotifications,
        );
      });

      handlePromiseRejection(async () => {
        if (conversation.status === 'joined') {
          const result = await twilioApi.getConversationParticipants(conversation);
          updateParticipants(result, conversation.sid);

          const messages = await conversation.getMessages();
          upsertMessages(conversation.sid, messages.items);
          await loadUnreadMessagesCount(conversation, updateUnreadMessages);
        }
      }, addNotifications);
    });

    localClient.on('conversationRemoved', (conversation) => {
      updateCurrentConversation('');
      handlePromiseRejection(() => {
        removeConversation(conversation.sid);
        updateParticipants([], conversation.sid);
      }, addNotifications);
    });
    localClient.on('messageAdded', async (message) => {
      await upsertMessage(message, upsertMessages, updateUnreadMessages);
      if (message.author === email) {
        clearAttachments(message.conversation.sid, '-1');
      }
    });
    localClient.on('participantLeft', (participant) => {
      handlePromiseRejection(
        () => handleParticipantsUpdate(participant, updateParticipants),
        addNotifications,
      );
    });
    localClient.on('participantUpdated', (event) => {
      handlePromiseRejection(
        () => handleParticipantsUpdate(event.participant, updateParticipants),
        addNotifications,
      );
    });
    localClient.on('participantJoined', (participant) => {
      handlePromiseRejection(
        () => handleParticipantsUpdate(participant, updateParticipants),
        addNotifications,
      );
    });
    localClient.on('conversationUpdated', ({ conversation }) => {
      handlePromiseRejection(
        () => upsertConversation(conversation),
        addNotifications,
      );
    });

    localClient.on('messageUpdated', ({ message }) => {
      handlePromiseRejection(
        () => upsertMessage(message, upsertMessages, updateUnreadMessages),
        addNotifications,
      );
    });

    localClient.on('messageRemoved', (message) => {
      handlePromiseRejection(
        () => removeMessages(message.conversation.sid, [message]),
        addNotifications,
      );
    });

    localClient.on('tokenAboutToExpire', () => {
      if (currentUserEmail) {
        twilioApi.getToken(currentUserEmail).then((newToken) => {
          client.updateToken(newToken);
          login(newToken);
        });
      }
    });

    localClient.on('tokenExpired', () => {
      if (currentUserEmail) {
        twilioApi.getToken(currentUserEmail).then((newToken) => {
          login(newToken);
          setClientIteration(x => x + 1);
        });
      }
    });

    localClient.on('connectionStateChanged', (state) => {
      setConnectionState(state);
    });

    updateLoadingState(false);
    setClient(localClient);

    return () => {
      localClient?.removeAllListeners();
    };
  }, [token, email, clientIteration]);


  const upsertMessage = async (message, upsertMessagesMethod, updateUnreadMessagesMethod) => {
    // transform the message and add it to redux
    const convo = message.conversation;
    if (sidRef.current === convo.sid || message.author === email) {
      await message.conversation.advanceLastReadMessageIndex(message.index);
      updateUnreadMessages(convo.sid, 0);
    } else {
      await loadUnreadMessagesCount(convo, updateUnreadMessagesMethod);
    }
    upsertMessagesMethod(convo.sid, [message]);
  };

  const renderContent = () => {
    if (loading || connectionState !== ConnectionStatus.Connected) return (<div>Connecting...</div>);

    if (openedConversation) {
      return (
        <ConversationContainer
          conversation={openedConversation}
          client={client}
          roles={roles}
        />
      );
    }

    return <ConversationsContainer />;
  };


  useEffect(requestToken, [requestToken]);
  useEffect(initClient, [initClient]);
  useEffect(() => {
    setSystemUsers(systemUsers);
  }, [systemUsers]);

  useEffect(() => {
    if (connectionState !== ConnectionStatus.Connected) return;

    if (!manifestData.manifest || !manifestData.driver) {
      setLoading(false);
      return;
    }

    const getOrCreateConversation = async (md) => {
      const convoUniqueTitle = `route-${md.manifest.id}`;
      const convoPaginator = await client.getSubscribedConversations();
      const convoForManifest = convoPaginator.items.find(c => c.uniqueName === convoUniqueTitle);
      let currentConvoSID;
      if (!convoForManifest) {
        const res = await twilioApi.createConversationForRoute(md.manifest.id);
        currentConvoSID = res.newConvoSID;
      } else currentConvoSID = convoForManifest.sid;
      updateCurrentConversation(currentConvoSID);
      setLoading(false);
    };

    getOrCreateConversation(manifestData);
  }, [connectionState]);

  return (
    <>
      {renderContent()}
    </>
  );
};

TwilioChat.propTypes = {
  currentUserEmail: PropTypes.string,
  roles: PropTypes.instanceOf(Object).isRequired,
  manifestData: PropTypes.instanceOf(Object),
  systemUsers: PropTypes.instanceOf(Array),
};

TwilioChat.defaultProps = {
  manifestData: {},
  currentUserEmail: '',
  systemUsers: [],
};

export default TwilioChat;
