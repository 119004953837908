import { Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const Horizon = React.forwardRef(
  ({ messageCount }, ref) => (
    <Box
      ref={ref}
      className="horizon"
    >
      <span>
        {messageCount}
        {' '}
        new
        {messageCount > 1 ? ' messages' : ' message'}
      </span>
    </Box>
  ),
);

Horizon.propTypes = {
  messageCount: PropTypes.number,
};

Horizon.defaultProps = {
  messageCount: 0,
};

export default Horizon;
