import React, { useState } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import api from '../../services/api';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { errorToast, successToast } from '../../services/toast';


const GenerationToolCreate = ({ type }) => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [level, setLevel] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleSubmit = () => {
    setOpenConfirm(false);
    let request;
    const payload = {};
    payload[`generate_date_${type.toLowerCase()}`] = date;
    payload[`generate_level_${type.toLowerCase()}`] = level;
    switch (type) {
    case 'RL':
      request = api.routeGenerationTool.generateManifestRL(payload);
      break;
    case 'DL':
      request = api.routeGenerationTool.generateManifestDL(payload);
      break;
    default:
      request = Promise.resolve(() => {});
      break;
    }
    request.then((r) => {
      if (!r.error) {
        successToast(r.message);
      } else {
        errorToast(r.message);
      }
    });
  };

  return (
    <>
      <div className=" route-generation-tools row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">{`Generate ${type} Routes`}</h1>
            </div>
            <div className="form-inline">
              <div className="form-group">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control generate_date"
                  id={`generate_date_${type.toLowerCase()}`}
                  tabIndex="-1"
                  placeholder="Date"
                  value={date}
                  onChange={e => setDate(e.target.value)}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                />
                <label>Level</label>
                <select name={`generate_level_${type.toLowerCase()}`} id={`generate_level_${type.toLowerCase()}`} className="form-control" onChange={e => setLevel(e.target.value)}>
                  <option value="">Select level</option>
                  <option value="level_one">Level 1</option>
                  {type !== 'RL' && (<option value="level_two">Level 2</option>)}
                </select>
                <button className="btn-primary btn" onClick={() => setOpenConfirm(true)}>{`Generate ${type} Route`}</button>
              </div>
            </div>
          </div>
          <ConfirmationDialog
            onConfirm={handleSubmit}
            onClose={() => setOpenConfirm(false)}
            message={`Are you sure you want to create ${type} routes?`}
            open={openConfirm}
          />
        </div>
      </div>
    </>
  );
};

GenerationToolCreate.propTypes = {
  type: PropTypes.string.isRequired,
};

export default GenerationToolCreate;
