import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box, Dialog, DialogContent, IconButton, Tab, Tabs,
} from '@material-ui/core';
import { capitalize } from 'lodash';
import { Clear } from '@material-ui/icons';
import api from '../../services/api';
import SetAddress from './SetAddress';
import ConfirmationDialog from '../common/ConfirmationDialog';
import AddressTableContainer from './AddressTableContainer';
import TabPanel from '../common/TabPanel';
import { updateChangeRowsPerPage } from '../../helpers';
import { PaginationKeys } from '../../constants';
import { ADDRESS_TABLE_FIELDS } from '../../services/helpers';

const AddressModal = ({
  handleClose, open, onAddressSelect, states, defaultTab, limits,
  companies, companyId, countryId, userId, permission, preferences,
}) => {
  const [rowsAddress, setRowsAddress] = useState([]);
  const [rowsWarehouses, setRowsWarehouses] = useState([]);
  const [openSetAddress, setOpenSetAddress] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [mode, setMode] = useState('');
  const [searchQueryWarehouses, setSearchQueryWarehouses] = useState('');
  const [searchQueryAddress, setSearchQueryAddress] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    warehouses: {
      page: 0,
      count: 0,
      limit: window.localStorage.getItem(PaginationKeys.warehouse) || preferences?.records_per_page || 10,
    },
    addresses: {
      page: 0,
      count: 0,
      limit: window.localStorage.getItem(PaginationKeys.address) || preferences?.records_per_page || 10,
    },
  });
  const [tab, setTab] = React.useState(defaultTab);

  const selectFileRef = useRef(null);

  useEffect(() => {
    if (companyId) {
      if (defaultTab) handleFilter({ forceMode: 'address' });
      else handleFilter({ forceMode: 'warehouse' });
    }
  }, [companyId, defaultTab]);

  const handleFilter = useCallback(({
    page, limit, clear, forceMode,
  }) => {
    const currentMode = forceMode || mode;
    const suffix = currentMode === 'warehouse' ? 's' : 'es';
    const search = currentMode === 'warehouse' ? searchQueryWarehouses : searchQueryAddress;
    api.addressBook[`get${capitalize(currentMode)}${suffix}`]({
      page: page !== undefined ? page + 1 : paginationOptions[`${currentMode}${suffix}`].page + 1,
      limit: limit || paginationOptions[`${currentMode}${suffix}`].limit,
      search_field: clear ? '' : search,
      company_id: companyId,
    })
      .then((res) => {
        currentMode === 'warehouse' ? setRowsWarehouses(res.warehouses_data) : setRowsAddress(res.address_book);
        setPaginationOptions({
          ...paginationOptions,
          [`${currentMode}${suffix}`]: {
            count: res.total_records,
            page: page || 0,
            limit: limit || paginationOptions[`${currentMode}${suffix}`].limit,
          },
        });
      })
      .catch(err => console.log(err));
  }, [paginationOptions, searchQueryWarehouses, searchQueryAddress, mode, companyId]);

  const handleChangePage = (e, newPage, forceMode) => {
    handleFilter({ page: newPage, forceMode });
  };

  const handleChangeRowsPerPage = async (e, forceMode) => {
    await updateChangeRowsPerPage(e, PaginationKeys[forceMode], val => handleFilter({ page: 0, limit: val, forceMode }));
  };

  const handleDelete = useCallback(() => {
    api.addressBook[`${mode}Destroy`](selectedRow.id).then(() => {
      setOpenDelete(false);
      handleFilter({});
    });
  }, [mode, selectedRow]);

  const handleImport = () => {
    selectFileRef.current.click();
  };

  const handleSelectFile = useCallback(async (e) => {
    const suffix = mode === 'warehouse' ? 's' : 'es';
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('csv', file);
    formData.append('company_id', companyId);
    api.addressBook[`import${capitalize(mode)}${suffix}`](formData);
    setTimeout(() => handleFilter({ forceMode: mode }), 1000);
  }, [mode]);

  const setProps = index => ({
    id: `ship-tab-${index}`,
    'aria-controls': `ship-tabpanel-${index}`,
  });

  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <Box sx={{ width: '100%' }}>
          <Box sx={{
            borderBottom: 1, borderColor: 'delimiter', display: 'flex', justifyContent: 'space-between',
          }}
          >
            <Tabs
              value={tab}
              onChange={(e, value) => {
                setTab(value);
                handleFilter({ forceMode: value === 1 ? 'address' : 'warehouse' });
              }}
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab label={<span style={{ fontSize: '18px' }}>Warehouse</span>} {...setProps(0)} />
              <Tab label={<span style={{ fontSize: '18px' }}>Address Book</span>} {...setProps(1)} />
            </Tabs>
            <IconButton onClick={handleClose}>
              <Clear />
            </IconButton>
          </Box>
          <TabPanel value={tab} index={0}>
            <AddressTableContainer
              setOpenDelete={setOpenDelete}
              setSearchQuery={setSearchQueryWarehouses}
              handleFilter={handleFilter}
              setOpenSetAddress={setOpenSetAddress}
              handleSelectFile={handleSelectFile}
              selectFileRef={selectFileRef}
              setSelectedRow={setSelectedRow}
              sampleHref="/warehouses_sample.csv"
              searchQuery={searchQueryWarehouses}
              handleImport={handleImport}
              title="Warehouses"
              mode="warehouse"
              setMode={setMode}
              userId={userId}
              permission={permission.warehouse}
              tableParams={{
                rows: rowsWarehouses,
                rowsProps: ADDRESS_TABLE_FIELDS.warehouse,
                paginationOptions: {
                  ...paginationOptions.warehouses,
                  handleChangePage: (e, newPage) => handleChangePage(e, newPage, 'warehouse'),
                  handleChangeRowsPerPage: e => handleChangeRowsPerPage(e, 'warehouse'),
                },
                onRowClick: row => onAddressSelect('warehouse', row),
                suffix: 'warehouse',
              }}
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <AddressTableContainer
              setOpenDelete={setOpenDelete}
              setSearchQuery={setSearchQueryAddress}
              handleFilter={handleFilter}
              setOpenSetAddress={setOpenSetAddress}
              handleSelectFile={handleSelectFile}
              selectFileRef={selectFileRef}
              setSelectedRow={setSelectedRow}
              sampleHref="/address_book_sample.csv"
              searchQuery={searchQueryAddress}
              handleImport={handleImport}
              title="Address Book"
              mode="address"
              setMode={setMode}
              userId={userId}
              permission={permission.address}
              tableParams={{
                rows: rowsAddress,
                rowsProps: ADDRESS_TABLE_FIELDS.address,
                paginationOptions: {
                  ...paginationOptions.addresses,
                  handleChangePage: (e, newPage) => handleChangePage(e, newPage, 'address'),
                  handleChangeRowsPerPage: e => handleChangeRowsPerPage(e, 'address'),
                },
                onRowClick: row => onAddressSelect('address', row),
                suffix: 'address',
              }}
            />
          </TabPanel>
        </Box>

        {openSetAddress && (
          <SetAddress
            mode={mode}
            onClose={() => setOpenSetAddress(false)}
            row={selectedRow}
            states={states}
            companies={companies}
            open={openSetAddress}
            update={handleFilter}
            countryId={countryId}
            userId={userId}
            limits={limits}
          />
        )}
        <ConfirmationDialog
          onConfirm={handleDelete}
          onClose={() => setOpenDelete(false)}
          message={`Are you sure you want to delete this ${mode}?`}
          open={openDelete}
        />
      </DialogContent>
    </Dialog>
  );
};

AddressModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onAddressSelect: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  countryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  open: PropTypes.bool,
  defaultTab: PropTypes.number,
  permission: PropTypes.instanceOf(Object).isRequired,
  companies: PropTypes.instanceOf(Array).isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  limits: PropTypes.instanceOf(Object).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

AddressModal.defaultProps = {
  open: false,
  defaultTab: 0,
};

export default AddressModal;
