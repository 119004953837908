import React, {
  useCallback, useEffect, useState, useLayoutEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Toast from '../common/Toast';
import api from '../../services/api';
import { isPickupFormValid, checkPickupFormForErrors } from '../../services/helpers';
import { errorToast } from '../../services/toast';
import AddressInputs from '../shipments_dashboard/AddressInputs';
import AddressModal from '../shipments_dashboard/AddressModal';
import SelectDays from '../common/SelectDays';

const NewPickup = ({
  companyList, defaultData, radiusFlexId,
  states, countryId, userId, limits, permission,
  isSchedule, timezoneFromServer, isAdmin, preferences,
}) => {
  // eslint-disable-next-line
  let [errors, setErrors] = useState({});
  const [originAddress, setOriginAddress] = useState({});
  const [originRecordInfo, setOriginRecordInfo] = useState({});
  const [nearestAirport, setNearestAirport] = useState({});
  const [company, setCompany] = useState('');
  const [carrier, setCarrier] = useState('');
  const [courierInstructions, setCourierInstructions] = useState('');
  const [carrierService, setCarrierService] = useState('');
  const [accountList, setAccountList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [carrierList, setCarrierList] = useState([]);
  const [carrierServicesList, setCarrierServicesList] = useState([]);
  const [packageTypesList, setPackageTypesList] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [pack, setPack] = useState({
    weight_calculated_in: 1,
    package_type_id: '',
    quantity: '',
    weight: '1',
  });
  const [originAddressModalVisible, setOriginAddressModalVisible] = useState(false);
  const [isUps, setIsUps] = useState(false);
  const [isFedex, setIsFedex] = useState(false);
  const [isOnCall, setIsOnCall] = useState(false);
  const [test, setTest] = useState(false);
  const [certainty, setCertainty] = useState(false);
  const [hasLockbox, setHasLockbox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serverTimezone, setServerTimezone] = useState('America/Chicago');
  const overrideCutoffTimeRef = useRef();

  useEffect(() => {
    const tz = timezoneFromServer?.info?.identifier;
    if (tz) {
      setServerTimezone(tz);
    } else {
      setServerTimezone('America/Chicago');
    }
  }, [timezoneFromServer]);


  useLayoutEffect(() => {
    setTimeout(() => {
      $(overrideCutoffTimeRef.current).datetimepicker({
        format: 'hh:mm a',
        ignoreReadonly: true,
        icons: {
          time: 'fa fa-clock-o',
          date: 'fa fa-calendar',
          up: 'fa fa-arrow-up',
          down: 'fa fa-arrow-down',
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-clock-o',
          clear: 'fa fa-trash-o',
        },
      });
    }, 10);
  }, [overrideCutoffTimeRef.current]);

  useEffect(() => {
    if (defaultData?.warehouse_data) {
      const data = defaultData?.warehouse_data;
      setOriginAddress({
        company_name: data.company_name,
        attention_to: data.attention_to,
        street_address1: data.street_address1,
        street_address2: data.street_address2,
        city: data.city,
        state: data.state_id,
        zip: data.zip,
        phone: data.phone,
        cutoffTime: data.cutoff_time,
      });
      setOriginRecordInfo({
        record_id: data.id,
        type: 'warehouse',
      });
      setCompany(data.company_id || '');
    }
    if (!company) return;
    if (defaultData?.user_carrier) {
      setCarrier(JSON.stringify({ carrier_details: { carrier_type: defaultData.user_carrier.carrier_type, carrier_id: defaultData.user_carrier.id } }));
    }
    if (defaultData?.user_carrier_service) {
      setCarrierService(defaultData.user_carrier_service.id);
    }
    let defaultPack = { ...pack };
    if (defaultData?.user_package_type) {
      defaultPack = { ...defaultPack, package_type_id: defaultData.user_package_type.id };
    }
    if (defaultData?.user_unit) {
      defaultPack = { ...defaultPack, weight_calculated_in: defaultData.user_unit.id };
    }
    setPack(defaultPack);
  }, [defaultData, company]);

  useEffect(async () => {
    if (!company) {
      setAccountList([]);
      setCarrierList([]);
      setCarrierServicesList([]);
      setPackageTypesList([]);
      setCustomerList([]);
    } else {
      await api.addressBook.getUserAccounts({ company_id: company }).then((res) => {
        setAccountList(res.accounts);
      });
      await api.addressBook.getCustomers({ company_id: company }).then((res) => {
        setCustomerList(res.customers);
      });
    }

    if (company && originAddress.zip) {
      const carriers = (await api.shipmentDashboard.getCarriers({ company_id: company })).data.return_carriers_r;
      const { result } = await api.pickupsDashboard.availableCarriers({ origin_zip: originAddress.zip });

      setCarrierList(setAvailableCarriers(result, carriers));
    }

    if (company && !originAddress.zip) {
      api.shipmentDashboard.getCarriers({ company_id: company }).then((res) => {
        setCarrierList(setAvailableCarriers(false, res.data.return_carriers_r));
        setCarrierServicesList([]);
        setPackageTypesList([]);
      });
    }
  }, [company, originAddress.zip]);

  useEffect(async () => {
    if (originAddress.street_address1 && originAddress.city && originAddress.state && originAddress.zip) {
      const { airport } = await api.pickupsDashboard.nearestAirport({
        origin_street_address1: originAddress.street_address1,
        origin_city: originAddress.city,
        origin_state: states.find(state => state.id === parseInt(originAddress.state, 10))?.alpha2,
        origin_zip: originAddress.zip,
      });

      setNearestAirport(airport);
    }
  }, [originAddress]);

  const setAvailableCarriers = (result, list) => {
    if (!result) {
      return list.filter(item => item.id !== radiusFlexId);
    }
    if (!list.find(item => item.id === radiusFlexId)) {
      return [...list, {
        id: radiusFlexId,
        name: 'Radius Flex Logistics',
      }];
    }
    return list;
  };

  useEffect(() => {
    if (!carrier) {
      setCarrierServicesList([]);
      setPackageTypesList([]);
      return;
    }
    carrier && api.shipmentDashboard.getCarrierServicesAndPackageTypes({ company_id: company, carrier_type: JSON.parse(carrier).carrier_details.carrier_type, pickup_only: true }).then((res) => {
      setCarrierServicesList(res.return_carrier_services_r);
      setPackageTypesList(res.package_types_r);
    }).catch(error => console.log(error));
  }, [carrier, company, originAddress.zip]);

  const validateForm = useCallback(() => {
    const pickupDate = isSchedule ? null : document.getElementById('pickup_date').value;
    const readyTime = document.getElementById('pickup_ready_time').value;
    const closeTime = document.getElementById('pickup_close_time').value;
    const overrideCutoffTime = overrideCutoffTimeRef.current?.value;
    let carrier_id;
    if (carrier) {
      const parsedCarrier = JSON.parse(carrier);
      // eslint-disable-next-line prefer-destructuring
      carrier_id = parsedCarrier.carrier_details.carrier_id;
    }
    const checkResults = checkPickupFormForErrors({
      company_id: company,
      carrier_id,
      carrier_service_id: carrierService,
      selectedDays,
    }, originAddress, {
      pickupDate,
      readyTime,
      closeTime,
      addressCutoffTime: originAddress.cutoffTime,
      overrideCutoffTime,
      serverTimezone,
    }, pack, radiusFlexId, isSchedule, nearestAirport);
    setErrors({ ...checkResults });
    return checkResults;
  }, [originAddress, carrier, carrierService, company, errors, pack, nearestAirport, selectedDays]);

  const handleChange = (e) => {
    const { id: field, value } = e.target;

    if (field === 'account_id' || field === 'customer_id') {
      setOriginAddress({
        ...originAddress,
        [field]: value,
      });
    } else {
      setOriginAddress({
        ...originAddress,
        cutoffTime: null,
        [field]: value,
      });
      setOriginRecordInfo({});
    }

    setErrors({ ...errors, origin: { ...errors.origin, [field]: '' } });
  };

  const handleSelectAddress = (type, address) => {
    const newAddress = {
      company_name: address.company_name || '',
      attention_to: address.attention_to || '',
      street_address1: address.street_address1 || '',
      street_address2: address.street_address2 || '',
      city: address.city || '',
      state: address.state.id || '',
      zip: address.zip || '',
      phone: address.phone || '',
      account_id: address.account_id || '',
      cutoffTime: address.cutoff_time || undefined,
      customer_id: address.customer_id || '',
    };

    setOriginAddress(newAddress);
    setOriginRecordInfo({
      record_id: address.id || null,
      type,
    });
    setOriginAddressModalVisible(false);
    setHasLockbox(address.has_lockbox);
  };

  const getParams = () => ({
    pickup: {
      origin_company_name: originAddress.company_name,
      origin_attention_to: originAddress.attention_to,
      origin_street_address1: originAddress.street_address1,
      origin_street_address2: originAddress.street_address2,
      origin_city: originAddress.city,
      origin_state: states.find(state => state.id === parseInt(originAddress.state, 10))?.alpha2,
      origin_zip: originAddress.zip.trim(),
      origin_country: 'US',
      origin_phone: originAddress.phone,
      origin_record_info: originRecordInfo,
      override_cutoff: overrideCutoffTimeRef.current?.value,
      account_id: originAddress.account_id,
      customer_id: originAddress.customer_id,
      carrier_id: carrier !== '' ? JSON.parse(carrier).carrier_details.carrier_id : carrier,
      carrier_type: carrier !== '' ? JSON.parse(carrier).carrier_details.carrier_type : carrier,
      carrier_service_id: carrierService,
      company_id: company,
      pickup_date: document.getElementById('pickup_date')?.value,
      ready_time: document.getElementById('pickup_ready_time').value,
      close_time: document.getElementById('pickup_close_time').value,
      courier_instructions: courierInstructions,
      schedule_days: selectedDays,
      pickup_certainty: certainty,
      is_test: test,
      is_ups: isUps,
      is_fedex: isFedex,
      is_on_call: isOnCall,
      has_lockbox: hasLockbox,
      pickup_details_attributes: {
        0: pack,
      },
    },
  });

  const handleSubmit = () => {
    if (isPickupFormValid(validateForm())) {
      setLoading(true);
      const prefix = isSchedule ? 'schedule' : 'create';
      api.pickupsDashboard[`${prefix}Pickup`](getParams()).then(() => {
        setLoading(false);
        window.location.href = isSchedule ? '/admin/scheduled_pickups' : '/admin/pickup_dashboard/all_pickups';
      }).catch((e) => {
        setLoading(false);
        console.log(e.message);
      });
    }
  };

  const handleChangePackage = async (e, radioValue) => {
    const field = e.target.name.split('-')[0];

    pack[field] = radioValue || e.target.value;

    setPack(pack);
    setErrors({
      ...errors,
      pack: {
        ...errors.pack,
        [field]: '',
      },
    });
  };

  const handleCancel = () => {
    window.history.back();
  };

  const setAddressModalOpen = useCallback(() => {
    if (!company) {
      errorToast('Please, choose a company first');
      return;
    }

    setOriginAddressModalVisible(true);
  }, [company]);

  const onSelectedDaysChange = useCallback((value) => {
    if (selectedDays.includes(value)) {
      setSelectedDays(selectedDays.filter(day => day !== value));
    } else {
      setSelectedDays([...selectedDays, value]);
    }
    setErrors({ ...errors, scheduleDays: '' });
  }, [selectedDays]);

  const handleSetCarrier = useCallback(() => {
    const err = {};

    Object.keys(originAddress).forEach((key) => {
      if (!['street_address2', 'attention_to', 'customer_id', 'cutoffTime'].includes(key) && !originAddress[key]) err[key] = 'Please enter value';
    });
    if (Object.keys(err).some(key => !isEmpty(err[key]))) {
      errorToast('Please, set the origin address with account');
    }
  }, [company, originAddress]);

  return (
    <div>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">{`${isSchedule ? 'Schedule' : 'Add'} Pickup`}</h1>
            </div>
            <div className="box-body">
              <div className="row">
                <div className="col-sm-6 form-group">
                  <label htmlFor="company">
                    Company
                    <span className="required">*</span>
                  </label>
                  <select
                    name="company"
                    id="company"
                    className="form-control get_company_id get_carriers"
                    value={company}
                    onChange={(e) => {
                      setCompany(e.target.value);
                      setErrors({ ...errors, company: '' });
                    }}
                  >
                    <option value="">Please select</option>
                    {companyList.map(item => <option key={item[1]} value={item[1]}>{item[0]}</option>)}
                  </select>
                  {errors?.company && (
                    <label className="error">
                      {errors.company}
                    </label>
                  )}
                </div>
              </div>
              <AddressInputs
                errors={errors?.origin || {}}
                buttonTitle="Choose Origin"
                onChange={handleChange}
                onButtonClick={() => setAddressModalOpen()}
                prefix="origin"
                values={originAddress}
                states={states || []}
                limits={limits}
                accounts={accountList}
                customers={customerList}
                disabled={isSchedule || !permission.pickups?.customAddresses}
                mode="pickup"
              />

              <div className="row">
                <div className="col-sm-6 form-group">
                  <label htmlFor="carrier_id">
                    Carrier
                    <span className="required">*</span>
                  </label>
                  <select
                    name="carrier_id"
                    id="carrier_id"
                    className="form-control fill_carriers"
                    value={carrier}
                    onChange={(e) => {
                      setCarrier(e.target.value);
                      setErrors({ ...errors, carrier_id: '' });
                    }}
                    onClick={() => handleSetCarrier()}
                  >
                    <option value="">Please select</option>
                    {carrierList?.map(item => <option key={item.id} value={JSON.stringify({ carrier_details: { carrier_type: item.carrier_type, carrier_id: item.id } })}>{item.name}</option>)}
                  </select>
                  {errors?.carrier_id && (
                    <label className="error">
                      {errors.carrier_id}
                    </label>
                  )}
                </div>
                <div className="col-sm-6 form-group carrier_service">
                  <label htmlFor="carrier_service_id">
                    Carrier Service
                    <span className="required">*</span>
                  </label>
                  <select
                    name="carrier_service_id"
                    id="carrier_service_id"
                    className="form-control fill_carrier_services"
                    value={carrierService}
                    onChange={(e) => {
                      setCarrierService(e.target.value);
                      setErrors({ ...errors, carrier_service_id: '' });
                    }}
                  >
                    <option value="">Please select</option>
                    {carrierServicesList.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                  </select>
                  {errors?.carrier_service_id && (
                    <label className="error">
                      {errors.carrier_service_id}
                    </label>
                  )}
                </div>
              </div>

              <div className="shipment_first_block_clss">
                <div className="fields">
                  <div className="row">
                    <div className="col-sm-6 form-group">
                      <label htmlFor="quantity">
                        Quantity
                        <span className="required">*</span>
                      </label>
                      <input
                        name="quantity"
                        id="quantity"
                        className="form-control static_quantity"
                        maxLength={15}
                        value={pack.quantity || ''}
                        onChange={e => handleChangePackage(e)}
                      />
                      {errors?.pack?.quantity && (
                        <label className="error">
                          {errors?.pack?.quantity}
                        </label>
                      )}
                    </div>
                    <div className="col-sm-6 form-group">
                      <label htmlFor="package_type_id">
                        Package Type
                        <span className="required">*</span>
                      </label>
                      <select
                        name="package_type_id"
                        id="package_type_id"
                        className="form-control fill_carrier_services"
                        value={pack.package_type_id || ''}
                        onChange={e => handleChangePackage(e)}
                      >
                        <option value="">Please select</option>
                        {packageTypesList.map(packageType => <option key={packageType.id} value={packageType.id}>{packageType.name}</option>)}
                      </select>
                      {errors?.pack?.package_type_id && (
                        <label className="error">
                          {errors?.pack?.package_type_id}
                        </label>
                      )}
                    </div>
                    <div className="col-sm-6 weight_block">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <label htmlFor="weight">
                            Weight
                            <span className="required">*</span>
                          </label>
                          <input
                            name="weight"
                            id="weight"
                            className="form-control"
                            maxLength={15}
                            value={pack.weight || ''}
                            onChange={e => handleChangePackage(e)}
                          />
                          {errors?.pack?.weight && (
                            <label className="error">
                              {errors?.pack?.weight}
                            </label>
                          )}
                        </div>
                        <div className="col-sm-6 media-radio">
                          <div className="col-xs-4 col-md-5 padding-zero">
                            <label className="radio-label margin-top30">
                              <input
                                type="radio"
                                name="weight_calculated_in"
                                id="weight_calculated_in_lbs"
                                checked={pack.weight_calculated_in === 1}
                                onChange={e => handleChangePackage(e, 1)}
                              />
                              Lbs
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="col-xs-8 col-md-7">
                            <label className="radio-label margin-top30">
                              <input
                                type="radio"
                                name="weight_calculated_in"
                                id="weight_calculated_in_lbs"
                                checked={pack.weight_calculated_in === 2}
                                onChange={e => handleChangePackage(e, 2)}
                              />
                              Kgs
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {!isSchedule && (
                    <div className="col-sm-4 form-group">
                      <label htmlFor="pickup_date">
                        Pickup Date
                        <span className="required">*</span>
                      </label>
                      <div className="admin-datepicker">
                        <input
                          name="pickup_date"
                          id="pickup_date"
                          className="form-control static_quantity"
                          readOnly
                        />
                      </div>
                      {errors?.pickupDate && (
                        <label className="error">
                          {errors?.pickupDate}
                        </label>
                      )}
                    </div>
                  )}

                  <div className="col-sm-4 form-group">
                    <label htmlFor="pickup_ready_time">
                      Ready Time
                      <span className="required">*</span>
                    </label>
                    <div className="admin-datepicker">
                      <input
                        name="ready_time"
                        id="pickup_ready_time"
                        className="form-control dimensions length"
                        readOnly
                      />
                    </div>
                    {errors?.readyTime && (
                      <label className="error">
                        {errors?.readyTime}
                      </label>
                    )}
                  </div>
                  <div className="col-sm-4 form-group">
                    <label htmlFor="pickup_close_time">
                      Close Time
                      <span className="required">*</span>
                    </label>
                    <div className="admin-datepicker">
                      <input
                        name="close_time"
                        id="pickup_close_time"
                        className="form-control dimensions length"
                        readOnly
                      />
                    </div>
                    {errors?.closeTime && (
                      <label className="error">
                        {errors?.closeTime}
                      </label>
                    )}
                  </div>

                  {isAdmin && (
                    <div className="col-sm-4 form-group">
                      <label htmlFor="override_cutoff_time">
                        Override Cutoff Time
                      </label>
                      <div className="admin-datepicker">
                        <input
                          ref={overrideCutoffTimeRef}
                          name="override_cutoff_time"
                          id="override_cutoff_time"
                          className="form-control dimensions length"
                          readOnly
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-sm-4 form-group">
                    <label htmlFor="courier_instructions">
                      Courier Instructions
                    </label>
                    <textarea
                      name="courier_instructions"
                      id="courier_instructions"
                      className="form-control"
                      style={{ height: '150px', padding: '5px' }}
                      value={courierInstructions}
                      onChange={e => setCourierInstructions(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4 form-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        id="has_lockbox"
                        style={{ marginLeft: '5px' }}
                        checked={hasLockbox}
                        onChange={() => setHasLockbox(!hasLockbox)}
                      />
                      Has Lockbox?
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-sm-4 form-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        id="pickup_certainty"
                        style={{ marginLeft: '5px' }}
                        checked={certainty}
                        onChange={() => setCertainty(!certainty)}
                      />
                      Confirmed?
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-sm-4 form-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        id="pickup_certainty"
                        style={{ marginLeft: '5px' }}
                        checked={test}
                        onChange={() => setTest(!test)}
                      />
                      Is tests?
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
              </div>
              {isSchedule && (
                <div className="form-group" style={{ marginTop: '20px' }}>
                  <label htmlFor="scheduleDays">
                    Schedule days
                    <span className="required">*</span>
                  </label>
                  <SelectDays id="scheduleDays" onChange={day => onSelectedDaysChange(day)} value={selectedDays} />
                  {errors?.scheduleDays && (
                    <label className="error">
                      {errors.scheduleDays}
                    </label>
                  )}
                </div>
              )}

              {permission.pickups?.flagsPermission
              && (
                <div className="row">
                  <div className="col-sm-4 form-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        id="is_ups"
                        style={{ marginLeft: '5px' }}
                        checked={isUps}
                        onChange={() => setIsUps(!isUps)}
                      />
                      Is UPS?
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-sm-4 form-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        id="is_fedex"
                        style={{ marginLeft: '5px' }}
                        checked={isFedex}
                        onChange={() => setIsFedex(!isFedex)}
                      />
                      Is FEDEX?
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-sm-4 form-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        id="is_on_call"
                        style={{ marginLeft: '5px' }}
                        checked={isOnCall}
                        onChange={() => setIsOnCall(!isOnCall)}
                      />
                      Is OnCall?
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
              )}

              <div className="form-group" style={{ marginTop: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <button
                    disabled={loading}
                    id="rate-button"
                    type="button"
                    className="btn bg-olive"
                    title="Continue"
                    style={{ marginRight: '10px' }}
                    onClick={handleSubmit}
                  >
                    {`${isSchedule ? 'Schedule' : 'Add'} Pickup`}
                  </button>
                  <button className="btn btn-primary continue_cancel" title="Cancel" onClick={handleCancel}>Cancel</button>
                </div>
              </div>
              {originAddressModalVisible && (
                <AddressModal
                  handleClose={() => setOriginAddressModalVisible(false)}
                  open={originAddressModalVisible}
                  onAddressSelect={(type, address) => handleSelectAddress(type, address)}
                  companies={companyList}
                  states={states}
                  companyId={company}
                  countryId={countryId}
                  userId={userId}
                  defaultTab={1}
                  permission={permission}
                  limits={limits}
                  preferences={preferences}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NewPickup.propTypes = {
  defaultData: PropTypes.instanceOf(Object).isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
  countryId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  companyList: PropTypes.instanceOf(Array).isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  radiusFlexId: PropTypes.number.isRequired,
  limits: PropTypes.instanceOf(Object).isRequired,
  isAdmin: PropTypes.bool,
  isSchedule: PropTypes.bool,
  timezoneFromServer: PropTypes.instanceOf(Object).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

NewPickup.defaultProps = {
  isSchedule: false,
  isAdmin: false,
};

export default NewPickup;
