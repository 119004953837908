import React from 'react';
import PropTypes from 'prop-types';
import CustomTable from '../common/Table';

const AddressTableContainer = ({
  selectFileRef, handleSelectFile, handleImport,
  setMode, mode, title, sampleHref,
  setSelectedRow, setOpenSetAddress, searchQuery,
  setSearchQuery, handleFilter, tableParams,
  setOpenDelete, style, userId, permission,
}) => (
  <div id="warehouses_table_modal" style={style}>
    <div className="address-title">{title}</div>
    <div>
      <input ref={selectFileRef} type="file" onChange={handleSelectFile} style={{ width: 0, height: 0 }} />
      <button
        style={{ marginLeft: 0 }}
        className="btn add-btn prevent_right_click add_warehouses_from_file"
        onClick={() => {
          handleImport();
          setMode(mode);
        }}
      >
        Upload From File
      </button>
      <a href={sampleHref} className="btn bg-olive margin add-btn" style={{ marginRight: '10px' }}>Download Template</a>
      <button
        className="btn add-btn pull-right prevent_right_click add_address_class_new"
        onClick={() => {
          setSelectedRow({});
          setOpenSetAddress(true);
          setMode(mode);
        }}
      >
        Add New Address
      </button>
    </div>

    <div className="pickup-search-input input-group">
      <div className="input-group">
        <input
          className="form-control warehouse_pickup_search"
          placeholder="Search"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleFilter({ forceMode: mode });
          }}
        />
        <button
          type="submit"
          className="search-clear"
          onClick={() => {
            setSearchQuery('');
            setMode(mode);
            handleFilter({ clear: true, forceMode: mode });
          }}
        >
          <i className="fa fa-times" />
        </button>
      </div>
      <button
        type="submit"
        className="btn btn-primary pickup_search_button"
        onClick={() => {
          setMode(mode);
          handleFilter({ forceMode: mode });
        }}
      >
        <i className="fa fa-search" />
      </button>
    </div>
    <CustomTable
      {...tableParams}
      formatters={{
        actions: value => (
          <div className="text-center" style={{ minWidth: '70px' }}>
            {((value.user_id !== userId && permission.editOthers) || (value.user_id === userId && permission.editMine)) && (
              <>
                <button
                  className="fa fa-edit space-left-right action-button"
                  onClick={() => {
                    setOpenSetAddress(true);
                    setMode(mode);
                    setSelectedRow(value);
                  }}
                />
                <button
                  className="fa fa-trash delete-warehouse action-button"
                  onClick={() => {
                    setOpenDelete(true);
                    setMode(mode);
                    setSelectedRow(value);
                  }}
                />
              </>
            )}
          </div>
        ),
        user: user => <span>{user ? `${user.first_name} ${user.last_name}` : ''}</span>,
      }}
    />
  </div>
);

AddressTableContainer.propTypes = {
  selectFileRef: PropTypes.instanceOf(Object).isRequired,
  handleSelectFile: PropTypes.func.isRequired,
  handleImport: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sampleHref: PropTypes.string.isRequired,
  setSelectedRow: PropTypes.func.isRequired,
  setOpenSetAddress: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  tableParams: PropTypes.instanceOf(Object).isRequired,
  style: PropTypes.instanceOf(Object),
  setOpenDelete: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

AddressTableContainer.defaultProps = {
  style: {},
};

export default AddressTableContainer;
