import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import api from '../../services/api';
import GetAddressesModal from './GetAddressesModal';
import CreateRoute from './NewRouteModal';

const GenerateRL2Route = ({
  allClustersCount, defaultLimit, googleMapsKey, preferences,
}) => {
  const [rows, setRows] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    page: 0,
    count: 0,
    limit: preferences?.records_per_page || 10,
  });
  const [selectedRow, setSelectedRow] = useState({});
  const [openGetAddresses, setOpenGetAddresses] = useState(false);
  const [openCreateRoute, setOpenCreateRoute] = useState(false);

  useEffect(() => {
    setPaginationOptions({
      ...paginationOptions,
      count: allClustersCount,
      limit: defaultLimit,
    });
  }, [allClustersCount, defaultLimit]);

  useEffect(() => {
    handleFilter();
  }, []);

  const handleFilter = useCallback((page, limit) => {
    api.routesDashboard.index({
      page: page !== undefined ? page + 1 : paginationOptions.page + 1,
      limit: limit || paginationOptions.limit,
    })
      .then((res) => {
        setRows(res.clusters);
        setPaginationOptions({
          count: res.all_records,
          page: page || 0,
          limit: limit || paginationOptions.limit,
        });
      })
      .catch(err => console.log(err));
  }, [paginationOptions]);


  // const handleChangePage = (e, newPage) => {
  //   handleFilter(newPage);
  // };
  //
  // const handleChangeRowsPerPage = (e) => {
  //   handleFilter(0, parseInt(e.target.value, 10));
  // };

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Generate RL2 Route</h1>
              <div className="pull-right">
                <button
                  className="btn bg-olive"
                  onClick={() => setOpenCreateRoute(true)}
                >
                  Create New Route
                </button>
              </div>
            </div>
            <div className="box-body main_listing">
              <CustomTable
                rows={rows}
                formatters={{
                  actions: value => (
                    <div className="text-center" style={{ minWidth: '70px' }}>
                      <button
                        className="fa fa-edit space-left-right action-button"
                        onClick={() => {
                          setOpenGetAddresses(true);
                          setSelectedRow(value);
                        }}
                      />
                    </div>
                  ),
                }}
                rowsProps={[
                  { header: '#', key: 'id' },
                  { header: 'Zip', key: 'zip' },
                  { header: 'Airport Code', key: 'airport_code' },
                  { header: 'Geo Lat', key: 'geo_lat_lvl2' },
                  { header: 'Geo Lon', key: 'geo_lon_lvl2' },
                  { header: 'Region Id', key: 'region_id_lvl2' },
                  { header: 'Actions', key: 'actions', format: true },
                ]}
                // paginationOptions={{
                //   ...paginationOptions,
                //   handleChangePage,
                //   handleChangeRowsPerPage,
                // }}
                suffix="clusters"
              />
            </div>
          </div>
        </div>
      </div>
      {openGetAddresses && (
        <GetAddressesModal
          handleClose={() => setOpenGetAddresses(false)}
          clusterId={selectedRow.id}
          open={openGetAddresses}
          googleMapsKey={googleMapsKey}
        />
      )}
      {openCreateRoute && (
        <CreateRoute
          handleClose={() => setOpenCreateRoute(false)}
          open={openCreateRoute}
          onSubmit={() => {
            handleFilter();
            setOpenCreateRoute(false);
          }}
        />
      )}
    </>
  );
};

GenerateRL2Route.propTypes = {
  allClustersCount: PropTypes.number.isRequired,
  defaultLimit: PropTypes.number.isRequired,
  googleMapsKey: PropTypes.string.isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

export default GenerateRL2Route;
