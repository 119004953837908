import { participantsMap } from '../../conversationObject';

import ActionType from '../action-types';

const initialState = {};

const reduxifyParticipant = participant => ({
  sid: participant.sid,
  attributes: participant.attributes,
  identity: participant.identity,
  type: participant.type,
  lastReadMessageIndex: participant.lastReadMessageIndex,
});

const reducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
  case ActionType.UPDATE_PARTICIPANTS: {
    const { participants, sid } = action.payload;

    for (const participant of participants) {
      participantsMap.set(participant.sid, participant);
    }

    return Object.assign({}, state, {
      [sid]: participants.map(reduxifyParticipant),
    });
  }
  default:
    return state;
  }
};

export default reducer;
