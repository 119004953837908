import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import TwilioChat from './TwilioChat';
import { store } from '../../stores';
import Toast from '../common/Toast';

const TwilioChatContainer = ({
  currentUserEmail, manifestData, roles, systemUsers,
}) => (
  <Provider store={store}>
    <Toast />
    <TwilioChat currentUserEmail={currentUserEmail} manifestData={manifestData} roles={roles} systemUsers={systemUsers} />
  </Provider>
);

TwilioChatContainer.propTypes = {
  currentUserEmail: PropTypes.string,
  manifestData: PropTypes.instanceOf(Object),
  roles: PropTypes.instanceOf(Object).isRequired,
  systemUsers: PropTypes.instanceOf(Array),
};

TwilioChatContainer.defaultProps = {
  manifestData: null,
  currentUserEmail: '',
  systemUsers: [],
};

export default TwilioChatContainer;
