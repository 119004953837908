import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import api from '../../services/api';
import ConfirmationDialog from '../common/ConfirmationDialog';
import SwitchPickupModal from '../pickups_dashboard/SwitchPickupModal';
import PickupDetailsModal from '../pickups_dashboard/PickupDetailsModal';
import AddAirportStopModal from './AddAirportStopModal';
import BagSummaryModal from './BagSummaryModal';
import { errorToast } from '../../services/toast';
import MyMapComponent from '../common/Map';
import UploadPhotoModal from '../common/UploadPhotoModal';
import SetPositionModal from './SetPositionModal';
import CertaintyIcon from '../common/CertaintyIcon';
import PickupTestIcon from '../common/PickupTestIcon';
import StatusDropdown from '../common/StatusDropdown';
import CreateConsolidationBagModal from './CreateConsolidationBagModal';
import { getIsTestModalConfirmMessage, getPickupRowStyle, RL_ROUTE_PICKUPS_FIELDS } from '../../services/helpers';
import FieldsModal from '../common/FieldsModal';
import AddRL2StopModal from './AddRL2StopModal';


const RLRoute = ({
  propsRoute, consolidationLabels, googleMapsKey, timezoneFromServer, permission, userId, radiusFlexId,
  driverList, stops, routeList,
}) => {
  const [rows, setRows] = useState(stops);
  const [routes, setRoutes] = useState(routeList);
  const [drivers, setDrivers] = useState(driverList);
  const [airports, setAirports] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [directions, setDirections] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(propsRoute.driver_id);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSwitch, setOpenSwitch] = useState(false);
  const [openSet, setOpenSet] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openLockBox, setOpenLockBox] = useState(false);
  const [openAddAirportStop, setOpenAddAirportStop] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [optimizeWaypoints, setOptimizeWaypoints] = useState(true);
  const [openUploadPhoto, setOpenUploadPhoto] = useState(false);
  const [openCertainty, setOpenCertainty] = useState(false);
  const [openTest, setOpenTest] = useState(false);
  const [route, setRoute] = useState(propsRoute);
  const [openCreateConsolidationBagModal, setOpenCreateConsolidationBagModal] = useState(false);
  const [renderMap, setRenderMap] = useState(true);
  const [serverTimezone, setServerTimezone] = useState('America/Chicago');
  const [bags, setBags] = useState([]);
  const [openBagInfo, setOpenBagInfo] = useState(false);
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [rl2Stops, setRl2Stops] = useState([]);
  const [openAddRl2Stop, setOpenAddRl2Stop] = useState(false);
  const [positionChanged, setPositionChanged] = useState(false);
  const [stopIds, setStopIds] = useState({});

  useEffect(() => {
    const obj = {};
    rows.forEach((r) => { obj[r.route_manifest_stops[0].id] = r; });
    setStopIds(obj);
  }, [rows]);

  useEffect(() => {
    const tz = timezoneFromServer?.info?.identifier;
    if (tz) {
      setServerTimezone(tz);
    } else {
      setServerTimezone('America/Chicago');
    }
  }, [timezoneFromServer]);

  useEffect(() => {
    if (rows.length > 0) {
      const points = [...rows].filter(p => !!p.geolocation && !!p.geolocation.latitude && !!p.geolocation.longitude);
      if (['RL1', 'RL2'].includes(route.route_manifest_type)) {
        const firstPoint = points.shift();
        const lastPoint = points.length ? points.pop() : null;

        const processedMarkers = [
          {
            position: { lat: firstPoint?.geolocation?.latitude, lng: firstPoint?.geolocation?.longitude },
            tip: firstPoint?.origin_company_name,
            color: 'green',
          },
          ...points.map(point => ({
            position: { lat: point?.geolocation?.latitude, lng: point?.geolocation?.longitude },
            tip: point?.origin_company_name,
            color: 'blue',
          })),
        ];
        if (lastPoint) {
          processedMarkers.push({
            position: { lat: lastPoint.geolocation?.latitude, lng: lastPoint.geolocation?.longitude },
            tip: lastPoint.origin_company_name,
            color: 'red',
          });
        }

        setMarkers(processedMarkers);

        const start = `${rows[0]?.geolocation?.latitude},${rows[0]?.geolocation?.longitude}`;
        const end = `${rows[rows.length - 1]?.geolocation?.latitude},${rows[rows.length - 1]?.geolocation?.longitude}`;
        const waypoints = rows.map(item => ({
          location: `${item?.geolocation?.latitude},${item?.geolocation?.longitude}`,
        }));

        setDirections({ start, end, waypoints });
      } else {
        setMarkers([
          {
            position: { lat: rows[0]?.geolocation?.latitude, lng: rows[0]?.geolocation?.longitude },
            tip: rows[0]?.origin_company_name,
            color: 'green',
          },
          {
            position: { lat: rows[1]?.geolocation?.latitude, lng: rows[1]?.geolocation?.longitude },
            tip: rows[1]?.origin_company_name,
            color: 'red',
          },
        ]);
      }
    }
  }, [rows, optimizeWaypoints, routes]);

  const updateData = (routeId) => {
    setRenderMap(false);
    api.routesDashboard.showRoute(routeId).then((res) => {
      setRenderMap(true);
      setRoute(res.route_manifest);
      setRows(res.stops);
      setRoutes(res.routes);
      setDrivers(res.drivers);
      setSelectedDriver(res.route_manifest.driver_id);
    });
  };

  const handleDelete = useCallback(async () => {
    await submitPositionChanges();
    api.routesDashboard.destroyStop({ id: selectedRow.route_manifest_stops[0].id }).then(() => {
      setOpenDelete(false);
      setSelectedRow({});
      updateData(route.id);
    });
  }, [selectedRow]);

  const handleSwitch = useCallback(async (routeId) => {
    await submitPositionChanges();
    api.pickupsDashboard.switchPickup({ id: selectedRow.id, route_id: routeId }).then(() => {
      setOpenSwitch(false);
      setSelectedRow({});
      updateData(route.id);
    });
  }, [selectedRow]);

  const handleSetLockBox = useCallback(async () => {
    await submitPositionChanges();
    api.pickupsDashboard.updatePickup(selectedRow.id, { has_lockbox: !selectedRow.has_lockbox }).then(() => {
      setOpenLockBox(false);
      setSelectedRow({});
      updateData(route.id);
    });
  }, [selectedRow]);

  const handleSetCertainty = useCallback(async () => {
    await submitPositionChanges();
    api.pickupsDashboard.updatePickup(selectedRow.id, { pickup_certainty: !selectedRow.pickup_certainty }).then(() => {
      setOpenCertainty(false);
      setSelectedRow({});
      updateData(route.id);
    });
  }, [selectedRow]);

  const handleSetApproved = useCallback(async () => {
    await submitPositionChanges();
    api.pickupsDashboard.updatePickup(selectedRow.id, { approved: !selectedRow.approved }).then(() => {
      setSelectedRow({});
      updateData(route.id);
    });
  }, [selectedRow]);

  const handleSetTest = useCallback(async () => {
    await submitPositionChanges();
    api.pickupsDashboard.updatePickup(selectedRow.id, { is_test: !selectedRow.is_test }).then(() => {
      setOpenTest(false);
      setSelectedRow({});
      updateData(route.id);
    });
  }, [selectedRow]);

  const handleChangePosition = useCallback((pickup, direction) => {
    const newPosition = direction === 'up' ? pickup.route_manifest_stops[0].position - 1 : pickup.route_manifest_stops[0].position + 1;
    updatePosition(pickup, newPosition);
  }, [rows, stopIds]);


  const handleSetPosition = useCallback(async (newPosition) => {
    await updatePosition(selectedRow, newPosition);
    setSelectedRow({});
  }, [selectedRow, rows, route, stopIds]);

  const updatePosition = async (pickup, newPosition) => {
    const currentId = pickup.route_manifest_stops[0].id;
    const reorderedStops = rows.filter(row => (row.route_manifest_stops[0].id) !== currentId).map(row => row.route_manifest_stops[0].id);
    reorderedStops.splice(newPosition - 1, 0, currentId);
    setRows(reorderedStops.map((s, idx) => {
      const stop = stopIds[s];
      stop.route_manifest_stops[0].position = idx + 1;
      return stop;
    }));
    setPositionChanged(true);
  };

  const submitPositionChanges = async () => {
    if (positionChanged) {
      await api.routesDashboard.updatePositions({
        manifest_id: route.id,
        reordered_stops: rows.map(r => r.route_manifest_stops[0].id),
      });
      setPositionChanged(false);
    }
  };

  const handleSubmit = useCallback(async () => {
    await submitPositionChanges();
    api.routesDashboard.updateRoute(route.id, { route_manifest: { driver_id: selectedDriver } });
  }, [selectedDriver, positionChanged, rows]);

  const handleOpenAddRL2Stop = () => {
    api.routesDashboard.getAirportStops(propsRoute.date).then((res) => {
      setRl2Stops(res.stops);
      if (res.stops.length > 0) setOpenAddRl2Stop(true);
      else errorToast('There are no available stops!');
    });
  };

  const handleOpenAddAirportStop = () => {
    api.routesDashboard.getAirports().then((res) => {
      setAirports(res.airports);
      if (res.airports.length > 0) setOpenAddAirportStop(true);
      else errorToast('List of enabled airports is empty!');
    });
  };

  const handleAddAirportStop = async (airportId) => {
    await submitPositionChanges();
    api.routesDashboard.setAirportStop({ airport_id: airportId, route_manifest_id: route.id }).then(() => {
      setOpenAddAirportStop(false);
      updateData(route.id);
    });
  };

  const handleAddRL2Stop = async (pickupId) => {
    await submitPositionChanges();
    api.pickupsDashboard.switchPickup({ id: pickupId, route_id: route.id }).then(() => {
      setOpenAddRl2Stop(false);
      updateData(route.id);
    });
  };

  const handleUpdateStatus = async (id, status) => {
    await submitPositionChanges();
    api.pickupsDashboard.updatePickup(id, { status }).then(() => {
      updateData(route.id);
    });
  };

  const handleDirectionInfo = useCallback(() => {
    if (route.route_details) localStorage.setItem('optimizedRoute', route.route_details.routes[0].waypoint_order);
  }, [route]);
  /* eslint-disable no-plusplus, no-await-in-loop */
  // eslint-disable-next-line no-unused-vars
  const handleOptimize = useCallback(async () => {
    const rawOptimizedRoutes = localStorage.getItem('optimizedRoute');
    const optimizedRoutes = rawOptimizedRoutes.split(',');
    let stopId = 0;
    let newPosition = 0;
    let i;
    let refresh = false;
    for (i = 0; i <= optimizedRoutes.length - 1; i++) {
      if (parseInt(optimizedRoutes[i], 10) !== i) {
        refresh = true;
        stopId = rows[i].route_manifest_stops[0].id;
        newPosition = parseInt(optimizedRoutes[i], 10) + 1;
        await api.routesDashboard.changePosition({
          position: newPosition,
          current_stop: stopId,
          next_stop: null,
        });
      }
    }
    if (refresh) updateData(route.id);
  }, [rows]);

  const shouldHide = () => {
    if (rows.length < 2) return { display: 'none' };
    return {};
  };

  const getFields = () => {
    const defaultArray = [
      { header: 'Company', key: 'company.name' },
      { header: 'Carrier', key: 'carrier.name' },
      { header: 'Ready Time', key: 'ready_time' },
      { header: 'Close Time', key: 'close_time' },
      { header: 'Cutoff', key: 'actual_cutoff_time' },
      { header: 'Origin Cutoff', key: 'origin_cutoff' },
      { header: 'Pickup Confirmation Number', key: 'pickup_confirmation_number' },
      { header: 'Origin', key: 'string_origin_new' },
      { header: 'Created At', key: 'created_at' },
      {
        header: 'Qty', key: 'packages_count', clickSkip: true, format: true,
      },
      {
        header: 'Status', key: 'status', format: true, clickSkip: true,
      },
      { header: 'Position', key: 'position', format: true },
      { header: 'Tracking ids', key: 'tracking_ids', format: true },
      { header: 'Has Lockbox', key: 'has_lockbox' },
      { header: 'Scheduled', key: 'is_scheduled' },
      {
        header: 'Confirmed', key: 'pickup_certainty', format: true, clickSkip: true,
      },
      {
        header: 'Approved', key: 'approved', format: true, clickSkip: true,
      },
      {
        header: 'Test', key: 'is_test', format: true, clickSkip: true,
      },
    ];
    if (permission.routeManifest.edit) {
      defaultArray.push({
        header: 'Change Position', key: 'change_position', format: true, clickSkip: true,
      });
    }
    if (permission.pickup.editMine || permission.pickup.editOthers) {
      defaultArray.push({
        header: 'Actions', key: 'actions', format: true, clickSkip: true,
      });
    }
    return defaultArray;
  };

  const loadConsolidationBags = async () => {
    // eslint-disable-next-line no-shadow
    const bags = [];
    for (const code of consolidationLabels) {
      const response = await api.pickupsDashboard.consolidationBagInfo(code);
      if (response.status) {
        bags.push({ code, ...response });
      } else {
        errorToast(response.error || response.message || 'Unknown error');
      }
    }
    setBags(bags);
    if (bags.length > 0) setOpenBagInfo(true);
  };

  const handleUpdateFields = (updatedFields, save = true) => {
    setSelectedFields(getFields().filter(f => updatedFields.includes(f.key)));
    setShowFieldsModal(false);
    if (save) window.localStorage.setItem(RL_ROUTE_PICKUPS_FIELDS, updatedFields.join(','));
  };

  useEffect(() => {
    const savedFields = window.localStorage.getItem(RL_ROUTE_PICKUPS_FIELDS);
    if (!savedFields) {
      setSelectedFields(getFields());
      return;
    }

    handleUpdateFields(savedFields.split(','), false);
  }, []);

  const submitCreateConsolidationBagModal = async (trackingCode, packageCodes) => {
    if (trackingCode.length === 0) return { ok: false, message: 'Please, enter tracking code' };
    if (packageCodes.length === 0) return { ok: false, message: 'Please, enter package code' };
    const codes = packageCodes.split(',').map(item => item.trim());
    let status = true;
    let message = '';
    await Promise.all(codes.map(async (code) => {
      await api.pickupsDashboard.addToConsolidationBag({
        bag_code: trackingCode,
        package_code: code,
      }).then((response) => {
        // eslint-disable-next-line no-bitwise
        status &= response.status;
        if (!response.status) {
          message += response.message;
        }
      }).catch((response) => {
        // eslint-disable-next-line no-bitwise
        status &= response.status;
        if (!response.status) {
          message += `${response.message}; `;
        }
      });
    }));
    if (message.length === 0 && status) {
      message = 'Success';
    } else {
      message = `Errors: ${message}`;
    }
    return { ok: status, message };
  };

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 1 }}>
                <h1 className="box-title">{`${route.id} - Route ${route.route_manifest_type}`}</h1>
                <div id="total-distance">{`Total distance: ${route.mileage}`}</div>
                <div id="total-time">{`Time without traffic: ${route.route_time}`}</div>
                {positionChanged && (<div style={{ fontWeight: 'bold', color: 'red' }}>Stop order was updated, don&apos;t forget to save changes!</div>)}
              </div>
              <div>
                <button className="btn btn-primary" onClick={() => setShowFieldsModal(true)}>Change selected fields</button>
                {consolidationLabels.length > 0 && (
                  <button
                    onClick={() => loadConsolidationBags()}
                    className="btn btn-primary"
                    style={{ marginLeft: '5px' }}
                  >
                    Consolidation bags
                  </button>
                )}
              </div>
              <div>
                <button
                  onClick={() => setOpenCreateConsolidationBagModal(true)}
                  className="btn btn-primary"
                >
                  Create consolidation bag
                </button>
              </div>
            </div>
            <div className="box-body main_listing">
              <CustomTable
                rows={rows || []}
                formatters={{
                  packages_count: value => (value.pickup_details && value.pickup_details.length ? value.pickup_details[0].quantity : 0),
                  created_at: value => moment(value).format('DD MMM YYYY'),
                  ready_time: value => moment(value).tz(serverTimezone).format('hh:mm A z'),
                  close_time: value => moment(value).tz(serverTimezone).format('hh:mm A z'),
                  actual_cutoff_time: value => (value ? moment(value).tz(serverTimezone).format('hh:mm A z') : ''),
                  pickup_confirmation_number: value => <span>{value?.toUpperCase()}</span>,
                  has_lockbox: value => value && <div className="text-center"><i className="fa fa-check" aria-hidden="true" /></div>,
                  is_scheduled: value => value && <div className="text-center"><i className="fa fa-check" aria-hidden="true" /></div>,
                  pickup_certainty: value => (
                    <CertaintyIcon
                      certainty={value.pickup_certainty}
                      onClick={() => {
                        if (!permission.pickup?.editOthers && !(permission.pickup?.editMine && value.user_id === userId)) return;

                        setSelectedRow(value);
                        setOpenCertainty(true);
                      }}
                    />
                  ),
                  approved: value => (
                    <div>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          disabled={(!permission.pickup?.editOthers && !(permission.pickup?.editMine && value.user_id === userId))}
                          style={{ marginLeft: '5px' }}
                          checked={value.approved}
                          onChange={() => {
                            setSelectedRow(value);
                            handleSetApproved();
                          }}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  ),
                  is_test: value => (
                    <PickupTestIcon
                      test={value.is_test}
                      onClick={() => {
                        if (!permission.pickup?.editOthers && !(permission.pickup?.editMine && value.user_id === userId)) return;

                        setSelectedRow(value);
                        setOpenTest(true);
                      }}
                    />
                  ),
                  status: ({ id, user_id, status }) => (
                    <div style={{ minWidth: '150px' }}>
                      {(permission.pickup?.editOthers || (permission.pickup?.editMine && user_id === userId)) ? (
                        <StatusDropdown status={status} id={id} handleUpdateStatus={handleUpdateStatus} />
                      ) : (status || '')}
                    </div>
                  ),
                  tracking_ids: value => value?.scanned_tracking_ids?.map(item => (<div key={item.tracking_code}>{item.tracking_code}</div>)),
                  position: ({ route_manifest_stops }) => <div className="text-center">{route_manifest_stops && route_manifest_stops[0] ? route_manifest_stops[0]?.position : '-'}</div>,
                  change_position: value => (
                    <div className="text-center" style={{ minWidth: '70px' }}>
                      {value.route_manifest_stops[0]?.position > 1 && (
                        <button
                          type="button"
                          className="close"
                          onClick={() => handleChangePosition(value, 'up')}
                        >
                          &#x25B2;
                        </button>
                      )}
                      {value.route_manifest_stops[0]?.position !== rows.length && (
                        <button
                          type="button"
                          className="close"
                          onClick={() => handleChangePosition(value, 'down')}
                        >
                          &#x25BC;
                        </button>
                      )}
                    </div>
                  ),
                  actions: value => (
                    <div className="text-center" style={{ minWidth: '100px' }}>
                      {(permission.pickup?.editOthers || (permission.pickup?.editMine && value.user_id === userId)) && (
                        <>
                          <button
                            onClick={() => {
                              setSelectedRow(value);
                              setOpenDelete(true);
                            }}
                            className="fa fa-times edit_user"
                            style={{ border: 'none', backgroundColor: 'transparent' }}
                          />
                          <button
                            onClick={() => {
                              setSelectedRow(value);
                              setOpenSwitch(true);
                            }}
                            className="fa fa-exchange edit_user"
                            style={{ border: 'none', backgroundColor: 'transparent' }}
                          />
                          <button
                            data-testid="lockbox-icon"
                            onClick={() => {
                              setSelectedRow(value);
                              setOpenLockBox(true);
                            }}
                            className={value.has_lockbox ? 'fa fa-lock edit_user' : 'fa fa-unlock-alt edit_user'}
                            style={{ border: 'none', backgroundColor: 'transparent' }}
                          />
                          <button
                            onClick={() => {
                              setSelectedRow(value);
                              setOpenUploadPhoto(true);
                            }}
                            className="fa fa-cloud-upload edit_user"
                            style={{ border: 'none', backgroundColor: 'transparent' }}
                          />
                        </>
                      )}
                      {(permission.pickup?.editOthers || (permission.pickup?.editMine && value.user_id === userId)) && permission.routeManifest.edit && (
                        <button
                          onClick={() => {
                            setSelectedRow(value);
                            setOpenSet(true);
                          }}
                          className="btn btn-primary"
                          style={shouldHide()}
                        >
                          Set Position
                        </button>
                      )}
                    </div>
                  ),
                }}
                rowsProps={selectedFields}
                suffix="stops"
                rowStyle={row => getPickupRowStyle(row, serverTimezone)}
                onRowClick={(row) => {
                  setOpenDetails(true);
                  setSelectedRow(row);
                }}
              />
              <div style={{ marginTop: '20px' }} />
              { renderMap && (
                <MyMapComponent
                  markers={markers}
                  {...(markers.length < 26 ? {
                    routes: [],
                    directions,
                    directionsCallback: handleDirectionInfo,
                    optimizeWaypoints,
                    directionsDetails: route.route_details,
                  } : {})}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&v=3.exp&libraries=geometry,drawing,places`}
                />
              )}
              {permission.routeManifest.edit && (
                <>
                  <div className="row" style={{ marginTop: '20px' }}>
                    <div className="col-sm-4 form-group">
                      <div>
                        Reassign Driver
                        <span className="required">*</span>
                      </div>
                      <select className="form-control" value={selectedDriver} onChange={e => setSelectedDriver(e.target.value)}>
                        <option key="default" value="">Please select</option>
                        {drivers.map(driver => (
                          <option key={driver[1]} value={driver[1]}>{driver[0]}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="modal-bottom-btn text-right">
                    {route.route_manifest_type === 'RL2' && (
                      <button
                        onClick={handleOpenAddRL2Stop}
                        className="btn add-btn"
                        style={{ marginRight: '10px' }}
                      >
                        Add RL2 Stop
                      </button>
                    )}
                    <button
                      onClick={handleOpenAddAirportStop}
                      className="btn add-btn"
                      style={{ marginRight: '10px' }}
                    >
                      Add Airport Stop
                    </button>
                    <button
                      onClick={handleSubmit}
                      className="btn bg-olive"
                    >
                      Update Route Manifest
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        onConfirm={handleDelete}
        onClose={() => setOpenDelete(false)}
        message="Are you sure you want to delete this pickup?"
        open={openDelete}
      />
      <ConfirmationDialog
        onConfirm={handleSetLockBox}
        onClose={() => setOpenLockBox(false)}
        message={selectedRow.has_lockbox ? 'Are you sure you want to remove lockbox from this pickup?' : 'Are you sure you want to add lockbox for this pickup?'}
        open={openLockBox}
      />
      <ConfirmationDialog
        onConfirm={handleSetCertainty}
        onClose={() => setOpenCertainty(false)}
        message={selectedRow.pickup_certainty ? 'Are you sure you want to unconfirm this pickup?' : 'Are you sure you want to confirm this pickup?'}
        open={openCertainty}
      />
      <ConfirmationDialog
        onConfirm={handleSetTest}
        onClose={() => setOpenTest(false)}
        message={getIsTestModalConfirmMessage(selectedRow.is_test)}
        open={openTest}
      />
      <SwitchPickupModal
        open={openSwitch}
        handleClose={() => setOpenSwitch(false)}
        handleConfirm={handleSwitch}
        pickupId={selectedRow.id}
        allowRl2={selectedRow.carrier_id === radiusFlexId}
      />
      <BagSummaryModal
        permission={permission}
        handleClose={() => setOpenBagInfo(false)}
        bags={bags}
        open={openBagInfo}
        handleUpdate={loadConsolidationBags}
      />
      {openDetails && (
        <PickupDetailsModal
          handleClose={() => setOpenDetails(false)}
          data={selectedRow}
          open={openDetails}
          serverTimezone={serverTimezone}
          googleMapsKey={googleMapsKey}
          permission={permission}
          userId={userId}
        />
      )}
      {openAddAirportStop && (
        <AddAirportStopModal
          handleClose={() => setOpenAddAirportStop(false)}
          airports={airports}
          open={openAddAirportStop}
          handleSubmit={handleAddAirportStop}
        />
      )}
      {openAddRl2Stop && (
        <AddRL2StopModal
          handleClose={() => setOpenAddRl2Stop(false)}
          stops={rl2Stops}
          open={openAddRl2Stop}
          handleSubmit={handleAddRL2Stop}
        />
      )}
      {openUploadPhoto && (
        <UploadPhotoModal
          handleClose={() => setOpenUploadPhoto(false)}
          open={openUploadPhoto}
          callback={() => updateData(route.id)}
          pickupId={selectedRow.id}
        />
      )}
      {openSet && (
        <SetPositionModal
          handleClose={() => setOpenSet(false)}
          open={openSet}
          onSubmit={handleSetPosition}
          maxValue={rows.length}
          currentValue={rows.indexOf(selectedRow) + 1}
        />
      )}
      {
        showFieldsModal && <FieldsModal open={showFieldsModal} fields={getFields()} selectedFields={selectedFields} updateFields={handleUpdateFields} handleClose={() => setShowFieldsModal(false)} />
      }
      {openCreateConsolidationBagModal && (
        <CreateConsolidationBagModal
          handleClose={() => setOpenCreateConsolidationBagModal(false)}
          open
          onSubmit={(trackingCode, packageCodes) => submitCreateConsolidationBagModal(trackingCode, packageCodes)}
        />
      )}
    </>
  );
};

RLRoute.propTypes = {
  propsRoute: PropTypes.instanceOf(Object).isRequired,
  consolidationLabels: PropTypes.instanceOf(Array),
  googleMapsKey: PropTypes.string.isRequired,
  timezoneFromServer: PropTypes.instanceOf(Object).isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
  userId: PropTypes.number.isRequired,
  radiusFlexId: PropTypes.number.isRequired,
  driverList: PropTypes.instanceOf(Array),
  stops: PropTypes.instanceOf(Array),
  routeList: PropTypes.instanceOf(Array),
};

RLRoute.defaultProps = {
  consolidationLabels: [],
  driverList: [],
  stops: [],
  routeList: [],
};

export default RLRoute;
