import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import CustomTable from '../common/Table';
import SendEmailModal from './SendEmailModal';
import TrackingDetailsModal from './TrackDetailsModal';
import QRShipmentTrackingIdModal from './QRShipmentTrackingIdModal';
import ConfirmationDialog from '../common/ConfirmationDialog';
import api from '../../services/api';
import { printZplLabels, sortHelper } from '../../services/helpers';
import { errorToast, successToast } from '../../services/toast';
import EditParentShipmentModal from './EditParentShipmentModal';
import { updateChangeRowsPerPage } from '../../helpers';

const ActualShipmentsTable = ({
  rows, selectedFields, serverTimezone, isAdmin, handleFilter, selectedRows, setSelectedRows, paginationOptions, suffix,
  states, limits, defaultData, countryId, userId, permission, companyList, sortable, order, setOrder, preferences, paginationKey,
}) => {
  const SHIPMENT_TYPES = ['Outbound', 'Outbound and Return', 'Return'];
  const [qrCodeTrackingId, setQrCodeTrackingId] = useState('');
  const [showVoidLabel, setShowVoidLabel] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [shipmentId, setShipmentId] = useState();
  const [trackingDetails, setTrackingDetails] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const handlePrintZPL = async (row) => {
    const res = await api.shipmentDashboard.bulkActions({
      bulk_action: 'print_zpl',
      ids: [row],
      controller_name: 'shipments',
    });

    printZplLabels(res);
  };


  const handleVoidLabel = useCallback(() => {
    setShowVoidLabel(false);
    api.shipmentDashboard.voidLabel(showVoidLabel).then(() => {
      handleFilter({});
      successToast('Request for refund submitted. This label has been voided.');
    }).catch(res => errorToast(res.error_message || 'Something went wrong'));
  }, [showVoidLabel]);


  const handleDelete = useCallback(() => {
    api.shipmentDashboard.destroy(selectedRow.id).then(() => {
      setSelectedRow(null);
      setOpenDelete(false);
      handleFilter({});
    });
  }, [selectedRow]);


  const handleTrack = (id) => {
    api.shipmentDashboard.track(id).then((res) => {
      if (res.status) {
        setTrackingDetails({ shipment: res.shipment, tracking_id: res.tracking_id });
      } else {
        errorToast('Something went wrong');
      }
    });
  };

  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };

  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, paginationKey, val => handleFilter({ page: 0, limit: val }));
  };

  const handleSort = (field) => {
    const updatedOrder = { ...sortHelper(field, order) };
    setOrder(updatedOrder);
  };

  return (
    <>
      <CustomTable
        rows={rows}
        formatters={{
          type: value => (value ? SHIPMENT_TYPES[value.is_return - 1] : ''),
          created_at: value => moment.tz(value, serverTimezone).format('DD MMM YYYY'),
          ship_date: value => <span>{value ? moment.tz(value, serverTimezone).format('DD MMM YYYY') : '-'}</span>,
          estimated_ship_date: value => <span>{value ? moment.tz(value, serverTimezone).format('DD MMM YYYY') : '-'}</span>,
          user: value => <div className="text-center" style={{ minWidth: '70px' }}>{`${value?.first_name || ''} ${value?.last_name || ''}`}</div>,
          actions: value => (
            <div className="text-center" style={{ minWidth: '70px' }}>
              {value?.zpl_file_attached && <button onClick={() => handlePrintZPL(value?.id)} className="fa fa-print pdf-icon" style={{ border: 'none', backgroundColor: 'transparent' }} />}
              {value?.pdf_file_attached && <a href={`/admin/shipments/${value?.id}/show_html_label`} className="fa fa-file-picture-o pdf-icon" target="_blank" rel="noreferrer" />}
              {value?.html_file_attached && <a href={`/admin/shipments/${value?.id}/show_pdf_label`} className="fa fa-file-pdf-o pdf-icon" target="_blank" rel="noreferrer" />}
              <a href={`/admin/shipments/${value?.id}`} className="fa fa-eye edit_user" />
              {value?.carrier_service?.carrier_type === 'UPS' && !value.is_parent && <button onClick={() => setQrCodeTrackingId(value.tracking_id)} className="fa fa-qrcode" style={{ border: 'none', backgroundColor: 'transparent' }} /> }
              { isAdmin && (
                <>
                  {Boolean(value?.shipengine_shipment?.label_id) && value.status !== 'void_label' && (
                    <i
                      className="fa fa-ban pdf-icon"
                      aria-hidden="true"
                      onClick={() => {
                        setShowVoidLabel(value.shipengine_shipment.label_id);
                      }}
                    />
                  )}
                  {!value.is_parent && (
                    <i
                      className="fa fa-paper-plane edit_user"
                      aria-hidden="true"
                      onClick={() => {
                        setShowEmailModal(true);
                        setShipmentId(value?.id);
                      }}
                    />
                  )}

                </>
              )}
              {value?.tracking_id && !value.is_parent && <i onClick={() => handleTrack(value.tracking_id)} style={{ color: '#285b8d' }} className="fa fa-truck" aria-hidden="true" />}
              {!value.outbound_id && value.is_parent && permission.shipments?.editParent && (
                <button
                  className="fa fa-edit action-button"
                  onClick={() => {
                    setOpenEdit(true);
                    setSelectedRow(value);
                  }}
                  data-testid={`edit-${value.id}`}
                />
              )}
              {!value.children_size && value.is_parent && permission.shipments?.editParent && (
                <button
                  className="fa fa-trash action-button"
                  onClick={() => {
                    setOpenDelete(true);
                    setSelectedRow(value);
                  }}
                />
              )}
            </div>
          ),
        }}
        rowsProps={selectedFields}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        sortable={sortable}
        handleSort={handleSort}
        sortOptions={order}
        paginationOptions={{
          ...paginationOptions,
          handleChangePage,
          handleChangeRowsPerPage,
        }}
        suffix={suffix}
      />
      {
        showEmailModal && <SendEmailModal shipmentId={shipmentId} handleClose={() => setShowEmailModal(false)} />
      }
      {
        trackingDetails && <TrackingDetailsModal open handleClose={() => setTrackingDetails(null)} trackingDetails={trackingDetails} />
      }
      {
        qrCodeTrackingId && <QRShipmentTrackingIdModal open trackingId={qrCodeTrackingId} handleClose={() => setQrCodeTrackingId('')} />
      }
      {openEdit
        && (
          <EditParentShipmentModal
            embed
            userId={userId}
            permission={permission}
            countryId={countryId}
            defaultData={defaultData}
            companyList={companyList}
            states={states}
            limits={limits}
            onUpdate={() => {
              setOpenEdit(false);
              handleFilter({});
            }}
            row={selectedRow}
            onClose={() => setOpenEdit(false)}
            open={openEdit}
            preferences={preferences}
          />
        )
      }
      {openDelete && (
        <ConfirmationDialog
          onConfirm={handleDelete}
          onClose={() => setOpenDelete(false)}
          message="Are you sure you want to delete this record?"
          open={openDelete}
        />
      )}
      <ConfirmationDialog
        onConfirm={handleVoidLabel}
        onClose={() => setShowVoidLabel(false)}
        message="Are you sure you want to refund this label?"
        open={Boolean(showVoidLabel)}
      />
    </>
  );
};

ActualShipmentsTable.propTypes = {
  rows: PropTypes.instanceOf(Array),
  selectedFields: PropTypes.instanceOf(Array),
  serverTimezone: PropTypes.string,
  isAdmin: PropTypes.bool,
  handleFilter: PropTypes.func,
  selectedRows: PropTypes.instanceOf(Array),
  setSelectedRows: PropTypes.func,
  paginationOptions: PropTypes.instanceOf(Object).isRequired,
  suffix: PropTypes.string.isRequired,
  states: PropTypes.instanceOf(Array),
  limits: PropTypes.instanceOf(Object),
  defaultData: PropTypes.instanceOf(Object),
  countryId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  permission: PropTypes.instanceOf(Object),
  companyList: PropTypes.instanceOf(Array),
  sortable: PropTypes.bool,
  order: PropTypes.instanceOf(Object),
  preferences: PropTypes.instanceOf(Object),
  setOrder: PropTypes.func,
  paginationKey: PropTypes.string.isRequired,
};

ActualShipmentsTable.defaultProps = {
  rows: [],
  selectedFields: [],
  serverTimezone: 'America/Chicago',
  isAdmin: false,
  handleFilter: () => {},
  setSelectedRows: () => {},
  selectedRows: [],
  sortable: false,
  order: { field: '', type: '' },
  setOrder: () => {},
  states: [],
  limits: {},
  defaultData: {},
  companyList: [],
  permission: {},
  preferences: {},
};

export default ActualShipmentsTable;
