import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import Toast from '../common/Toast';
import api from '../../services/api';
import ActualShipmentsTable from './ActualShipmentsTable';
import { PARENT_SHIPMENT_FIELDS } from '../../services/helpers';
import FieldsModal from '../common/FieldsModal';
import { PaginationKeys } from '../../constants';

const ParentShipments = ({
  newShipmentPath,
  shipments, allLabelsCount, companyList, states, limits, defaultData, countryId, userId, permission,
  defaultLimit, timezoneFromServer, isAdmin, preferences,
}) => {
  const getFields = () => [
    { header: 'Type', key: 'type', format: true },
    {
      header: 'Created by', key: 'user', sortKey: 'users.first_name', sortable: true,
    },
    {
      header: 'Estimated Ship Date', key: 'estimated_ship_date', sortKey: 'shipments.estimated_ship_date', sortable: true,
    },
    {
      header: 'Origin Company Name', key: 'origin_company_name', sortKey: 'shipments.origin_company_name', sortable: true,
    },
    {
      header: 'Origin Attention To', key: 'origin_attention_to', sortKey: 'shipments.origin_attention_to', sortable: true,
    },
    {
      header: 'Origin Street Address', key: 'origin_street_address1', sortKey: 'shipments.origin_street_address1', sortable: true,
    },
    {
      header: 'Origin City', key: 'origin_city', sortKey: 'shipments.origin_city', sortable: true,
    },
    {
      header: 'Origin State', key: 'origin_state', sortKey: 'shipments.origin_state', sortable: true,
    },
    {
      header: 'Destination Company Name', key: 'destination_company_name', sortKey: 'shipments.destination_company_name', sortable: true,
    },
    {
      header: 'Destination Attention To', key: 'destination_attention_to', sortKey: 'shipments.destination_attention_to', sortable: true,
    },
    {
      header: 'Destination Street Address', key: 'destination_street_address1', sortKey: 'shipments.destination_street_address1', sortable: true,
    },
    {
      header: 'Destination City', key: 'destination_city', sortKey: 'shipments.destination_city', sortable: true,
    },
    {
      header: 'Destination State', key: 'destination_state', sortKey: 'shipments.destination_state', sortable: true,
    },
    {
      header: 'Created At', key: 'created_at', sortKey: 'shipments.created_at', sortable: true,
    },
    { header: 'Carrier Service', key: 'carrier_service.name', sortable: true },
    { header: 'Actions', key: 'actions', format: true },
  ];
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || parseInt(window.localStorage.getItem(PaginationKeys.parentShipments), 10) || preferences?.records_per_page || 10,
  });
  const [selectedCompany, setCompany] = useState(urlSearchParams.filter_company || '');
  const [search, setSearch] = useState(urlSearchParams.search_field || '');
  const [shipmentByDay, setShipmentByDay] = useState(urlSearchParams.estimated_ship_day || '');
  const [shipmentCreateDay, setShipmentCreateDay] = useState(urlSearchParams.created_day || '');
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState(getFields());
  const [serverTimezone, setServerTimezone] = useState('America/Chicago');
  const [order, setOrder] = useState({ field: urlSearchParams.sort || 'id', type: urlSearchParams.direction || 'desc' });

  useEffect(() => {
    const tz = timezoneFromServer?.info?.identifier;
    if (tz) {
      setServerTimezone(tz);
    } else {
      setServerTimezone('America/Chicago');
    }
  }, [timezoneFromServer]);

  useEffect(() => {
    setRows(shipments);
    setPaginationOptions({
      ...paginationOptions,
      count: allLabelsCount,
      limit: defaultLimit,
    });
  }, [shipments, allLabelsCount]);

  useEffect(() => {
    handleFilter({});
  }, [shipmentByDay, shipmentCreateDay, selectedCompany, order]);

  const handleFilter = useCallback(({ page, limit, query }) => {
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filters = {
      filter_company: selectedCompany,
      estimated_ship_day: shipmentByDay,
      created_day: shipmentCreateDay,
      direction: order.type,
      sort: order.field,
      search_field: query !== undefined ? query : search,
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
    };
    api.shipmentDashboard.parentShipments(filters)
      .then((res) => {
        setRows(res?.shipments);
        setPaginationOptions({
          count: res.all_labels,
          page: fixedPage || 0,
          limit: limit || paginationOptions.limit,
        });
        const url = `/admin/shipment_dashboard/parent_shipments?${qs.stringify(filters)}`;
        window.history.replaceState(null, '', url);
      })
      .catch(err => console.log(err));
  }, [shipmentByDay, shipmentCreateDay, selectedCompany, paginationOptions, search, order]);

  const handleReset = () => {
    setCompany('');
    setShipmentCreateDay('');
    setShipmentByDay('');
  };

  const handleUpdateFields = (updatedFields, save = true) => {
    setSelectedFields(getFields.filter(f => updatedFields.includes(f.key)));
    setShowFieldsModal(false);
    if (save) window.localStorage.setItem(PARENT_SHIPMENT_FIELDS, updatedFields.join(','));
  };

  useEffect(() => {
    const savedFields = window.localStorage.getItem(PARENT_SHIPMENT_FIELDS);
    if (!savedFields) return;

    handleUpdateFields(savedFields.split(','), false);
  }, []);

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Parent Shipments</h1>
              <div className="pull-right">
                <button className="btn btn-primary" onClick={() => setShowFieldsModal(true)}>Change selected fields</button>
                {permission.shipments?.add && (
                  <a href={newShipmentPath} className="btn bg-olive margin add-btn" style={{ marginLeft: '5px' }}>Add Shipment</a>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="status-sec">
                <div className="form-inline" style={{ display: 'flex' }}>
                  <div className="datepicker">
                    <input
                      type="date"
                      className="form-control dimensions length"
                      id="created_at"
                      name="date"
                      value={shipmentCreateDay}
                      onChange={(e) => {
                        setShipmentCreateDay(e.target.value);
                      }}
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    />
                  </div>
                  <select
                    name="company_id"
                    id="company_id"
                    className="form-control"
                    value={selectedCompany}
                    onChange={(e) => {
                      setCompany(e.target.value);
                    }}
                  >
                    <option value="">Select company</option>
                    {companyList.map(company => <option key={company[1]} value={company[1]}>{company[0]}</option>)}
                  </select>
                  <div className="datepicker">
                    <input
                      type="date"
                      className="form-control dimensions length"
                      id="date"
                      name="date"
                      value={shipmentByDay}
                      onChange={(e) => {
                        setShipmentByDay(e.target.value);
                      }}
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    />
                  </div>
                  <button style={{ marginLeft: '10px' }} className="btn-danger btn shipment_filters_reset" onClick={handleReset}>Reset</button>
                </div>
                <div className="status-sec" style={{ paddingLeft: 0 }}>
                  <div className="form-inline">
                    <div className="input-group">
                      <input
                        className="form-control shipment_search"
                        placeholder="Search"
                        value={search}
                        onKeyPress={e => (e.key === 'Enter' && handleFilter({ page: 0 }))}
                        onChange={e => setSearch(e.target.value)}
                      />
                      {/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                      <span
                        className="search-clear fa fa-times"
                        onClick={() => { setSearch(''); handleFilter({ query: '', page: 0 }); }}
                        data-testid="clear-search"
                      />
                      {/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    </div>
                    <button type="submit" style={{ marginLeft: 10 }} className="btn btn-primary shipment_search_button" onClick={() => handleFilter({ page: 0 })}>
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-body main_listing">
              <ActualShipmentsTable
                rows={rows}
                selectedFields={selectedFields}
                serverTimezone={serverTimezone}
                isAdmin={isAdmin}
                handleFilter={handleFilter}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                paginationOptions={paginationOptions}
                states={states}
                limits={limits}
                defaultData={defaultData}
                countryId={countryId}
                userId={userId}
                permission={permission}
                companyList={companyList}
                sortable
                order={order}
                setOrder={setOrder}
                preferences={preferences}
                suffix="parent-shipments"
                paginationKey={PaginationKeys.parentShipments}
              />
            </div>
          </div>
        </div>
        {
          showFieldsModal && <FieldsModal open={showFieldsModal} fields={getFields()} selectedFields={selectedFields} updateFields={handleUpdateFields} handleClose={() => setShowFieldsModal(false)} />
        }
      </div>
    </>
  );
};

ParentShipments.propTypes = {
  newShipmentPath: PropTypes.string,
  shipments: PropTypes.instanceOf(Array).isRequired,
  allLabelsCount: PropTypes.number.isRequired,
  companyList: PropTypes.instanceOf(Array).isRequired,
  defaultLimit: PropTypes.number.isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  limits: PropTypes.instanceOf(Object).isRequired,
  defaultData: PropTypes.instanceOf(Object).isRequired,
  countryId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
  isAdmin: PropTypes.bool,
  timezoneFromServer: PropTypes.instanceOf(Object).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

ParentShipments.defaultProps = {
  newShipmentPath: '',
  isAdmin: false,
};

export default ParentShipments;
