import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

const AddAirportStopModal = ({
  handleClose, open, airports, handleSubmit,
}) => {
  const [airportId, setAirportId] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const options = airports.map(a => ({
    value: a.id,
    label: a.name,
  }));

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Add Airport Stop
      </DialogTitle>
      <DialogContent className={`preview-item-container ${isFocused ? 'container-height' : ''}`} id="preview-modal-dialog">
        <div className="select-airport">Select Airport</div>
        <Select
          options={options}
          multi={false}
          onChange={air => setAirportId(air.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          blurInputOnSelect
          filterOption={createFilter({
            matchFrom: 'any',
            stringify: option => option.label,
          })}
        />
        <div className="modal-bottom-btn text-right" style={{ borderTop: 'none' }}>
          <button className="btn bg-olive" onClick={() => handleSubmit(airportId)}>Create</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={handleClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

AddAirportStopModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  airports: PropTypes.instanceOf(Array).isRequired,
  open: PropTypes.bool,
};

AddAirportStopModal.defaultProps = {
  open: false,
};

export default AddAirportStopModal;
