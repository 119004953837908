import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import api from '../../services/api';

const RoutePickupsModal = ({
  onClose, selected, open, onSubmit, serverTimezone, route,
}) => {
  const [selectedPickups, setSelectedPickups] = useState([]);
  const [allPickups, setAllPickups] = useState([]);
  const [checkedAvailable, setCheckedAvailable] = useState([]);
  const [checkedPicked, setCheckedPicked] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [availableSearch, setAvailableSearch] = useState('');
  const [selectedSearch, setSelectedSearch] = useState('');
  const [visibleSelected, setVisibleSelected] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    api.rlNetwork.getAvailablePickupsForRoute({ id: route.id, show_all: false }).then((res) => {
      setAllPickups(res.remaining_pickups);
      const tempSelectedPickups = res.selected_pickups.map((el, idx) => ({
        ...el,
        position: idx + 1,
      }));
      setSelectedPickups(tempSelectedPickups);
      setVisibleSelected(tempSelectedPickups);
    });
  }, [selected]);

  useEffect(() => {
    fetchAvailable();
  }, [showAll]);

  const fetchAvailable = useCallback((overrideQuery) => {
    api.rlNetwork.getAvailablePickupsForRoute({
      id: route.id,
      show_all: showAll,
      query: overrideQuery !== undefined ? overrideQuery : availableSearch,
      exclude: selectedPickups.length ? selectedPickups.map(p => p.id).join(',') : null,
    }).then((res) => {
      setAllPickups(res.remaining_pickups);
      setTotalRecords(res.total_records);
    });
  }, [showAll, availableSearch]);

  const checkPickupForCondition = (pickup, filter) => {
    const additionalCondition = pickup.company.name.toLowerCase().includes(filter.toLowerCase()) || pickup.carrier.name.toLowerCase().includes(filter.toLowerCase());
    const originCondition = pickup.origin_record_json ? pickup.origin_record_json.string_origin.toLowerCase().includes(filter.toLowerCase()) : pickup.string_origin_new.toLowerCase().includes(filter.toLowerCase());
    return additionalCondition || originCondition;
  };

  const filterSelected = useCallback((overrideQuery) => {
    const filter = overrideQuery !== undefined ? overrideQuery : selectedSearch;
    if (!filter) setVisibleSelected([...selectedPickups]);
    else setVisibleSelected([...selectedPickups.filter(pickup => checkPickupForCondition(pickup, filter))]);
  }, [selectedSearch, selectedPickups]);

  const addPickup = () => {
    if (!checkedAvailable.length) return;

    let checkedPickups = allPickups.filter(p => checkedAvailable.includes(p.id));
    let nextPosition = selectedPickups.length ? Math.max(selected.length + 1, selectedPickups[selectedPickups.length - 1].position + 1) : 1;
    checkedPickups = checkedPickups.map((el) => {
      let position = selected.findIndex(idx => idx === el.id);
      if (position > -1) position += 1;
      else {
        position = nextPosition;
        nextPosition += 1;
      }
      return { ...el, position };
    });
    setSelectedPickups([...selectedPickups, ...checkedPickups].sort((a, b) => a.position - b.position));
    const newVisible = [];
    checkedPickups.forEach((p) => {
      if (checkPickupForCondition(p, selectedSearch)) newVisible.push(p);
    });
    if (newVisible.length) setVisibleSelected([...visibleSelected, ...newVisible]);
    setAllPickups(allPickups.filter(p => !checkedAvailable.includes(p.id)));
    setTotalRecords(totalRecords - checkedAvailable.length);
    setCheckedAvailable([]);
  };

  const removePickup = () => {
    if (!checkedPicked.length) return;

    const searchResults = [];
    checkedPicked.forEach((p) => {
      const pickup = selectedPickups.find(pk => pk.id === p);
      const clusterCondition = showAll || pickup.package_cluster_id === route.package_cluster_id;
      const originCondition = checkPickupForCondition(pickup, availableSearch);
      if (clusterCondition && originCondition) searchResults.push(pickup);
    });
    if (searchResults.length) {
      setAllPickups([...allPickups, ...searchResults]);
      setTotalRecords(totalRecords + searchResults.length);
    }
    setSelectedPickups(selectedPickups.filter(p => !checkedPicked.includes(p.id)));
    setVisibleSelected(visibleSelected.filter(p => !checkedPicked.includes(p.id)));
    setCheckedPicked([]);
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Update network route pickups
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <label style={{ display: 'flex', alignItems: 'center' }}>
          <input
            style={{ marginRight: 3 }}
            type="checkbox"
            value={showAll}
            onClick={(e) => {
              setShowAll(e.target.checked);
            }}
          />
          Show all pickups
        </label>
        <div className="row network-pickups-controls">
          <div className="col-sm-5 form-group">
            <div>
              Available Pickups
              {' '}
              {`(shown: ${allPickups.length}, total: ${totalRecords})`}
            </div>
            <div className="form-inline" style={{ display: 'flex', marginBottom: 20 }}>
              <div className="input-group" style={{ flex: 1 }}>
                <input
                  className="form-control shipment_search"
                  placeholder="Search"
                  value={availableSearch}
                  onKeyPress={e => (e.key === 'Enter' && fetchAvailable())}
                  onChange={e => setAvailableSearch(e.target.value)}
                />
                {/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <span
                  className="search-clear fa fa-times"
                  onClick={() => { setAvailableSearch(''); fetchAvailable(''); }}
                />
                {/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              </div>
              <button type="submit" style={{ marginLeft: 10 }} className="btn btn-primary shipment_search_button" onClick={() => fetchAvailable()}>
                <i className="fa fa-search" />
              </button>
            </div>
            <div className="network-pickups-select" data-testid="available_pickups">
              {allPickups.map(p => (
                <label key={`available-${p.id}`} className="network-pickups-select-option">
                  <input
                    type="checkbox"
                    value={p.id}
                    onClick={(e) => {
                      setCheckedAvailable(e.target.checked ? [...checkedAvailable, parseInt(e.target.value, 10)] : checkedAvailable.filter(val => val !== parseInt(e.target.value, 10)));
                    }}
                  />
                  <div className="network-pickups-select-option-value">
                    <div>{`Company: ${p.origin_record_json?.company?.name || p.company.name || '-'}`}</div>
                    <div>{`Carrier: ${p.carrier.name || '-'}`}</div>
                    <div>{`Ready Time: ${moment(p.ready_time).tz(serverTimezone).format('hh:mm a z') || '-'}`}</div>
                    <div>{`Close Time: ${moment(p.close_time).tz(serverTimezone).format('hh:mm a z') || '-'}`}</div>
                    <div>{`Address: ${p.origin_record_json?.string_origin || p.string_origin_new}`}</div>
                  </div>
                </label>
              ))}
            </div>
          </div>
          <div className="network-pickups-buttons col-sm-2">
            <button
              className="btn admin-btn add-btn bg-aqua"
              title="Left to Right"
              onClick={addPickup}
              data-testid="add_pickups"
            >
              &#x27A1;
            </button>
            <button
              className="btn admin-btn remove-btn"
              title="Right to Left"
              onClick={removePickup}
              data-testid="remove_pickups"
            >
              &#x2B05;
            </button>
          </div>
          <div className="col-sm-5 form-group">
            <div>
              Selected Pickups
              {' '}
              {`(shown: ${visibleSelected.length}, total: ${selectedPickups.length})`}
            </div>
            <div className="form-inline" style={{ display: 'flex', marginBottom: 20 }}>
              <div className="input-group" style={{ flex: 1 }}>
                <input
                  className="form-control shipment_search"
                  placeholder="Search"
                  value={selectedSearch}
                  onKeyPress={e => (e.key === 'Enter' && filterSelected())}
                  onChange={e => setSelectedSearch(e.target.value)}
                />
                {/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <span
                  className="search-clear fa fa-times"
                  onClick={() => { setSelectedSearch(''); filterSelected(''); }}
                />
                {/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              </div>
              <button type="submit" style={{ marginLeft: 10 }} className="btn btn-primary shipment_search_button" onClick={() => filterSelected()}>
                <i className="fa fa-search" />
              </button>
            </div>
            <div className="network-pickups-select" data-testid="selected_pickups">
              {visibleSelected.sort((a, b) => a.position - b.position).map(p => (
                <label key={`selected-${p.id}`} className="network-pickups-select-option">
                  <input
                    type="checkbox"
                    value={p.id}
                    onClick={(e) => {
                      setCheckedPicked(e.target.checked ? [...checkedPicked, parseInt(e.target.value, 10)] : checkedPicked.filter(val => val !== parseInt(e.target.value, 10)));
                    }}
                  />
                  <div className="network-pickups-select-option-value">
                    <div>{`Company: ${p.origin_record_json?.company?.name || p.company.name || '-'}`}</div>
                    <div>{`Carrier: ${p.carrier.name || '-'}`}</div>
                    <div>{`Ready Time: ${moment(p.ready_time).tz(serverTimezone).format('hh:mm a z') || '-'}`}</div>
                    <div>{`Close Time: ${moment(p.close_time).tz(serverTimezone).format('hh:mm a z') || '-'}`}</div>
                    <div>{`Address: ${p.origin_record_json?.string_origin || p.string_origin_new}`}</div>
                  </div>
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className="modal-bottom-btn text-right">
          <button
            className="btn bg-olive"
            onClick={() => {
              onSubmit(selectedPickups.map((p, idx) => ({ scheduled_pickup_id: p.id, position: idx + 1 })));
              onClose();
            }}
            data-testid="update_route_pickups"
          >
            Confirm
          </button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={onClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

RoutePickupsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  selected: PropTypes.instanceOf(Array),
  onSubmit: PropTypes.func.isRequired,
  route: PropTypes.instanceOf(Object).isRequired,
  serverTimezone: PropTypes.string.isRequired,
};

RoutePickupsModal.defaultProps = {
  open: false,
  selected: [],
};

export default RoutePickupsModal;
