import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { Multiselect } from 'multiselect-react-dropdown';
import { isEmpty } from 'lodash';
import api from '../../services/api';


const SetAccount = ({
  companies, users, open, onClose, update, row,
}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [selectedUsers, setUsers] = useState([]);
  const isEdit = !isEmpty(row);

  useEffect(() => {
    if (isEdit) {
      setName(row.name);
      setCompany(row.company_id);
      setUsers(row.users.map(item => ({ name: `${item.first_name} ${item.last_name} ${item.email}`, id: item.id })));
    } else {
      setName('');
      setCompany('');
      setUsers([]);
    }
  }, [row]);

  const handleSubmit = () => {
    setLoading(true);
    const method = isEdit ? 'update' : 'create';
    api.accounts[`${method}Account`](row?.id, {
      account_name: name,
      company_id: company,
      users: selectedUsers.map(item => item.id),
    }).then(() => {
      onClose();
      setLoading(false);
      update({});
    });
  };

  const onSelect = (selectedList) => {
    setUsers(selectedList);
  };

  const onRemove = (selectedList) => {
    setUsers(selectedList);
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="name">
                Name
              </label>
              <input
                className="form-control"
                id="name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="company_id">
                Company
                <span className="required">*</span>
              </label>
              <select
                className="form-control"
                id="company_id"
                value={company}
                onChange={e => setCompany(e.target.value)}
              >
                <option key="default" value="">Please Select</option>
                {companies.map(item => <option key={item[1]} value={item[1]}>{item[0]}</option>)}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="users_id">
                Users
                <span className="required">*</span>
              </label>
              <Multiselect
                options={users}
                selectedValues={selectedUsers}
                onSelect={onSelect}
                onRemove={onRemove}
                displayValue="name"
              />
            </div>
          </div>
        </div>
        <div className="modal-bottom-btn text-right">
          <button disabled={loading} className="btn bg-olive" onClick={handleSubmit}>{isEdit ? 'Save' : 'Create'}</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={onClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

SetAccount.propTypes = {
  companies: PropTypes.instanceOf(Array).isRequired,
  users: PropTypes.instanceOf(Array).isRequired,
  row: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

SetAccount.defaultProps = {
  open: false,
};

export default SetAccount;
