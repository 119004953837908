import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

const ConfirmationDialog = ({
  onConfirm, onClose, open, message,
}) => (
  <Dialog
    className="confirmation-dialog"
    open={open}
    onClose={onClose}
    aria-labelledby="preview-modal-title"
  >
    <DialogTitle id="preview-modal-title">
      Confirmation Required
    </DialogTitle>
    <DialogContent className="preview-item-container" id="preview-modal-dialog">
      {message}
      <div className="modal-bottom-btn text-right">
        <button className="btn btn-primary" onClick={onClose}>Cancel</button>
        <button className="btn bg-olive" style={{ marginLeft: '5px' }} onClick={onConfirm} data-testid="confirm">OK</button>
      </div>
    </DialogContent>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
  open: false,
};

export default ConfirmationDialog;
