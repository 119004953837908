import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle,
} from '@material-ui/core';
import { QRCodeCanvas } from 'qrcode.react';

const QRShipmentTrackingIdModal = ({
  open, handleClose, trackingId,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    className="dialog-sm"
    aria-labelledby="preview-modal-title"
  >
    <DialogTitle id="preview-modal-title">
      Tracking Id QR Code
    </DialogTitle>
    <DialogContent className="preview-item-container" id="preview-modal-dialog">
      <div className="box-body">
        <QRCodeCanvas value={trackingId} size={256} />
      </div>
    </DialogContent>
  </Dialog>
);

QRShipmentTrackingIdModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  trackingId: PropTypes.string.isRequired,
};

QRShipmentTrackingIdModal.defaultProps = {
  open: false,
};

export default QRShipmentTrackingIdModal;
