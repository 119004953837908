import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

const FieldsModal = ({
  fields, handleClose, updateFields, open, selectedFields,
}) => {
  const [currentFields, setCurrentFields] = useState(selectedFields.map(f => f.key));
  const handleChange = (e) => {
    const { id: field, checked } = e.target;
    if (field === 'select_all') setCurrentFields(checked ? fields.map(f => f.key) : ['actions']);
    else setCurrentFields(checked ? [...currentFields, field] : currentFields.filter(item => item !== field));
  };

  const renderListItem = (key, label, checked) => (
    <div key={key}>
      <label className="checkbox-label">
        <input
          type="checkbox"
          id={key}
          style={{ marginLeft: '5px' }}
          checked={checked}
          disabled={key === 'actions'}
          onChange={handleChange}
        />
        {label}
        <span className="checkmark" />
      </label>
    </div>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Select fields you want to see in the table
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div style={{ maxHeight: 500, overflowY: 'auto' }}>
          {renderListItem('select_all', 'Select All', currentFields.length === fields.length)}
          {fields.map(f => renderListItem(f.key, f.header, currentFields.includes(f.key)))}
        </div>
        <div className="modal-bottom-btn" style={{ textAlign: 'center' }}>
          <button className="btn bg-olive" onClick={() => updateFields(currentFields)} data-testid="confirm">OK</button>
          <button className="btn btn-primary" onClick={handleClose} style={{ marginLeft: '5px' }}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

FieldsModal.propTypes = {
  fields: PropTypes.instanceOf(Array),
  selectedFields: PropTypes.instanceOf(Array),
  handleClose: PropTypes.func.isRequired,
  updateFields: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

FieldsModal.defaultProps = {
  open: false,
  fields: [],
  selectedFields: [],
};

export default FieldsModal;
