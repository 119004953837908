import React, {
  useCallback, useEffect, useState, useRef, useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { isEmpty, pick } from 'lodash';
import moment from 'moment-timezone';
import api from '../../services/api';

const DLRouteEditModal = ({
  row, onClose, open, onConfirm, drivers, // serverTimezone,
}) => {
  const [dlRoute, setDLRoute] = useState({});
  const [errors, setErrors] = useState({});
  const isEdit = !isEmpty(row);
  const routeDate = useRef();

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!dlRoute.driver_id) newErrors.driver_id = 'Please select driver';
    setErrors(newErrors);

    return !Object.keys(newErrors).some(key => !isEmpty(newErrors[key]));
  }, [dlRoute]);


  const handleSubmit = () => {
    if (!validateForm()) return;
    api.routesDashboard.updateRoute(dlRoute.id, { ...dlRoute, date: moment(routeDate.current?.value).format('YYYY-M-DD') }).then(() => {
      onClose();
      onConfirm();
    }).catch((e) => {
      console.log(e);
    });
  };

  const handleChange = (data) => {
    const { id: field, value } = data;
    setDLRoute({
      ...dlRoute,
      [field]: value,
    });
    setErrors({ ...errors, [field]: '' });
  };

  useLayoutEffect(() => {
    if (open) {
      setTimeout(() => {
        $(routeDate.current).datepicker({
          format: 'DD M yy',
        });
        dlRoute.date && $(routeDate.current).val(moment(dlRoute.date).format('DD M yy'));
      }, 10);
    }
  }, [routeDate.current, open, dlRoute.ready_time]);

  useEffect(() => {
    if (isEdit) {
      setDLRoute(pick(row, ['id', 'driver_id', 'date']));
    } else {
      setDLRoute({
        driver_id: '',
        date: '',
      });
    }
  }, [row]);

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Edit a DLRoute
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="position">
              Driver
              <span className="required">*</span>
            </label>
            <select id="driver_id" className="form-control" value={dlRoute.driver_id ?? ''} onChange={e => handleChange(e.target)}>
              <option key="default" value="">Please select</option>
              {drivers.map(driver => (
                <option key={driver[1]} value={driver[1]}>{driver[0]}</option>
              ))}
            </select>
            {errors?.driver_id && (
              <label className="error">
                {errors.driver_id}
              </label>
            )}
          </div>
        </div>
        <div className="form-group" style={{ marginTop: '20px' }}>
          <label htmlFor="scheduleDays">
            Date
            <span className="required">*</span>
          </label>
          <div className="admin-datepicker">
            <input
              ref={routeDate}
              name="dl_route_date"
              id="dl_route_date"
              className="form-control static_quantity"
              readOnly
            />
          </div>
          {errors?.date && (
            <label className="error">
              {errors.date}
            </label>
          )}
        </div>
        <div className="modal-bottom-btn text-right">
          <button className="btn bg-olive" onClick={handleSubmit} data-testid="create_DLRoute">Confirm</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={onClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

DLRouteEditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  row: PropTypes.instanceOf(Object),
  onConfirm: PropTypes.func.isRequired,
  drivers: PropTypes.instanceOf(Array),
  // serverTimezone: PropTypes.any,
};

DLRouteEditModal.defaultProps = {
  open: false,
  row: {},
  drivers: [],
};

export default DLRouteEditModal;
