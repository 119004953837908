import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { printLabel, printZplLabels, splitArrayPerChunks } from '../../services/helpers';
import api from '../../services/api';
import CustomTable from '../common/Table';

const PrintLabels = ({ shipAdminShipmentsPath, adminShipmentsPath, rows }) => {
  const [bulkAction, setBulkAction] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(async () => {
    if (rows.length > 0) {
      // const res = await api.shipmentDashboard.bulkActions({
      //   bulk_action: 'print_zpl',
      //   ids: rows.map(row => row.id),
      //   controller_name: 'shipments',
      // });
      //
      // printZplLabels(res);
    }
  }, [rows]);

  const handlePrintZPL = async (row) => {
    const res = await api.shipmentDashboard.bulkActions({
      bulk_action: 'print_zpl',
      ids: [row],
      controller_name: 'shipments',
    });

    printZplLabels(res);
  };

  const handleAction = useCallback(async (action, records) => {
    if ((action || bulkAction) === 'display_html') {
      let labelsToPrint = '';
      for await (const items of splitArrayPerChunks(records || selectedRows, 10)) {
        const res = await api.shipmentDashboard.bulkActions({
          bulk_action: (action || bulkAction),
          ids: items.map(row => row.id),
          controller_name: 'shipments',
        });
        labelsToPrint += res.img_tags;
      }
      printLabel(labelsToPrint);
    } else if ((action || bulkAction) === 'print_zpl') {
      const res = await api.shipmentDashboard.bulkActions({
        bulk_action: (action || bulkAction),
        ids: (records || selectedRows).map(row => row.id),
        controller_name: 'shipments',
      });

      printZplLabels(res);
    } else {
      api.shipmentDashboard.bulkActionsF({
        bulk_action: (action || bulkAction),
        ids: (records || selectedRows).map(row => row.id),
        controller_name: 'shipments',
      });
    }
  }, [bulkAction, selectedRows]);

  const getFields = () => [
    { header: 'Company', key: 'company.name' },
    { header: 'Shipment Date', key: 'ship_date' },
    { header: 'Carrier', key: 'carrier.name' },
    { header: 'Price($)', key: 'price' },
    { header: 'Tracking ID', key: 'tracking_id' },
    { header: 'Created At', key: 'created_at' },
    { header: 'Status', key: 'status_text' },
    { header: 'Actions', key: 'actions', format: true },
  ];

  return (
    <>
      <div className="status-sec">
        <div className="form-inline">
          <div className="form-group">
            <div className="form-group">
              <select className="form-control" value={bulkAction} onChange={e => setBulkAction(e.target.value)}>
                <option value="">Select Action</option>
                <option value="export">Export</option>
                <option value="print_zpl">Print ZPL</option>
                <option value="display_html">Print selected labels</option>
                <option value="download_pdf">Download selected labels</option>
              </select>
            </div>
            <button style={{ marginLeft: '10px' }} className="bulk_action_submit btn-primary btn" onClick={() => handleAction(bulkAction, selectedRows)}>Submit</button>
          </div>
        </div>
        <div className="status-sec" style={{ paddingLeft: 0, marginLeft: 0 }}>
          <div className="form-inline">
            <div style={{ paddingLeft: 0, marginLeft: 0 }}>
              <button
                className="print_all_shipments btn-primary btn"
                style={{ marginRight: '5px', marginLeft: 0 }}
                onClick={() => {
                  handleAction('display_html', rows);
                }}
              >
                Print All
              </button>
              <button
                className="download_all_shipments btn-primary btn"
                style={{ marginRight: '5px' }}
                onClick={() => {
                  handleAction('download_pdf', rows);
                }}
              >
                Download All PDF
              </button>
              <button
                className="print_all_zpl btn-primary btn"
                style={{ marginRight: '5px' }}
                onClick={() => {
                  handleAction('print_zpl', rows);
                }}
              >
                Print All ZPL
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="box-body main_listing">
        <CustomTable
          rows={rows}
          formatters={{
            ship_date: value => <span>{value ? moment(value).format('DD MMM YYYY') : '-'}</span>,
            created_at: value => moment(value).format('DD MMM YYYY'),
            actions: value => (
              <div className="text-center" style={{ minWidth: '70px' }}>
                {value?.zpl_file_attached && <button onClick={() => handlePrintZPL(value?.id)} className="fa fa-print pdf-icon" style={{ border: 'none', backgroundColor: 'transparent' }} />}
                {value?.html_file_attached && <a href={`/admin/shipments/${value?.id}/show_html_label`} className="fa fa-file-picture-o pdf-icon" target="_blank" rel="noreferrer" />}
                {value?.pdf_file_attached && <a href={`/admin/shipments/${value?.id}/show_pdf_label`} className="fa fa-file-pdf-o pdf-icon" target="_blank" rel="noreferrer" />}
                <a href={`/admin/shipments/${value?.id}`} className="fa fa-eye edit_user" />
              </div>
            ),
            tracking_id: value => <a href={`/admin/shipments/${value?.tracking_id}/track`} target="_blank" rel="noreferrer" />,
          }}
          rowsProps={getFields()}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          suffix="shipments"
        />
      </div>
      <div>
        <button onClick={shipAdminShipmentsPath} className="btn bg-olive margin add-btn" style={{ marginRight: '5px' }}>Add New Shipment</button>
        <a href={adminShipmentsPath} className="btn add-btn">View All Shipments</a>
      </div>
    </>
  );
};

PrintLabels.propTypes = {
  shipAdminShipmentsPath: PropTypes.func.isRequired,
  adminShipmentsPath: PropTypes.string.isRequired,
  rows: PropTypes.instanceOf(Array).isRequired,
};

export default PrintLabels;
