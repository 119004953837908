import React, {
  useCallback, useEffect, useState, useLayoutEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { Clear } from '@material-ui/icons';
import { pick, isEmpty, capitalize } from 'lodash';
import api from '../../services/api';
import { isAddressValid } from '../../services/helpers';

const SetAddress = ({
  row, states, companies, onClose, mode, open, update, countryId, userId, limits, serverTimezone,
}) => {
  const [errors, setErrors] = useState({});
  const [address, setAddress] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const pickupCutoffTime = useRef();

  const isEdit = !isEmpty(row);

  useEffect(() => {
    if (isEdit) {
      setAddress(pick(row, ['city', 'attention_to', 'long_company_name', 'long_street_address1', 'email', 'company_id', 'company_name', 'id', 'phone', 'state_id', 'street_address1', 'street_address2', 'zip', 'record_id', 'account_id', 'has_lockbox', 'cutoff_time', 'customer_id']));
    } else {
      setAddress({
        city: '',
        attention_to: '',
        long_company_name: '',
        long_street_address1: '',
        email: '',
        company_id: '',
        company_name: '',
        id: '',
        phone: '',
        state_id: '',
        street_address1: '',
        street_address2: '',
        zip: '',
        record_id: '',
        account_id: '',
        has_lockbox: false,
        cutoff_time: null,
        customer_id: null,
      });
    }
  }, [row]);

  useLayoutEffect(() => {
    if (open) {
      setTimeout(() => {
        $(pickupCutoffTime.current).datetimepicker({
          format: 'hh:mm a',
          ignoreReadonly: true,
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-arrow-up',
            down: 'fa fa-arrow-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-clock-o',
            clear: 'fa fa-trash-o',
          },
        });
        address.cutoff_time && $(pickupCutoffTime.current).val(moment(address.cutoff_time).tz(serverTimezone).format('hh:mm a'));
      }, 10);
    }
  }, [pickupCutoffTime.current, open, address.cutoff_time]);

  useEffect(() => {
    if (mode === 'address' && address.company_id != null) {
      api.addressBook.getUserAccounts({ company_id: address.company_id }).then((res) => {
        setAccounts(res.accounts);
      });
      api.addressBook.getCustomers({ company_id: address.company_id }).then((res) => {
        setCustomers(res.customers);
      });
    }
  }, [address.company_id]);

  const validate = useCallback(() => {
    Object.keys(address).forEach((key) => {
      if (!['street_address2', 'email', 'long_company_name', 'long_street_address1', 'id', 'attention_to', 'record_id', 'account_id', 'has_lockbox', 'cutoff_time', 'customer_id'].includes(key) && !address[key]) {
        errors[key] = `Please enter ${key.replace('_', ' ')}`;
      }
    });

    setErrors({ ...errors });
    return errors;
  }, [address]);

  const handleChange = (e) => {
    const { id: field, value, checked } = e.target;
    if (field === 'company_id') {
      setAddress({
        ...address,
        company_id: value,
        customer_id: '',
        account_id: '',
      });
    } else {
      setAddress({
        ...address,
        [field]: ['has_lockbox'].includes(field) ? checked : value,
      });
    }
    setErrors({ ...errors, [field]: '' });
  };

  const handleSubmit = () => {
    const method = isEdit ? 'Update' : 'Create';
    const field = mode === 'warehouse' ? mode : 'admin_address_book';
    if (isAddressValid(validate())) {
      setLoading(true);
      const props = {
        ...address,
        country_id: countryId,
        zip: address.zip.trim(),
        cutoff_time: pickupCutoffTime.current.value,
      };
      if (!isEdit) props.user_id = userId;
      api.addressBook[`${mode}${method}`](address.id, {
        [field]: props,
      }).then(() => {
        onClose();
        setLoading(false);
        update({ forceMode: mode });
      }).catch((e) => {
        console.log(e);
        if (e.ask && mode === 'address') {
          // eslint-disable-next-line no-alert
          const r = window.confirm('There is a similar address in the AddressBook it could be a duplicate. Confirm submission?');
          if (r === true) {
            props.force_add = true;
            api.addressBook[`${mode}${method}`](address.id, {
              [field]: props,
            }).then(() => {
              onClose();
              setLoading(false);
              update({ forceMode: mode });
            }).catch((err) => {
              console.log(err);
            });
          }
        }
        setLoading(false);
      });
    }
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        {`${isEdit ? 'Edit' : 'Add New'} ${capitalize(mode)}`}
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="company_name">
                Company Name
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                id="company_name"
                value={address.company_name || ''}
                onChange={e => handleChange(e)}
                maxLength={limits?.company}
              />
              {errors.company_name && (
                <label className="error">
                  {errors.company_name}
                </label>
              )}
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="attention_to">
                Attention to
              </label>
              <input
                className="form-control"
                id="attention_to"
                value={address.attention_to || ''}
                onChange={e => handleChange(e)}
                maxLength={limits?.name}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="phone">
                Phone
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                id="phone"
                value={address.phone || ''}
                onChange={e => handleChange(e)}
              />
              {errors.phone && (
                <label className="error">
                  {errors.phone}
                </label>
              )}
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="street_address1">
                Street Address1
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                id="street_address1"
                value={address.street_address1 || ''}
                onChange={e => handleChange(e)}
                maxLength={limits?.address}
              />
              {errors.street_address1 && (
                <label className="error">
                  {errors.street_address1}
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="street_address2">
                Street Address2
              </label>
              <input
                className="form-control"
                id="street_address2"
                value={address.street_address2 || ''}
                onChange={e => handleChange(e)}
                maxLength={limits?.address}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="city">
                City
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                id="city"
                value={address.city || ''}
                onChange={e => handleChange(e)}
                maxLength={limits?.city}
              />
              {errors.city && (
                <label className="error">
                  {errors.city}
                </label>
              )}
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="state_id">
                State
                <span className="required">*</span>
              </label>
              <select
                className="form-control"
                id="state_id"
                value={address.state_id || ''}
                onChange={e => handleChange(e)}
              >
                <option key="first" value="">Please Select</option>
                {states.map(state => <option key={state.id} value={state.id}>{state.name}</option>)}
              </select>
              {errors.state_id && (
                <label className="error">
                  {errors.state_id}
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="zip">
                Zip
                <span className="required">*</span>
              </label>
              <input
                className="form-control"
                id="zip"
                value={address.zip || ''}
                onChange={e => handleChange(e)}
              />
              {errors.zip && (
                <label className="error">
                  {errors.zip}
                </label>
              )}
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="email">
                Email
              </label>
              <input
                className="form-control"
                id="email"
                value={address.email || ''}
                onChange={e => handleChange(e)}
                maxLength={limits?.email}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="long_company_name">
                Long Company Name
              </label>
              <input
                className="form-control"
                id="long_company_name"
                value={address.long_company_name || ''}
                onChange={e => handleChange(e)}
                maxLength={300}
              />
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="long_street_address1">
                Long Street Address1
              </label>
              <input
                className="form-control"
                id="long_street_address1"
                value={address.long_street_address1 || ''}
                onChange={e => handleChange(e)}
                maxLength={300}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="company_id">
                Company
                <span className="required">*</span>
              </label>
              <select
                className="form-control"
                id="company_id"
                value={address.company_id || ''}
                onChange={e => handleChange(e)}
              >
                <option key="default" value="">Please Select</option>
                {companies.map(company => <option key={company[1]} value={company[1]}>{company[0]}</option>)}
              </select>
              {errors.company_id && (
                <label className="error">
                  {errors.company_id}
                </label>
              )}
            </div>
            { mode === 'address' && (
              <>
                <div className="col-sm-6 form-group">
                  <label htmlFor="record_id">
                    Record ID
                  </label>
                  <input
                    className="form-control"
                    id="record_id"
                    value={address.record_id || ''}
                    onChange={e => handleChange(e)}
                    maxLength={300}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="account_id">
                    Account / Territory
                  </label>
                  <select
                    className="form-control"
                    id="account_id"
                    value={address.account_id || ''}
                    onChange={e => handleChange(e)}
                  >
                    <option key="default" value="">Please Select</option>
                    {accounts.map(account => <option key={account.id} value={account.id}>{account.name}</option>)}
                  </select>
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="customer_id">
                    Customer
                  </label>
                  <select
                    className="form-control address_customer_id"
                    id="customer_id"
                    value={address.customer_id || ''}
                    onChange={e => setAddress({ ...address, customer_id: e.target.value })}
                  >
                    <option key="default" value="">Please Select</option>
                    {customers.map(customer => (<option key={customer.id} value={customer.id}>{customer.name}</option>))}
                  </select>
                </div>
                <div className="col-sm-12 form-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      id="has_lockbox"
                      style={{ marginLeft: '5px' }}
                      checked={address.has_lockbox}
                      onChange={e => handleChange(e)}
                    />
                    Has Lockbox?
                    <span className="checkmark" />
                  </label>
                </div>
              </>
            )}
            <div className="col-sm-4 form-group">
              <label htmlFor="cutoffTime">
                Cutoff Time
              </label>
              <div className="admin-datepicker">
                <input
                  ref={pickupCutoffTime}
                  name="cutoffTime"
                  id="cutoffTime"
                  className="form-control dimensions length"
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-bottom-btn text-right">
          <button disabled={loading} className="btn bg-olive" onClick={handleSubmit}>{`${isEdit ? 'Update' : 'Add'} ${capitalize(mode)}`}</button>
          <button
            className="btn btn-primary"
            style={{ marginLeft: '5px' }}
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

SetAddress.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  mode: PropTypes.string.isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  companies: PropTypes.instanceOf(Array).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  update: PropTypes.func.isRequired,
  countryId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  limits: PropTypes.instanceOf(Object).isRequired,
  serverTimezone: PropTypes.string,
};

SetAddress.defaultProps = {
  open: false,
  serverTimezone: 'America/Chicago',
};

export default SetAddress;
