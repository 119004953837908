export const UNEXPECTED_ERROR_MESSAGE = 'Something went wrong. Please try again.';
export const NOTIFICATION_TIMEOUT = 4000;
export const CONVERSATION_MESSAGES = {
  CREATED: 'Conversation created.',
  NAME_CHANGED: 'Conversation name changed.',
  LEFT: 'You left the conversation.',
};

export const PARTICIPANT_MESSAGES = {
  ADDED: 'Participant added.',
  REMOVED: 'Participant removed.',
};

export const CONVERSATION_PAGE_SIZE = 30;

export const ConnectionStatus = {
  Connecting: 'connecting',
  Connected: 'connected',
  Disconnected: 'disconnected',
  Disconnecting: 'disconnecting',
  Failed: 'failed',
};

export const PaginationKeys = {
  accounts: 'accounts-per-page',
  userTypes: 'user-types-per-page',
  users: 'users-per-page',
  companies: 'companies-per-page',
  address: 'address-per-page',
  warehouse: 'warehouse-per-page',
  drivers: 'drivers-per-page',
  carriers: 'carriers-per-page',
  airports: 'airports-per-page',
  driverCoverage: 'driver-coverage-per-page',
  shipmentPricing: 'shipment-pricing-per-page',
  driverRates: 'driver-rates-per-page',
  rln: 'rln-per-page',
  scheduledPickups: 'scheduled-pickups-per-page',
  myPickupDashboard: 'my-pickups-per-page',
  pickups: 'pickups-per-page',
  pickupScans: 'pickup-scans-per-page',
  pickupAnalytics: 'pickup-analytics-per-page',
  parentShipments: 'parent-shipments-per-page',
  childrenShipments: 'children-shipments-per-page',
  shipments: 'shipments-per-page',
  batchShipments: 'batch-shipments-per-page',
  fedexPackageScans: 'fedex-package-scans-per-page',
  invoices: 'invoices-per-page',
  shipmentManifests: 'ship-manifests-per-page',
  shipmentAnalytics: 'ship-analytics-per-page',
  flightRoutes: 'flight-routes-per-page',
  flightRouteClusters: 'flight-route-clusters-per-page',
  consolidationBags: 'consolidation-bags-per-page',
  rl1Routes: 'rl1-routes-per-page',
  rl2Routes: 'rl2-routes-per-page',
  dlRoutes: 'dl-routes-per-page',
  payments: 'payments-per-page',
  cmpPages: 'cms-per-page',
  settings: 'settings-per-page',
};
