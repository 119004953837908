import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import ConversationsList from './ConversationsList';

const ConversationsContainer = () => {
  const [date, setDate] = useState('');

  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="box">
          <div className="box-header">
            <h1 className="box-title">Driver Communication</h1>
            <div className="conversation-date-wrapper">
              <div>Filter by date:</div>
              <div className="datepicker">
                <input
                  type="date"
                  className="form-control dimensions length"
                  id="date"
                  name="date"
                  value={date}
                  onChange={e => setDate(e.target.value)}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                />
              </div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
              <div
                role="button"
                className="fa fa-times"
                onClick={() => { setDate(''); }}
              />
            </div>
          </div>
          <div className="box-body">
            <ConversationsList date={date} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationsContainer;
