import React, {
  useEffect, useState, useRef, useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import moment from 'moment-timezone';
import api from '../../services/api';
// import { errorToast } from '../../services/toast';

const OperationModal = ({
  row, onClose, open, onUpdate, isEdit,
}) => {
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const scheduledArrivalTimeRef = useRef();
  const estInBlkRef = useRef();
  const outInBlkRef = useRef();
  const inBlkTimeRef = useRef();
  const offTimeRef = useRef();
  const onTimeRef = useRef();
  const PIRIFCRef = useRef();
  const PFOIFCRef = useRef();

  useLayoutEffect(() => {
    if (open) {
      setTimeout(() => {
        $(scheduledArrivalTimeRef.current).datetimepicker({
          format: 'HH:mm',
          ignoreReadonly: true,
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-arrow-up',
            down: 'fa fa-arrow-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-clock-o',
            clear: 'fa fa-trash-o',
          },
        });
        $(estInBlkRef.current).datetimepicker({
          format: 'HH:mm',
          ignoreReadonly: true,
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-arrow-up',
            down: 'fa fa-arrow-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-clock-o',
            clear: 'fa fa-trash-o',
          },
        });
        $(outInBlkRef.current).datetimepicker({
          format: 'HH:mm',
          ignoreReadonly: true,
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-arrow-up',
            down: 'fa fa-arrow-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-clock-o',
            clear: 'fa fa-trash-o',
          },
        });
        $(inBlkTimeRef.current).datetimepicker({
          format: 'HH:mm',
          ignoreReadonly: true,
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-arrow-up',
            down: 'fa fa-arrow-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-clock-o',
            clear: 'fa fa-trash-o',
          },
        });
        $(offTimeRef.current).datetimepicker({
          format: 'HH:mm',
          ignoreReadonly: true,
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-arrow-up',
            down: 'fa fa-arrow-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-clock-o',
            clear: 'fa fa-trash-o',
          },
        });
        $(onTimeRef.current).datetimepicker({
          format: 'HH:mm',
          ignoreReadonly: true,
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-arrow-up',
            down: 'fa fa-arrow-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-clock-o',
            clear: 'fa fa-trash-o',
          },
        });
        $(PIRIFCRef.current).datetimepicker({
          format: 'HH:mm',
          ignoreReadonly: true,
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-arrow-up',
            down: 'fa fa-arrow-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-clock-o',
            clear: 'fa fa-trash-o',
          },
        });
        $(PFOIFCRef.current).datetimepicker({
          format: 'HH:mm',
          ignoreReadonly: true,
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-arrow-up',
            down: 'fa fa-arrow-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-clock-o',
            clear: 'fa fa-trash-o',
          },
        });
        if (isEdit) {
          $(scheduledArrivalTimeRef.current).val(moment(row.scheduled_arrival_time).utc().format('HH:mm'));
          $(estInBlkRef.current).val(moment(row.est_in_blk).utc().format('HH:mm'));
          $(outInBlkRef.current).val(moment(row.out_in_blk).utc().format('HH:mm'));
          $(inBlkTimeRef.current).val(moment(row.in_blk_time).utc().format('HH:mm'));
          $(offTimeRef.current).val(moment(row.off_time).utc().format('HH:mm'));
          $(onTimeRef.current).val(moment(row.on_time).utc().format('HH:mm'));
          $(PIRIFCRef.current).val(moment(row.pir_ifc).utc().format('HH:mm'));
          $(PFOIFCRef.current).val(moment(row.pfo_ifc).utc().format('HH:mm'));
        }
      }, 10);
    }
  }, [scheduledArrivalTimeRef.current, scheduledArrivalTimeRef.current, estInBlkRef.current,
    outInBlkRef.current, inBlkTimeRef.current, inBlkTimeRef.current,
    offTimeRef.current, onTimeRef.current, PIRIFCRef.current,
    PFOIFCRef.current, open, row]);


  useEffect(() => {
    setErrors({});
    setData(row || {});
  }, [row]);

  const getParams = () => ({
    ...data,
    // trip_date:localTripDateRef.current?.value,
    // scheduled_arrival_date:scheduledArrivalDateRef.current?.value,
    scheduled_arrival_time: scheduledArrivalTimeRef.current?.value,
    est_in_blk: estInBlkRef.current?.value,
    out_in_blk: outInBlkRef.current?.value,
    in_blk_time: inBlkTimeRef.current?.value,
    off_time: offTimeRef.current?.value,
    on_time: onTimeRef.current?.value,
    pir_ifc: PIRIFCRef.current?.value,
    pfo_ifc: PFOIFCRef.current?.value,
  });

  const handleChange = (e) => {
    const { id: field, value } = e.target;
    setData({
      ...data,
      [field]: value,
      // [field]: ['ramp'].includes(field) ? checked : value,
    });
    setErrors({ ...errors, [field]: '' });
  };

  const handleSubmit = async () => {
    if (isEdit) {
      await api.flight.update(getParams());
    } else {
      await api.flight.create(getParams());
    }
    onUpdate();
  };

  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        {isEdit ? 'Edit' : 'Create'}
        {' '}
        Operation
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="local_trip">
              Local trip date
            </label>
            <input
              type="date"
              className="form-control"
              id="trip_date"
              name="trip_date"
              value={data.trip_date}
              onChange={(e) => {
                handleChange(e);
              }}
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            />
          </div>
          {/* <div className="col-sm-6 form-group">
            <label htmlFor="utc_trip">
              UTC trip date
            </label>
            <div className="admin-datepicker">
              <input
                ref={UTCTripDateRef}
                name="utc_trip"
                id="utc_trip"
                className="form-control"
                readOnly
              />
            </div>
          </div> */}
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="registration_number">
              Registration number
            </label>
            <input
              className="form-control"
              id="registration_number"
              value={data.registration_number || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="leg_code">
              Leg code
            </label>
            <input
              className="form-control"
              id="leg_code"
              value={data.leg_code || ''}
              onChange={e => handleChange(e)}
            />
            {/* <select
              className="form-control"
              id="leg_code"
              value={data.leg_code || ''}
              onChange={e => handleChange(e)}
            >
              <option value="">Please Select</option>
              <option value="P">P</option>
              <option value="C">C</option>
              <option value="M">M</option>
              <option value="F">F</option>
              <option value="T">T</option>
            </select> */}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="flight_no">
              Flight No
            </label>
            <input
              className="form-control"
              id="flight_no"
              value={data.flight_no || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="captain_id">
              Captain ID
            </label>
            <input
              className="form-control"
              id="captain_id"
              value={data.captain_id || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="first_officer_id">
              First officer ID
            </label>
            <input
              className="form-control"
              id="first_officer_id"
              value={data.first_officer_id || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="second_officer_id">
              Second Officer ID
            </label>
            <input
              className="form-control"
              id="second_officer_id"
              value={data.second_officer_id || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="first_flight_attendant_id">
              First flight attendant ID
            </label>
            <input
              className="form-control"
              id="first_flight_attendant_id"
              value={data.first_flight_attendant_id || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="second_flight_attendant_id">
              Second flight attendant ID
            </label>
            <input
              className="form-control"
              id="second_flight_attendant_id"
              value={data.second_flight_attendant_id || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="avrg_weight">
              Avrg. Weight per passenger + carry-on
            </label>
            <input
              className="form-control"
              id="avrg_weight"
              value={data.avrg_weight || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="no_of_passengers">
              No. Of passengers
            </label>
            <input
              className="form-control"
              id="no_of_passengers"
              value={data.no_of_passengers || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="total_luggage_weight">
              Total Luggage weight
            </label>
            <input
              className="form-control"
              id="total_luggage_weight"
              value={data.total_luggage_weight || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="total_cargo_weight">
              Total cargo weight
            </label>
            <input
              className="form-control"
              id="total_cargo_weight"
              value={data.total_cargo_weight || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="departing_airport_code">
              Departing airport code
            </label>
            <input
              className="form-control"
              id="departing_airport_code"
              value={data.departing_airport_code || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="arriving_airport_code">
              Arriving Airport code
            </label>
            <input
              className="form-control"
              id="arriving_airport_code"
              value={data.arriving_airport_code || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="scheduled_arrival_date">
              Scheduled arrival date
            </label>
            <input
              type="date"
              className="form-control"
              id="scheduled_arrival_date"
              name="scheduled_arrival_date"
              value={data.scheduled_arrival_date}
              onChange={(e) => {
                handleChange(e);
              }}
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="scheduled_arrival_time">
              Scheduled arrival time
            </label>
            <div className="admin-datepicker">
              <input
                ref={scheduledArrivalTimeRef}
                name="scheduled_arrival_time"
                id="scheduled_arrival_time"
                className="form-control"
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="est_in_blk">
              Est. In BIk
            </label>
            <div className="admin-datepicker">
              <input
                ref={estInBlkRef}
                name="est_in_blk"
                id="est_in_blk"
                className="form-control"
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="out_in_blk">
              OUT. In BIk
            </label>
            <div className="admin-datepicker">
              <input
                ref={outInBlkRef}
                name="out_in_blk"
                id="out_in_blk"
                className="form-control"
                readOnly
              />
            </div>
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="in_blk_time">
              In BIk Time
            </label>
            <div className="admin-datepicker">
              <input
                ref={inBlkTimeRef}
                name="in_blk_time"
                id="in_blk_time"
                className="form-control"
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="off_time">
              OFF Time
            </label>
            <div className="admin-datepicker">
              <input
                ref={offTimeRef}
                name="off_time"
                id="off_time"
                className="form-control"
                readOnly
              />
            </div>
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="on_time">
              On Time
            </label>
            <div className="admin-datepicker">
              <input
                ref={onTimeRef}
                name="on_time"
                id="on_time"
                className="form-control"
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="tat">
              TAT
            </label>
            <input
              className="form-control"
              id="tat"
              value={data.tat || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="cycles">
              Cycles
            </label>
            <input
              className="form-control"
              id="cycles"
              value={data.cycles || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 form-group">
            <label htmlFor="max_allow_gtow">
              Max Allow GTOW
            </label>
            <input
              className="form-control"
              id="max_allow_gtow"
              value={data.max_allow_gtow || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-4 form-group">
            <label htmlFor="delay_code">
              Delay Code
            </label>
            <input
              className="form-control"
              id="delay_code"
              value={data.delay_code || ''}
              onChange={e => handleChange(e)}
            />
            {/* <select
              className="form-control"
              id="delay_code"
              value={data.delay_code || ''}
              onChange={e => handleChange(e)}
            >
              <option value="">Please Select</option>
              <option value="MX">MX</option>
              <option value="OP">OP</option>
              <option value="CU">CU</option>
              <option value="GH">GH</option>
              <option value="WX">WX</option>
              <option value="AT">AT</option>
              <option value="DI">DI</option>
            </select> */}
          </div>
          <div className="col-sm-4 form-group">
            <label htmlFor="airc_config">
              AIRC Config
            </label>
            <input
              className="form-control"
              id="airc_config"
              value={data.airc_config || ''}
              onChange={e => handleChange(e)}
            />
            {/* <select
              className="form-control"
              id="airc_config"
              value={data.airc_config || ''}
              onChange={e => handleChange(e)}
            >
              <option value="">Please Select</option>
              <option value="P">P</option>
              <option value="C">C</option>
            </select> */}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="fuel_remaining">
              Fuel remaining (lbs)
            </label>
            <input
              className="form-control"
              id="fuel_remaining"
              value={data.fuel_remaining || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="fuel_used">
              Fuel used (lbs)
            </label>
            <input
              className="form-control"
              id="fuel_used"
              value={data.fuel_used || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="lbs_brh">
              Lbs/Brh
            </label>
            <input
              className="form-control"
              id="lbs_brh"
              value={data.lbs_brh || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="lbs_fhr">
              Lbs/Fhr
            </label>
            <input
              className="form-control"
              id="lbs_fhr"
              value={data.lbs_fhr || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="left_oil_used">
              Left oil used
            </label>
            <input
              className="form-control"
              id="left_oil_used"
              value={data.left_oil_used || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="right_oil_used">
              Right oil used
            </label>
            <input
              className="form-control"
              id="right_oil_used"
              value={data.right_oil_used || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="take_off">
              Take off
            </label>
            <input
              className="form-control"
              id="take_off"
              value={data.take_off || ''}
              onChange={e => handleChange(e)}
            />
            {/* <select
              className="form-control"
              id="take_off"
              value={data.take_off || ''}
              onChange={e => handleChange(e)}
            >
              <option value="">Please Select</option>
              <option value="D">D</option>
              <option value="N">N</option>
            </select> */}
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="take_off_by">
              Take off by
            </label>
            <input
              className="form-control"
              id="take_off_by"
              value={data.take_off_by || ''}
              onChange={e => handleChange(e)}
            />
            {/* <select
              className="form-control"
              id="take_off_by"
              value={data.take_off_by || ''}
              onChange={e => handleChange(e)}
            >
              <option value="">Please Select</option>
              <option value="P">P</option>
              <option value="F">F</option>
            </select> */}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="landing">
              Landing
            </label>
            <input
              className="form-control"
              id="landing"
              value={data.landing || ''}
              onChange={e => handleChange(e)}
            />
            {/* <select
              className="form-control"
              id="landing"
              value={data.landing || ''}
              onChange={e => handleChange(e)}
            >
              <option value="">Please Select</option>
              <option value="D">D</option>
              <option value="N">N</option>
            </select> */}
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="landing_by">
              Lading by
            </label>
            <input
              className="form-control"
              id="landing_by"
              value={data.landing_by || ''}
              onChange={e => handleChange(e)}
            />
            {/* <select
              className="form-control"
              id="landing_by"
              value={data.landing_by || ''}
              onChange={e => handleChange(e)}
            >
              <option value="">Please Select</option>
              <option value="P">P</option>
              <option value="F">F</option>
            </select> */}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="type_of_approach">
              Type of Approach
            </label>
            <input
              className="form-control"
              id="type_of_approach"
              value={data.type_of_approach || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="approach_by">
              Approach by
            </label>
            <input
              className="form-control"
              id="approach_by"
              value={data.approach_by || ''}
              onChange={e => handleChange(e)}
            />
            {/* <select
              className="form-control"
              id="approach_by"
              value={data.approach_by || ''}
              onChange={e => handleChange(e)}
            >
              <option value="">Please Select</option>
              <option value="P">P</option>
              <option value="F">F</option>
            </select> */}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="pir_ifc">
              PIR IFC
            </label>
            <div className="admin-datepicker">
              <input
                ref={PIRIFCRef}
                name="pir_ifc"
                id="pir_ifc"
                className="form-control"
                readOnly
              />
            </div>
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="pfo_ifc">
              PFO IFC
            </label>
            <div className="admin-datepicker">
              <input
                ref={PFOIFCRef}
                name="pfo_ifc"
                id="pfo_ifc"
                className="form-control"
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="night_flight_hours">
              Night flight Hours
            </label>
            <input
              className="form-control"
              id="night_flight_hours"
              value={data.night_flight_hours || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="leg_approach">
              Leg Approach NM
            </label>
            <input
              className="form-control"
              id="leg_approach"
              value={data.leg_approach || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="gallons_uploaded">
              Gallons Uploaded
            </label>
            <input
              className="form-control"
              id="gallons_uploaded"
              value={data.gallons_uploaded || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="leg_approx">
              Leg approx NM
            </label>
            <input
              className="form-control"
              id="leg_approx"
              value={data.leg_approx || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="takeoff_fuel_onboard">
              Takeoff fuel onboard (lbs)
            </label>
            <input
              className="form-control"
              id="takeoff_fuel_onboard"
              value={data.takeoff_fuel_onboard || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="unaccompanied_minors">
              Unaccompanied Minor(s)
            </label>
            <input
              className="form-control"
              id="unaccompanied_minors"
              value={data.unaccompanied_minors || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="wheelchairs">
              Wheelchairs
            </label>
            <input
              className="form-control"
              id="wheelchairs"
              value={data.wheelchairs || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="aisle_chairs">
              Aisle chairs
            </label>
            <input
              className="form-control"
              id="aisle_chairs"
              value={data.aisle_chairs || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="passengers_need_assist">
              Passengers need assist
            </label>
            <input
              className="form-control"
              id="passengers_need_assist"
              value={data.passengers_need_assist || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="lap_children">
              Lap Children
            </label>
            <input
              className="form-control"
              id="lap_children"
              value={data.lap_children || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="additional_crew_members">
              Additional Crew members
            </label>
            <input
              className="form-control"
              id="additional_crew_members"
              value={data.additional_crew_members || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="cabin_jump_seat">
              Cabin jump seat
            </label>
            <input
              className="form-control"
              id="cabin_jump_seat"
              value={data.cabin_jump_seat || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="operations">
              Operations
            </label>
            <input
              className="form-control"
              id="operations"
              value={data.operations || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="other_cateing">
              Other/Cateing
            </label>
            <input
              className="form-control"
              id="other_cateing"
              value={data.other_cateing || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="hobbs_out">
              Hobbs Out
            </label>
            <input
              className="form-control"
              id="hobbs_out"
              value={data.hobbs_out || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="hobbs_in">
              Hobbs In
            </label>
            <input
              className="form-control"
              id="hobbs_in"
              value={data.hobbs_in || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="maintenance">
              Maintenance
            </label>
            <input
              className="form-control"
              id="maintenance"
              value={data.maintenance || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="ramp">
              Ramp
            </label>
            <input
              className="form-control"
              id="ramp"
              value={data.ramp || ''}
              onChange={e => handleChange(e)}
            />
            {/* <select
              className="form-control"
              id="ramp"
              value={data.ramp || ''}
              onChange={e => handleChange(e)}
            >
              <option value="">Please Select</option>
              <option value="Y">Y</option>
              <option value="N">N</option>
            </select> */}
          </div>
        </div>
        <div className="form-group" style={{ marginTop: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button
              type="button"
              className="btn bg-olive"
              title="Continue"
              style={{ marginRight: '10px' }}
              onClick={handleSubmit}
            >
              {`${isEdit ? 'Update' : 'Add'} Flight Operation`}
            </button>
            <button className="btn btn-primary continue_cancel" title="Cancel" onClick={onClose}>Cancel</button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

OperationModal.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

OperationModal.defaultProps = {
  open: false,
  isEdit: false,
};

export default OperationModal;
