import React, { useState } from 'react';
import PropTypes from 'prop-types';

const MessageInput = ({
  message, onChange, onKeyPress,
}) => {
  const [cursorPosition, setCursorPostions] = useState(0);

  return (
    <textarea
      className="messageInput"
      onChange={(e) => {
        setCursorPostions(e.currentTarget.selectionStart);
        onChange(e.currentTarget.value);
      }}
      aria-describedby="message_help_text"
      id="message-input"
      name="message-input"
      value={message}
      onFocus={e => e.currentTarget.setSelectionRange(cursorPosition, cursorPosition)}
      onKeyPress={onKeyPress}
    />
  );
};

MessageInput.propTypes = {
  message: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
};

MessageInput.defaultProps = {
  message: '',
};

export default MessageInput;
