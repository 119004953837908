/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import qs from 'qs';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import api from '../../services/api';
import ConfirmationDialog from '../common/ConfirmationDialog';
import FieldsModal from '../common/FieldsModal';
import { DL_ROUTE_FIELDS, sortHelper } from '../../services/helpers';
import DLRouteEditModal from './DLRouteEditModal';

const fixDateFormat = (date) => {
  if (!date) return '';
  const localDate = new Date(date);
  return new Date(localDate.getTime() - (localDate.getTimezoneOffset() * 60000))
    .toISOString()
    .split('T')[0];
};

const DLRoutes = ({
  permission, newRouteManifestPath, routeManifests, defaultLimit, allRecordsCount,
  timezoneFromServer, currentUserType, userTypes, drivers, // mergeRouteManifestsPath,
}) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || 10,
  });
  const [selectedRow, setSelectedRow] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [filter, setFilter] = useState({});
  const [routeByDay, setRouteByDay] = useState(fixDateFormat(urlSearchParams.generate_filter_date));
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(urlSearchParams.filter_status || '');
  const [selectedLevel, setSelectedLevel] = useState(urlSearchParams.filter_level || '');
  const [order, setOrder] = useState({ field: urlSearchParams.sort || 'id', type: urlSearchParams.direction || 'desc' });
  const [openEdit, setOpenEdit] = useState(false);

  const [serverTimezone, setServerTimezone] = useState('America/Chicago');

  useEffect(() => {
    const tz = timezoneFromServer?.info?.identifier;
    if (tz) {
      setServerTimezone(tz);
    } else {
      setServerTimezone('America/Chicago');
    }
  }, [timezoneFromServer]);

  useEffect(() => {
    setRows(routeManifests);
    setPaginationOptions({
      ...paginationOptions,
      count: allRecordsCount,
      limit: defaultLimit,
    });
  }, [routeManifests, allRecordsCount, defaultLimit]);

  useEffect(() => {
    handleFilter();
  }, [filter, order]);

  const handleFilter = useCallback((page, limit) => {
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filters = {
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
      filter_level: selectedLevel,
      filter_status: selectedStatus,
      generate_filter_date: routeByDay,
      direction: order.type,
      sort: order.field,
      ...filter,
    };
    api.routesDashboard.dlRoutes(filters)
      .then((res) => {
        setRows(res.records);
        setPaginationOptions({
          count: res.all_records,
          page: fixedPage || 0,
          limit: limit || paginationOptions.limit,
        });
        const url = `/admin/dl_routes?${qs.stringify(filters)}`;
        window.history.replaceState(null, '', url);
      })
      .catch(err => console.log(err));
  }, [paginationOptions, selectedStatus, selectedLevel, routeByDay, filter, order]);

  const handleChangePage = (e, newPage) => {
    handleFilter(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    handleFilter(0, parseInt(e.target.value, 10));
  };

  const handleReset = () => {
    setSelectedLevel('');
    setRouteByDay('');
    setSelectedStatus('');
  };

  const handleDelete = useCallback(() => {
    api.routesDashboard.deleteRoute(selectedRow.id).then(() => {
      setOpenDelete(false);
      handleFilter(paginationOptions.page, paginationOptions.limit);
    });
  }, [selectedRow]);

  const getFields = () => {
    const defaultArray = [
      {
        header: 'Date', key: 'date', dateFilter: true, sortable: true,
      },
      {
        header: 'Level', key: 'route_manifest_type', filtering: true, filterKey: 'level', sortable: true,
      },
      { header: 'Packages', key: 'packages_count_for_user_role', sortable: true },
      {
        header: 'Status', key: 'status', filtering: true, sortable: true,
      },
      {
        header: 'Package Cluster', key: 'package_cluster_id', sortKey: 'route_manifests.package_cluster_id', format: true, filtering: true, sortable: true,
      },
      {
        header: 'Driver', key: 'driver', format: true, sortable: true,
      },
      { header: 'Actions', key: 'actions', format: true },
    ];

    return defaultArray;
  };

  const handleUpdateFields = (updatedFields, save = true) => {
    setSelectedFields(getFields().filter(f => updatedFields.includes(f.key)));
    setShowFieldsModal(false);
    if (save) window.localStorage.setItem(DL_ROUTE_FIELDS, updatedFields.join(','));
  };

  useEffect(() => {
    const savedFields = window.localStorage.getItem(DL_ROUTE_FIELDS);
    if (!savedFields) {
      setSelectedFields(getFields());
      return;
    }

    handleUpdateFields(savedFields.split(','), false);
  }, []);

  const handleSort = (field) => {
    const updatedOrder = { ...sortHelper(field, order) };
    setOrder(updatedOrder);
  };

  const handleEdit = () => {
    handleFilter(paginationOptions.page, paginationOptions.limit);
  };

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">DL Routes</h1>
              <div className="pull-right">
                <button className="btn btn-primary" onClick={() => setShowFieldsModal(true)}>Change selected fields</button>
                {permission.routeManifests?.add && (
                  <>
                    {/* <a href={mergeRouteManifestsPath} data-remote="true" className="btn btn-primary" style={{ marginLeft: '5px' }}>Merge Lists</a> */}
                    <a href={newRouteManifestPath} data-remote="true" className="btn bg-olive margin add-btn" style={{ marginLeft: '5px' }}>Add New Route Manifest</a>
                  </>
                )}
              </div>
            </div>
            {(currentUserType === userTypes.SUPER_ADMIN_TYPE || currentUserType === userTypes.OWNER_TYPE || currentUserType === userTypes.BROKER_ADMIN_TYPE) && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="status-sec">
                  <div className="form-inline" style={{ display: 'flex' }}>
                    <select
                      name="filter_status"
                      id="filter_status"
                      className="form-control"
                      value={selectedStatus}
                      onChange={(e) => {
                        setSelectedStatus(e.target.value);
                      }}
                    >
                      <option value="">Select status</option>
                      <option value="unassigned">Unassigned</option>
                      <option value="assigned">Assigned</option>
                      <option value="rejected">Rejected</option>
                      <option value="accepted">Accepted</option>
                    </select>
                    <select
                      name="filter_level"
                      id="filter_level"
                      className="form-control"
                      value={selectedLevel}
                      onChange={(e) => {
                        setSelectedLevel(e.target.value);
                      }}
                    >
                      <option value="">Select level</option>
                      <option value="level_one">Level 1</option>
                      <option value="level_two">Level 2</option>
                    </select>
                    <div className="datepicker">
                      <input
                        type="date"
                        className="form-control dimensions length"
                        id="route_date"
                        name="route_date"
                        value={routeByDay}
                        onChange={e => setRouteByDay(e.target.value)}
                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                      />
                    </div>
                    <button style={{ margin: '0 5px' }} className="btn-primary btn" onClick={() => handleFilter()}>Submit</button>
                    <button className="btn-danger btn pickups_list_filter_reset" onClick={handleReset}>Reset</button>
                  </div>
                </div>
              </div>
            )}
            <div className="box-body main_listing">
              <CustomTable
                rows={rows}
                isFilteringActive
                setFilter={setFilter}
                filter={filter}
                formatters={{
                  actions: value => (
                    <div className="text-center" style={{ whiteSpace: 'nowrap' }}>
                      {permission.routeManifests?.edit && (
                        <>
                          <a
                            className="fa fa-edit"
                            onClick={() => {
                              setSelectedRow(value);
                              setOpenEdit(true);
                            }}
                          />
                          <a
                            className="fa fa-trash space-left-right delete-package-pickup-list"
                            onClick={() => {
                              setOpenDelete(true);
                              setSelectedRow(value);
                            }}
                          />
                        </>
                      )}
                      <a
                        className="fa fa-eye show-package-pickup-list"
                        onClick={() => {
                          setSelectedRow(value);
                        }}
                        href={`/admin/route_manifests/${value.id}`}
                      />
                    </div>
                  ),
                  package_cluster_id: value => (
                    <span>{value.package_cluster ? `${value.package_cluster.region_id_lvl1} @ ${value.package_cluster.airport_code}` : ''}</span>
                  ),
                  driver: value => (
                    <span>
                      {value.driver?.user
                    && (
                      <>
                        {value.driver.user.first_name || ''}
                        {' '}
                        {value.driver.user.last_name || ''}
                        <br />
                        {value.driver.user.email || ''}
                      </>
                    )}
                    </span>
                  ),
                  packages_count_for_user_role: value => (
                    <span>{value || 0}</span>
                  ),
                  status: value => (
                    <span>{value}</span>
                  ),
                }}
                rowsProps={selectedFields}
                paginationOptions={{
                  ...paginationOptions,
                  handleChangePage,
                  handleChangeRowsPerPage,
                }}
                rowStyle={(row) => {
                  const status = get(row, 'status');
                  if (status === 'accepted') return ({ backgroundColor: '#90ee90' });
                  if (status === 'rejected') return ({ backgroundColor: '#ff000070' });
                  return ({});
                }}
                suffix="route_manifest"
                handleSort={handleSort}
                sortOptions={order}
              />
            </div>
            <ConfirmationDialog
              onConfirm={handleDelete}
              onClose={() => setOpenDelete(false)}
              message="Are you sure you want to delete this route?"
              open={openDelete}
            />
            {
              showFieldsModal && <FieldsModal open={showFieldsModal} fields={getFields()} selectedFields={selectedFields} updateFields={handleUpdateFields} handleClose={() => setShowFieldsModal(false)} />
            }
            {
              openEdit
              && (
                <DLRouteEditModal
                  open
                  onClose={() => setOpenEdit(false)}
                  onConfirm={handleEdit}
                  drivers={drivers}
                  row={selectedRow}
                  serverTimezone={serverTimezone}
                />
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

DLRoutes.propTypes = {
  routeManifests: PropTypes.instanceOf(Array).isRequired,
  currentUserType: PropTypes.number.isRequired,
  userTypes: PropTypes.instanceOf(Object).isRequired,
  allRecordsCount: PropTypes.number.isRequired,
  defaultLimit: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  timezoneFromServer: PropTypes.any.isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
  drivers: PropTypes.instanceOf(Array).isRequired,
  newRouteManifestPath: PropTypes.string.isRequired,
};

export default DLRoutes;
