import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import ConsolidationSummary from '../common/ConsolidationSummary';
import AddPackageToBagModal from './AddPackageToBagModal';


const BagSummaryModal = ({
  handleClose, open, bags, handleUpdate, permission,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedBag, setSelectedBad] = useState();
  const handleAdd = async (data) => {
    setSelectedBad(data);
    setShowModal(true);
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Consolidation summary
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        {bags.map(bagData => (
          <ConsolidationSummary key={bagData.code} bagData={bagData} handleAdd={() => handleAdd(bagData)} handleUpdate={handleUpdate} permission={permission} />
        ))}
      </DialogContent>
      {
        showModal && (
          <AddPackageToBagModal
            open={showModal}
            handleClose={() => setShowModal(false)}
            bagCode={selectedBag.code}
            handleUpdate={handleUpdate}
          />
        )
      }
    </Dialog>
  );
};

BagSummaryModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  bags: PropTypes.instanceOf(Array).isRequired,
  open: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
};

BagSummaryModal.defaultProps = {
  open: false,
};

export default BagSummaryModal;
