import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import api from '../../services/api';

const UploadModal = ({
  handleClose, open, callback, pickupId,
}) => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectFileRef = useRef(null);

  useEffect(() => {
    if (loading) {
      $('.overlay').show();
    } else {
      $('.overlay').hide();
    }
  }, [loading]);

  const handleSelectFile = useCallback(async (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoading(true);
      const prefix = `pickup/${pickupId}/`;
      api.s3.getPresignedUrl({ prefix, filename: file.name }).then((res) => {
        api.s3.uploadFile(file, res.data.presigned_url).then(() => {
          setPhotos([...photos, res.data.public_url]);
          api.pickupsDashboard.addPickupPhoto(pickupId, { url: res.data.public_url }).then(() => setLoading(false));
        });
      });
    }
  }, [photos]);

  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Upload Photo
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div>
          <input ref={selectFileRef} type="file" onChange={handleSelectFile} style={{ width: 0, height: 0 }} />
          <button
            className="btn add-btn prevent_right_click add_warehouses_from_file"
            onClick={() => selectFileRef.current.click()}
          >
            Choose photo
          </button>
          {photos.length > 0 && (
            <>
              <h4>Gallery</h4>
              <div className="block">
                <div className="gallery">
                  {photos.map((photo, index) => photo && (
                    <div id={`photo-${index}`} key={`photo-${index.toString()}`} style={{ marginBottom: '20px' }}>
                      <img
                        src={photo}
                        alt="img"
                        onError={() => {
                          document.getElementById(`photo-${index}`).style.display = 'none';
                        }}
                      />
                    </div>
                  ))
                  }
                </div>
              </div>
            </>
          )}
        </div>
        <div className="modal-bottom-btn text-right">
          <button className="btn btn-primary" onClick={() => { handleClose(); callback(); }}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

UploadModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  pickupId: PropTypes.number.isRequired,
  open: PropTypes.bool,
};

UploadModal.defaultProps = {
  open: false,
};

export default UploadModal;
