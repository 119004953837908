import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import api from '../../services/api';
import { successToast, errorToast } from '../../services/toast';

const SendMessageModal = ({
  handleClose, open, driver,
}) => {
  const [message, setMessage] = useState('');

  const handleSubmit = async () => {
    if (!message) {
      errorToast('Enter message please!');
      return;
    }

    api.routesDashboard.sendMessageToDriver({ driver_id: driver.id, message })
      .then((res) => {
        if (res.status) {
          successToast(res.message);
          handleClose();
        } else {
          errorToast(res.message);
        }
      })
      .catch(err => console.log(err));
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        {`Send Message to ${driver.name}`}
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row" style={{ marginLeft: 10, marginRight: 10 }}>
          <div className="col-sm-12 ">
            <div>Message</div>
            <textarea id="message" rows="4" aria-label="message-input" className="form-control" onChange={e => setMessage(e.target.value)} />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="modal-bottom-btn text-right" style={{ borderTop: 'none', paddingRight: '15%' }}>
          <button className="btn bg-olive" onClick={handleSubmit}>Confirm</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={handleClose}>Close</button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

SendMessageModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  driver: PropTypes.instanceOf(Object).isRequired,
  open: PropTypes.bool,
};

SendMessageModal.defaultProps = {
  open: false,
};

export default SendMessageModal;
