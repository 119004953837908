import React, {
  useEffect, useState, Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { Paper, TableContainer } from '@material-ui/core';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import TableScrollable from '../common/TableScrollable';

const PivotTable = ({
  data, suffix,
}) => {
  const [routeSpoiler, setRouteSpoiler] = useState([]);
  const [addressSpoiler, setAddressSpoiler] = useState([]);

  const statuses = Object.keys(data.totals).filter(totalKey => totalKey !== 'Grand Total').sort((a, b) => (a.totalKey > b.totalKey ? 1 : -1));

  const setupScrolls = () => {
    const scrollWidth = $(`#${suffix}-head`).width();
    $(`#top-horizontal-scroll-${suffix}`).width(scrollWidth);

    $(() => {
      $(`#${suffix}`).scroll(() => {
        $(`#top-horizontal-scrollbar-${suffix}`).scrollLeft($(`#${suffix}`).scrollLeft());
      });
      $(`#top-horizontal-scrollbar-${suffix}`).scroll(() => {
        $(`#${suffix}`).scrollLeft($(`#top-horizontal-scrollbar-${suffix}`).scrollLeft());
      });
    });
  };

  const handleSpoilerToggle = (key, type, close) => {
    let target;
    let setTarget;
    switch (type) {
    case 'route':
      target = routeSpoiler;
      setTarget = setRouteSpoiler;
      break;
    case 'address':
      target = addressSpoiler;
      setTarget = setAddressSpoiler;
      break;
    default:
      return;
    }
    let newArray;
    if (close) {
      newArray = target.filter(x => x !== key);
    } else {
      newArray = [...target];
      newArray.push(key);
    }
    setTarget(newArray);
  };

  useEffect(() => {
    setupScrolls();

    $(document).on('shown.bs.modal', () => {
      setupScrolls();
    });

    $(window).on('resize', () => {
      setupScrolls();
    });

    const scroll = document.querySelector(`#${suffix}-head`);
    new ResizeObserver(setupScrolls).observe(scroll);
  }, []);

  return (
    <>
      <TableScrollable suffix={suffix}>
        <TableContainer component={Paper} id={suffix} style={{ maxHeight: '90vh', minHeight: '500px' }}>
          <table className="table pivotTable">
            <thead id={`${suffix}-head`}>
              <tr>
                <th>Row Labels</th>
                {statuses.map(totalKey => (
                  <th key={`header-${totalKey}`}>
                    {totalKey}
                  </th>
                ))}
                <th>
                  Grand Total
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data).filter(key => key !== 'totals').sort().map(key => (
                <Fragment key={key}>
                  <tr className="bold">
                    <td style={{ paddingLeft: 10 }}>
                      {
                        routeSpoiler.includes(key)
                          ? <RemoveCircleOutline onClick={() => handleSpoilerToggle(key, 'route', true)} />
                          : <AddCircleOutline onClick={() => handleSpoilerToggle(key, 'route', false)} />
                      }
                      <span>{key}</span>
                    </td>
                    {statuses.map(totalKey => (
                      <td key={`${key}-data-row-${totalKey}`}>
                        {data[key].totals[totalKey]}
                      </td>
                    ))}
                    <td>
                      {data[key].totals['Grand Total']}
                    </td>
                  </tr>
                  {routeSpoiler.includes(key) && Object.keys(data[key]).filter(key2 => key2 !== 'totals').map(key2 => (
                    <Fragment key={`${key}-${key2}`}>
                      <tr className="bold">
                        <td style={{ paddingLeft: 25 }}>
                          {
                            addressSpoiler.includes(`${key}-${key2}`)
                              ? <RemoveCircleOutline onClick={() => handleSpoilerToggle(`${key}-${key2}`, 'address', true)} />
                              : <AddCircleOutline onClick={() => handleSpoilerToggle(`${key}-${key2}`, 'address', false)} />
                          }
                          <span>{key2}</span>
                        </td>
                        {statuses.map(totalKey => (
                          <td key={`${key}-${key2}-data-row-${totalKey}`}>
                            {data[key][key2].totals[totalKey]}
                          </td>
                        ))}
                        <td>
                          {data[key][key2].totals['Grand Total']}
                        </td>
                      </tr>
                      {addressSpoiler.includes(`${key}-${key2}`) && Object.keys(data[key][key2]).filter(key3 => key3 !== 'totals').map(key3 => (
                        <Fragment key={`${key}-${key2}-${key3}`}>
                          <tr>
                            <td style={{ paddingLeft: 40 }}>
                              <span>{key3}</span>
                            </td>
                            {statuses.map(totalKey => (
                              <td key={`${key}-${key2}-${key3}-data-row-${totalKey}`}>
                                {data[key][key2][key3].totals[totalKey]}
                              </td>
                            ))}
                            <td>
                              {data[key][key2][key3].totals['Grand Total']}
                            </td>
                          </tr>
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
              <tr className="bold">
                <td>Grand Total</td>
                {statuses.map(totalKey => (
                  <td key={`grant-total-${totalKey}`}>
                    {data.totals[totalKey]}
                  </td>
                ))}
                <td>
                  {data.totals['Grand Total']}
                </td>
              </tr>
            </tbody>
          </table>
        </TableContainer>
      </TableScrollable>
    </>
  );
};


PivotTable.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  suffix: PropTypes.string.isRequired,
};

export default PivotTable;
