import { useState, useEffect } from 'react';
import print from 'print-js';
import {
  capitalize, isEmpty, isEqual, uniqWith, get,
} from 'lodash';
import moment from 'moment-timezone';

/* eslint-disable no-param-reassign, camelcase, consistent-return, no-plusplus */
export const printLabel = (value) => {
  $('<div id="print" style="position: fixed; top: 0; width: 0; height: 0; left: 0">')
    .append(value)
    .appendTo('body');
  setTimeout(() => {
    print({ printable: 'print', type: 'html' });
    $('#print').remove();
  }, 100);
};

export const printZplLabels = ({
  fedex_ups_print_zpl, rfl_print_zpl, fedex_ups_printer_ips, rfl_printer_ips,
}) => {
  const fedexUpsZpl = JSON.parse(window.localStorage.getItem('fedex-ups-print-zpl'));
  const rflZpl = JSON.parse(window.localStorage.getItem('rfl-print-zpl'));

  if (fedexUpsZpl && fedexUpsZpl.fedex_ups_print_zpl && fedexUpsZpl.fedex_ups_print_zpl.length > 0) fedex_ups_print_zpl = fedexUpsZpl.fedex_ups_print_zpl.concat(fedex_ups_print_zpl);
  if (fedexUpsZpl && fedexUpsZpl.fedex_ups_printer_ips && fedexUpsZpl.fedex_ups_printer_ips.length > 0) fedex_ups_printer_ips = fedexUpsZpl.fedex_ups_printer_ips.concat(fedex_ups_printer_ips);
  if (rflZpl && rflZpl.rfl_print_zpl && rflZpl.rfl_print_zpl.length > 0) rfl_print_zpl = rflZpl.rfl_print_zpl.concat(rfl_print_zpl);
  if (rflZpl && rflZpl.rfl_printer_ips && rflZpl.rfl_printer_ips.length > 0) rfl_printer_ips = rflZpl.rfl_printer_ips.concat(rfl_printer_ips);

  window.localStorage.setItem('fedex-ups-print-zpl', JSON.stringify({ fedex_ups_print_zpl, fedex_ups_printer_ips }));
  window.localStorage.setItem('rfl-print-zpl', JSON.stringify({ rfl_print_zpl, rfl_printer_ips }));
};

export const splitArrayPerChunks = (inputArray, perChunk) => inputArray.reduce((resultArray, item, index) => {
  const chunkIndex = Math.floor(index / perChunk);

  if (!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [];
  }

  resultArray[chunkIndex].push(item);

  return resultArray;
}, []);

export const setList = (firstArray, secondArray, type) => {
  switch (type) {
  case 1: return firstArray;
  case 2: return uniqWith([...firstArray, ...secondArray], isEqual);
  case 3: return secondArray;
  default: return [];
  }
};

export const isFormValid = ({
  origin, destination, packages, carrier_id, carrier_service_id, ...rest
}) => {
  let outboundError = false;
  if (Object.keys(origin).some(key => !isEmpty(origin[key]))) return false;
  if (Object.keys(destination).some(key => !isEmpty(destination[key]))) return false;
  if (Object.keys(carrier_id).some(key => !isEmpty(carrier_id[key]))) return false;
  if (Object.keys(carrier_service_id).some(key => !isEmpty(carrier_service_id[key]))) return false;

  Object.keys(packages.outbound).forEach((item) => {
    if (Object.keys(packages.outbound[item]).some(key => !isEmpty(packages.outbound[item][key]))) {
      outboundError = true;
      return false;
    }
  });
  if (outboundError) return false;

  if (Object.keys(packages.return[0]).some(key => !isEmpty(packages.return[0][key]))) return false;
  return !Object.keys(rest).some(key => !isEmpty(rest[key]));
};

export const isPickupFormValid = ({
  origin, pack, ...rest
}) => {
  if (Object.keys(origin).some(key => !isEmpty(origin[key]))) return false;
  if (Object.keys(pack).some(key => !isEmpty(pack[key]))) return false;
  return !Object.keys(rest).some(key => !isEmpty(rest[key]));
};

export const isAddressValid = data => !Object.keys(data).some(key => !isEmpty(data[key]));

export const setPackageErrors = (errors, pack, index, type) => {
  const err = { ...errors };
  Object.keys(pack).forEach((key) => {
    if (key === 'package_type_id' && !pack[key]) err.packages[type][index].package_type_id = 'Please select package type';
    if (key === 'quantity' && !pack[key]) err.packages[type][index].quantity = 'Please enter quantity';
    if (key === 'weight' && !pack[key]) {
      if (!pack[key]) err.packages[type][index].weight = 'Please enter weight';
      if (pack[key] && !pack[key].match('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')) err.packages[type][index].weight = 'Weight should be a number';
    }
    if (['width', 'height', 'length'].includes(key)) {
      if (!pack[key]) err.packages[type][index][key] = `Please enter ${key}`;
      if (pack[key] && !pack[key].match('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')) err.packages[type][index][key] = `${capitalize(key)} should be a number`;
      if (parseFloat(pack[key]) < 1) err.packages[type][index][key] = `${capitalize(key)} should be greater than or equal to 1`;
    }
  });
  return err;
};

export const sortHelper = (field, options) => {
  let sort = { ...options };
  if (options.field === field && options.type === 'asc') {
    sort.type = 'desc';
  } else if (options.field === field && options.type === 'desc') {
    sort.type = 'asc';
  } else if (options.field !== field) {
    sort = { field, type: 'asc' };
  }

  return sort;
};

export const base64ToObjectURL = (string) => {
  const csvContent = atob(string);
  const blob = new Blob([csvContent], { type: 'data:application/octet-stream;base64' });
  return window.URL.createObjectURL(blob);
};

export const computeTotalDistance = (result) => {
  let total_distance = 0;
  let total_time = 0;
  const myroute = result.routes[0];
  let res = {};
  if (myroute) {
    for (let i = 0; i < myroute.legs.length; i++) {
      total_distance += myroute.legs[i].distance.value;
      total_time += myroute.legs[i].duration.value;
    }

    total_distance /= 1609.34;
    total_time = secondsToHms(total_time);

    res = {
      totalDistance: `Total distance: ${total_distance.toFixed(2)} mi`,
      totalTime: `Total time: ${total_time}`,
    };
  }

  return res;
};

export const secondsToHms = (d) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
  return hDisplay + mDisplay + sDisplay;
};

export const getCoords = (value) => {
  const [lat, lng] = value.split(',');
  return { lat: parseFloat(lat), lng: parseFloat(lng) };
};

export const getIsTestModalConfirmMessage = isTest => (isTest ? 'Are you sure you want to stop using this pickup for testing?' : 'Are you sure you want to use this pickup for testing?');

// eslint-disable-next-line no-unused-vars
export const checkPickupFormForErrors = (recordData, originAddress, timeData, pack, radiusFlexId, isSchedule, nearestAirport) => {
  const {
    company_id, carrier_id, carrier_service_id, selectedDays,
  } = recordData;
  const {
    // eslint-disable-next-line no-unused-vars
    pickupDate, readyTime, closeTime, serverTimezone, addressCutoffTime, overrideCutoffTime,
  } = timeData;
  const errors = {};
  // eslint-disable-next-line no-unused-vars
  const isHasten = carrier_id && carrier_id === radiusFlexId;

  if (!company_id) errors.company_id = 'Please select company';
  if (!carrier_id) errors.carrier_id = 'Please select carrier';
  if (!carrier_service_id) errors.carrier_service_id = 'Please select carrier service';
  if (!isSchedule && !pickupDate) errors.pickupDate = 'Please enter value';
  if (!readyTime) errors.readyTime = 'Please enter value';
  if (!closeTime) errors.closeTime = 'Please enter value';
  if (!isSchedule && pickupDate) errors.pickupDate = '';
  if (readyTime) errors.readyTime = '';
  if (closeTime) errors.closeTime = '';
  if (isSchedule && selectedDays.length === 0) errors.scheduleDays = 'Please select at least one day!';

  const time = moment.tz(`2000/01/01 ${readyTime}`, 'YYYY/M/D hh:mm a', serverTimezone);
  if (time.hour() >= 22) errors.readyTime = 'Ready time can not be greater than 9:59 pm';
  const timeEnd = moment.tz(`2000/01/01 ${closeTime}`, 'YYYY/M/D hh:mm a', serverTimezone);
  // difference between Close and Ready
  const diff = timeEnd.diff(time);
  if (!isSchedule && diff <= 0) {
    errors.closeTime = 'Close time must be greater than ready time';
  }

  // const nowDiffHours = (+timeEnd.getHours() - (new Date()).getHours());
  // const nowDiffMinutes = Math.abs(+timeEnd.getMinutes() - (new Date()).getMinutes());
  // const nowDiff = (nowDiffHours * 60) + nowDiffMinutes;

  // These checks apply only to Radius Flex pickups
  // if (isHasten && nowDiff < 60) errors.closeTime = 'Close time must be at least 1 hr from now';

  // origin
  errors.origin = {};
  Object.keys(originAddress).forEach((key) => {
    if (!['street_address2', 'attention_to', 'cutoffTime', 'customer_id'].includes(key) && !originAddress[key]) errors.origin[key] = 'Please enter value';
  });

  // pack
  errors.pack = {};
  Object.keys(pack).forEach((key) => {
    if (!['weight_calculated_in'].includes(key) && !pack[key]) errors.pack[key] = 'Please enter value';
  });
  return errors;
};

export const getPickupRowStyle = (row, serverTimezone, radiusFlexCarrierName = null) => {
  if ((!(row.pickup_list1 || row.pickup_list2 || row.pickup_list3 || row.pickup_list4) && (radiusFlexCarrierName != null && (get(row, 'carrier.name') === radiusFlexCarrierName)))
    || get(row, 'is_test') === true) return ({ backgroundColor: '#ff0000b3' });
  if (get(row, 'is_on_call')) {
    return ({ backgroundColor: '#ffce00' });
  }
  if (get(row, 'is_ups') || get(row, 'carrier.carrier_type') === 'UPS') return ({ backgroundColor: '#33000070' });
  if (get(row, 'is_fedex') || get(row, 'carrier.carrier_type') === 'FEDEX') return ({ backgroundColor: '#4d148cb3' });
  return {};
};

export const getDateForTimeComparison = (date) => {
  if (date.date() === 31) date.add(1, 'day');
  if (date.date() === 2) date.subtract(1, 'day');

  return date;
};


export const PICKUPS_FIELDS = 'pickupsTableFields';
export const SCHEDULED_PICKUPS_FIELDS = 'scheduledPickupsTableFields';
export const ROUTE_PICKUPS_FIELDS = 'rlNetworkPickupsTableFields';
export const RL_ROUTE_PICKUPS_FIELDS = 'routePickupsTableFields';
export const ROUTE_FIELDS = 'routesDashboardTableFields';
export const DL_ROUTE_FIELDS = 'dlRoutesDashboardTableFields';
export const SHIPMENTS_FIELDS = 'shipmentTableFields';
export const PICKUP_ANALYTIC_FIELDS = 'pickupAnalyticsTableFields';
export const CHILD_SHIPMENT_FIELDS = 'childShipmentTableFields';
export const PARENT_SHIPMENT_FIELDS = 'parentShipmentTableFields';
export const FLIGHT_OPERATION_FIELDS = 'flightOperationFields';

const DEFAULT_ADDRESS_ROWS_PROPS = [
  { header: 'Action', key: 'actions', format: true },
  { header: 'Company Name', key: 'company_name' },
  { header: 'Attention To', key: 'attention_to' },
  { header: 'Email', key: 'email' },
  { header: 'Street Address1', key: 'street_address1' },
  { header: 'Street Address2', key: 'street_address2' },
  { header: 'City', key: 'city' },
  { header: 'State', key: 'state.alpha2' },
  { header: 'Zip', key: 'zip' },
  { header: 'Country', key: 'country.alpha2' },
  { header: 'Phone', key: 'phone' },
  { header: 'Long Company Name', key: 'long_company_name' },
  { header: 'Long Street Address1', key: 'long_street_address1' },
  { header: 'Owner', key: 'user' },
];

export const ADDRESS_TABLE_FIELDS = {
  address: [...DEFAULT_ADDRESS_ROWS_PROPS, { header: 'Record ID', key: 'record_id' }, { header: 'Account', key: 'account.name' }, { header: 'Customer', key: 'customer_name', format: true }],
  warehouse: [...DEFAULT_ADDRESS_ROWS_PROPS, { header: 'Company ID', key: 'company.name' }, { header: 'User', key: 'user.email' }],
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
