import React from 'react';
import PropTypes from 'prop-types';

const AddressInputs = ({
  buttonTitle, errors, values, prefix, onChange, onButtonClick, states, embed, disabled, limits, accounts, mode, customers,
}) => {
  const getFieldWidth = (field) => {
    if (field === 'street_address2') return 2;
    if (['account_id', 'customer_id'].includes(field)) return 6;

    return 3;
  };

  const getFields = () => {
    const defaultFields = [
      {
        label: 'Company Name', key: 'company_name', required: true, limit: 'company',
      },
      {
        label: 'Attention to', key: 'attention_to', required: false, limit: 'name',
      },
      {
        label: 'Street Address 1', key: 'street_address1', required: true, limit: 'address',
      },
      {
        label: 'Street Address 2', key: 'street_address2', required: false, limit: 'address',
      },
      {
        label: 'City', key: 'city', required: true, limit: 'city',
      },
      { label: 'State', key: 'state', required: true },
      { label: 'Zip', key: 'zip', required: true },
      { label: 'Phone', key: 'phone', required: true },
      { label: 'Account', key: 'account_id', required: true },
    ];

    if (mode === 'pickup') defaultFields.push({ label: 'Customer', key: 'customer_id', required: false });

    return defaultFields;
  };

  const getDataArray = (field) => {
    if (field === 'state') return states;
    if (field === 'account_id') return accounts;
    if (field === 'customer_id') return customers;

    return [];
  };

  return (
    <>
      <div className="row" style={{ display: 'flex', flexWrap: 'wrap' }}>
        {!embed && (
          <div className="col-sm-1 form-group address_book_btn">
            <button
              className="btn bg-olive margin add-btn open_address_book"
              onClick={onButtonClick}
            >
              {buttonTitle}
            </button>
          </div>
        )}
        {getFields().map(field => (
          <div key={field.key} className={`col-sm-${getFieldWidth(field.key)} form-group`}>
            <label htmlFor={field.key}>
              {field.label}
              {field.required && <span className="required">*</span>}
            </label>
            {!['state', 'account_id', 'customer_id'].includes(field.key) ? (
              <input
                className="form-control"
                id={field.key}
                value={values[field.key] || ''}
                onChange={e => onChange(e, prefix)}
                disabled={disabled}
                maxLength={limits[field.limit]}
              />
            ) : (
              <select
                className="form-control"
                id={field.key}
                value={values[field.key] || ''}
                onChange={e => onChange(e, prefix)}
                disabled={disabled || field.key === 'customer_id'}
              >
                <option key="first" value="">Please Select</option>
                {getDataArray(field?.key).map(item => <option key={item?.id} value={item?.id}>{item?.name}</option>)}
              </select>
            )
            }
            {errors[field.key] && (
              <label className="error">
                {errors[field.key]}
              </label>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

AddressInputs.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  prefix: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  accounts: PropTypes.instanceOf(Array),
  customers: PropTypes.instanceOf(Array),
  embed: PropTypes.bool,
  disabled: PropTypes.bool,
  limits: PropTypes.instanceOf(Object).isRequired,
  mode: PropTypes.string,
};

AddressInputs.defaultProps = {
  embed: false,
  disabled: false,
  accounts: [],
  customers: [],
  mode: '',
};

export default AddressInputs;
