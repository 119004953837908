import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import { actionCreators } from '../../stores';
import ConversationDetails from './ConversationDetails';
import MessagesBox from './message/MessageBox';
import MessageInputField from './message/MessageInputField';
import { getSdkConversationObject } from '../../conversationObject';
import api from '../../services/api';

const ConversationContainer = ({ conversation, client }) => {
  const sid = useSelector(state => state.sid);
  const email = useSelector(state => state.email);
  const loadingStatus = useSelector(state => state.loadingStatus);
  const participants = useSelector(state => state.participants)[sid] ?? [];
  const messages = useSelector(state => state.messages);
  const typingData = useSelector(state => state.typingData)[sid] ?? [];
  const lastReadIndex = useSelector(state => state.lastReadIndex);
  const [members, setMembers] = useState([]);
  const [convoTitle, setConvoTitle] = useState('');

  const dispatch = useDispatch();
  const { upsertMessages, updateUnreadMessages, updateCurrentConversation } = bindActionCreators(actionCreators, dispatch);

  const handleBackClick = async () => {
    // update unread messages
    updateUnreadMessages(sid, 0);
    // set messages to be read
    const lastMessage = messages[sid].length
      && messages[sid][messages[sid].length - 1];
    if (lastMessage && lastMessage.index !== -1) {
      await getSdkConversationObject(
        conversation,
      ).advanceLastReadMessageIndex(lastMessage.index);
    }
    updateCurrentConversation('');
  };

  useEffect(() => {
    if (!conversation) {
      setConvoTitle(conversation.friendlyName);
      return;
    }
    const fetchManifest = async (id) => {
      const res = await api.routesDashboard.routeInfo(id);
      if (!res.ok) {
        setConvoTitle(conversation.friendlyName);
        return;
      }
      const { user } = res.route_manifest?.driver || {};
      const { dispatcher } = res.route_manifest?.package_cluster?.airport || {};
      setMembers([user, dispatcher].filter(item => Boolean(item)));
      const { package_cluster } = res.route_manifest;
      setConvoTitle(`${package_cluster?.region_id_lvl1}@${package_cluster?.airport_code}: ${user?.first_name} ${user?.last_name}`);
    };
    fetchManifest(parseInt(conversation.uniqueName.replace('route-', ''), 10));
  }, [conversation]);

  return (
    <Box className="conversationContainer">
      {sid && conversation && client ? (
        <>
          <ConversationDetails
            participants={participants}
            convoName={convoTitle}
            onBack={handleBackClick}
            members={members}
            email={email}
          />

          <MessagesBox
            key={sid}
            convoSid={sid}
            convo={conversation}
            upsertMessage={upsertMessages}
            client={client}
            messages={messages[sid]}
            loadingState={loadingStatus}
            participants={participants}
            lastReadIndex={lastReadIndex}
            members={members}
            email={email}
          />

          <MessageInputField
            convoSid={sid}
            client={client}
            messages={messages[sid]}
            convo={conversation}
            typingData={typingData}
          />
        </>
      ) : null }
    </Box>
  );
};

ConversationContainer.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  conversation: PropTypes.instanceOf(Object).isRequired,
};

export default ConversationContainer;
