import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Toast from '../common/Toast';
import api from '../../services/api';
import AddressInputs from './AddressInputs';
import { isFormValid, printZplLabels, setPackageErrors } from '../../services/helpers';
import AddressModal from './AddressModal';
import Packages from './Packages';
import PrintLabels from './PrintLabels';
import { errorToast } from '../../services/toast';

const NewShipment = ({
  defaultData, allShipmentsPath, limits, companyList,
  states, recordId, embed, modelData, modelDataDefaults,
  countryId, userId, shipmentDetails, permission, preferences,
}) => {
  // eslint-disable-next-line
  let [errors, setErrors] = useState({});
  const [originAddress, setOriginAddress] = useState({});
  const [destinationAddress, setDestinationAddress] = useState({});
  const [shipDate, setShipDate] = useState('');
  const [company, setCompany] = useState('');
  const [accountList, setAccountList] = useState([]);
  const [carrier, setCarrier] = useState({
    outbound: '',
    return: '',
  });
  const [carrierService, setCarrierService] = useState({
    outbound: '',
    return: '',
  });
  const [isReturn, setIsReturn] = useState(1);
  const [returnCarrierList, setReturnCarrierList] = useState([]);
  const [outboundCarrierList, setOutboundCarrierList] = useState([]);
  const [createdRows, setCreatedRows] = useState([]);
  const [carrierServicesList, setCarrierServicesList] = useState({
    outbound: [],
    return: [],
  });
  const [packageTypesList, setPackageTypesList] = useState({
    outbound: [],
    return: [],
  });
  const [packages, setPackages] = useState({
    outbound: [],
    return: [],
  });
  // eslint-disable-next-line no-unused-vars
  const [defaultDimensions, setDefaultDimensions] = useState({
    width: '1',
    height: '1',
    length: '1',
  });

  const [finalPrice, setFinalPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [originAddressModalVisible, setOriginAddressModalVisible] = useState(false);
  const [destinationAddressModalVisible, setDestinationAddressModalVisible] = useState(false);
  const [fedexFormula, setFedexFormula] = useState(false);
  const [currentCarrier, setCurrentCarrier] = useState('');
  const [returnShipmentRateId, setReturnShipmentRateId] = useState('');
  const [outboundShipmentRateId, setOutboundShipmentRateId] = useState('');
  const [isParent, setIsParent] = useState(false);
  const [isExternal, setIsExternal] = useState(false);
  const [notInSystem, setNotInSystem] = useState(false);
  const [externalTrackingId, setExternalTrackingId] = useState('');

  useEffect(() => {
    carrier.outbound && setCurrentCarrier(outboundCarrierList.find(item => item.id === parseInt(carrier.outbound, 10))?.name);
  }, [carrier.outbound, outboundCarrierList]);

  useEffect(() => {
    if (loading) {
      $('.overlay').show();
    } else {
      $('.overlay').hide();
    }
  }, [loading]);

  useEffect(() => {
    let defaultLocalDimentions = {};

    if (modelDataDefaults?.warehouse_data) {
      const data = modelDataDefaults?.warehouse_data;
      setOriginAddress({
        company_name: data.company_name,
        attention_to: data.attention_to,
        street_address1: data.street_address1,
        street_address2: data.street_address2,
        city: data.city,
        state: data.state_id,
        zip: data.zip,
        phone: data.phone,
        account_id: data.account_id,
      });
      setCompany(data.company_id || '');
    }

    if (modelData) {
      setShipDate(modelData.estimated_ship_date || moment(new Date()).format('YYYY-MM-DD'));
      setDestinationAddress({
        company_name: modelData.destination_company_name || '',
        attention_to: modelData.destination_attention_to || '',
        street_address1: modelData.destination_street_address1 || '',
        street_address2: modelData.destination_street_address2 || '',
        city: modelData.destination_city || '',
        state: modelData.destination_state || '',
        zip: modelData.destination_zip || '',
        phone: modelData.destination_phone || '832-454-6304',
        account_id: modelDataDefaults?.warehouse_data.account_id || '',
      });
    }
    if (modelDataDefaults) {
      setCompany(modelDataDefaults.company_id || '');
      setCarrier({
        outbound: modelDataDefaults.carrier_id || '',
        return: modelDataDefaults.carrier_id || '',
      });
      setCarrierService({
        outbound: modelDataDefaults.carrier_service_id || '',
        return: modelDataDefaults.carrier_service_id || '',
      });
      defaultLocalDimentions = {
        width: modelDataDefaults.width ? modelDataDefaults.width : defaultDimensions.width,
        height: modelDataDefaults.height ? modelDataDefaults.height : defaultDimensions.height,
        length: modelDataDefaults.length ? modelDataDefaults.length : defaultDimensions.length,
      };
    }

    if (shipmentDetails.length > 0) {
      setPackages({
        outbound: shipmentDetails.map(item => ({
          ...item,
          quantity: embed ? '1' : item.quantity,
          weight: embed ? '2' : item.weight,
          width: defaultLocalDimentions.width,
          height: defaultLocalDimentions.height,
          length: defaultLocalDimentions.length,
          weight_calculated_in: 1,
          dimension_calculated_in: 1,
        })),
        return: [{
          ...shipmentDetails[0],
          width: defaultLocalDimentions.width,
          height: defaultLocalDimentions.height,
          length: defaultLocalDimentions.length,
          weight_calculated_in: 1,
          dimension_calculated_in: 1,
        }],
      });

      setErrors({
        ...errors,
        carrier_service_id: {
          outbound: '',
          return: '',
        },
        carrier_id: {
          outbound: '',
          return: '',
        },
        packages: {
          outbound: shipmentDetails.reduce((a, v, i) => ({ ...a, [i]: {} }), {}),
          return: { 0: {} },
        },
      });
    }
  }, [defaultData]);

  useEffect(() => {
    if (!company) {
      setReturnCarrierList([]);
      setOutboundCarrierList([]);
    } else {
      api.shipmentDashboard.getCarriers({ company_id: company }).then((res) => {
        setReturnCarrierList(res.data.return_carriers_r);
        setOutboundCarrierList(res.data.outbound_carriers_r);
      });
      api.addressBook.getUserAccounts({ company_id: company }).then((res) => {
        setAccountList(res.accounts);
      });
    }

    setCarrierServicesList({
      outbound: [],
      return: [],
    });
    setPackageTypesList({
      outbound: [],
      return: [],
    });
  }, [company]);

  const setCarrierServicesAndPackageTypes = (carrierObject, field) => {
    carrierObject[field] && api.shipmentDashboard.getCarrierServicesAndPackageTypes({ company_id: company, carrier_type: JSON.parse(carrierObject[field]).carrier_details.carrier_type }).then((res) => {
      setCarrierServicesList({
        ...carrierServicesList,
        [field]: res[`${field}_carrier_services_r`],
      });
      setPackageTypesList({
        ...packageTypesList,
        [field]: res.package_types_r,
      });
    }).catch(error => console.log(error));
  };

  useEffect(() => {
    switch (isReturn) {
    case 1: {
      setCarrierServicesAndPackageTypes(carrier, 'outbound');
      return;
    }
    case 2: {
      setCarrierServicesAndPackageTypes(carrier, 'outbound');
      setCarrierServicesAndPackageTypes(carrier, 'return');
      return;
    }
    case 3: {
      setCarrierServicesAndPackageTypes(carrier, 'return');
    }
    // eslint-disable-next-line
      default: return;
    }
  }, [carrier, isReturn]);

  const validate = useCallback(() => {
    if (!shipDate && !isParent) errors.shipDate = 'Please select ship date';
    if (!company) errors.company = 'Please select company';
    if (!carrier.return && isReturn !== 1) errors.carrier_id.return = 'Please select carrier';
    if (!carrier.outbound && isReturn !== 3) errors.carrier_id.outbound = 'Please select carrier';
    if (!carrierService.return && isReturn !== 1) errors.carrier_service_id.return = 'Please select carrier service';
    if (!carrierService.outbound && isReturn !== 3) errors.carrier_service_id.outbound = 'Please select carrier service';

    // origin
    errors.origin = {};
    Object.keys(originAddress).forEach((key) => {
      if (!['street_address2', 'attention_to'].includes(key) && !originAddress[key]) errors.origin[key] = 'Please enter value';
    });

    // destination
    errors.destination = {};
    Object.keys(destinationAddress).forEach((key) => {
      if (!['street_address2', 'attention_to'].includes(key) && !destinationAddress[key]) errors.destination[key] = 'Please enter value';
    });

    // outbound packages
    if (isReturn !== 3) {
      packages.outbound.forEach((pack, index) => {
        errors = setPackageErrors(errors, pack, index, 'outbound');
      });
    }

    // return packages
    if (isReturn !== 1) errors = setPackageErrors(errors, packages.return[0], 0, 'return');

    if (destinationAddress.street_address1 === originAddress.street_address1) errors.destination.street_address1 = 'Should not be equal to origin street address';

    setErrors({ ...errors });
    return errors;
  }, [originAddress, destinationAddress, shipDate, carrier, carrierService, company, packages, isReturn, errors, isParent]);

  const handleChange = (e, prefix) => {
    const { id: field, value } = e.target;
    if (prefix === 'origin') {
      setOriginAddress({
        ...originAddress,
        [field]: value,
      });
      setErrors({ ...errors, origin: { ...errors.origin, [field]: '' } });
    } else {
      setDestinationAddress({
        ...destinationAddress,
        [field]: value,
      });
      setErrors({ ...errors, destination: { ...errors.destination, [field]: '' } });
    }
  };

  const handleSelectAddress = (type, address) => {
    const newAddress = {
      company_name: address.company_name || '',
      attention_to: address.attention_to || '',
      street_address1: address.street_address1 || '',
      street_address2: address.street_address2 || '',
      city: address.city || '',
      state: address.state.id || '',
      zip: address.zip || '',
      phone: address.phone || '',
      account_id: address.account_id || '',
    };

    if (type === 'origin') {
      setOriginAddress(newAddress);
      setOriginAddressModalVisible(false);
    } else {
      setDestinationAddress(newAddress);
      setDestinationAddressModalVisible(false);
    }
  };

  const getParams = ({ outboundQuantity, returnQuantity }) => ({
    shipment: {
      origin_company_name: originAddress.company_name,
      origin_attention_to: originAddress.attention_to,
      origin_street_address1: originAddress.street_address1,
      origin_street_address2: originAddress.street_address2,
      origin_city: originAddress.city,
      origin_state: states.find(state => state.id === parseInt(originAddress.state, 10))?.alpha2,
      origin_zip: originAddress.zip.trim(),
      origin_country: 'US',
      origin_phone: originAddress.phone,
      account_id: originAddress.account_id,
      destination_company_name: destinationAddress.company_name,
      destination_attention_to: destinationAddress.attention_to,
      destination_street_address1: destinationAddress.street_address1,
      destination_street_address2: destinationAddress.street_address2,
      destination_city: destinationAddress.city,
      destination_state: states.find(state => state.id === parseInt(destinationAddress.state, 10))?.alpha2,
      destination_zip: destinationAddress.zip.trim(),
      destination_country: 'US',
      destination_phone: destinationAddress.phone,
      carrier_type: carrier.outbound !== '' ? JSON.parse(carrier.outbound).carrier_details.carrier_type : carrier.outbound,
      carrier_id: carrier.outbound !== '' ? JSON.parse(carrier.outbound).carrier_details.carrier_id : carrier.outbound,
      carrier_service_id: carrierService.outbound,
      estimated_ship_date: shipDate,
      is_return: isReturn,
      record_id: recordId,
      shipment_details_attributes: outboundQuantity ? packages.outbound.map(item => ({
        ...item,
        quantity: outboundQuantity,
      })) : packages.outbound,
      company_id: company,
      is_parent: isParent,
      not_in_system: notInSystem,
      tracking_id: externalTrackingId,
    },
    fedex_formula: fedexFormula,
    outbound_shipment_rate_id: outboundShipmentRateId,
    return_shipment_rate_id: returnShipmentRateId,
    shipment_return: {
      carrier_type: carrier.return !== '' ? JSON.parse(carrier.return).carrier_details.carrier_type : carrier.return,
      carrier_id: carrier.return !== '' ? JSON.parse(carrier.return).carrier_details.carrier_id : carrier.return,
      carrier_service_id: carrierService.return,
      shipment_details_attributes: returnQuantity ? {
        ...packages.return[0],
        quantity: returnQuantity,
      } : packages.return[0],
    },
    use_shipengine: isExternal,
  });

  /* eslint-disable camelcase */
  const handleRate = () => {
    if (isFormValid(validate())) {
      setLoading(true);
      api.shipmentDashboard.getRate(getParams({}))
        .then(({ final_price, return_shipment_rate_id, outbound_shipment_rate_id }) => {
          setFinalPrice(final_price);
          setOutboundShipmentRateId(outbound_shipment_rate_id);
          setReturnShipmentRateId(return_shipment_rate_id);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const currentQuantity = parseInt(packages.outbound[0].quantity, 10);
    const currentReturnQuantity = parseInt(packages.return[0].quantity, 10);
    const clustersCount = Math.floor(currentQuantity / 5);
    const clustersReturnCount = Math.floor(currentReturnQuantity / 5);
    const currentQuantityMod = currentQuantity % 5;
    const currentReturnQuantityMod = currentReturnQuantity % 5;
    const quantities = (new Array(clustersCount)).fill(5);
    const quantitiesReturn = (new Array(clustersReturnCount)).fill(5);
    if (currentQuantityMod !== 0) quantities.push(currentQuantityMod);
    if (currentReturnQuantityMod !== 0) quantitiesReturn.push(currentReturnQuantityMod);
    const data = [];

    const values = quantities.length >= quantitiesReturn.length ? quantities : quantitiesReturn;
    const values2 = quantities.length < quantitiesReturn.length ? quantities : quantitiesReturn;
    const type = quantities.length >= quantitiesReturn.length ? 'outbound' : 'return';
    let idx = 0;
    try {
      if (isParent) {
        const res = await api.shipmentDashboard.createParentShipment(getParams({ outboundQuantity: currentQuantity, returnQuantity: currentReturnQuantity }));
        if (res.ok) window.location = '/admin/shipment_dashboard/parent_shipments';
      } else {
        for await (const item of values) {
          const params = type === 'outbound' ? { outboundQuantity: item, returnQuantity: values2[idx] } : { outboundQuantity: values2[idx], returnQuantity: item };
          const {
            // eslint-disable-next-line no-unused-vars
            fedex_ups_print_zpl, rfl_print_zpl, fedex_ups_printer_ips, rfl_printer_ips, model_data,
          } = await api.shipmentDashboard.shipSubmit(getParams(params));
          data.push(...model_data);
          if (!notInSystem) {
            const res = await api.shipmentDashboard.bulkActions({
              bulk_action: 'print_zpl',
              ids: model_data.map(row => row.id),
              controller_name: 'shipments',
            });

            printZplLabels(res);
          }
          idx += 1;
        }
      }
      if (embed) {
        setLoading(false);
        // printZplLabels({ zpl_labels, printer_ip });

        handleCancel();
      } else {
        setLoading(false);
        setCreatedRows(data);
      }
    } catch (e) {
      setLoading(false);
    }
  };
  /* eslint-enable camelcase */

  const handleChangePackage = async (e, id, radioValue, type) => {
    const field = e.target.name.split('-')[0];
    if (field === 'package_type_id') {
      packages[type][id][field] = e.target.value;
      if (e.target.value === '') {
        packages[type][id].width = '1';
        packages[type][id].height = '1';
        packages[type][id].length = '1';
      } else {
        const { data } = await api.shipmentDashboard.getPackageType({ package_type_id: e.target.value });
        packages[type][id].width = data.width;
        packages[type][id].height = data.height;
        packages[type][id].length = data.length;
      }
    } else if (field === 'is_special_day_delivery') {
      packages[type][id][field] = !packages[type][id][field];
    } else {
      packages[type][id][field] = radioValue || e.target.value;
    }

    setPackages(packages);
    setErrors({
      ...errors,
      packages: {
        ...errors.packages,
        [type]: {
          ...errors.packages[type],
          [id]: {
            ...errors.packages[type][id],
            [field]: '',
          },
        },
      },
    });
  };

  const handleCancel = () => {
    if (embed) {
      parent.postMessage('shipment_completed', '*');
    } else {
      window.history.back();
    }
  };

  const setAddressModalOpen = useCallback((mode) => {
    if (!company) {
      errorToast('Please, choose a company first');
      return;
    }

    if (mode === 'origin') setOriginAddressModalVisible(true);
    else setDestinationAddressModalVisible(true);
  }, [company]);

  const handleChangeType = (type) => {
    setIsReturn(type);
    setErrors({
      ...errors,
      packages: {
        outbound: shipmentDetails.reduce((a, v, i) => ({ ...a, [i]: {} }), {}),
        return: { 0: {} },
      },
    });
  };

  const handleChangeNotInSystem = (value) => {
    if (!value) setExternalTrackingId('');
    setNotInSystem(value);
  };

  return (
    <div>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Add Shipment</h1>
            </div>
            <div className="box-body">
              {createdRows.length === 0 && (
                <>
                  <div className="popup_errors" />
                  <input hidden className="shipment_origin_email" />
                  <input hidden className="shipment_destination_email" />
                  {!embed && permission.shipments?.createParent && (
                    <div className="row">
                      <div className="col-sm-4 form-group">
                        <label className="checkbox-label">
                          <input
                            disabled={finalPrice}
                            type="checkbox"
                            id="is_parent"
                            style={{ marginLeft: '5px' }}
                            checked={isParent}
                            onChange={() => setIsParent(!isParent)}
                          />
                          Is Parent?
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    {!isParent && (
                      <div className="col-sm-6 form-group">
                        <label htmlFor="ship_date">
                          Ship Date
                          <span className="required">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="date"
                          name="date"
                          value={shipDate}
                          disabled={finalPrice}
                          onChange={(e) => {
                            setShipDate(e.target.value);
                            setErrors({ ...errors, shipDate: '' });
                          }}
                          pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                        />
                        {errors?.shipDate && (
                          <label className="error">
                            {errors.shipDate}
                          </label>
                        )}
                      </div>
                    )}
                    <div className="col-sm-6 form-group">
                      <label htmlFor="company">
                        Company
                        <span className="required">*</span>
                      </label>
                      <select
                        name="company"
                        id="company"
                        className="form-control get_company_id get_carriers"
                        value={company}
                        disabled={finalPrice}
                        onChange={(e) => {
                          setCompany(e.target.value);
                          setErrors({ ...errors, company: '' });
                        }}
                      >
                        <option value="">Select company</option>
                        {companyList.map(item => <option key={item[1]} value={item[1]}>{item[0]}</option>)}
                      </select>
                      {errors?.company && (
                        <label className="error">
                          {errors.company}
                        </label>
                      )}
                    </div>
                  </div>

                  <AddressInputs
                    errors={errors?.origin || {}}
                    buttonTitle="Choose Origin"
                    onChange={handleChange}
                    onButtonClick={() => setAddressModalOpen('origin')}
                    prefix="origin"
                    values={originAddress}
                    states={states}
                    accounts={accountList}
                    embed={embed}
                    disabled={Boolean(finalPrice) || !permission.shipments.customAddresses}
                    limits={limits}
                  />
                  <AddressInputs
                    errors={errors?.destination || {}}
                    buttonTitle="Choose Destination"
                    onChange={handleChange}
                    onButtonClick={() => setAddressModalOpen('destination')}
                    prefix="destination"
                    values={destinationAddress}
                    states={states}
                    accounts={accountList}
                    embed={embed}
                    disabled={Boolean(finalPrice) || !permission.shipments.customAddresses}
                    limits={limits}
                  />
                  {
                    !embed && (
                      <div className="row radio-mobile">
                        <div className="col-xs-4 col-lg-2 form-group full-width">
                          <label className="radio-label margin-top5">
                            <input
                              type="radio"
                              name="is_return"
                              id="is_return"
                              className="is_return_class"
                              value={1}
                              checked={isReturn === 1}
                              disabled={Boolean(finalPrice)}
                              onChange={() => handleChangeType(1)}
                            />
                            Outbound
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="col-xs-4 col-lg-2 form-group full-width">
                          <label className="radio-label margin-top5">
                            <input
                              type="radio"
                              name="is_return"
                              id="is_return"
                              className="is_return_class"
                              value={2}
                              checked={isReturn === 2}
                              disabled={Boolean(finalPrice)}
                              onChange={() => handleChangeType(2)}
                            />
                            Outbound and Return
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="col-xs-4 col-lg-2 form-group full-width">
                          <label className="radio-label margin-top5">
                            <input
                              type="radio"
                              name="is_return"
                              id="is_return"
                              className="is_return_class"
                              checked={isReturn === 3}
                              disabled={Boolean(finalPrice)}
                              onChange={() => handleChangeType(3)}
                            />
                            Return only
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    )
                  }
                  {
                    isReturn !== 3 && (
                      <Packages
                        setCarrier={value => setCarrier({ ...carrier, outbound: value })}
                        setErrors={e => setErrors({ ...errors, [e.target.id]: { ...errors[e.target.id], outbound: '' } })}
                        packageTypesList={packageTypesList.outbound}
                        errors={errors}
                        packages={packages.outbound}
                        handleChangePack={(e, id, radioValue) => handleChangePackage(e, id, radioValue, 'outbound')}
                        setCarrierService={value => setCarrierService({ ...carrierService, outbound: value })}
                        carrierServicesList={carrierServicesList.outbound}
                        carriersList={outboundCarrierList}
                        type="outbound"
                        carrier={carrier.outbound}
                        carrierService={carrierService.outbound}
                        disabled={Boolean(finalPrice)}
                      />
                    )
                  }
                  {
                    isReturn !== 1 && (
                      <Packages
                        setCarrier={value => setCarrier({ ...carrier, return: value })}
                        setErrors={e => setErrors({ ...errors, [e.target.id]: { ...errors[e.target.id], return: '' } })}
                        packageTypesList={packageTypesList.return}
                        errors={errors}
                        packages={packages.return}
                        handleChangePack={(e, id, radioValue) => handleChangePackage(e, id, radioValue, 'return')}
                        setCarrierService={value => setCarrierService({ ...carrierService, return: value })}
                        carrierServicesList={carrierServicesList.return}
                        carriersList={returnCarrierList}
                        type="return"
                        carrier={carrier.return}
                        carrierService={carrierService.return}
                        disabled={Boolean(finalPrice)}
                      />
                    )
                  }
                  {!isParent && (
                    <div className="row radio-mobile">
                      <div className="col-xs-4 col-lg-2 full-width">
                        <label className="radio-label margin-top10">
                          <input
                            type="radio"
                            name="direct"
                            id="direct"
                            className="is_return_class"
                            value={1}
                            checked={!isExternal}
                            disabled={Boolean(finalPrice)}
                            onChange={() => setIsExternal(false)}
                          />
                          Direct
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="col-xs-4 col-lg-2 full-width">
                        <label className="radio-label margin-top10">
                          <input
                            type="radio"
                            name="external"
                            id="external"
                            className="is_return_class"
                            checked={isExternal}
                            disabled={Boolean(finalPrice)}
                            onChange={() => setIsExternal(true)}
                          />
                          External
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="row" style={{ padding: '5px' }}>
                    <div className="col-sm-4 form-group">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          id="is_external"
                          style={{ marginLeft: '5px' }}
                          checked={notInSystem}
                          onChange={() => handleChangeNotInSystem(!notInSystem)}
                        />
                        Not in System?
                        <span className="checkmark" />
                      </label>
                    </div>
                    {notInSystem
                       && (
                         <div className="col-sm-6 form-group">
                           <label htmlFor="tracking_id">
                             Tracking id
                           </label>
                           <input
                             name="tracking_id"
                             id="tracking_id"
                             className="form-control static_quantity"
                             maxLength={18}
                             value={externalTrackingId}
                             onChange={e => setExternalTrackingId(e.target.value)}
                           />
                           {errors?.externalTrackingId && (
                             <label className="error">
                               {errors.externalTrackingId}
                             </label>
                           )}
                         </div>
                       )
                    }
                  </div>
                  <label className="api_errors error" />
                  <div className="form-group final-price-col" style={{ marginTop: '20px' }}>
                    {finalPrice && <div className="final_price">{finalPrice}</div>}
                    {finalPrice && (
                      <button type="button" className="btn mb-30 btn-primary edit_shipment" title="Edit Shipment" onClick={() => setFinalPrice(null)}>
                        Edit Shipment
                      </button>
                    )}
                    {finalPrice ? (
                      <>
                        <button disabled={loading} id="submit-button" type="button" className="btn bg-olive ship_submit_btn" title="Continue" style={{ marginRight: '10px' }} onClick={handleSubmit}>
                          {isParent ? 'Create Parent Shipment' : 'Ship Now'}
                        </button>
                        <button className="btn btn-primary ship_cancel" title="Cancel" onClick={handleCancel}>Cancel</button>
                      </>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button disabled={loading} id="rate-button" type="button" className="btn bg-olive get_rate_btn" title="Continue" style={{ marginRight: '10px' }} onClick={handleRate}>Continue</button>
                        <button className="btn btn-primary continue_cancel" title="Cancel" onClick={handleCancel}>Cancel</button>
                        {currentCarrier === 'Fed-Ex' && (
                          <div hidden className="col-sm-12">
                            <label className="checkbox-label">
                              <input
                                type="checkbox"
                                name="fedex_formula"
                                id="fedex_formula"
                                checked={fedexFormula}
                                disabled={Boolean(finalPrice)}
                                onChange={() => setFedexFormula(!fedexFormula)}
                              />
                              FedEx Formula Pricing
                              <span className="checkmark" />
                            </label>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="print-labels" />
                  {originAddressModalVisible && (
                    <AddressModal
                      handleClose={() => setOriginAddressModalVisible(false)}
                      open={originAddressModalVisible}
                      onAddressSelect={(type, address) => handleSelectAddress('origin', address)}
                      companies={companyList}
                      states={states}
                      companyId={company}
                      countryId={countryId}
                      userId={userId}
                      defaultTab={0}
                      permission={permission}
                      limits={limits}
                    />
                  )}
                  {destinationAddressModalVisible && (
                    <AddressModal
                      handleClose={() => setDestinationAddressModalVisible(false)}
                      open={destinationAddressModalVisible}
                      onAddressSelect={(type, address) => handleSelectAddress('destination', address)}
                      companies={companyList}
                      states={states}
                      companyId={company}
                      countryId={countryId}
                      userId={userId}
                      defaultTab={1}
                      permission={permission}
                      limits={limits}
                      preferences={preferences}
                    />
                  )}
                </>
              )}

              {createdRows.length > 0 && (
                <PrintLabels
                  rows={createdRows}
                  adminShipmentsPath={allShipmentsPath}
                  shipAdminShipmentsPath={() => {
                    setCreatedRows([]);
                    setFinalPrice(null);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NewShipment.propTypes = {
  defaultData: PropTypes.instanceOf(Object).isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
  modelDataDefaults: PropTypes.instanceOf(Object).isRequired,
  modelData: PropTypes.instanceOf(Object).isRequired,
  shipmentDetails: PropTypes.instanceOf(Object).isRequired,
  allShipmentsPath: PropTypes.string,
  countryId: PropTypes.number.isRequired,
  recordId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  companyList: PropTypes.instanceOf(Array).isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  limits: PropTypes.instanceOf(Object).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
  embed: PropTypes.bool,
};

NewShipment.defaultProps = {
  allShipmentsPath: '',
  embed: false,
};

export default NewShipment;
