import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import moment from 'moment-timezone';
import api from '../../services/api';
import { errorToast, successToast } from '../../services/toast';

const NetworkDeployModal = ({
  network, open, onClose,
}) => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [dateError, setDateError] = useState('');
  const routeDate = useRef();

  // eslint-disable-next-line consistent-return
  const handleSubmit = () => {
    const day = moment(date).format('dddd');

    if (!network.days.includes(day)) return setDateError('Day of the week for the selected date does not match the network days');

    api.rlNetwork.deploy(network.id, { date }).then((res) => {
      onClose();
      if (res.status) {
        successToast(res.message);
      } else {
        errorToast(res.message);
      }
    }).catch(onClose);
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Release the network live
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row">
          <div className="col-sm-12 form-group">
            <label>Days:</label>
            {' '}
            <span>{network?.days.join(', ')}</span>
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="pickup_date">
              Date
              <span className="required">*</span>
            </label>
            <div>
              <input
                ref={routeDate}
                type="date"
                name="date"
                id="date"
                className="form-control"
                value={date}
                onChange={e => setDate(e.target.value)}
              />
            </div>
          </div>
          {dateError && (
            <label className="error col-sm-12" data-testid="date_error">
              {dateError}
            </label>
          )}
        </div>
        <div className="modal-bottom-btn text-right">
          <button className="btn bg-olive" onClick={handleSubmit} data-testid="deploy_network">Confirm</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={onClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

NetworkDeployModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  network: PropTypes.instanceOf(Object).isRequired,
};

NetworkDeployModal.defaultProps = {
  open: false,
};

export default NetworkDeployModal;
