import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import api from '../../services/api';
import MyMapComponent from '../common/Map';

const GetAddressesModal = ({
  handleClose, open, clusterId, googleMapsKey,
}) => {
  const [rows, setRows] = useState([]);
  const [pickups, setPickups] = useState([]);
  const [selectedRow, setSelectedRow] = useState('');
  const [date, setDate] = useState(new Date());
  const [parsedSelectedRow, setParsedSelectedRow] = useState(null);

  useEffect(() => {
    if (clusterId) getClusters();
  }, [clusterId, date]);

  useEffect(() => {
    if (selectedRow) setParsedSelectedRow(JSON.parse(selectedRow));
  }, [selectedRow]);

  const getClusters = useCallback(() => {
    api.routesDashboard.getClusterAddresses({ cluster_id: clusterId, date }).then((res) => {
      setRows(res.addresses);
      setPickups(res.pickups);
      if (res.addresses.length === 1) {
        setParsedSelectedRow(res.addresses[0]);
      }
    });
  }, [clusterId, date]);

  const handleSubmit = () => {
    api.routesDashboard.generateRL2({ cluster_id: clusterId, address: parsedSelectedRow, date }).then(() => {
      handleClose();
    });
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Cluster addresses
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="form-group">
          <div className="datepicker">
            <input
              type="date"
              className="form-control dimensions length"
              id="date"
              name="date"
              value={date}
              onChange={e => setDate(e.target.value)}
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            />
          </div>
          {
            rows.length > 1 && (
              <select className="form-control" value={selectedRow} onChange={e => setSelectedRow(e.target.value)}>
                <option key="default" value="">Please select</option>
                {rows.map(row => (
                  <option key={JSON.stringify(row)} value={JSON.stringify(row)}>
                    {row.name}
                    {' '}
                    |
                    {' '}
                    {row.address}
                  </option>
                ))}
              </select>
            )
          }
        </div>
        {Boolean(parsedSelectedRow) && (
          <>
            <div className="form-group">
              <div>{`Name: ${parsedSelectedRow?.name || '-'}`}</div>
              <div>{`Address: ${parsedSelectedRow?.address || '-'}`}</div>
            </div>
            <MyMapComponent
              markers={[
                ...parsedSelectedRow.clusters.map(cluster => ({
                  position: { lat: cluster.geo_lat_lvl1, lng: cluster.geo_lon_lvl1 },
                  tip: cluster.airport_code,
                  color: 'red',
                })),
                ...pickups.map(pickup => ({
                  position: { lat: pickup.geolocation.latitude, lng: pickup.geolocation.longitude },
                  tip: `${pickup.origin_street_address1 || ''}, ${pickup.origin_street_address2 ? `${pickup.origin_street_address2},` : ''} ${pickup.origin_city || ''}, ${pickup.origin_state || ''} ${pickup.origin_zip || ''}`,
                  color: 'green',
                })),
                { position: parsedSelectedRow.coords, tip: parsedSelectedRow.address, color: 'blue' },
              ]}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&v=3.exp&libraries=geometry,drawing,places`}
            />
          </>
        )}
        <div className="modal-bottom-btn text-right">
          <button className="btn bg-olive" onClick={handleSubmit}>Generate RL2</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={handleClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

GetAddressesModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  clusterId: PropTypes.number.isRequired,
  googleMapsKey: PropTypes.string.isRequired,
};

GetAddressesModal.defaultProps = {
  open: false,
};

export default GetAddressesModal;
