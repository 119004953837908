import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import qs from 'qs';
import api from '../../services/api';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import NetworkModal from './NetworkModal';
import NetworkDeployModal from './NetworkDeployModal';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { updateChangeRowsPerPage } from '../../helpers';
import { PaginationKeys } from '../../constants';

const Networks = ({ permissions, preferences }) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [deployNetworkModal, setDeployNetworkModal] = useState(false);
  const [networkModal, setNetworkModal] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || parseInt(window.localStorage.getItem(PaginationKeys.rln), 10) || preferences?.records_per_page || 10,
  });
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    handleFilter({});
  }, []);

  const handleFilter = useCallback(({ page, limit }) => {
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filters = {
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
    };
    api.rlNetwork.getNetworks(filters)
      .then((res) => {
        setRows(res.records);
        setPaginationOptions({
          count: res.total_records,
          page: fixedPage || 0,
          limit: limit || paginationOptions.limit,
        });
        const url = `/admin/rl_networks?${qs.stringify(filters)}`;
        window.history.replaceState(null, '', url);
      })
      .catch(err => console.log(err));
  }, [paginationOptions]);

  const handleDelete = useCallback(() => {
    api.rlNetwork.destroyNetwork(selectedRow.id).then(() => {
      setSelectedRow(null);
      setOpenDelete(false);
      handleFilter({});
    });
  }, [selectedRow]);

  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };

  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, PaginationKeys.rln, val => handleFilter({ page: 0, limit: val }));
  };

  const getFields = () => {
    const defaultArray = [
      { header: 'Name', key: 'name' },
      { header: 'Day', key: 'day' },
      { header: 'Created At', key: 'created_at' },
    ];
    if (permissions.rlNetwork?.view || permissions.rlNetwork?.edit) {
      defaultArray.push({
        header: 'Actions', key: 'actions', format: true, clickSkip: true,
      });
    }
    return defaultArray;
  };

  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="box">
          <Toast />
          <div className="box-header">
            <h1 className="box-title">RL Networks</h1>
            { permissions.rlNetwork?.add && (
              <div className="pull-right">
                <button
                  className="btn add-btn pull-right prevent_right_click add_address_class_new"
                  onClick={() => {
                    setSelectedRow({});
                    setNetworkModal(true);
                  }}
                  data-testid="add_network"
                >
                  Add new network
                </button>
              </div>
            )}
          </div>

          <div className="box-body main_listing">
            <CustomTable
              rows={rows}
              formatters={{
                days: value => value.join(', '),
                created_at: value => moment(value).format('DD MMM YYYY'),
                actions: value => (
                  <div style={{ minWidth: '100px' }}>
                    {permissions.rlNetwork?.view && (
                      <a href={`/admin/rl_networks/${value?.id}`} className="fa fa-eye  edit_user" />
                    )}
                    {permissions.rlNetwork?.edit && (
                      <>
                        <button
                          onClick={() => {
                            setSelectedRow(value);
                            setNetworkModal(true);
                          }}
                          className="fa fa-edit  edit_user"
                          style={{ border: 'none', backgroundColor: 'transparent' }}
                        />
                        <button
                          onClick={() => {
                            setSelectedRow(value);
                            setDeployNetworkModal(true);
                          }}
                          data-testid={`deploy_${value.id}`}
                          className="fa fa-level-up"
                          style={{ color: 'blue', border: 'none', backgroundColor: 'transparent' }}
                        />
                        <button
                          className="fa fa-trash action-button"
                          onClick={() => {
                            setOpenDelete(true);
                            setSelectedRow(value);
                          }}
                        />
                      </>
                    )}
                  </div>
                ),
              }}
              paginationOptions={{
                ...paginationOptions,
                handleChangePage,
                handleChangeRowsPerPage,
              }}
              rowsProps={getFields()}
              onRowClick={(row) => { if (permissions.rlNetwork?.view) window.location = `/admin/rl_networks/${row.id}`; }}
              rowStyle={() => ({ cursor: 'pointer' })}
              suffix="rl_networks"
            />
          </div>
          {networkModal && (
            <NetworkModal
              onClose={() => setNetworkModal(false)}
              row={selectedRow}
              open={networkModal}
              onUpdate={handleFilter}
            />
          )}
          {deployNetworkModal && (
            <NetworkDeployModal
              network={selectedRow}
              onClose={() => setDeployNetworkModal(false)}
              open={deployNetworkModal}
            />
          )}
          <ConfirmationDialog
            onConfirm={handleDelete}
            onClose={() => setOpenDelete(false)}
            message="Are you sure you want to delete this network?"
            open={openDelete}
          />
        </div>
      </div>
    </div>
  );
};

Networks.propTypes = {
  permissions: PropTypes.instanceOf(Object).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

export default Networks;
