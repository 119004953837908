/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { get } from 'lodash';
import qs from 'qs';
import moment from 'moment-timezone';
import {
  FLIGHT_OPERATION_FIELDS,
} from '../../services/helpers';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import api from '../../services/api';
import ConfirmationDialog from '../common/ConfirmationDialog';
import FieldsModal from '../common/FieldsModal';
import OperationModal from './OperationModal';

const OperationTracker = ({
  timezoneFromServer, operations, allRecordCount, defaultLimit,
}) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || 10,
  });
  const [selectedRow, setSelectedRow] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [openCreateEdit, setOpenCreateEdit] = useState(false);

  const [serverTimezone, setServerTimezone] = useState('America/Chicago');

  useEffect(() => {
    const tz = timezoneFromServer?.info?.identifier;
    if (tz) {
      setServerTimezone(tz);
    } else {
      setServerTimezone('America/Chicago');
    }
  }, [timezoneFromServer]);

  useEffect(() => {
    setRows(operations);
    setPaginationOptions({
      ...paginationOptions,
      count: allRecordCount,
      limit: defaultLimit,
    });
  }, [operations, allRecordCount, defaultLimit]);

  const handleFilter = useCallback((page, limit) => {
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filters = {
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
    };
    api.flight.index(filters)
      .then((res) => {
        setRows(res.flights);
        setPaginationOptions({
          count: res.all_records,
          page: fixedPage || 0,
          limit: limit || paginationOptions.limit,
        });
        const url = `/admin/flights/operation_tracker?${qs.stringify(filters)}`;
        window.history.replaceState(null, '', url);
      })
      .catch(err => console.log(err));
  }, [paginationOptions]);

  const handleChangePage = (e, newPage) => {
    handleFilter(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    handleFilter(0, parseInt(e.target.value, 10));
  };

  const handleDelete = useCallback(() => {
    api.flight.delete(selectedRow.id).then(() => {
      setOpenDelete(false);
      handleFilter(paginationOptions.page, paginationOptions.limit);
    });
  }, [selectedRow]);

  const getFields = () => {
    const defaultArray = [
      { header: 'Trip date', key: 'trip_date' },
      { header: 'Registration Number', key: 'registration_number' },
      { header: 'Leg Code', key: 'leg_code' },
      { header: 'Flight No', key: 'flight_no' },
      { header: 'Captain ID', key: 'captain_id' },
      { header: 'First Officer ID', key: 'first_officer_id' },
      { header: 'Second Officer ID', key: 'second_officer_id' },
      { header: 'First Flight Attendant ID', key: 'first_flight_attendant_id' },
      { header: 'Second Flight Attendant ID', key: 'second_flight_attendant_id' },
      { header: 'Avrg. Weight', key: 'avrg_weight' },
      { header: 'Number of passengers', key: 'no_of_passengers' },
      { header: 'Total Luggage Weight', key: 'total_luggage_weight' },
      { header: 'Total Cargo Weight', key: 'total_cargo_weight' },
      { header: 'Departing Airport Code', key: 'departing_airport_code' },
      { header: 'Arriving Airport Code', key: 'arriving_airport_code' },
      { header: 'Scheduled Arrival Date', key: 'scheduled_arrival_date' },
      { header: 'Scheduled Arrival Time', key: 'scheduled_arrival_time' },
      { header: 'Est. In Blk', key: 'est_in_blk' },
      { header: 'OUT. In Blk', key: 'out_in_blk' },
      { header: 'OFF Time', key: 'off_time' },
      { header: 'On Time', key: 'on_time' },
      { header: 'In Blk Time', key: 'in_blk_time' },
      { header: 'TAT', key: 'tat' },
      { header: 'Cycles', key: 'cycles' },
      { header: 'Delay Code', key: 'delay_code' },
      { header: 'Max Allow GTOW', key: 'max_allow_gtow' },
      { header: 'AIRC Config', key: 'airc_config' },
      { header: 'Fuel Remaining', key: 'fuel_remaining' },
      { header: 'Fuel Used', key: 'fuel_used' },
      { header: 'Lbs/Brh', key: 'lbs_brh' },
      { header: 'Lbs/Fhr', key: 'lbs_fhr' },
      { header: 'Left Oil Used', key: 'left_oil_used' },
      { header: 'Right Oil Used', key: 'right_oil_used' },
      { header: 'Take Off', key: 'take_off' },
      { header: 'Take Off by', key: 'take_off_by' },
      { header: 'Landing', key: 'landing' },
      { header: 'Landing By', key: 'landing_by' },
      { header: 'Type Of Approach', key: 'type_of_approach' },
      { header: 'Approach By', key: 'approach_by' },
      { header: 'PIR IFC', key: 'pir_ifc' },
      { header: 'PFO IFC', key: 'pfo_ifc' },
      { header: 'Night Flight Hours', key: 'night_flight_hours' },
      { header: 'Leg Approach', key: 'leg_approach' },
      { header: 'Gallons Uploaded', key: 'gallons_uploaded' },
      { header: 'Leg Approx', key: 'leg_approx' },
      { header: 'Takeoff Fuel Onboard', key: 'takeoff_fuel_onboard' },
      { header: 'Unaccompanied Minor(s)', key: 'unaccompanied_minors' },
      { header: 'Wheelchairs', key: 'wheelchairs' },
      { header: 'Aisle Chairs', key: 'aisle_chairs' },
      { header: 'Passengers Need Assist', key: 'passengers_need_assist' },
      { header: 'Lap Children', key: 'lap_children' },
      { header: 'Additional Crew Members', key: 'additional_crew_members' },
      { header: 'Cabin Jump Seat', key: 'cabin_jump_seat' },
      { header: 'Ramp', key: 'ramp' },
      { header: 'Maintenance', key: 'maintenance' },
      { header: 'Operations', key: 'operations' },
      { header: 'Other/Cateing', key: 'other_cateing' },
      { header: 'Hobbs Out', key: 'hobbs_out' },
      { header: 'Hobbs In', key: 'hobbs_in' },
      { header: 'Actions', key: 'actions', format: true },
    ];

    return defaultArray;
  };

  const handleUpdateFields = (updatedFields, save = true) => {
    setSelectedFields(getFields().filter(f => updatedFields.includes(f.key)));
    setShowFieldsModal(false);
    if (save) window.localStorage.setItem(FLIGHT_OPERATION_FIELDS, updatedFields.join(','));
  };

  useEffect(() => {
    const savedFields = window.localStorage.getItem(FLIGHT_OPERATION_FIELDS);
    if (!savedFields) {
      setSelectedFields(getFields());
      return;
    }

    handleUpdateFields(savedFields.split(','), false);
  }, []);

  const handleEdit = () => {
    handleFilter(paginationOptions.page, paginationOptions.limit);
  };

  const handleUpdate = () => {
    handleClose();
    handleEdit();
  };

  const handleClose = () => {
    setOpenCreateEdit(false);
    setSelectedRow(null);
  };

  const handleNew = () => {
    setSelectedRow(null);
    setOpenCreateEdit(true);
  };

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Title</h1>
              <div className="pull-right">
                <button className="btn btn-primary" onClick={() => setShowFieldsModal(true)}>Change selected fields</button>
                <a className="btn bg-olive margin add-btn" onClick={() => handleNew()} style={{ marginLeft: '5px' }}>New</a>
              </div>
            </div>
            <div className="box-body main_listing">
              <CustomTable
                rows={rows}
                formatters={{
                  actions: value => (
                    <div className="text-center" style={{ whiteSpace: 'nowrap' }}>
                      <a
                        className="fa fa-edit"
                        onClick={() => {
                          setSelectedRow(value);
                          setOpenCreateEdit(true);
                        }}
                      />
                      <a
                        className="fa fa-trash space-left-right"
                        onClick={() => {
                          setOpenDelete(true);
                          setSelectedRow(value);
                        }}
                      />
                    </div>
                  ),
                  trip_date: value => (value ? moment(value).tz(serverTimezone).format('DD MMM YYYY') : ''),
                  scheduled_arrival_date: value => (value ? moment(value).tz(serverTimezone).format('DD MMM YYYY') : ''),
                  scheduled_arrival_time: value => (value ? moment(value).tz(serverTimezone).format('HH:mm') : ''),
                  pir_ifc: value => (value ? moment(value).tz(serverTimezone).format('HH:mm') : ''),
                  pfo_ifc: value => (value ? moment(value).tz(serverTimezone).format('HH:mm') : ''),
                  est_in_blk: value => (value ? moment(value).tz(serverTimezone).format('HH:mm') : ''),
                  out_in_blk: value => (value ? moment(value).tz(serverTimezone).format('HH:mm') : ''),
                  off_time: value => (value ? moment(value).tz(serverTimezone).format('HH:mm') : ''),
                  on_time: value => (value ? moment(value).tz(serverTimezone).format('HH:mm') : ''),
                  in_blk_time: value => (value ? moment(value).tz(serverTimezone).format('HH:mm') : ''),
                }}
                rowsProps={selectedFields}
                paginationOptions={{
                  ...paginationOptions,
                  handleChangePage,
                  handleChangeRowsPerPage,
                }}
                suffix="suffix"
              />
            </div>
            <ConfirmationDialog
              onConfirm={handleDelete}
              onClose={() => setOpenDelete(false)}
              message="Are you sure you want to delete this Flight Operation?"
              open={openDelete}
            />
            {
              showFieldsModal && <FieldsModal open={showFieldsModal} fields={getFields()} selectedFields={selectedFields} updateFields={handleUpdateFields} handleClose={() => setShowFieldsModal(false)} />
            }
            {
              openCreateEdit
              && (
                <OperationModal open row={selectedRow} isEdit={!!selectedRow} onClose={() => handleClose()} onUpdate={() => handleUpdate()} />
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

OperationTracker.propTypes = PropTypes.any;

export default OperationTracker;
