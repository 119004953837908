import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import qs from 'qs';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import api from '../../services/api';
import { sortHelper } from '../../services/helpers';
import FieldsModal from '../common/FieldsModal';
import { updateChangeRowsPerPage } from '../../helpers';
import { PaginationKeys } from '../../constants';

const Analytics = ({
  pickups, totalCount,
  companyList, defaultLimit,
  carrierList, preferences,
}) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || parseInt(window.localStorage.getItem(PaginationKeys.shipmentAnalytics), 10) || preferences?.records_per_page || 10,
  });
  const [startDate, setStartDate] = useState(urlSearchParams.start_date || moment().subtract(1, 'day').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(urlSearchParams.end_date || moment().subtract(1, 'day').format('YYYY-MM-DD'));
  const [selectedCompany, setCompany] = useState(urlSearchParams.filter_company || '');
  const [order, setOrder] = useState({ field: urlSearchParams.sort || 'id', type: urlSearchParams.direcction || 'desc' });
  const [exportFormat, setExportFormat] = useState('xlsx');
  const [selectedCarrier, setCarrier] = useState(urlSearchParams.filter_carrier || '');
  const [datePeriod, setDatePeriod] = useState((urlSearchParams.start_date || urlSearchParams.end_date) ? 'custom' : 'yesterday');
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);

  useEffect(() => {
    setPaginationOptions({
      ...paginationOptions,
      count: totalCount,
      limit: defaultLimit,
    });
  }, [pickups, totalCount]);

  useEffect(() => {
    if (datePeriod === 'yesterday') {
      setStartDate(moment().subtract(1, 'day').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'day').format('YYYY-MM-DD'));
    } else if (datePeriod === 'lastMonth') {
      setStartDate(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
    } else if (datePeriod === 'thisWeek') {
      setStartDate(moment().startOf('isoWeek').format('YYYY-MM-DD'));
      setEndDate(moment().endOf('isoWeek').format('YYYY-MM-DD'));
    } else if (datePeriod === 'thisMonth') {
      setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
      setEndDate(moment().endOf('month').format('YYYY-MM-DD'));
    } else if (datePeriod === 'lastWeek') {
      setStartDate(moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'));
    } else if (datePeriod === 'last2Weeks') {
      setStartDate(moment().subtract(2, 'week').startOf('isoWeek').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'));
    } else if (datePeriod === 'last4Weeks') {
      setStartDate(moment().subtract(4, 'week').startOf('isoWeek').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'));
    }
  }, [datePeriod]);

  useEffect(() => {
    handleFilter({});
  }, [startDate, endDate, selectedCompany, selectedCarrier, order, datePeriod]);

  const handleFilter = useCallback(({ page, limit }) => {
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filters = {
      start_date: startDate,
      end_date: endDate,
      filter_company: selectedCompany,
      filter_carrier: selectedCarrier,
      direction: order.type,
      sort: order.field,
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
    };
    api.shipmentDashboard.analytics(filters)
      .then((res) => {
        setRows(res.shipments);
        setPaginationOptions({
          count: res.total_count,
          page: fixedPage || 0,
          limit: limit || paginationOptions.limit,
        });
        const url = `/admin/shipment_dashboard/analytics?${qs.stringify(filters)}`;
        window.history.replaceState(null, '', url);
      })
      .catch(err => console.log(err));
  }, [startDate, endDate, selectedCompany, selectedCarrier, paginationOptions, order, datePeriod]);

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setCompany('');
    setCarrier('');
  };

  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };

  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, PaginationKeys.shipmentAnalytics, val => handleFilter({ page: 0, limit: val }));
  };

  const handleSort = (field) => {
    const updatedOrder = { ...sortHelper(field, order) };
    setOrder(updatedOrder);
  };

  const getFields = () => [
    {
      header: '#',
      key: '#',
    },
    {
      header: 'Company',
      key: 'company.name',
      sortKey: 'companies.name',
      sortable: true,
    },
    {
      header: 'Carrier',
      key: 'carrier.name',
      sortKey: 'carriers.name',
      sortable: true,
    },
    {
      header: 'Shipment Date',
      key: 'ship_date',
      sortable: true,
    },
    {
      header: 'Qty',
      key: 'qty',
      sortKey: 'shipment_details.quantity',
      format: true,
      sortable: true,
    },
    {
      header: 'Origin',
      key: 'string_origin',
    },
    {
      header: 'Created At',
      key: 'created_at',
      sortable: true,
    },
    {
      header: 'Status',
      key: 'status',
      sortable: true,
    },
    {
      header: 'Tracking id',
      key: 'tracking_id',
    },
    {
      header: 'Company Column',
      key: 'company_id',
      sortable: true,
    },
    {
      header: 'Zone',
      key: 'zone',
      sortable: true,
    },
    {
      header: 'Service',
      key: 'carrier_services.name',
      sortable: true,
    },
    {
      header: 'Weight',
      key: 'shipment_weight',
      sortKey: 'shipment_details.weight',
      format: true,
      sortable: true,
    },
    {
      header: 'Base Fee',
      key: 'customer_base_price',
      sortable: true,
    },
    {
      header: 'FSC Percent',
      key: 'fuel_surcharge_percent',
      sortable: true,
    },
    {
      header: 'RF Total Fee',
      key: 'price',
      sortable: true,
    },
  ];


  const handleUpdateFields = (updatedFields, save = true) => {
    setSelectedFields(getFields().filter(f => updatedFields.includes(f.key)));
    setShowFieldsModal(false);
    if (save) window.localStorage.setItem('shipmentAnalyticsTableFields', updatedFields.join(','));
  };


  useEffect(() => {
    const savedFields = window.localStorage.getItem('shipmentAnalyticsTableFields');
    if (!savedFields) {
      setSelectedFields(getFields());
      return;
    }

    handleUpdateFields(savedFields.split(','), false);
  }, []);

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Analytics</h1>
              <a
                className="export_csv_button btn-primary btn pull-right"
                title="Export"
                href={`/admin/shipment_dashboard/analytics?${qs.stringify({
                  start_date: startDate,
                  end_date: endDate,
                  filter_company: selectedCompany,
                  export: !!exportFormat,
                  format: exportFormat,
                  filter_carrier: selectedCarrier,
                })}`}
              >
                Export
              </a>
              <select
                value={exportFormat}
                onChange={e => setExportFormat(e.target.value)}
                className="pull-right export_format_select"
              >
                <option value="xlsx">XLSX</option>
                <option value="csv">CSV</option>
                {/* <option value="pdf">PDF</option> */}
              </select>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="status-sec">
                <div className="form-inline" style={{ display: 'flex' }}>
                  <select
                    name="date_period"
                    id="date_period"
                    className="form-control"
                    value={datePeriod}
                    onChange={e => setDatePeriod(e.target.value)}
                  >
                    <option value="yesterday">Yesterday</option>
                    <option value="thisWeek">This week</option>
                    <option value="thisMonth">This month</option>
                    <option value="lastWeek">Last week</option>
                    <option value="last2Weeks">Last 2 weeks</option>
                    <option value="last4Weeks">Last 4 weeks</option>
                    <option value="lastMonth">Last month</option>
                    <option value="custom">Custom</option>
                  </select>
                  <div className="datepicker">
                    <input
                      type="date"
                      className="form-control dimensions length"
                      id="startDate"
                      name="startDate"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                        setDatePeriod('custom');
                      }}
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    />
                  </div>
                  <div className="datepicker">
                    <input
                      type="date"
                      className="form-control dimensions length"
                      id="endDate"
                      name="endDate"
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                        setDatePeriod('custom');
                      }}
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    />
                  </div>
                  <select
                    name="company"
                    id="company"
                    className="form-control"
                    value={selectedCompany}
                    onChange={(e) => {
                      setCompany(e.target.value);
                    }}
                  >
                    <option value="">Select company</option>
                    {companyList.map(company => <option key={company[1]} value={company[1]}>{company[0]}</option>)}
                  </select>
                  <select
                    name="carrier"
                    id="carrier"
                    className="form-control"
                    value={selectedCarrier}
                    onChange={(e) => {
                      setCarrier(e.target.value);
                    }}
                  >
                    <option value="">Select carrier</option>
                    {carrierList.map(carrier => <option key={carrier[1]} value={carrier[1]}>{carrier[0]}</option>)}
                  </select>
                  <button style={{ marginLeft: '10px' }} className="btn-danger btn" onClick={handleReset}>Reset</button>
                </div>
                <div className="pull-right">
                  <button className="btn btn-primary" onClick={() => setShowFieldsModal(true)}>Change selected fields</button>
                </div>
              </div>
            </div>
            <div className="box-body main_listing">
              <CustomTable
                rows={rows}
                formatters={{
                  created_at: value => (value ? moment(value).format('DD MMM YYYY') : '-'),
                  ship_date: value => (value ? moment(value).format('DD MMM YYYY') : '-'),
                  shipment_weight: value => (value && value.shipment_details?.length ? value.shipment_details[0].weight : '-'),
                  qty: value => (value && value.shipment_details?.length ? value.shipment_details[0].quantity : '-'),
                }}
                rowsProps={selectedFields}
                paginationOptions={{
                  ...paginationOptions,
                  handleChangePage,
                  handleChangeRowsPerPage,
                }}
                suffix="pickups"
                handleSort={handleSort}
                sortOptions={order}
              />
            </div>
          </div>
        </div>
      </div>
      {
        showFieldsModal && <FieldsModal open={showFieldsModal} fields={getFields()} selectedFields={selectedFields} updateFields={handleUpdateFields} handleClose={() => setShowFieldsModal(false)} />
      }
    </>
  );
};

Analytics.propTypes = {
  pickups: PropTypes.instanceOf(Array).isRequired,
  totalCount: PropTypes.number.isRequired,
  companyList: PropTypes.instanceOf(Array).isRequired,
  defaultLimit: PropTypes.number.isRequired,
  carrierList: PropTypes.instanceOf(Array).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

export default Analytics;
