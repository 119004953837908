import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle,
} from '@material-ui/core';

const CopyShipmentsModal = ({
  open, handleClose, handleSubmit,
}) => {
  const [date, setDate] = useState('');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="dialog-sm"
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Copy Shipments
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="modal-header">
          <div className="block">
            <div className="row">
              <input
                type="date"
                className="form-control dimensions length"
                id="date_copy"
                name="date_copy"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              />
            </div>
            <div className="modal-bottom-btn text-right" style={{ borderTop: 'none' }}>
              <button className="btn bg-olive" onClick={() => handleSubmit(date)}>Copy</button>
              <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={handleClose}>Cancel</button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

CopyShipmentsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

CopyShipmentsModal.defaultProps = {
  open: false,
};

export default CopyShipmentsModal;
