import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import moment from 'moment-timezone';
import api from '../../services/api';

const SwitchPickupModal = ({
  handleClose, open, handleConfirm, allowRl2, pickup,
}) => {
  const [routes, setRoutes] = useState([]);
  const [routesByDay, setRoutesByDay] = useState(moment().format('YYYY-MM-DD'));
  const [selectedRoute, setSelectedRoute] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('level_one');

  useEffect(() => {
    if (!pickup) return;

    setSelectedRoute(pickup.pickup_list1_id);
  }, [pickup]);

  useEffect(() => {
    let sortedRoutes = [];
    api.pickupsDashboard.getTodayRoutes({ filter_date: routesByDay, filter_level: selectedLevel }).then((res) => {
      sortedRoutes = res.routes;
      sortedRoutes.sort((a, b) => {
        const textA = a.cluster_name.toUpperCase();
        const textB = b.cluster_name.toUpperCase();
        if (textA < textB) return -1;
        return (textA > textB) ? 1 : 0;
      });
      setRoutes(res.routes);
    });
    if (!pickup) return;

    // eslint-disable-next-line default-case
    switch (selectedLevel) {
    case 'level_one':
      setSelectedRoute(pickup.pickup_list1_id);
      break;
    case 'level_two':
      setSelectedRoute(pickup.pickup_list2_id);
      break;
    }
  }, [routesByDay, selectedLevel]);

  return (
    <Dialog
      className="dialog-sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Switch Pickup
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row">
          <div className="col-sm-12 form-group">
            <div>Date</div>
            <div className="datepicker">
              <input
                className="form-control dimensions length"
                id="date"
                name="date"
                type="date"
                value={routesByDay}
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                onChange={e => setRoutesByDay(e.target.value)}
                style={{ minWidth: 300 }}
              />
            </div>
            {allowRl2 && (
              <>
                <div style={{ marginTop: '10px' }}>Route Level</div>
                <select
                  name="level"
                  id="level"
                  className="form-control"
                  value={selectedLevel}
                  onChange={(e) => {
                    setSelectedLevel(e.target.value);
                  }}
                >
                  <option value="">Select level</option>
                  <option value="level_one">RL1</option>
                  <option value="level_two">RL2</option>
                </select>
              </>
            )}
            <div style={{ marginTop: '10px' }}>New Route</div>
            <select
              name="company"
              id="company"
              className="form-control"
              value={selectedRoute}
              onChange={(e) => {
                setSelectedRoute(e.target.value);
              }}
            >
              <option value="">Select route</option>
              {routes.map(route => <option key={route.id} value={route.id}>{route.name}</option>)}
            </select>
          </div>
        </div>
        <button onClick={() => handleConfirm(selectedRoute)} className="btn btn-primary popup_cancel_btn">Confirm</button>
        <button onClick={handleClose} className="btn btn-primary pull-right popup_cancel_btn">Cancel</button>
      </DialogContent>
    </Dialog>
  );
};

SwitchPickupModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  pickup: PropTypes.instanceOf(Object).isRequired,
  open: PropTypes.bool,
  allowRl2: PropTypes.bool,
};

SwitchPickupModal.defaultProps = {
  open: false,
  allowRl2: false,
};

export default SwitchPickupModal;
