import React, {
  useCallback, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import api from '../../services/api';
import { errorToast } from '../../services/toast';
import UploadDetailsModal from '../common/UploadDetailsModal';

const PickupsUploadModal = ({
  handleClose, open, companies, sampleHref, callback, mode,
}) => {
  const [companyId, setCompanyId] = useState('');
  const [openDetails, setOpenDetails] = useState(false);
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const selectFileRef = useRef(null);

  const handleImport = () => {
    if (!companyId) {
      errorToast('Please select company first!');
      return;
    }
    selectFileRef.current.click();
  };

  const handleSelectFile = useCallback(async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('csv', file);
    formData.append('company_id', companyId);
    setLoading(true);
    api.pickupsDashboard[`import${mode.charAt(0).toUpperCase() + mode.slice(1)}s`](formData).then((res) => {
      setDetails(res);
      setOpenDetails(true);
      callback();
      setLoading(false);
    }).catch(err => console.error(err));
  }, [mode, companyId]);

  return (
    <Dialog
      className="confirmation-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Upload From File
      </DialogTitle>
      <DialogContent className="preview-item-container text-center" id="preview-modal-dialog">
        {loading ? (
          <div
            className="spinner-border"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <div>
              <input ref={selectFileRef} type="file" onChange={handleSelectFile} style={{ width: 0, height: 0 }} />
              <select
                className={`form-control import_${mode}s_company_id`}
                style={{ maxWidth: 180, float: 'left' }}
                id="company_id"
                value={companyId}
                onChange={e => setCompanyId(e.target.value)}
              >
                <option key="default" value="">Select Company</option>
                {companies.map(company => <option key={company[1]} value={company[1]}>{company[0]}</option>)}
              </select>
              <button
                className={`btn add-btn prevent_right_click add_${mode}s_from_file`}
                style={{ marginLeft: 20, marginRight: 5 }}
                onClick={() => {
                  handleImport();
                }}
              >
                Upload From File
              </button>
              <a href={sampleHref} className="bg-olive btn add-btn" style={{ marginRight: '10px' }}>Download Template</a>
            </div>
            <div className="modal-bottom-btn text-right">
              <button className="btn btn-primary" onClick={handleClose}>Cancel</button>
            </div>
          </>
        )}

      </DialogContent>
      {openDetails && (
        <UploadDetailsModal
          open={openDetails}
          handleClose={() => {
            setOpenDetails(false);
            handleClose();
          }}
          data={details}
        />
      )}
    </Dialog>
  );
};

PickupsUploadModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  sampleHref: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  open: PropTypes.bool,
  companies: PropTypes.instanceOf(Array).isRequired,
};

PickupsUploadModal.defaultProps = {
  open: false,
};

export default PickupsUploadModal;
