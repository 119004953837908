import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import api from '../../services/api';
import { errorToast } from '../../services/toast';
import ServicesModal from './ServicesModal';
import EditCarrierModal from './EditCarrierModal';
import AddCarrierModal from './AddCarrierModal';
import { updateChangeRowsPerPage } from '../../helpers';
import { PaginationKeys } from '../../constants';

const Carriers = ({
  carriers, allCarriersCount, defaultLimit, companies, preferences,
}) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || parseInt(window.localStorage.getItem(PaginationKeys.carriers), 10) || preferences?.records_per_page || 10,
  });
  const [action, setAction] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentCarrier, setCurrentCarrier] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const [openServices, setOpenServices] = useState(false);
  const [carrierType, setCarrierType] = useState('');
  const [carrierModal, setCarrierModal] = useState(false);

  useEffect(() => {
    setPaginationOptions({
      ...paginationOptions,
      count: allCarriersCount,
      limit: defaultLimit,
    });
  }, [carriers, allCarriersCount]);

  useEffect(() => {
    handleFilter({});
  }, []);

  const handleFilter = useCallback(({ page, limit }) => {
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filters = {
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
    };
    api.carriers.getCarriers(filters)
      .then((res) => {
        setRows(res.carriers);
        setPaginationOptions({
          count: res.all_carriers,
          page: fixedPage || 0,
          limit: limit || paginationOptions.limit,
        });
        const url = `/admin/carriers?${qs.stringify(filters)}`;
        window.history.replaceState(null, '', url);
      })
      .catch(err => console.log(err));
  }, [paginationOptions]);


  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };

  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, PaginationKeys.carriers, val => handleFilter({ page: 0, limit: val }));
  };

  const handleChangeStatuses = useCallback(() => {
    if (selectedRows.length === 0) {
      errorToast('Please select any record');
      return;
    }
    api.carriers.setStatuses({
      bulk_action: action,
      ids: selectedRows.map(item => item.id),
      controller_name: 'carrier',
    }).then(() => handleFilter({}));
  }, [action, selectedRows]);

  const handleChangeStatus = (value) => {
    api.carriers.setStatus({
      id: value.id,
      status: !value.status,
    });
  };

  const handleOpenServices = (carrier) => {
    setCurrentCarrier(carrier);
    setOpenServices(true);
  };

  const handleOpenEdit = (carrier) => {
    setCurrentCarrier(carrier);
    setOpenEdit(true);
  };

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Carriers</h1>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="status-sec">
                <div className="form-inline" style={{ display: 'flex' }}>
                  <select
                    name="action"
                    id="action"
                    className="form-control"
                    value={action}
                    onChange={(e) => {
                      setAction(e.target.value);
                    }}
                  >
                    <option value="">Select Action</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>

                  <button style={{ marginLeft: '10px' }} className="btn-primary btn shipment_filters_reset" onClick={handleChangeStatuses}>Submit</button>
                </div>
              </div>
              <div className="status-sec">
                <button
                  className="btn bg-olive"
                  onClick={() => {
                    setCarrierModal(true);
                    setCarrierType('FEDEX');
                  }}
                >
                  Add Fed-Ex Carrier
                </button>
                <button
                  className="btn bg-olive"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    setCarrierModal(true);
                    setCarrierType('UPS');
                  }}
                >
                  Add UPS Carrier
                </button>
              </div>
            </div>
            <div className="box-body main_listing">
              <CustomTable
                rows={rows}
                formatters={{
                  status: value => (
                    <button
                      type="button"
                      className={`btn btn-xs status-btn btn-toggle ${value.status ? 'active' : 'inactive'}`}
                      data-toggle="button"
                      title={`${value.status ? 'Click to inactive' : 'Click to active'}`}
                      onClick={() => handleChangeStatus(value)}
                    >
                      <span className="handle" />
                    </button>
                  ),
                  actions: value => (
                    <div>
                      <button onClick={() => handleOpenServices(value)} className="fa fa-eye" style={{ border: 'none', backgroundColor: 'transparent' }} />
                      <button onClick={() => handleOpenEdit(value)} className="fa fa-edit" style={{ border: 'none', backgroundColor: 'transparent' }} />
                    </div>
                  ),
                }}
                rowsProps={[{ header: 'Name', key: 'name' }, { header: 'Status', key: 'status', format: true }, { header: 'Actions', key: 'actions', format: true }]}
                paginationOptions={{
                  ...paginationOptions,
                  handleChangePage,
                  handleChangeRowsPerPage,
                }}
                suffix="carriers"
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            </div>
            {currentCarrier.id && (
              <>
                <ServicesModal carrierId={currentCarrier?.id} handleClose={() => setOpenServices(false)} open={openServices} />
                <EditCarrierModal update={handleFilter} row={currentCarrier} onClose={() => setOpenEdit(false)} open={openEdit} companies={companies} />
              </>
            )}
            {
              carrierModal && (
                <AddCarrierModal update={handleFilter} onClose={() => setCarrierModal(false)} carrierType={carrierType} open={carrierModal} companies={companies} />
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

Carriers.propTypes = {
  carriers: PropTypes.instanceOf(Array).isRequired,
  allCarriersCount: PropTypes.number.isRequired,
  defaultLimit: PropTypes.number.isRequired,
  companies: PropTypes.instanceOf(Array).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

export default Carriers;
