import { errorToast, successToast } from './toast';
import {
  CONVERSATION_PAGE_SIZE,
  PARTICIPANT_MESSAGES,
  UNEXPECTED_ERROR_MESSAGE,
} from '../constants';
import { MessageStatus } from '../stores/reducers/messageListReducer';
import { getSdkMessageObject } from '../conversationObject';


const apiFetch = (url, method, body) => (
  fetch(url, {
    method,
    body: JSON.stringify(body),
    headers: {
      'Access-Control-Allow-Origin': '*',
      // Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
        ? document.querySelector('meta[name=csrf-token]').content : '',
    },
  })
    .then(res => res.json())
    .then((res) => {
      if (!res.ok) {
        errorToast(res?.message);
        throw res;
      }
      if (res?.message) successToast(res.message);
      return res;
    })
);

const get = url => apiFetch(url, 'GET');
const post = (url, data) => apiFetch(url, 'POST', data);

const getToken = identity => get(`/api/twilio/get_token?email=${identity}`);
const getManifestMembers = id => get(`/api/twilio/manifest_members?id=${id}`);

const createUser = identity => post('/api/twilio/create_conversation_user', { email: identity });

const createConversationForRoute = id => post('/api/twilio/create_conversation_for_route', { id });

const getConversationParticipants = conversation => conversation.getParticipants();

const addConversation = async (
  friendlyName,
  uniqueName,
  updateParticipants,
  client,
  identity,
  attrs = {},
) => {
  if (friendlyName.length > 0 && uniqueName.length > 0 && client !== undefined) {
    try {
      const conversation = await client.createConversation({
        friendlyName,
        uniqueName,
      });
      await conversation.add(identity, attrs);

      const participants = await getConversationParticipants(conversation);
      updateParticipants(participants, conversation.sid);

      successToast('Conversation created');

      return conversation;
    } catch (e) {
      errorToast('Something went wrong');

      return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
  }
  return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
};

const addParticipant = async (
  name,
  attrs,
  convo,
) => {
  if (convo === undefined) {
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }

  if (name.length > 0) {
    try {
      const result = await convo.add(name, attrs);
      successToast(PARTICIPANT_MESSAGES.ADDED);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
};

const getMessageStatus = async (
  message,
  channelParticipants,
  email,
) => {
  const statuses = {
    [MessageStatus.Delivered]: 0,
    [MessageStatus.Read]: 0,
    [MessageStatus.Failed]: 0,
    [MessageStatus.Sending]: 0,
    [MessageStatus.Sent]: 0,
  };

  if (message.index === -1) {
    return Promise.resolve({
      ...statuses,
      [MessageStatus.Sending]: 1,
    });
  }
  statuses[MessageStatus.Sent] += 1;

  channelParticipants.forEach((participant) => {
    if (
      participant.identity === email
      || participant.type !== 'chat'
    ) {
      return;
    }

    if (
      participant.lastReadMessageIndex
      && participant.lastReadMessageIndex >= message.index
    ) {
      statuses[MessageStatus.Read] += 1;
    }
  });

  if (message.aggregatedDeliveryReceipt) {
    const sdkMessage = getSdkMessageObject(message);
    const receipts = await sdkMessage.getDetailedDeliveryReceipts(); // paginated backend query every time

    receipts.forEach((receipt) => {
      if (receipt.status === 'read') {
        statuses[MessageStatus.Read] += 1;
      }

      if (receipt.status === 'delivered') {
        statuses[MessageStatus.Delivered] += 1;
      }

      if (receipt.status === 'failed' || receipt.status === 'undelivered') {
        statuses[MessageStatus.Failed] += 1;
      }

      if (receipt.status === 'sent' || receipt.status === 'queued') {
        statuses[MessageStatus.Sending] += 1;
      }
    });
  }

  return statuses;
};

const getMessages = async conversation => conversation.getMessages(CONVERSATION_PAGE_SIZE);

export default {
  getToken,
  getConversationParticipants,
  addConversation,
  addParticipant,
  getMessageStatus,
  getMessages,
  createUser,
  createConversationForRoute,
  getManifestMembers,
};
