import qs from 'qs';
import { errorToast, successToast } from './toast';

const apiFetch = (url, method, body) => (
  fetch(url, {
    method,
    body: JSON.stringify(body),
    headers: {
      'Access-Control-Allow-Origin': '*',
      // Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
        ? document.querySelector('meta[name=csrf-token]').content : '',
    },
  })
    .then(res => res.json())
    .then((res) => {
      if (!res.id && !res.ok && !res.status) {
        errorToast(res?.message);
        throw res;
      }
      if (res?.message) successToast(res.message);
      return res;
    })
);

const apiFetchFile = (url, method, body, filename) => (
  fetch(url, {
    method,
    body: JSON.stringify(body),
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
        ? document.querySelector('meta[name=csrf-token]').content : '',
    },
  }).then((response) => {
    response.blob().then((blob) => {
      // eslint-disable-next-line no-shadow
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      if (filename) a.download = filename;
      // a.download = 'label.pdf';
      a.click();
    });
  })
);

const uploadFile = (url, data) => (
  fetch(url, {
    method: 'POST',
    body: data,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
        ? document.querySelector('meta[name=csrf-token]').content : '',
    },
  })
    .then(res => res.json())
    .then((res) => {
      if (!res.id && !res.ok) {
        errorToast(res?.message);
        throw res;
      }
      if (res?.message) successToast(res.message);
      return res;
    })
);

const get = url => apiFetch(url, 'GET');
const post = (url, data) => apiFetch(url, 'POST', data);
const postF = (url, data, filename = null) => apiFetchFile(url, 'POST', data, filename);
// const put = (url, data) => apiFetch(url, 'PUT', data);
const patch = (url, data) => apiFetch(url, 'PATCH', data);
const destroy = url => apiFetch(url, 'DELETE');

const api = {
  user: {
    getUserEmail: () => get('/admin/get_user_email'),
    getOwners: () => get('/admin/owners'),
    getSystemUsers: () => get('/admin/system_users'),
    setRecordsPerPage: data => post('/admin/admin_parents/set_records_per_page', data),
  },
  accounts: {
    getAccounts: data => get(`/admin/accounts?${qs.stringify(data)}`),
    createAccount: (id, data) => post('/admin/accounts', data),
    updateAccount: (id, data) => patch(`/admin/accounts/${id}`, data),
  },
  flightDashboard: {
    bulkActions: data => post('/admin/admin_parents/perform_bulk_action', data),
    bulkActionsF: data => postF('/admin/admin_parents/perform_bulk_action', data),
    labels: data => get(`/admin/consolidation_labels?${qs.stringify(data)}`),
    generateLabel: data => post('/admin/consolidation_labels/generate_label/', data),
  },
  shipmentDashboard: {
    bulkActions: data => post('/admin/admin_parents/perform_bulk_action', data),
    bulkStatusChange: data => post('/admin/shipment_dashboard/bulk_status_change', data),
    bulkCopy: data => post('/admin/shipment_dashboard/bulk_copy', data),
    bulkActionsF: data => postF('/admin/admin_parents/perform_bulk_action', data),
    shipments: data => get(`/admin/shipment_dashboard?${qs.stringify(data)}`),
    parentShipments: data => get(`/admin/shipment_dashboard/parent_shipments?${qs.stringify(data)}`),
    invoices: data => get(`/admin/shipment_dashboard/invoices?${qs.stringify(data)}`),
    manifests: data => get(`/admin/shipment_dashboard/manifests?${qs.stringify(data)}`),
    importData: data => uploadFile('/admin/shipment_dashboard/import_shipments', data),
    getRate: data => get(`/admin/shipments/get_rate?${qs.stringify(data)}`),
    shipSubmit: data => post('/admin/shipments/', data),
    getCarriers: data => get(`/admin/shipments/get_carriers?${qs.stringify(data)}`),
    getCarrierServicesAndPackageTypes: data => get(`/admin/shipments/get_services_and_package_types?${qs.stringify(data)}`),
    getPackageType: data => get(`/admin/shipments/get_package_type?${qs.stringify(data)}`),
    sendEmail: data => post('/admin/shipments/send_email', data),
    analytics: data => get(`/admin/shipment_dashboard/analytics?${qs.stringify(data)}`),
    voidLabel: id => get(`/admin/shipment_dashboard/void_label?label_id=${id}`),
    createParentShipment: data => post('/admin/shipments/create_parent_shipment', data),
    getChildren: data => get(`/admin/shipment_dashboard/children_shipments?${qs.stringify(data)}`),
    updateParentShipment: data => patch('/admin/shipments/update_parent_shipment', data),
    show: id => get(`/admin/shipments/${id}`),
    track: id => get(`/admin/shipments/track_json?tracking_id=${id}`),
    destroy: id => destroy(`/admin/shipments/${id}`),
    generateLabel: data => post('/admin/shipments/generate_label/', data),
  },
  addressBook: {
    getWarehouses: data => get(`/admin/warehouses?${qs.stringify(data)}`),
    warehouseCreate: (_, data) => post('/admin/warehouses', data),
    warehouseUpdate: (id, data) => patch(`/admin/warehouses/${id}`, data),
    warehouseDestroy: id => destroy(`/admin/warehouses/${id}`),
    warehouseShow: id => get(`/admin/warehouses/${id}`),
    importWarehouses: data => uploadFile('/admin/warehouses/upload_address', data),
    getAddresses: data => get(`/admin/address_book?${qs.stringify(data)}`),
    addressCreate: (_, data) => post('/admin/address_book', data),
    addressUpdate: (id, data) => patch(`/admin/address_book/${id}`, data),
    addressDestroy: id => destroy(`/admin/address_book/${id}`),
    importAddresses: data => uploadFile('/admin/address_book/upload_address', data),
    getCompanyUsers: data => get(`/admin/address_book/get_users?${qs.stringify(data)}`),
    getUserAccounts: data => get(`/admin/address_book/get_user_accounts?${qs.stringify(data)}`),
    warehouseReassignUser: data => post('/admin/warehouses/reassign_user', data),
    addressReassignUser: data => post('/admin/address_book/reassign_user', data),
    addressShow: id => get(`/admin/address_book/${id}`),
    getCustomers: data => get(`/admin/address_book/get_customers?${qs.stringify(data)}`),
  },
  zonePriceTable: {
    getTables: data => get(`/admin/zone_price_tables?${qs.stringify(data)}`),
    createTable: data => post('/admin/zone_price_tables', data),
    updateTable: (data, id) => patch(`/admin/zone_price_tables/${id}`, data),
    importData: data => uploadFile('/admin/zone_price_tables/import_data', data),
    getCarrierServices: data => get(`/admin/zone_price_tables/get_services?${qs.stringify(data)}`),
    getCarriers: data => get(`/admin/zone_price_tables/get_carriers?${qs.stringify(data)}`),
  },
  customerPriceConstants: {
    getTables: data => get(`/admin/customer_price_constants_tables?${qs.stringify(data)}`),
    createTable: data => post('/admin/customer_price_constants_tables', data),
    updateTable: (data, id) => patch(`/admin/customer_price_constants_tables/${id}`, data),
    importData: data => uploadFile('/admin/customer_price_constants_tables/import_data', data),
    getCarriers: data => get(`/admin/customer_price_constants_tables/get_carriers?${qs.stringify(data)}`),
  },
  carriers: {
    getCarriers: data => get(`/admin/carriers?${qs.stringify(data)}`),
    getServices: data => get(`/admin/carriers/services?${qs.stringify(data)}`),
    updateCarrier: (data, id) => patch(`/admin/carriers/${id}`, data),
    createCarrier: data => post('/admin/carriers', data),
    setStatuses: data => post('/admin/admin_parents/perform_bulk_action', data),
    setStatus: data => post('/admin/carriers/change_status', data),
    setServiceStatus: data => post('/admin/carriers/change_service_status', data),
    getAllCarriers: () => get('/api/carriers'),
  },
  carrierServices: {
    updateService: (data, id) => patch(`/admin/carrier_services/${id}`, data),
  },
  pickupsDashboard: {
    pickups: data => get(`/admin/pickup_dashboard/all_pickups?${qs.stringify(data)}`),
    destroyPickup: id => destroy(`/admin/pickup_dashboard/${id}`),
    getTodayRoutes: data => get(`/admin/pickup_dashboard/get_today_routes?${qs.stringify(data)}`),
    switchPickup: data => post('/admin/pickups/switch_route', data),
    availableCarriers: data => get(`/admin/pickups/available_carriers?${qs.stringify(data)}`),
    createPickup: data => post('/admin/pickups', data),
    nearestAirport: data => post('/admin/pickups/get_nearest_airport', data),
    schedulePickup: data => post('/admin/scheduled_pickups', data),
    updateScheduledPickup: (id, data) => patch(`/admin/scheduled_pickups/${id}`, data),
    updatePickup: (id, data) => patch(`/admin/pickups/${id}`, data),
    addPickupPhoto: (id, data) => patch(`/api/my_pickup_dashboard/${id}`, data),
    scheduledIndex: data => get(`/admin/scheduled_pickups?${qs.stringify(data)}`),
    destroyScheduledPickup: id => destroy(`/admin/scheduled_pickups/${id}`),
    forceSchedule: data => get(`/admin/scheduled_pickups/force_schedule?${qs.stringify(data)}`),
    updateStatus: data => post('/api/pickups_scan/update_status', data),
    addToConsolidationBag: data => post('/api/pickups_scan/add_to_consolidation_bag', data),
    removeFromConsolidationBag: data => post('/api/pickups_scan/remove_from_consolidation_bag', data),
    consolidationBagInfo: code => get(`/api/pickups_scan/consolidation_bag_summary?bag_code=${code}`),
    getScheduledPickupsByDay: day => get(`/admin/scheduled_pickups/pickups_by_day?day=${day}`),
    deletePhoto: data => post('/admin/pickup_dashboard/delete_photo', data),
    deleteScannedTrackingId: data => post('/admin/pickup_dashboard/delete_scanned_tracking_id', data),
    importPickups: data => uploadFile('/admin/pickup_dashboard/import_pickups', data),
    importScheduledPickups: data => uploadFile('/admin/scheduled_pickups/import_pickups', data),
    customerPickups: data => get(`/admin/pickup_dashboard/confirm_pickups?${qs.stringify(data)}`),
    analytics: data => get(`/admin/pickup_dashboard/analytics?${qs.stringify(data)}`),
    setCustomer: id => post(`/admin/scheduled_pickups/set_customer/${id}`),
    convertToFile: data => postF('/admin/scheduled_pickups/export_response_to_file', { responses: data }),
  },
  routesDashboard: {
    index: data => get(`/admin/route_manifests/generate_rl2_manifest_index?${qs.stringify(data)}`),
    showRoute: id => get(`/admin/route_manifests/${id}`),
    setAirportStop: data => post('/admin/route_manifests/set_airport_stop', data),
    changePosition: data => post('/admin/route_manifests/change_position', data),
    updatePositions: data => post('/admin/route_manifests/update_positions', data),
    getClusterAddresses: data => get(`/admin/route_manifests/get_rl2_manifest_addresses?${qs.stringify(data)}`),
    generateRL2: data => post('/admin/route_manifests/generate_rl2_manifest', data),
    getAirports: () => get('/admin/airports'),
    getAirportClusters: data => get(`/admin/route_manifests/get_airport_clusters?${qs.stringify(data)}`),
    createRoute: data => post('/admin/route_manifests', data),
    destroyStop: data => post('/admin/route_manifests/destroy_stop', data),
    updateRoute: (id, data) => patch(`/admin/route_manifests/${id}`, data),
    optimizeRoutesList: data => post('/admin/route_manifests/optimize_routes_list', data),
    routeInfo: id => get(`/admin/route_manifests/route_info?id=${id}`),
    getAirportStops: date => get(`/admin/route_manifests//get_airport_stops?date=${date}`),
    deleteRoute: id => destroy(`/admin/route_manifests/${id}`),
    dlRoutes: data => get(`/admin/dl_routes?${qs.stringify(data)}`),
    rl1_routes: data => get(`/admin/route_manifests?${qs.stringify(data)}`),
    rl2_routes: data => get(`/admin/route_manifests/rl2_routes?${qs.stringify(data)}`),
    approveRoute: (id, data) => patch(`/admin/route_manifests/${id}?${qs.stringify(data)}`),
    copyRoute: id => post(`/admin/route_manifests/copy_single_route?id=${id}`),
    generateFinancialReport: data => postF('/admin/route_manifests/generate_financial_report', data, `Financial-Reports-${Date.now()}.xlsx`),
    sendMessageToDriver: data => post('/admin/route_manifests/send_message_to_driver', data),
    fetchRouteCompanies: id => get(`/admin/route_manifests/select_route_companies?id=${id}`),
    generateReport: (routeId, companyId) => get(`/admin/route_manifests/generate_single_manifest_report?id=${routeId}&client_id=${companyId}`),
    track: id => get(`/admin/route_manifests/driver_location_track?manifest_id=${id}`),
  },
  routeGenerationTool: {
    get: data => get(`/admin/route_generation_tools/get_routes?${qs.stringify(data)}`),
    generateManifestDL: data => get(`/admin/route_generation_tools/generate_manifest_dl?${qs.stringify(data)}`),
    generateManifestRL: data => get(`/admin/route_generation_tools/generate_manifest_rl?${qs.stringify(data)}`),
    copyRoutes: data => post('/admin/route_generation_tools/copy_routes', data),
    copyRoute: id => post('/admin/route_generation_tools/copy_route', { id }),
  },
  rlNetwork: {
    getNetworks: data => get(`/admin/rl_networks?${qs.stringify(data)}`),
    createNetwork: (_, data) => post('/admin/rl_networks', data),
    updateNetwork: (id, data) => patch(`/admin/rl_networks/${id}`, data),
    destroyNetwork: id => destroy(`/admin/rl_networks/${id}`),
    createRoute: data => post('/admin/rl_network_routes', data),
    updateRoute: (id, data) => patch(`/admin/rl_network_routes/${id}`, data),
    destroyRoute: id => destroy(`/admin/rl_network_routes/${id}`),
    showRoute: (id, data) => get(`/admin/rl_network_routes/${id}?${qs.stringify(data)}`),
    show: id => get(`/admin/rl_networks/${id}`),
    deploy: (id, data) => post(`/admin/rl_networks/deploy?id=${id}`, data),
    getAvailablePickupsForRoute: data => get(`/admin/rl_network_routes/available_pickups?${qs.stringify(data)}`),
    getPackageClusters: (airport_id, network_id) => get(`/admin/rl_network_routes/package_clusters?airport_id=${airport_id}&network_id=${network_id}`),
    updatePickupPositions: data => post('/admin/rl_network_routes/update_pickup_position', data),
    switchPickupRoute: data => post('/admin/rl_network_route_pickups/switch_route', data),
  },
  drivers: {
    getForCluster: id => get(`/admin/drivers/for_cluster?id=${id}`),
    getDrivers: () => get('/admin/drivers/'),
  },
  flight: {
    index: data => get(`/admin/flights/operation_tracker?${qs.stringify(data)}`),
    create: data => post('/admin/flights', { flight_operation: data }),
    update: data => patch(`/admin/flights/${data.id}`, { flight_operation: data }),
    delete: id => destroy(`/admin/flights/${id}`),
  },
  s3: {
    getPresignedUrl: data => post('/api/pickups_scan/presign_upload', data),
    uploadFile: (file, presignedUrl) => new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', presignedUrl);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(errorToast(`Error uploading ${file.type} to S3: ${xhr.status}`));
          }
        }
      };
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    }),
  },
};

export default api;
