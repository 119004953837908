import React, { useState } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Clear } from '@material-ui/icons';

const GalleryItem = ({
  photo, serverTimezone, deleteImage, canEdit,
}) => {
  const [hide, setHide] = useState(false);
  const hideIfError = shouldHide => (shouldHide ? { display: 'none' } : { marginBottom: '20px', position: 'relative' });
  return (
    <div id={`photo-${photo.id}`} key={photo.id} style={hideIfError(hide)}>
      <img
        src={photo.photo_uri}
        alt="img"
        onError={() => {
          setHide(true);
        }}
      />
      {
        canEdit && (
          <div style={{
            cursor: 'pointer', position: 'absolute', top: 10, right: 25,
          }}
          >
            <Clear data-testid="delet-image-button" onClick={() => deleteImage(photo.photo_uri)} style={{ color: 'red', fontSize: 28 }} />
          </div>
        )
      }
      <div>
        {photo.local_time ? moment(photo.local_time.replace('Z', '')).tz(serverTimezone).format('llll') : moment(photo.created_at).tz(serverTimezone).format('llll')}
        {' '}
        Local Time
      </div>
    </div>
  );
};

GalleryItem.propTypes = {
  serverTimezone: PropTypes.string,
  photo: PropTypes.instanceOf(Object).isRequired,
  deleteImage: PropTypes.func,
  canEdit: PropTypes.bool,
};

GalleryItem.defaultProps = {
  serverTimezone: 'America/Chicago',
  deleteImage: () => {},
  canEdit: false,
};

export default GalleryItem;
