import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import Toast from '../common/Toast';
import api from '../../services/api';
import {
  printLabel, splitArrayPerChunks, printZplLabels, SHIPMENTS_FIELDS,
} from '../../services/helpers';
import { errorToast, successToast } from '../../services/toast';
import ShipmentImportModal from './ShipmentImportModal';
import FieldsModal from '../common/FieldsModal';
import ActualShipmentsTable from './ActualShipmentsTable';
import { PaginationKeys } from '../../constants';

const Shipments = ({
  // eslint-disable-next-line no-unused-vars
  newShipmentPath, summaryPath, date, created_day, shipments, allLabelsCount,
  companyList, defaultLimit, isAdmin, commentList, fedexCarrierList, timezoneFromServer,
  userId, countryId, preferences,
}) => {
  const getFields = () => [
    { header: 'Tracking Comment', key: 'tracking_comment' },
    { header: 'Created by', key: 'user' },
    { header: 'Ship Date', key: 'ship_date' },
    { header: 'Origin Company Name', key: 'origin_company_name' },
    { header: 'Origin Attention To', key: 'origin_attention_to' },
    { header: 'Origin Street Address', key: 'origin_street_address1' },
    { header: 'Origin City', key: 'origin_city' },
    { header: 'Origin State', key: 'origin_state' },
    { header: 'Destination Company Name', key: 'destination_company_name' },
    { header: 'Destination Attention To', key: 'destination_attention_to' },
    { header: 'Destination Street Address', key: 'destination_street_address1' },
    { header: 'Destination City', key: 'destination_city' },
    { header: 'Destination State', key: 'destination_state' },
    { header: 'Status', key: 'status_text' },
    { header: 'Created At', key: 'created_at' },
    { header: 'Carrier Service', key: 'carrier_service.name' },
    { header: 'Tracking ID', key: 'tracking_id' },
    { header: 'Pickup ID', key: 'pickup_id' },
    { header: 'Actions', key: 'actions', format: true },
  ];
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || parseInt(window.localStorage.getItem(PaginationKeys.shipments), 10) || preferences?.records_per_page || 10,
  });
  const [bulkAction, setBulkAction] = useState('');
  const [shipmentByDay, setShipmentByDay] = useState(urlSearchParams.filter_day || '');
  const [shipmentByEstimatedDay, setShipmentByEstimatedDay] = useState(urlSearchParams.estimated_ship_day || '');
  const [shipmentCreateDay, setShipmentCreateDay] = useState(urlSearchParams.created_day || '');
  const [statusResult, setStatusResult] = useState(urlSearchParams.filter_status || '');
  const [shipmentStatus, setShipmentStatus] = useState('');
  const [selectedCompany, setCompany] = useState(urlSearchParams.filter_company || '');
  const [search, setSearch] = useState(urlSearchParams.search_field || '');

  const [selectedComment, setSelectedComment] = useState(urlSearchParams.filter_comment || '');
  const [comments, setComments] = useState(commentList);
  const [showImportModal, setShowImportModal] = useState(false);
  const [serverTimezone, setServerTimezone] = useState('America/Chicago');
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState(getFields());
  const [isShipnengine, setIsShipengine] = useState(urlSearchParams.is_shipengine || '');
  const [seen, setSeen] = useState(urlSearchParams.is_read || '');
  const [failed, setFailed] = useState(urlSearchParams.shipment_error || '');

  const [shouldDropPage, setShouldDropPage] = useState(false);

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    const tz = timezoneFromServer?.info?.identifier;
    if (tz) {
      setServerTimezone(tz);
    } else {
      setServerTimezone('America/Chicago');
    }
  }, [timezoneFromServer]);

  useEffect(() => {
    setRows(shipments);
    setPaginationOptions({
      ...paginationOptions,
      count: allLabelsCount,
      limit: defaultLimit,
    });
    date && setShipmentByDay(date);
    created_day && setShipmentCreateDay(date);
  }, [shipments, date, created_day, allLabelsCount]);

  const handleAction = useCallback(async () => {
    if (bulkAction === 'display_html') {
      let labelsToPrint = '';
      for await (const items of splitArrayPerChunks(selectedRows, 10)) {
        const res = await api.shipmentDashboard.bulkActions({
          bulk_action: bulkAction,
          ids: items.map(row => row.id),
          controller_name: 'shipments',
        });
        labelsToPrint += res.img_tags;
      }
      printLabel(labelsToPrint);
    } else if (bulkAction === 'print_zpl') {
      const res = await api.shipmentDashboard.bulkActions({
        bulk_action: bulkAction,
        ids: selectedRows.map(row => row.id),
        controller_name: 'shipments',
      });

      printZplLabels(res);
    } else if (bulkAction === 'fetch_labels') {
      api.shipmentDashboard.bulkActions({
        bulk_action: bulkAction,
        ids: selectedRows.map(row => row.id),
        controller_name: 'shipments',
      });
    } else {
      api.shipmentDashboard.bulkActionsF({
        bulk_action: bulkAction,
        ids: selectedRows.map(row => row.id),
        controller_name: 'shipments',
      });
    }
  }, [bulkAction, selectedRows]);

  const handleFilter = useCallback(({ page, limit, query }) => {
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filters = {
      filter_day: shipmentByDay,
      created_day: shipmentCreateDay,
      estimated_ship_day: shipmentByEstimatedDay,
      filter_company: selectedCompany,
      filter_status: statusResult,
      filter_comment: selectedComment,
      is_shipengine: isShipnengine,
      is_read: seen,
      shipment_error: failed,
      search_field: query !== undefined ? query : search,
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
    };
    if (!firstLoad) sessionStorage.setItem('shipmentFilter', JSON.stringify(filters));
    api.shipmentDashboard.shipments(filters)
      .then((res) => {
        setRows(res?.shipments);
        setComments(res?.comments);
        setPaginationOptions({
          count: res.all_labels,
          page: fixedPage || 0,
          limit: limit || paginationOptions.limit,
        });
        const url = `/admin/shipment_dashboard?${qs.stringify(filters)}`;
        window.history.replaceState(null, '', url);
      })
      .catch(err => console.log(err));
  }, [shipmentByDay, firstLoad, shipmentCreateDay, statusResult, selectedCompany, selectedComment, paginationOptions, search, isShipnengine, seen, failed, shipmentByEstimatedDay]);

  const handleReset = () => {
    setShipmentCreateDay('');
    setShipmentByDay('');
    setShipmentByEstimatedDay('');
    setStatusResult('');
    setCompany('');
    setSelectedComment('');
    setIsShipengine('');
    setSeen('');
    setFailed('');
  };

  const handleSelectFile = (e, carrier_id) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('csv', file);
    formData.append('carrier_id', carrier_id);
    api.shipmentDashboard.importData(formData);
    setShowImportModal(false);
  };

  const handleSubmitStatus = useCallback(() => {
    if (selectedRows.length > 0) {
      api.shipmentDashboard.bulkStatusChange({ ids: selectedRows.map(row => row.id), status: shipmentStatus }).then(() => {
        handleFilter({});
        successToast('Successfully updated!');
      });
    } else errorToast('Select at least one shipment');
  }, [selectedRows, shipmentStatus]);

  const handleUpdateFields = (updatedFields, save = true) => {
    setSelectedFields(getFields().filter(f => updatedFields.includes(f.key)));
    setShowFieldsModal(false);
    if (save) window.localStorage.setItem(SHIPMENTS_FIELDS, updatedFields.join(','));
  };


  useEffect(() => {
    const savedFields = window.localStorage.getItem(SHIPMENTS_FIELDS);
    if (!savedFields) return;

    handleUpdateFields(savedFields.split(','), false);
  }, []);

  useEffect(() => {
    const filterString = sessionStorage.getItem('shipmentFilter');
    if (filterString) {
      const filter = JSON.parse(filterString);
      setShipmentByDay(filter.filter_day);
      setShipmentCreateDay(filter.created_day);
      setCompany(filter.filter_company);
      setShipmentStatus(filter.filter_status);
      setIsShipengine(filter.is_shipengine);
      setSearch(filter.search_field);
      setPaginationOptions({
        ...paginationOptions,
        page: filter.page,
        limit: filter.limit,
      });
    }
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    handleFilter(shouldDropPage ? { page: 0 } : {});
  }, [shipmentByDay, shipmentCreateDay, statusResult, selectedCompany, selectedComment, isShipnengine, seen, failed, shipmentByEstimatedDay]);

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Shipments Dashboard</h1>
              <div className="pull-right">
                <button className="btn btn-primary" onClick={() => setShowFieldsModal(true)}>Change selected fields</button>
                {/* <a href={summaryPath} className="btn btn-primary" style={{ marginLeft: '5px' }}>Shipments Summary</a> */}
                <a href={newShipmentPath} className="btn bg-olive margin add-btn" style={{ marginLeft: '5px' }}>Add Shipment</a>
                <a
                  href={`/admin/shipment_dashboard/export_shipments?${qs.stringify({
                    filter_day: shipmentByDay,
                    estimated_ship_day: shipmentByEstimatedDay,
                    created_day: shipmentCreateDay,
                    filter_company: selectedCompany,
                    filter_status: statusResult,
                    filter_comment: selectedComment,
                    search_field: search,
                    is_shipengine: isShipnengine,
                    is_read: seen,
                    shipment_error: failed,
                  })}`}
                  title="Export"
                  className="btn btn-primary"
                  style={{ marginLeft: '5px' }}
                >
                  Export
                </a>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="status-sec">
                <div className="form-inline">
                  <button style={{ marginRight: '10px' }} className="bulk_action_submit btn-primary btn" onClick={() => setShowImportModal(true)} data-testid="call-upload-modal">Upload InSight File</button>
                  <a href="/shipments_sample.csv" className="btn bg-olive margin add-btn" style={{ marginRight: '10px' }}>Download Template</a>
                  <div className="form-group">
                    <select className="form-control" value={bulkAction} onChange={e => setBulkAction(e.target.value)}>
                      <option value="">Select Action</option>
                      <option value="export">Export</option>
                      <option value="print_zpl">Print ZPL</option>
                      <option value="display_html">Print selected labels</option>
                      <option value="download_pdf">Download selected labels</option>
                      <option value="fetch_labels">Get images</option>
                    </select>
                  </div>
                  <button style={{ marginLeft: '10px' }} className="bulk_action_submit btn-primary btn" onClick={handleAction}>Submit</button>
                </div>
                <span className="bulk_action_error" />
              </div>
              <div className="status-sec">
                <div className="row">
                  <div className="col-xs-3">
                    <div className="datepicker" style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <label style={{ marginRight: 5, whiteSpace: 'nowrap' }}>Created at:</label>
                      <input
                        type="date"
                        className="form-control dimensions length"
                        id="created_at"
                        name="date"
                        value={shipmentCreateDay}
                        onChange={(e) => {
                          setSelectedComment('');
                          setShipmentCreateDay(e.target.value);
                          setShouldDropPage(true);
                        }}
                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                      />
                    </div>
                  </div>
                  <div className="col-xs-3">
                    <div className="datepicker" style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <label style={{ marginRight: 5, whiteSpace: 'nowrap' }}>Ship date:</label>
                      <input
                        type="date"
                        className="form-control dimensions length"
                        id="date"
                        name="date"
                        value={shipmentByDay}
                        onChange={(e) => {
                          setSelectedComment('');
                          setShipmentByDay(e.target.value);
                          setShouldDropPage(true);
                        }}
                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                      />
                    </div>

                  </div>
                </div>
                <div className="row" style={{ marginTop: 10 }}>
                  <div className="col-xs-6">
                    <div className="datepicker" style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <label style={{ marginRight: 5, whiteSpace: 'nowrap' }}>Estimated ship date:</label>
                      <input
                        type="date"
                        className="form-control dimensions length"
                        id="estimated_ship_day"
                        name="estimated_ship_day"
                        value={shipmentByEstimatedDay}
                        onChange={(e) => {
                          setSelectedComment('');
                          setShipmentByEstimatedDay(e.target.value);
                          setShouldDropPage(true);
                        }}
                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                        style={{ maxWidth: 200 }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-inline" style={{ display: 'flex', marginTop: 10 }}>
                  <select
                    name="bag_status"
                    id="bag_status"
                    className="form-control"
                    value={statusResult}
                    onChange={(e) => {
                      setStatusResult(e.target.value);
                      setShouldDropPage(true);
                    }}
                  >
                    <option value="">Tracking status results</option>
                    <option value="label_created">Label Created</option>
                    <option value="rl1_driver">RL1 Driver</option>
                    <option value="rl2_driver">RL2 Driver</option>
                    <option value="origin_airport">Departure Airport</option>
                    <option value="destination_airport">Destination Airport</option>
                    <option value="delivered">Delivered</option>
                    <option value="dl2_driver">DL2 Driver</option>
                    <option value="dl1_driver">DL1 Driver</option>
                    <option value="void_label">Voided</option>
                    <option value="exception">Exception</option>
                    <option value="not_found">Not found</option>
                    <option value="in_transit">In transit</option>
                  </select>
                  <select
                    name="bag_status"
                    id="bag_status"
                    className="form-control"
                    value={selectedCompany}
                    onChange={(e) => {
                      setCompany(e.target.value);
                      setShouldDropPage(true);
                    }}
                  >
                    <option value="">Select company</option>
                    {companyList.map(company => <option key={company[1]} value={company[1]}>{company[0]}</option>)}
                  </select>
                  <select
                    name="tracking_comment"
                    id="tracking_comment"
                    className="form-control"
                    style={{ width: '250px' }}
                    value={selectedComment}
                    onChange={(e) => {
                      setSelectedComment(e.target.value);
                      setShouldDropPage(true);
                    }}
                  >
                    <option value="">Select comment</option>
                    {comments.map(comment => <option key={comment} value={comment}>{comment}</option>)}
                  </select>
                  <select
                    name="is_shipengine"
                    id="is_shipengine"
                    className="form-control"
                    value={isShipnengine}
                    onChange={(e) => {
                      setIsShipengine(e.target.value);
                      setShouldDropPage(true);
                    }}
                  >
                    <option value="">All</option>
                    <option value="true">External</option>
                    <option value="false">Direct</option>
                  </select>
                  <select
                    name="seen"
                    id="seen"
                    className="form-control"
                    value={seen}
                    onChange={(e) => {
                      setSeen(e.target.value);
                    }}
                  >
                    <option value="">All</option>
                    <option value="true">Seen</option>
                    <option value="false">Not seen</option>
                  </select>
                  <select
                    name="show_errors"
                    id="show_errors"
                    className="form-control"
                    value={failed}
                    onChange={(e) => {
                      setFailed(e.target.value);
                    }}
                  >
                    <option value="">All</option>
                    <option value="true">Failed</option>
                    <option value="false">Successful</option>
                  </select>
                  <button style={{ marginLeft: '10px' }} className="btn-danger btn shipment_filters_reset" onClick={handleReset}>Reset</button>
                </div>
                {isAdmin && (
                  <>
                    <div className="row text-bold margin-top10 shipment-by-day-info">
                      <div className="col-xs-12">
                        <span>Change status to</span>
                      </div>
                    </div>
                    <div className="form-inline" style={{ display: 'flex' }}>
                      <select
                        name="shipment_status"
                        id="shipment_status"
                        className="form-control"
                        value={shipmentStatus}
                        onChange={(e) => {
                          setShipmentStatus(e.target.value);
                          setShouldDropPage(true);
                        }}
                      >
                        <option value="">Tracking status results</option>
                        <option value="label_created">Label Created</option>
                        <option value="rl1_driver">RL1 Driver</option>
                        <option value="rl2_driver">RL2 Driver</option>
                        <option value="origin_airport">Departure Airport</option>
                        <option value="destination_airport">Destination Airport</option>
                        <option value="delivered">Delivered</option>
                        <option value="dl2_driver">DL2 Driver</option>
                        <option value="dl1_driver">DL1 Driver</option>
                        <option value="void_label">Voided</option>
                        <option value="exception">Exception</option>
                        <option value="not_found">Not found</option>
                      </select>
                      <button style={{ marginLeft: '10px' }} className="btn-primary btn" onClick={handleSubmitStatus}>Submit</button>
                    </div>
                  </>
                )}
                <div className="row text-bold margin-top10 margin-bottom shipment-by-day-info">
                  <div className="col-xs-12">
                    <span>{`Total number of shipments: ${paginationOptions.count}`}</span>
                  </div>
                </div>
                <div className="status-sec" style={{ paddingLeft: 0 }}>
                  <div className="form-inline">
                    <div className="input-group">
                      <input
                        className="form-control shipment_search"
                        placeholder="Search"
                        value={search}
                        onKeyPress={e => (e.key === 'Enter' && handleFilter({ page: 0 }))}
                        onChange={e => setSearch(e.target.value)}
                      />
                      {/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                      <span
                        className="search-clear fa fa-times"
                        onClick={() => { setSearch(''); handleFilter({ query: '', page: 0 }); }}
                      />
                      {/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    </div>
                    <button type="submit" style={{ marginLeft: 10 }} className="btn btn-primary shipment_search_button" onClick={() => handleFilter({ page: 0 })}>
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-body main_listing">
              <ActualShipmentsTable
                rows={rows}
                selectedFields={selectedFields}
                serverTimezone={serverTimezone}
                isAdmin={isAdmin}
                handleFilter={handleFilter}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                paginationOptions={paginationOptions}
                userId={userId}
                countryId={countryId}
                suffix="shipments"
                paginationKey={PaginationKeys.shipments}
              />
            </div>
          </div>
        </div>
        {
          showImportModal && <ShipmentImportModal fedexCarrierList={fedexCarrierList} handleSelectFile={handleSelectFile} handleClose={() => setShowImportModal(false)} open={showImportModal} />
        }
        {
          showFieldsModal && <FieldsModal open={showFieldsModal} fields={getFields()} selectedFields={selectedFields} updateFields={handleUpdateFields} handleClose={() => setShowFieldsModal(false)} />
        }
      </div>
    </>
  );
};

Shipments.propTypes = {
  summaryPath: PropTypes.string,
  newShipmentPath: PropTypes.string,
  date: PropTypes.string,
  created_day: PropTypes.string,
  shipments: PropTypes.instanceOf(Array).isRequired,
  fedexCarrierList: PropTypes.instanceOf(Array),
  allLabelsCount: PropTypes.number.isRequired,
  companyList: PropTypes.instanceOf(Array).isRequired,
  commentList: PropTypes.instanceOf(Array).isRequired,
  defaultLimit: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool,
  timezoneFromServer: PropTypes.instanceOf(Object).isRequired,
  userId: PropTypes.number.isRequired,
  countryId: PropTypes.number.isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

Shipments.defaultProps = {
  date: '',
  created_day: '',
  summaryPath: '',
  newShipmentPath: '',
  isAdmin: false,
  fedexCarrierList: [],
};

export default Shipments;
