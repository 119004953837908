import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle,
} from '@material-ui/core';
import api from '../../services/api';
import { successToast, errorToast } from '../../services/toast';

const DeletePhotoModal = ({
  open, handleClose, pickupId, photoUrl, deleteSuccess,
}) => {
  const handleDelete = async () => {
    api.pickupsDashboard.deletePhoto({ id: pickupId, url: photoUrl }).then((res) => {
      if (res.ok) {
        deleteSuccess();
        handleClose();
        successToast(res.message);
      } else {
        errorToast(res.message);
      }
    });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="dialog-sm"
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Are you sure you want to delete this photo?
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="text-center" style={{ marginBottom: 10 }}>
          <button className="btn bg-olive" onClick={handleDelete}>Delete</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={handleClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

DeletePhotoModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  pickupId: PropTypes.number.isRequired,
  photoUrl: PropTypes.string.isRequired,
  deleteSuccess: PropTypes.func.isRequired,
};

DeletePhotoModal.defaultProps = {
  open: false,
};

export default DeletePhotoModal;
