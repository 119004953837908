import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import api from '../../services/api';
import NetworkRouteModal from './NetworkRouteModal';
import ConfirmationDialog from '../common/ConfirmationDialog';


const Network = ({ network, permissions, drivers }) => {
  const [rows, setRows] = useState([]);
  const [routeCreateModal, setRouteCreateModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [unassignedPickups, setUnassignedPickups] = useState({});

  const updateData = () => {
    api.rlNetwork.show(network.id).then((res) => {
      setRows(res.routes);
      setUnassignedPickups(res.unassigned);
    });
  };

  const handleRouteSubmit = (route) => {
    api.rlNetwork.createRoute({ rl_network_route: route }).then(updateData);
  };

  const getFields = () => {
    const defaultArray = [
      { header: 'Pickups', key: 'pickups', format: true },
      { header: 'Package Cluster', key: 'package_cluster', format: true },
      { header: 'Driver', key: 'driver', format: true },
      { header: 'State', key: 'state', format: true },
    ];
    if (permissions.rlNetwork?.edit || permissions.rlNetwork?.view) {
      defaultArray.push({
        header: 'Actions', key: 'actions', format: true, clickSkip: true,
      });
    }
    return defaultArray;
  };

  const handleDelete = useCallback(() => {
    api.rlNetwork.destroyRoute(selectedRow.id).then(() => {
      setOpenDelete(false);
      setSelectedRow({});
      updateData();
    });
  }, [selectedRow]);

  useEffect(() => {
    updateData();
  }, []);

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 1 }}>
                <h1 className="box-title">{`${network.name} - ${network.days.join(', ')}`}</h1>
                { permissions.rlNetwork?.edit && (
                  <div className="pull-right">
                    <button
                      className="btn add-btn prevent_right_click"
                      onClick={() => {
                        setRouteCreateModal(true);
                      }}
                      data-testid="add_route"
                    >
                      Add new route
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="box-body main_listing">
              <CustomTable
                rows={rows}
                onRowClick={(row) => { if (permissions.rlNetwork?.view) window.location = `/admin/rl_network_routes/${row.id}`; }}
                formatters={{
                  pickups: value => `${value.pickup_count_for_user_role}/${unassignedPickups[value.package_cluster_id] || 0}`,
                  state: value => value.package_cluster?.airport?.state,
                  package_cluster: value => (value.package_cluster ? `${value.package_cluster.region_id_lvl1}@${value.package_cluster.airport_code}` : '-'),
                  driver: value => (value.driver ? `${value.driver.user?.first_name} ${value.driver.user?.last_name}` : '-'),
                  actions: value => (
                    <div style={{ minWidth: '100px' }}>
                      {permissions.rlNetwork.view && <a href={`/admin/rl_network_routes/${value?.id}`} className="fa fa-eye  edit_user" />}
                      {permissions.rlNetwork.edit && (
                        <button
                          onClick={() => {
                            setSelectedRow(value);
                            setOpenDelete(true);
                          }}
                          className="fa fa-trash  edit_user"
                          style={{ border: 'none', backgroundColor: 'transparent' }}
                          data-testid={`delete_route_${value.id}`}
                        />
                      )}
                    </div>
                  ),
                }}
                rowsProps={getFields()}
                rowStyle={() => ({ cursor: 'pointer' })}
                suffix="rl_network_route"
              />
            </div>
            {routeCreateModal && (
              <NetworkRouteModal
                drivers={drivers}
                onClose={() => setRouteCreateModal(false)}
                open={routeCreateModal}
                onSubmit={handleRouteSubmit}
                network={network}
              />
            )}
            <ConfirmationDialog
              onConfirm={handleDelete}
              onClose={() => setOpenDelete(false)}
              message="Are you sure you want to delete this route?"
              open={openDelete}
            />
          </div>
        </div>
      </div>
    </>
  );
};

Network.propTypes = {
  network: PropTypes.instanceOf(Object).isRequired,
  permissions: PropTypes.instanceOf(Object).isRequired,
  drivers: PropTypes.instanceOf(Array),
};

Network.defaultProps = {
  drivers: [],
};

export default Network;
