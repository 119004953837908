import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { MessageStatus } from '../../../stores/reducers/messageListReducer';
import SendingIcon from '../icons/Sending';
import DeliveredIcon from '../icons/Delivered';
import ReadIcon from '../icons/Read';
import FailedIcon from '../icons/Failed';

const statusStyle = {
  display: 'inline-block',
  verticalAlign: 'middle',
  marginLeft: '4px',
};

const statusIconStyle = {
  marginLeft: '10px',
};

const MessageView = ({
  text, getStatus, author, messageTime, manifestMembers,
}) => {
  const [status, setStatus] = useState({});
  const email = useSelector(state => state.email);
  const systemUsers = useSelector(state => state.systemUsers);
  const isMyMessage = author === email;

  const getClass = () => `message ${isMyMessage ? 'mine' : 'notMine'}`;

  const getAuthor = () => {
    const m = manifestMembers.find(item => item.email === author);
    if (m) return `${m.first_name} ${m.last_name}`;

    const su = systemUsers.find(item => item.email === author);
    if (su) return `${su.first_name} ${su.last_name}`;

    return 'Admin';
  };

  const renderStatusIcon = () => {
    if (status[MessageStatus.Failed]) {
      return (
        <>
          <FailedIcon
            color="red"
            style={{ ...statusStyle, ...statusIconStyle }}
          />
        </>
      );
    }

    if (status[MessageStatus.Read]) {
      return (
        <>
          <ReadIcon
            color="gray"
            style={{ ...statusStyle, ...statusIconStyle }}
          />
        </>
      );
    }

    if (status[MessageStatus.Sent]) {
      return (
        <>
          <DeliveredIcon
            color="gray"
            style={{ ...statusStyle, ...statusIconStyle }}
          />
        </>
      );
    }

    if (status[MessageStatus.Sending]) {
      return (
        <>
          <SendingIcon
            color="gray"
            style={{ ...statusStyle, ...statusIconStyle }}
          />
        </>
      );
    }

    return null;
  };

  useEffect(() => {
    getStatus.then(value => setStatus(value));
  }, [getStatus]);

  return (
    <div className={getClass()}>
      <div className="messageContent">
        <div className="messageAuthor">
          {getAuthor()}
        </div>
        <div className="messageText">
          {text}
        </div>
        <div className="messageInfo">
          <div>
            {messageTime}
          </div>
          {isMyMessage && (
            <div className="statusIconWrapper">
              {renderStatusIcon()}
            </div>
          )}
        </div>
      </div>

      {isMyMessage && status[MessageStatus.Failed] ? (
        <div className="errorStatusWrapper">
          <span>
            An error has occurred.
          </span>
        </div>
      ) : null}
    </div>
  );
};

MessageView.propTypes = {
  text: PropTypes.string,
  getStatus: PropTypes.instanceOf(Promise).isRequired,
  author: PropTypes.string,
  messageTime: PropTypes.string,
  manifestMembers: PropTypes.instanceOf(Array),
};

MessageView.defaultProps = {
  text: '',
  author: '',
  messageTime: '',
  manifestMembers: [],
};

export default MessageView;
