import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import Toast from '../common/Toast';
import api from '../../services/api';
import ConfirmationDialog from '../common/ConfirmationDialog';
import SwitchPickupModal from './SwitchPickupModal';
import {
  getIsTestModalConfirmMessage,
  PICKUPS_FIELDS,
} from '../../services/helpers';
import PickupDetailsModal from './PickupDetailsModal';
import EditPickupModal from './EditPickupModal';
import StatusDropdown from '../common/StatusDropdown';
import PickupsUploadModal from './PickupsUploadModal';
import FieldsModal from '../common/FieldsModal';
import PickupsTable from './PickupsTable';
import { PaginationKeys } from '../../constants';

const Pickups = ({
  byDayPath, withRoutePath, byDriverPath, byClusterPath,
  newPickupPath, date,
  companyList, carrierList, defaultLimit, googleMapsKey,
  states, radiusFlexCarrierName, logoAsset, timezoneFromServer,
  limits, formStates, radiusFlexId, permission, userId, isCustomerPickupRole, isAdmin,
  isCompanyAdmin,
}) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [pickupByDay, setPickupByDay] = useState(urlSearchParams.pickup_by_day || date || '');
  const [isAssigned, setIsAssigned] = useState(urlSearchParams.is_assigned || '');
  const [selectedCompany, setCompany] = useState(urlSearchParams.filter_company || '');
  const [selectedCarrier, setCarrier] = useState(urlSearchParams.filter_carrier || '');
  const [selectedRow, setSelectedRow] = useState({});
  const [search, setSearch] = useState(urlSearchParams.search_field || '');
  const [routeFilter, setRouteFilter] = useState(urlSearchParams.route_filter || '');
  const [openDelete, setOpenDelete] = useState(false);
  const [openSwitch, setOpenSwitch] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openLockBox, setOpenLockBox] = useState(false);
  const [openTest, setOpenTest] = useState(false);
  const [openCertainty, setOpenCertainty] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState(urlSearchParams.filter_flag || '');
  const [order, setOrder] = useState({ field: urlSearchParams.sort || 'id', type: urlSearchParams.direction || 'desc' });
  const [state, setState] = useState(urlSearchParams.filter_state || '');
  const [selectedStatus, setSelectedStatus] = useState(urlSearchParams.filter_status || '');
  const [serverTimezone, setServerTimezone] = useState('America/Chicago');
  const [openImport, setOpenImport] = useState(false);
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [activeTab, setActiveTab] = useState(urlSearchParams.tab || 'primary');
  const [forceUpdate, setForceUpdate] = useState(false);
  const [isCertain, setIsCertain] = useState(urlSearchParams.pickup_certainty || '');

  useEffect(() => {
    const tz = timezoneFromServer?.info?.identifier;
    if (tz) {
      setServerTimezone(tz);
    } else {
      setServerTimezone('America/Chicago');
    }
  }, [timezoneFromServer]);

  const handleReset = () => {
    setPickupByDay('');
    setIsAssigned('');
    setCompany('');
    setCarrier('');
    setState('');
    setSelectedFlag('');
    setIsCertain('');
    setSelectedStatus('');
  };

  const handleDelete = useCallback(() => {
    api.pickupsDashboard.destroyPickup(selectedRow.id).then(() => {
      setOpenDelete(false);
      setSelectedRow({});
      setForceUpdate(true);
    });
  }, [selectedRow]);

  const handleSwitch = useCallback((routeId) => {
    api.pickupsDashboard.switchPickup({ id: selectedRow.id, route_id: routeId }).then(() => {
      setOpenSwitch(false);
      setSelectedRow({});
      setForceUpdate(true);
    });
  }, [selectedRow]);

  const handleSetLockBox = useCallback(() => {
    api.pickupsDashboard.updatePickup(selectedRow.id, { has_lockbox: !selectedRow.has_lockbox }).then(() => {
      setOpenLockBox(false);
      setSelectedRow({});
      setForceUpdate(true);
    });
  }, [selectedRow]);

  const handleSetCertainty = useCallback(() => {
    api.pickupsDashboard.updatePickup(selectedRow.id, { pickup_certainty: !selectedRow.pickup_certainty }).then(() => {
      setOpenCertainty(false);
      setSelectedRow({});
      setForceUpdate(true);
    });
  }, [selectedRow]);

  const handleSetTest = useCallback(() => {
    api.pickupsDashboard.updatePickup(selectedRow.id, { is_test: !selectedRow.is_test }).then(() => {
      setOpenTest(false);
      setSelectedRow({});
      setForceUpdate(true);
    });
  }, [selectedRow]);

  const handleFilterStatus = (_, status) => {
    setSelectedStatus(status);
  };

  const handleUpdateFields = (updatedFields, save = true) => {
    setSelectedFields(getFields().filter(f => updatedFields.includes(f.key)));
    setShowFieldsModal(false);
    if (save) window.localStorage.setItem(PICKUPS_FIELDS, updatedFields.join(','));
  };

  const isSecondaryTab = () => activeTab === 'secondary';

  const getFields = () => {
    if (isCustomerPickupRole) {
      return [
        {
          header: 'Origin',
          key: 'string_origin_new',
          sortable: false,
        },
        {
          header: 'Confirmed',
          key: 'pickup_certainty',
          sortable: true,
          clickSkip: true,
          format: true,
        },
      ];
    }

    return [
      {
        header: 'Pickup Id',
        key: 'id',
      },
      {
        header: 'Customer',
        key: 'customer',
      },
      {
        header: 'Company',
        key: 'company.name',
        sortKey: 'companies.name',
        sortable: true,
      },
      {
        header: 'Carrier',
        key: 'carrier.name',
        sortKey: 'carriers.name',
        sortable: true,
      },
      {
        header: 'Pickup Date',
        key: 'pickup_date',
        sortable: true,
      },
      {
        header: 'Ready Time',
        key: 'ready_time',
        sortable: true,
      },
      {
        header: 'Close Time',
        key: 'close_time',
        sortable: true,
      },
      {
        header: 'Cutoff',
        key: 'actual_cutoff_time',
        sortable: true,
      },
      {
        header: 'Pickup Confirmation Number',
        key: 'pickup_confirmation_number',
        sortable: true,
      },
      {
        header: 'Qty',
        key: 'packages_count',
        sortKey: 'pickup_details.quantity',
        sortable: true,
        format: true,
      },
      {
        header: 'Driver',
        key: 'driver',
        format: true,
      },
      {
        header: 'Origin',
        key: 'string_origin_new',
      },
      {
        header: 'RL1',
        key: 'pickup_list1',
      },
      {
        header: 'RL2',
        key: 'pickup_list2',
      },
      {
        header: 'DL2',
        key: 'pickup_list3',
      },
      {
        header: 'DL1',
        key: 'pickup_list4',
      },
      {
        header: 'Created At',
        key: 'created_at',
        sortable: true,
      },
      {
        header: 'Created By',
        key: 'user',
      },
      {
        header: 'Status',
        key: 'status',
        sortable: true,
        clickSkip: true,
        format: true,
      },
      {
        header: '# of Packages',
        key: 'package_count',
        sortable: true,
        clickSkip: true,
        format: true,
      },
      {
        header: '# of Photos',
        key: 'photo_count',
        sortable: true,
        format: true,
      },
      {
        header: 'Tracking ids',
        key: 'tracking_ids',
        format: true,
      },
      {
        header: 'Has Lockbox',
        key: 'has_lockbox',
        sortable: true,
      },
      {
        header: 'Confirmed',
        key: 'pickup_certainty',
        sortable: true,
        clickSkip: true,
        format: true,
      },
      {
        header: 'Approved',
        key: 'approved',
        sortable: true,
        clickSkip: true,
        format: true,
      },
      {
        header: 'Approved at',
        key: 'approved_at',
        sortable: true,
      },
      {
        header: 'Approved By',
        key: 'approved_by',
        sortable: true,
        format: true,
      },
      {
        header: 'Test',
        key: 'is_test',
        sortable: true,
        clickSkip: true,
        format: true,
      },
      {
        header: 'Scheduled',
        key: 'is_scheduled',
      },
      {
        header: 'OnCall',
        key: 'is_on_call',
        sortable: true,
      },
      {
        header: 'Record ID',
        key: 'origin_record_info',
      },
      {
        header: 'Actions',
        key: 'actions',
        clickSkip: true,
        format: true,
      },
    ];
  };

  const getTableProps = tab => ({
    companyList,
    defaultLimit,
    googleMapsKey,
    radiusFlexCarrierName,
    logoAsset,
    serverTimezone,
    limits,
    formStates,
    radiusFlexId,
    permission,
    userId,
    isCustomerPickupRole,
    isAdmin,
    isCompanyAdmin,
    pickupByDay,
    selectedCompany,
    selectedCarrier,
    order,
    state,
    selectedFlag,
    selectedStatus,
    routeFilter,
    search,
    setOrder,
    selectedFields,
    setForceUpdate,
    forceUpdate,
    setOpenDelete,
    setOpenSwitch,
    setOpenDetails,
    setOpenLockBox,
    setOpenTest,
    setOpenCertainty,
    setOpenEdit,
    setSelectedRow,
    selectedRow,
    tab: activeTab,
    visible: activeTab === tab,
    isCertain: tab === 'secondary' ? 'true' : isCertain,
    isAssigned: tab === 'secondary' ? 'unassigned' : isAssigned,
    suffix: `pickups-${tab}`,
    paginationKey: PaginationKeys.pickups,
  });

  useEffect(() => {
    const savedFields = window.localStorage.getItem(PICKUPS_FIELDS);
    if (!savedFields) {
      setSelectedFields(getFields());
      return;
    }

    handleUpdateFields(savedFields.split(','), false);
  }, []);

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">All Pickups</h1>
              <div className="pull-right">
                {
                  isCompanyAdmin || (
                    <button className="btn btn-primary" onClick={() => setShowFieldsModal(true)}>
                      Change selected fields
                    </button>
                  )
                }
                {!isCustomerPickupRole && (
                  <>
                    <a href={byDayPath} className="btn btn-primary" style={{ marginLeft: '5px' }}>Pickups By Day</a>
                    {
                      isCompanyAdmin || (
                        <>
                          <a href={withRoutePath} className="btn bg-olive margin add-btn" style={{ marginLeft: '5px' }}>Pickups With Route</a>
                          <a href={byDriverPath} className="btn bg-olive margin add-btn" style={{ marginLeft: '5px' }}>Pickups By Drive</a>
                          <a href={byClusterPath} className="btn bg-olive margin add-btn" style={{ marginLeft: '5px' }}>Pickups By Cluster</a>
                          <button
                            className="btn add-btn"
                            style={{ marginLeft: '5px' }}
                            onClick={() => {
                              setOpenImport(true);
                            }}
                          >
                            Import From File
                          </button>
                        </>
                      )
                    }
                    <a
                      className="export_csv_button btn-primary btn pull-right"
                      title="Export"
                      style={{ marginLeft: '5px' }}
                      href={`/admin/pickup_dashboard/export_pickups?${qs.stringify({
                        pickup_by_day: pickupByDay,
                        filter_company: selectedCompany,
                        filter_carrier: selectedCarrier,
                        filter_flag: selectedFlag,
                        filter_status: selectedStatus,
                        filter_state: state,
                        search_field: search,
                        route_filter: routeFilter,
                        direction: order.type,
                        sort: order.field,
                        pickup_certainty: isSecondaryTab() ? 'true' : isCertain,
                        is_assigned: isSecondaryTab() ? 'false' : isAssigned,
                      })}`}
                    >
                      Export
                    </a>
                    <a href={newPickupPath} className="btn bg-olive margin add-btn" style={{ marginLeft: '5px' }}>Add Pickup</a>
                  </>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="status-sec">
                <div className="form-inline" style={{ display: 'flex' }}>
                  <div className="datepicker">
                    <input
                      type="date"
                      className="form-control dimensions length"
                      id="date"
                      name="date"
                      value={pickupByDay}
                      onChange={e => setPickupByDay(e.target.value)}
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    />
                  </div>
                  <select
                    name="bag_status"
                    id="bag_status"
                    className="form-control"
                    value={isSecondaryTab() ? 'unassigned' : isAssigned}
                    disabled={isSecondaryTab()}
                    onChange={(e) => {
                      if (isSecondaryTab()) return;

                      setIsAssigned(e.target.value);
                    }}
                  >
                    <option value="">All Pickups</option>
                    <option value="assigned">Assigned</option>
                    <option value="unassigned">Unassigned</option>
                  </select>
                  <select
                    name="pickup_certainty"
                    id="pickup_certainty"
                    className="form-control"
                    value={isSecondaryTab() ? true : isCertain}
                    disabled={isSecondaryTab()}
                    onChange={(e) => {
                      if (isSecondaryTab()) return;

                      setIsCertain(e.target.value);
                    }}
                  >
                    <option value="">All Pickups</option>
                    <option value="true">Confirmed</option>
                    <option value="false">Not confirmed</option>
                  </select>
                  <select
                    name="flag"
                    id="flag"
                    className="form-control"
                    value={selectedFlag}
                    onChange={(e) => {
                      setSelectedFlag(e.target.value);
                    }}
                  >
                    <option value="">All Pickups flags</option>
                    <option value="is_on_call">OnCall</option>
                    <option value="is_ups">UPS</option>
                    <option value="is_fedex">FEDEX</option>
                  </select>
                  <StatusDropdown status={selectedStatus} handleUpdateStatus={handleFilterStatus} addEmpty />
                  <select
                    name="company"
                    id="company"
                    className="form-control"
                    value={selectedCompany}
                    onChange={(e) => {
                      setCompany(e.target.value);
                    }}
                  >
                    <option value="">Select company</option>
                    {companyList.map(company => <option key={company[1]} value={company[1]}>{company[0]}</option>)}
                  </select>
                  <select
                    name="carrier"
                    id="carrier"
                    className="form-control"
                    value={selectedCarrier}
                    onChange={(e) => {
                      setCarrier(e.target.value);
                    }}
                  >
                    <option value="">Select carrier</option>
                    {carrierList.map(carrier => <option key={carrier[1]} value={carrier[1]}>{carrier[0]}</option>)}
                  </select>
                  <select
                    name="state"
                    id="state"
                    className="form-control"
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                  >
                    {states.map(s => (
                      <option value={s[1]} key={s[1]}>{s[0]}</option>
                    ))}
                  </select>
                  <button style={{ marginLeft: '10px' }} className="btn-danger btn shipment_filters_reset" onClick={handleReset}>Reset</button>
                </div>
                <div className="status-sec" style={{ paddingLeft: 0 }}>
                  <div className="form-inline">
                    <div className="input-group">
                      <input
                        className="form-control shipment_search"
                        placeholder="Search"
                        value={search}
                        onKeyPress={e => (e.key === 'Enter' && setForceUpdate(true))}
                        onChange={e => setSearch(e.target.value)}
                      />
                      {/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                      <span
                        className="search-clear fa fa-times"
                        onClick={() => { setSearch(''); }}
                      />
                      {/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    </div>
                    <div className="input-group">
                      <input
                        className="form-control shipment_search"
                        placeholder="Route filter"
                        value={routeFilter}
                        onKeyPress={e => e.key === 'Enter' && setForceUpdate(true)}
                        onChange={e => setRouteFilter(e.target.value)}
                      />
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                      <span
                        className="search-clear fa fa-times"
                        onClick={() => { setRouteFilter(''); }}
                      />
                    </div>
                    <button type="submit" style={{ marginLeft: 10 }} className="btn btn-primary shipment_search_button" onClick={() => setForceUpdate(true)}>
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button className={`tabButton ${activeTab === 'primary' ? 'active' : ''}`} onClick={() => setActiveTab('primary')}>Regular</button>
              <button className={`tabButton ${activeTab === 'secondary' ? 'active' : ''}`} onClick={() => setActiveTab('secondary')}>Unassigned and confirmed</button>
            </div>
            <PickupsTable
              {...getTableProps('primary')}
            />
            <PickupsTable
              {...getTableProps('secondary')}
            />
          </div>
        </div>
      </div>
      <ConfirmationDialog
        onConfirm={handleDelete}
        onClose={() => setOpenDelete(false)}
        message="Are you sure you want to delete this pickup?"
        open={openDelete}
      />
      <ConfirmationDialog
        onConfirm={handleSetCertainty}
        onClose={() => setOpenCertainty(false)}
        message={selectedRow.pickup_certainty ? 'Are you sure you want to unconfirm this pickup?' : 'Are you sure you want to confirm this pickup?'}
        open={openCertainty}
      />
      <ConfirmationDialog
        onConfirm={handleSetTest}
        onClose={() => setOpenTest(false)}
        message={getIsTestModalConfirmMessage(selectedRow.is_test)}
        open={openTest}
      />
      <ConfirmationDialog
        onConfirm={handleSetLockBox}
        onClose={() => setOpenLockBox(false)}
        message={selectedRow.has_lockbox ? 'Are you sure you want to remove lockbox from this pickup?' : 'Are you sure you want to add lockbox for this pickup?'}
        open={openLockBox}
      />
      <SwitchPickupModal
        open={openSwitch}
        handleClose={() => setOpenSwitch(false)}
        handleConfirm={handleSwitch}
        pickupId={selectedRow.id}
        allowRl2={selectedRow.carrier_id === radiusFlexId}
        pickup={selectedRow}
      />
      {openEdit && (
        <EditPickupModal
          update={() => setForceUpdate(true)}
          companyList={companyList}
          row={selectedRow}
          onClose={() => setOpenEdit(false)}
          serverTimezone={serverTimezone}
          open={openEdit}
          states={formStates}
          limits={limits}
          radiusFlexId={radiusFlexId}
          permission={permission}
          isAdmin={isAdmin}
        />
      )}
      {openDetails && (
        <PickupDetailsModal
          handleClose={() => {
            setOpenDetails(false);
            setSelectedRow({});
            setForceUpdate(true);
          }}
          data={selectedRow}
          open={openDetails}
          serverTimezone={serverTimezone}
          googleMapsKey={googleMapsKey}
          permission={permission}
          userId={userId}
        />
      )}
      <PickupsUploadModal
        mode="pickup"
        sampleHref="/pickups_sample.csv"
        handleClose={() => setOpenImport(false)}
        companies={companyList}
        callback={() => {
          setForceUpdate(true);
        }}
        open={openImport}
      />
      {
        showFieldsModal && <FieldsModal open={showFieldsModal} fields={getFields()} selectedFields={selectedFields} updateFields={handleUpdateFields} handleClose={() => setShowFieldsModal(false)} />
      }
    </>
  );
};

Pickups.propTypes = {
  byDayPath: PropTypes.string,
  withRoutePath: PropTypes.string,
  byDriverPath: PropTypes.string,
  byClusterPath: PropTypes.string,
  newPickupPath: PropTypes.string,
  date: PropTypes.string,
  pickups: PropTypes.instanceOf(Array).isRequired,
  allPickupsCount: PropTypes.number.isRequired,
  companyList: PropTypes.instanceOf(Array).isRequired,
  carrierList: PropTypes.instanceOf(Array).isRequired,
  defaultLimit: PropTypes.number.isRequired,
  googleMapsKey: PropTypes.string.isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  formStates: PropTypes.instanceOf(Array).isRequired,
  radiusFlexCarrierName: PropTypes.string.isRequired,
  logoAsset: PropTypes.string.isRequired,
  timezoneFromServer: PropTypes.instanceOf(Object).isRequired,
  limits: PropTypes.instanceOf(Object).isRequired,
  isCustomerPickupRole: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  radiusFlexId: PropTypes.number.isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
  userId: PropTypes.number.isRequired,
  isCompanyAdmin: PropTypes.bool,
};

Pickups.defaultProps = {
  date: '',
  byDayPath: '',
  withRoutePath: '',
  byDriverPath: '',
  byClusterPath: '',
  newPickupPath: '',
  isAdmin: false,
  isCompanyAdmin: false,
};

export default Pickups;
