import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import api from '../../services/api';
import { sortHelper } from '../../services/helpers';
import SetAccount from './SetAccount';
import { updateChangeRowsPerPage } from '../../helpers';
import { PaginationKeys } from '../../constants';

const Accounts = ({ companies, users, preferences }) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || parseInt(window.localStorage.getItem(PaginationKeys.accounts), 10) || preferences?.records_per_page || 10,
  });

  const [order, setOrder] = useState({ field: urlSearchParams.sort || 'id', type: urlSearchParams.direction || 'desc' });

  useEffect(() => {
    handleFilter({});
  }, [order]);

  const handleFilter = useCallback(({ page, limit }) => {
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filters = {
      direction: order.type,
      sort: order.field,
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
    };
    api.accounts.getAccounts(filters)
      .then((res) => {
        setRows(res.records);
        setPaginationOptions({
          count: res.all_records,
          page: fixedPage || 0,
          limit: limit || paginationOptions.limit,
        });
        const url = `/admin/accounts?${qs.stringify(filters)}`;
        window.history.replaceState(null, '', url);
      })
      .catch(err => console.log(err));
  }, [paginationOptions, order]);


  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };

  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, PaginationKeys.accounts, val => handleFilter({ page: 0, limit: val }));
  };

  const handleSort = (field) => {
    const updatedOrder = { ...sortHelper(field, order) };
    setOrder(updatedOrder);
  };

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Accounts</h1>
              <div className="pull-right">
                <button onClick={() => setOpenAddNew(true)} className="btn btn-primary pull-right popup_cancel_btn">Add New Account</button>
              </div>
            </div>
            <div className="box-body main_listing">
              <CustomTable
                rows={rows}
                formatters={{
                  actions: value => (
                    <div className="text-center" style={{ minWidth: '70px' }}>
                      <button
                        onClick={() => {
                          setSelectedAccount(value);
                          setOpenAddNew(true);
                        }}
                        className="fa fa-edit edit_user"
                        style={{ border: 'none', backgroundColor: 'transparent' }}
                      />
                    </div>
                  ),
                }}
                rowsProps={[
                  {
                    header: 'Name',
                    key: 'name',
                    sortable: true,
                  },
                  {
                    header: 'Company',
                    key: 'company.name',
                    sortKey: 'companies.name',
                    sortable: true,
                  },
                  {
                    header: 'Shipments Count',
                    key: 'shipments_count',
                    sortable: false,
                  },
                  {
                    header: 'Actions',
                    key: 'actions',
                    sortable: false,
                    format: true,
                  },
                ]}
                paginationOptions={{
                  ...paginationOptions,
                  handleChangePage,
                  handleChangeRowsPerPage,
                }}
                suffix="accounts"
                handleSort={handleSort}
                sortOptions={order}
              />
            </div>
          </div>
        </div>
      </div>
      <SetAccount
        open={openAddNew}
        update={handleFilter}
        companies={companies}
        users={users}
        onClose={() => {
          setOpenAddNew(false);
          setSelectedAccount({});
        }}
        row={selectedAccount}
      />
    </>
  );
};

Accounts.propTypes = {
  companies: PropTypes.instanceOf(Array).isRequired,
  users: PropTypes.instanceOf(Array).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

export default Accounts;
