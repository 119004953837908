import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { isEmpty, pick } from 'lodash';
import api from '../../services/api';
import SelectDays from '../common/SelectDays';

const NetworkModal = ({
  row, onClose, open, onUpdate,
}) => {
  const [network, setNetwork] = useState({ days: [] });
  const [errors, setErrors] = useState({});
  const isEdit = !isEmpty(row);

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!network.name) newErrors.name = 'Please set network name';
    if (!network.days.length) newErrors.days = 'Please select network day';
    setErrors(newErrors);

    return !Object.keys(newErrors).some(key => !isEmpty(newErrors[key]));
  }, [network]);


  const handleSubmit = () => {
    if (!validateForm()) return;

    // setLoading(true);
    api.rlNetwork[`${isEdit ? 'update' : 'create'}Network`](network.id, network).then(() => {
      onClose();
      onUpdate({});
    }).catch((e) => {
      console.log(e);
      // setLoading(false);
    });
  };

  const handleChange = (data) => {
    const { id: field, value } = data;
    if (field === 'days') {
      let result = network.days;
      if (network.days.includes(value)) {
        result = result.filter(day => day !== value);
      } else {
        result = [...result, value];
      }
      setNetwork({
        ...network,
        days: result,
      });
    } else {
      setNetwork({
        ...network,
        [field]: value,
      });
    }

    setErrors({ ...errors, [field]: '' });
  };

  useEffect(() => {
    if (isEdit) {
      setNetwork(pick(row, ['id', 'name', 'days']));
    } else {
      setNetwork({
        name: '',
        days: [],
      });
    }
  }, [row]);

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Create a network
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="position">
              Set Network Title
            </label>
            <input
              className="form-control"
              id="name"
              name="name"
              type="text"
              value={network.name}
              onChange={e => handleChange(e.target)}
              required
            />
            {errors?.name && (
              <label className="error" data-testid="title_error">
                {errors.name}
              </label>
            )}
          </div>
        </div>
        <div className="form-group" style={{ marginTop: '20px' }}>
          <label htmlFor="scheduleDays">
            Schedule days
            <span className="required">*</span>
          </label>
          <SelectDays id="days" onChange={day => handleChange({ id: 'days', value: day })} value={network.days} />
          {errors?.days && (
            <label className="error" data-testid="day_error">
              {errors.days}
            </label>
          )}
        </div>
        <div className="modal-bottom-btn text-right">
          <button className="btn bg-olive" onClick={handleSubmit} data-testid="create_network">Confirm</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={onClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

NetworkModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  row: PropTypes.instanceOf(Object),
  onUpdate: PropTypes.func.isRequired,
};

NetworkModal.defaultProps = {
  open: false,
  row: {},
};

export default NetworkModal;
