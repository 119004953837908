/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import {
  withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, Polyline,
} from 'react-google-maps';
import DirectionsRendererComponent from './DirectionsRendererComponent';
import { getCoords } from '../../services/helpers';

const Map = ({
  markers, directions, smallMarkers, directionsCallback, optimizeWaypoints, routes, directionsDetails, clickCallback, constZoom,
}) => {
  const [showTipIndex, setShowTipIndex] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const [details, setDetails] = useState(directionsDetails);
  const mapRef = useRef();

  useEffect(() => {
    if (!details) return;
    const detailsObj = details;
    const bounds = new window.google.maps.LatLngBounds(detailsObj.routes[0].bounds.southwest, detailsObj.routes[0].bounds.northeast);
    detailsObj.routes[0].bounds = bounds;
    detailsObj.request = {};
    detailsObj.request.travelMode = 'DRIVING';

    // eslint-disable-next-line new-cap
    detailsObj.routes[0].overview_path = new window.google.maps.geometry.encoding.decodePath(detailsObj.routes[0].overview_polyline.points);

    detailsObj.routes[0].legs = detailsObj.routes[0].legs.map((l) => {
      const leg = l;
      leg.start_location = new window.google.maps.LatLng(leg.start_location.lat, leg.start_location.lng);
      leg.end_location = new window.google.maps.LatLng(leg.end_location.lat, leg.end_location.lng);
      leg.steps = leg.steps.map((s) => {
        const step = s;
        // eslint-disable-next-line new-cap
        step.path = new window.google.maps.geometry.encoding.decodePath(step.polyline.points);
        step.start_location = new window.google.maps.LatLng(step.start_location.lat, step.start_location.lng);
        step.end_location = new window.google.maps.LatLng(step.end_location.lat, step.end_location.lng);
        return step;
      });
      return leg;
    });
    setDetails(detailsObj);
  }, [directionsDetails]);

  const onMarkerClick = (index) => {
    setShowTipIndex(index);
  };

  useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((marker) => {
      bounds.extend(marker.position);
    });
    setMapBounds(bounds);
  }, [markers]);

  useEffect(() => {
    if (!mapBounds || !mapRef.current || directions) return;

    mapRef.current.fitBounds(mapBounds);
  }, [mapBounds, mapRef.current, directions]);

  return (
    <GoogleMap
      defaultZoom={6}
      defaultCenter={markers.length === 1 ? { ...markers[0].position } : { lat: 41.85, lng: -87.65 }}
      libraries={['geometry']}
      ref={mapRef}
      zoom={constZoom}
      onClick={ev => (clickCallback ? clickCallback(ev) : () => {})}
    >
      {markers.map((marker, index) => (
        <Marker
          key={index.toString()}
          position={marker.position}
          onClick={() => onMarkerClick(index)}
          // eslint-disable-next-line no-undef
          icon={smallMarkers ? { url: `http://maps.google.com/mapfiles/ms/icons/${marker.color}-dot.png`, scaledSize: new google.maps.Size(25, 25) } : { url: `http://maps.google.com/mapfiles/ms/icons/${marker.color}-dot.png` }}
        >
          {showTipIndex === index && (
            <InfoWindow onCloseClick={() => setShowTipIndex(false)}>
              <div>{marker.tip}</div>
            </InfoWindow>
          )}
        </Marker>
      ))}
      {directions && <DirectionsRendererComponent directionsDetails={details} directionsData={directions} callback={directionsCallback} optimizeWaypoints={optimizeWaypoints} />}
      {routes
        && routes.map((route, index) => (
          <Polyline
            key={index.toString()}
            path={[getCoords(route[0].coords), getCoords(route[1].coords)]}
            options={{
              geodesic: true,
              strokeColor: 'red',
              strokeOpacity: 1.0,
              strokeWeight: smallMarkers ? 2 : 4,
            }}
          />
        ))
      }
    </GoogleMap>
  );
};

Map.propTypes = PropTypes.any;

const MyMapComponent = compose(
  withProps({
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '400px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(props => <Map {...props} />);

export const MapWithFlexContainer = compose(
  withProps({
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(props => <Map {...props} />);

export default MyMapComponent;
