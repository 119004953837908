import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle,
} from '@material-ui/core';
import api from '../../services/api';

const AdditemToGalleryModal = ({
  open, handleClose, pickupId, setNewPhotos, newPhotos,
}) => {
  const fileInput = React.createRef();

  const handleUpload = async () => {
    const file = fileInput.current.files[0];
    const aproximatedTime = new Date().toString();
    if (!file) return;
    const prefix = `pickup/${pickupId}/`;
    api.s3.getPresignedUrl({ prefix, filename: file.name }).then((res) => {
      api.s3.uploadFile(file, res.data.presigned_url).then(() => {
        setNewPhotos([...newPhotos,
          {
            photo_uri: res.data.public_url,
            id: `fake-id-${newPhotos.length}`,
            local_time: aproximatedTime,
            created_at: aproximatedTime,
          },
        ]);
        api.pickupsDashboard.addPickupPhoto(pickupId, { url: res.data.public_url }).then(() => handleClose());
      });
    });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="dialog-sm"
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Add Gallery Photo
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="modal-header">
          <div className="block">
            <div className="row">
              <input type="file" data-testid="photo-uploader" ref={fileInput} />
            </div>
            <div className="modal-bottom-btn text-right" style={{ borderTop: 'none' }}>
              <button className="btn bg-olive" onClick={handleUpload}>Upload</button>
              <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={handleClose}>Cancel</button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

AdditemToGalleryModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  pickupId: PropTypes.number.isRequired,
  setNewPhotos: PropTypes.func.isRequired,
  newPhotos: PropTypes.func.isRequired,
};

AdditemToGalleryModal.defaultProps = {
  open: false,
};

export default AdditemToGalleryModal;
