import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import qs from 'qs';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import api from '../../services/api';
import { sortHelper, PICKUP_ANALYTIC_FIELDS } from '../../services/helpers';
import FieldsModal from '../common/FieldsModal';
import PivotTable from './PivotTable';
import { updateChangeRowsPerPage } from '../../helpers';
import { PaginationKeys } from '../../constants';

const Analytics = ({
  pickups, totalCount,
  companyList, defaultLimit,
  carrierList, timezoneFromServer,
  packageClusterList, preferences,
}) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || parseInt(window.localStorage.getItem(PaginationKeys.pickupAnalytics), 10) || preferences?.records_per_page || 10,
  });
  const [startDate, setStartDate] = useState(urlSearchParams.start_date || moment().subtract(1, 'day').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(urlSearchParams.end_date || moment().subtract(1, 'day').format('YYYY-MM-DD'));
  const [selectedCompany, setCompany] = useState(urlSearchParams.filter_company || '');
  const [order, setOrder] = useState({ field: urlSearchParams.sort || 'id', type: urlSearchParams.direcction || 'desc' });
  const [selectedCarrier, setCarrier] = useState(urlSearchParams.filter_carrier || '');
  const [datePeriod, setDatePeriod] = useState((urlSearchParams.start_date || urlSearchParams.end_date) ? 'custom' : 'yesterday');
  const [serverTimezone, setServerTimezone] = useState('America/Chicago');
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedPackageCluster, setSelectedPackageCluster] = useState(urlSearchParams.filter_package_cluster || '');
  const [pivotTableData, setPivotTableData] = useState([]);
  const [activeTab, setActiveTab] = useState(urlSearchParams.active_tab || 'dataTable');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const tz = timezoneFromServer?.info?.identifier;
    if (tz) {
      setServerTimezone(tz);
    } else {
      setServerTimezone('America/Chicago');
    }
  }, [timezoneFromServer]);

  useEffect(() => {
    setPaginationOptions({
      ...paginationOptions,
      count: totalCount,
      limit: defaultLimit,
    });
  }, [pickups, totalCount]);

  useEffect(() => {
    if (datePeriod === 'yesterday') {
      setStartDate(moment().subtract(1, 'day').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'day').format('YYYY-MM-DD'));
    } else if (datePeriod === 'lastMonth') {
      setStartDate(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
    } else if (datePeriod === 'thisWeek') {
      setStartDate(moment().startOf('isoWeek').format('YYYY-MM-DD'));
      setEndDate(moment().endOf('isoWeek').format('YYYY-MM-DD'));
    } else if (datePeriod === 'thisMonth') {
      setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
      setEndDate(moment().endOf('month').format('YYYY-MM-DD'));
    } else if (datePeriod === 'lastWeek') {
      setStartDate(moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'));
    } else if (datePeriod === 'last2Weeks') {
      setStartDate(moment().subtract(2, 'week').startOf('isoWeek').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'));
    } else if (datePeriod === 'last4Weeks') {
      setStartDate(moment().subtract(4, 'week').startOf('isoWeek').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'));
    }
  }, [datePeriod]);

  useEffect(() => {
    handleFilter({});
  }, [order]);

  useEffect(() => {
    handleFilter({ page: paginationOptions.page, limit: paginationOptions.limit, updatePivotTable: true });
  }, [startDate, endDate, selectedCompany, selectedCarrier, datePeriod, selectedPackageCluster]);

  const handleFilter = useCallback(({ page, limit, updatePivotTable }) => {
    setLoading(true);
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filter = {
      start_date: startDate,
      end_date: endDate,
      filter_company: selectedCompany,
      filter_carrier: selectedCarrier,
      filter_package_cluster: selectedPackageCluster,
      direction: order.type,
      sort: order.field,
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
    };
    api.pickupsDashboard.analytics({ ...filter, fetch_pivot_table_data: updatePivotTable })
      .then((res) => {
        setRows(res.pickups);
        setPaginationOptions({
          count: res.total_count,
          page: fixedPage || 0,
          limit: limit || paginationOptions.limit,
        });
        const url = `/admin/pickup_dashboard/analytics?${qs.stringify({ ...filter, active_tab: activeTab })}`;
        window.history.replaceState(null, '', url);

        if (!updatePivotTable || !res.table_data) return;

        const output = res.table_data.reduce((r, item) => {
          const result = r;
          // eslint-disable-next-line no-multi-assign
          const pickupList = result[item.pickup_list1_id] = result[item.pickup_list1_id] || { totals: { 'Grand Total': 0 } };
          // eslint-disable-next-line no-multi-assign
          const origin = pickupList[item.string_origin_new] = pickupList[item.string_origin_new] || { totals: { 'Grand Total': 0 } };
          // eslint-disable-next-line no-multi-assign
          const date = origin[item.pickup_date] = origin[item.pickup_date] || { totals: { 'Grand Total': 0 } };

          const packages_count = item.package_count;

          if (!date.totals[item.status_text]) date.totals[item.status_text] = 0;
          date.totals[item.status_text] += packages_count;
          date.totals['Grand Total'] += packages_count;

          if (!origin.totals[item.status_text]) origin.totals[item.status_text] = 0;
          origin.totals[item.status_text] += packages_count;
          origin.totals['Grand Total'] += packages_count;

          if (!pickupList.totals[item.status_text]) pickupList.totals[item.status_text] = 0;
          pickupList.totals[item.status_text] += packages_count;
          pickupList.totals['Grand Total'] += packages_count;

          if (!result.totals[item.status_text]) result.totals[item.status_text] = 0;
          result.totals[item.status_text] += packages_count;
          result.totals['Grand Total'] += packages_count;

          return result;
        }, { totals: { 'Grand Total': 0 } });

        setPivotTableData(output);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, [startDate, endDate, selectedCompany, selectedCarrier, paginationOptions, order, datePeriod, selectedPackageCluster]);

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setCompany('');
    setCarrier('');
    setSelectedPackageCluster('');
  };

  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };

  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, PaginationKeys.pickupAnalytics, val => handleFilter({ page: 0, limit: val }));
  };

  const handleSort = (field) => {
    const updatedOrder = { ...sortHelper(field, order) };
    setOrder(updatedOrder);
  };

  const getFields = () => [
    {
      header: '#',
      key: '#',
    },
    {
      header: 'Company',
      sortKey: 'companies.name',
      key: 'company.name',
      sortable: true,
    },
    {
      header: 'Carrier',
      sortKey: 'carriers.name',
      key: 'carrier.name',
      sortable: true,
    },
    {
      header: 'Pickup Date',
      key: 'pickup_date',
      sortable: true,
    },
    {
      header: 'Ready Time',
      key: 'ready_time',
      sortable: true,
    },
    {
      header: 'Close Time',
      key: 'close_time',
      sortable: true,
    },
    {
      header: 'Cutoff',
      key: 'actual_cutoff_time',
      sortable: true,
    },
    {
      header: 'Pickup Confirmation Number',
      key: 'pickup_confirmation_number',
      sortable: true,
    },
    {
      header: 'Qty',
      key: 'packages_count',
      sortKey: 'pickup_details.quantity',
      sortable: true,
    },
    {
      header: 'Origin',
      key: 'string_origin_new',
    },
    {
      header: 'RL1',
      key: 'pickup_list1',
    },
    {
      header: 'RL2',
      key: 'pickup_list2',
    },
    {
      header: 'DL2',
      key: 'pickup_list3',
    },
    {
      header: 'DL1',
      key: 'pickup_list4',
    },
    {
      header: 'Created At',
      key: 'created_at',
      sortable: true,
    },
    {
      header: 'Status',
      key: 'status',
      sortable: true,
      format: true,
    },
    {
      header: '# of Packages',
      key: 'package_count',
      format: true,
      sortable: true,
    },
    {
      header: '# of Photos',
      key: 'photo_count',
      sortable: true,
      format: true,
    },
    {
      header: 'Tracking ids',
      key: 'tracking_ids',
      format: true,
    },
    {
      header: 'Approved',
      key: 'approved',
      sortable: true,
    },
    {
      header: 'Has Lockbox',
      key: 'has_lockbox',
      sortable: true,
    },
    {
      header: 'Company Column',
      key: 'company_id',
      sortable: true,
    },
    {
      header: 'Service',
      key: 'carrier_services.name',
      sortable: true,
    },
    {
      header: 'Weight',
      sortKey: 'pickup_details.weight',
      key: 'pickup_weight',
      sortable: true,
      format: true,
    },
  ];


  const handleUpdateFields = (updatedFields, save = true) => {
    setSelectedFields(getFields().filter(f => updatedFields.includes(f.key)));
    setShowFieldsModal(false);
    if (save) window.localStorage.setItem(PICKUP_ANALYTIC_FIELDS, updatedFields.join(','));
  };


  useEffect(() => {
    const savedFields = window.localStorage.getItem(PICKUP_ANALYTIC_FIELDS);
    if (!savedFields) {
      setSelectedFields(getFields());
      return;
    }

    handleUpdateFields(savedFields.split(','), false);
  }, []);

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Analytics</h1>
              <a
                className="export_csv_button btn-primary btn pull-right"
                title="Export"
                href={`/admin/pickup_dashboard/analytics?${qs.stringify({
                  start_date: startDate,
                  end_date: endDate,
                  filter_company: selectedCompany,
                  export: 'xlsx',
                  filter_carrier: selectedCarrier,
                })}`}
              >
                Export
              </a>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="status-sec">
                <div className="form-inline" style={{ display: 'flex' }}>
                  <select
                    name="date_period"
                    id="date_period"
                    className="form-control"
                    value={datePeriod}
                    onChange={e => setDatePeriod(e.target.value)}
                  >
                    <option value="yesterday">Yesterday</option>
                    <option value="thisWeek">This week</option>
                    <option value="thisMonth">This month</option>
                    <option value="lastWeek">Last week</option>
                    <option value="last2Weeks">Last 2 weeks</option>
                    <option value="last4Weeks">Last 4 weeks</option>
                    <option value="lastMonth">Last month</option>
                    <option value="custom">Custom</option>
                  </select>
                  <div className="datepicker">
                    <input
                      type="date"
                      className="form-control dimensions length"
                      id="startDate"
                      name="startDate"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                        setDatePeriod('custom');
                      }}
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    />
                  </div>
                  <div className="datepicker">
                    <input
                      type="date"
                      className="form-control dimensions length"
                      id="endDate"
                      name="endDate"
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                        setDatePeriod('custom');
                      }}
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    />
                  </div>
                  <select
                    name="company"
                    id="company"
                    className="form-control"
                    value={selectedCompany}
                    onChange={(e) => {
                      setCompany(e.target.value);
                    }}
                  >
                    <option value="">Select company</option>
                    {companyList.map(company => <option key={company[1]} value={company[1]}>{company[0]}</option>)}
                  </select>
                  <select
                    name="carrier"
                    id="carrier"
                    className="form-control"
                    value={selectedCarrier}
                    onChange={(e) => {
                      setCarrier(e.target.value);
                    }}
                  >
                    <option value="">Select carrier</option>
                    {carrierList.map(carrier => <option key={carrier[1]} value={carrier[1]}>{carrier[0]}</option>)}
                  </select>
                  <select
                    name="package_cluster"
                    id="package_cluster"
                    className="form-control"
                    value={selectedPackageCluster}
                    onChange={(e) => {
                      setSelectedPackageCluster(e.target.value);
                    }}
                  >
                    <option value="">Select package cluster</option>
                    {packageClusterList.map(cluster => <option key={cluster[0]} value={cluster[0]}>{`${cluster[1]}@${cluster[2]}`}</option>)}
                  </select>
                  <button style={{ marginLeft: '10px' }} className="btn-danger btn" onClick={handleReset}>Reset</button>
                </div>
                <div className="pull-right">
                  <button className="btn btn-primary" onClick={() => setShowFieldsModal(true)}>Change selected fields</button>
                </div>
              </div>
            </div>
            <div className="box-body main_listing">
              <div>
                <button className={`tabButton ${activeTab === 'dataTable' ? 'active' : ''}`} onClick={() => setActiveTab('dataTable')}>Data</button>
                <button className={`tabButton ${activeTab === 'pivotTable' ? 'active' : ''}`} onClick={() => setActiveTab('pivotTable')}>Pivot Table</button>
              </div>
              <div className="tabContent">
                {loading === true && (
                  <div
                    className="spinner-border"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {activeTab === 'dataTable' && !loading && (
                  <CustomTable
                    rows={rows}
                    formatters={{
                      created_at: value => (value ? moment(value).format('DD MMM YYYY') : '-'),
                      pickup_date: value => (value ? moment(value).format('DD MMM YYYY') : '-'),
                      ready_time: value => moment(value).tz(serverTimezone).format('hh:mm A z'),
                      close_time: value => moment(value).tz(serverTimezone).format('hh:mm A z'),
                      actual_cutoff_time: value => (value ? moment(value).tz(serverTimezone).format('hh:mm A z') : ''),
                      pickup_weight: value => (value && value.pickup_details && value.pickup_details.length ? value.pickup_details[0].weight : '-'),
                      pickup_list1: value => <div className="text-center">{value ? `${value?.package_cluster?.region_id_lvl1} @ ${value?.package_cluster?.airport_code}` : '-'}</div>,
                      pickup_list2: value => <div className="text-center">{value ? `${value?.package_cluster?.region_id_lvl1} @ ${value?.package_cluster?.airport_code}` : '-'}</div>,
                      pickup_list3: value => <div className="text-center">{value ? `${value?.package_cluster?.region_id_lvl1} @ ${value?.package_cluster?.airport_code}` : '-'}</div>,
                      pickup_list4: value => <div className="text-center">{value ? `${value?.package_cluster?.region_id_lvl1} @ ${value?.package_cluster?.airport_code}` : '-'}</div>,
                      package_count: value => <div className="text-center">{value.status === 'not_found' ? 1 : (value.package_count || 0)}</div>,
                      photo_count: value => <div className="text-center">{value.photos.length}</div>,
                      tracking_ids: value => (
                        <div>
                          { value?.scanned_tracking_ids?.map(item => (<div key={item.tracking_code}>{item.tracking_code}</div>)) }
                          { value?.scanned_fedex_tracking_ids?.map(item => (<div key={item.tracking_code}>{item.tracking_code}</div>)) }
                          { value?.scanned_ups_tracking_ids?.map(item => (<div key={item.tracking_code}>{item.tracking_code}</div>)) }
                        </div>
                      ),
                      approved: value => <div className="text-center">{`${value ? '+' : '-'}`}</div>,
                      has_lockbox: value => <div className="text-center">{`${value ? '+' : '-'}`}</div>,
                      pickup_confirmation_number: value => <span>{value?.toUpperCase()}</span>,
                      status: value => <span>{value.status_text}</span>,
                      packages_count: value => (value.pickup_details && value.pickup_details.length ? value.pickup_details[0].quantity : 0),
                    }}
                    rowsProps={selectedFields}
                    paginationOptions={{
                      ...paginationOptions,
                      handleChangePage,
                      handleChangeRowsPerPage,
                    }}
                    suffix="pickups"
                    sortable
                    handleSort={handleSort}
                    sortOptions={order}
                  />
                )}
                {activeTab === 'pivotTable' && !loading && (
                  <PivotTable data={pivotTableData} suffix="pickup-pivot" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        showFieldsModal && <FieldsModal open={showFieldsModal} fields={getFields()} selectedFields={selectedFields} updateFields={handleUpdateFields} handleClose={() => setShowFieldsModal(false)} />
      }
    </>
  );
};

Analytics.propTypes = {
  pickups: PropTypes.instanceOf(Array).isRequired,
  totalCount: PropTypes.number.isRequired,
  companyList: PropTypes.instanceOf(Array).isRequired,
  defaultLimit: PropTypes.number.isRequired,
  carrierList: PropTypes.instanceOf(Array).isRequired,
  timezoneFromServer: PropTypes.instanceOf(Object).isRequired,
  packageClusterList: PropTypes.instanceOf(Array).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

export default Analytics;
