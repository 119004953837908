import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import ConversationView from './ConversationView';
import { actionCreators } from '../../stores';
import { getTypingMessage, unexpectedErrorNotification } from '../../helpers';
import { getSdkConversationObject } from '../../conversationObject';

function getLastMessage(messages, typingData) {
  if (messages === undefined || messages === null) {
    return 'Loading...';
  }
  if (typingData.length) {
    return getTypingMessage(typingData);
  }
  if (messages.length === 0) {
    return 'No messages';
  }
  return messages[messages.length - 1].body || 'Media message';
}

function isMyMessage(messages, email) {
  if (messages === undefined || messages === null || messages.length === 0) {
    return null;
  }
  return messages[messages.length - 1].author
  === email
    ? messages[messages.length - 1]
    : null;
}

// eslint-disable-next-line consistent-return
async function updateCurrentConvo(
  setSid,
  convo,
  updateParticipants,
) {
  setSid(convo.sid);

  try {
    const participants = await getSdkConversationObject(
      convo,
    ).getParticipants();
    updateParticipants(participants, convo.sid);
  } catch {
    return Promise.reject(new Error('Unexpected error occurred'));
  }
}

function setUnreadMessagesCount(
  currentConvoSid,
  convoSid,
  unreadMessages,
  updateUnreadMessages,
) {
  if (currentConvoSid === convoSid && unreadMessages[convoSid] !== 0) {
    updateUnreadMessages(convoSid, 0);
    return 0;
  }
  if (currentConvoSid === convoSid) {
    return 0;
  }
  return unreadMessages[convoSid];
}

const ConversationsList = ({ date }) => {
  const sid = useSelector(state => state.sid);
  const conversations = useSelector(state => state.convos);
  const messages = useSelector(state => state.messages);
  const unreadMessages = useSelector(state => state.unreadMessages);
  const participants = useSelector(state => state.participants);
  const typingData = useSelector(state => state.typingData);
  const email = useSelector(state => state.email);
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const {
    updateCurrentConversation,
    updateParticipants,
    updateUnreadMessages,
    setLastReadIndex,
    addNotifications,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    if (!date) {
      setFilteredConversations(conversations);
      return;
    }
    setLoading(true);
    setFilteredConversations(conversations.filter(convo => moment(convo.dateCreated).format('YYYY-MM-DD') === date));
    setLoading(false);
  }, [date, conversations]);

  const renderContent = () => {
    if (loading) return <div>Loading...</div>;

    if (!filteredConversations.length) return <div>No conversations found</div>;

    return filteredConversations.map(convo => (
      <ConversationView
        key={convo.sid}
        convoId={convo.sid}
        setSid={updateCurrentConversation}
        currentConvoSid={sid}
        lastMessage={
          getLastMessage(messages[convo.sid], typingData[convo.sid] ?? [])
          ?? ''
        }
        messages={messages[convo.sid]}
        typingInfo={typingData[convo.sid] ?? []}
        myMessage={isMyMessage(messages[convo.sid], email)}
        unreadMessagesCount={setUnreadMessagesCount(
          sid,
          convo.sid,
          unreadMessages,
          updateUnreadMessages,
        )}
        participants={participants[convo.sid] ?? []}
        convo={convo}
        onClick={async () => {
          try {
            setLastReadIndex(convo.lastReadMessageIndex ?? -1);
            await updateCurrentConvo(
              updateCurrentConversation,
              convo,
              updateParticipants,
            );
            // update unread messages
            updateUnreadMessages(convo.sid, 0);
            // set messages to be read
            const lastMessage = messages[convo.sid].length
              && messages[convo.sid][messages[convo.sid].length - 1];
            if (lastMessage && lastMessage.index !== -1) {
              await getSdkConversationObject(
                convo,
              ).advanceLastReadMessageIndex(lastMessage.index);
            }
          } catch {
            unexpectedErrorNotification(addNotifications);
          }
        }}
      />
    ));
  };

  return (
    <div id="conversation-list">
      {renderContent()}
    </div>
  );
};

ConversationsList.propTypes = {
  date: PropTypes.string,
};

ConversationsList.defaultProps = {
  date: '',
};

export default ConversationsList;
