import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import qs from 'qs';
import api from '../../services/api';
import SetAddress from '../shipments_dashboard/SetAddress';
import ConfirmationDialog from '../common/ConfirmationDialog';
import CustomTable from '../common/Table';
import { errorToast } from '../../services/toast';
import Toast from '../common/Toast';
import UploadModal from '../common/UploadModal';
import { updateChangeRowsPerPage } from '../../helpers';
import { PaginationKeys } from '../../constants';
import { ADDRESS_TABLE_FIELDS } from '../../services/helpers';

const SAMPLE_HREF = {
  address: '/address_book_sample.csv',
  warehouse: '/warehouses_sample.csv',
};

const Addresses = ({
  states, countryId, companies, userId, filters, requestPath, permission, mode, limits, timezoneFromServer, preferences,
}) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [openSetAddress, setOpenSetAddress] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState(urlSearchParams.search_field || '');
  const [companyId, setCompanyId] = useState(urlSearchParams.filter_company || '');
  const [filterUserEmail, setFilterUserEmail] = useState(urlSearchParams.user_email || '');
  const [reassignUserId, setReassignUserId] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || parseInt(window.localStorage.getItem(PaginationKeys[mode]), 10) || preferences?.records_per_page || 10,
  });
  const [serverTimezone, setServerTimezone] = useState('America/Chicago');

  useEffect(() => {
    const tz = timezoneFromServer?.info?.identifier;
    if (tz) {
      setServerTimezone(tz);
    } else {
      setServerTimezone('America/Chicago');
    }
  }, [timezoneFromServer]);

  useEffect(() => {
    handleFilter({});
  }, []);

  const handleFilter = useCallback(({
    page, limit, clear, clearFilters,
  }) => {
    const suffix = mode === 'warehouse' ? 's' : 'es';
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filter = {
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
      search_field: clear ? '' : searchQuery,
      filter_company: clearFilters ? '' : companyId,
      user_email: clearFilters ? '' : filterUserEmail,
    };
    api.addressBook[`get${capitalize(mode)}${suffix}`](filter)
      .then((res) => {
        mode === 'warehouse' ? setRows(res?.warehouses_data) : setRows(res?.address_book);
        setPaginationOptions({
          count: res?.total_records,
          page: fixedPage || 0,
          limit: limit || paginationOptions.limit,
        });
        const controller = mode === 'warehouse' ? 'warehouses' : 'address_book';
        const url = `/admin/${controller}?${qs.stringify(filter)}`;
        window.history.replaceState(null, '', url);
      })
      .catch(err => console.log(err));
  }, [paginationOptions, searchQuery, companyId, filterUserEmail]);

  const handleDelete = useCallback(() => {
    api.addressBook[`${mode}Destroy`](selectedRow.id).then(() => {
      setOpenDelete(false);
      handleFilter({});
    });
  }, [mode, selectedRow]);

  useEffect(() => {
    companyId && api.addressBook.getCompanyUsers({ company_id: companyId }).then(({ data }) => {
      setUsers(data.return_users_r);
    });
  }, [companyId]);

  const handleReassign = useCallback(() => {
    api.addressBook[`${mode}ReassignUser`]({
      hidden_company_id: companyId,
      reassign_user: reassignUserId,
      is_react: true,
      ids: selectedRows.map(item => item.id),
    })
      .then(() => {
        handleFilter({});
      })
      .catch(err => console.log(err));
  }, [selectedRows, companyId, reassignUserId, mode]);

  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };

  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, PaginationKeys[mode], val => handleFilter({ page: 0, limit: val }));
  };

  const handleChangeUser = () => {
    if (!companyId) errorToast('Please select company first!');
  };

  const handleReset = () => {
    setFilterUserEmail('');
    setCompanyId('');
  };

  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="box">
          <Toast />
          <div className="box-header">
            <h1 className="box-title">{mode === 'warehouse' ? 'Warehouses' : 'Address Book'}</h1>
            <div className="pull-right">
              {mode === 'warehouse' && <a className="export_csv_button btn-primary btn pull-right" style={{ marginLeft: '5px' }} title="Export All" href={`${requestPath}?export=true`}>Export All</a>}
              <button
                className="btn add-btn pull-right prevent_right_click add_address_class_new"
                style={{ marginLeft: '5px' }}
                onClick={() => {
                  setOpenUpload(true);
                }}
              >
                Upload From File
              </button>
              <button
                className="btn add-btn pull-right prevent_right_click add_address_class_new"
                onClick={() => {
                  setSelectedRow({});
                  setOpenSetAddress(true);
                }}
              >
                {`Add New ${capitalize(mode)}`}
              </button>
            </div>
          </div>

          <div className="import-addresses-container">
            {
              filters && (
                <div>
                  <div className="pull-right" style={{ paddingRight: 0 }}>
                    <div className="status-sec" style={{ paddingRight: 0 }}>
                      <div className="form-inline">
                        <div className="form-group">
                          <select
                            className="form-control import_address_company_id"
                            style={{ width: '200px' }}
                            id="reassign_user_id"
                            value={reassignUserId}
                            onChange={e => setReassignUserId(e.target.value)}
                            onClick={() => handleChangeUser()}
                          >
                            <option key="default" value="">Select User</option>
                            {users.map(user => <option key={user.id} value={user.id}>{user.email}</option>)}
                          </select>
                        </div>
                        <button
                          type="submit"
                          className="reassign_user_submit_btn btn-primary btn"
                          style={{ marginLeft: '5px' }}
                          onClick={() => {
                            handleReassign();
                          }}
                        >
                          Reassign
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="status-sec" style={{ paddingLeft: 0 }}>
                    <div className="form-inline" style={{ display: 'flex' }}>
                      <select
                        className="form-control import_address_company_id"
                        id="filter_company_id"
                        value={companyId}
                        onChange={e => setCompanyId(e.target.value)}
                      >
                        <option key="default" value="">Select Company</option>
                        {companies.map(company => <option key={company[1]} value={company[1]}>{company[0]}</option>)}
                      </select>
                      <select
                        className="form-control import_address_company_id"
                        style={{ width: '200px' }}
                        id="filter_user_id"
                        value={filterUserEmail}
                        onChange={e => setFilterUserEmail(e.target.value)}
                        onClick={() => handleChangeUser()}
                      >
                        <option key="default" value="">Select User</option>
                        {users.map(user => <option key={user.id} value={user.email}>{user.email}</option>)}
                      </select>
                      <button
                        type="submit"
                        className="btn-primary btn"
                        style={{ marginLeft: '5px' }}
                        onClick={() => {
                          handleFilter({});
                        }}
                      >
                        Submit
                      </button>
                      <button
                        type="submit"
                        className="btn-danger btn companies_filter_reset"
                        style={{ marginLeft: '5px' }}
                        onClick={() => {
                          handleReset();
                          handleFilter({ clearFilters: true });
                        }}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              )}
            <div style={{ display: 'flex', paddingTop: '12px' }}>
              <div className="warehouse-search-input input-group">
                <input
                  className="form-control"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                />
                <button
                  type="submit"
                  className="search-clear"
                  onClick={() => {
                    setSearchQuery('');
                    handleFilter({ clear: true });
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                style={{ marginLeft: '5px' }}
                onClick={() => {
                  handleFilter({});
                }}
              >
                <i className="fa fa-search" />
              </button>
            </div>
          </div>
          <CustomTable
            rows={rows}
            paginationOptions={{
              ...paginationOptions,
              handleChangePage,
              handleChangeRowsPerPage,
            }}
            suffix={mode}
            selectedRows={filters ? selectedRows : false}
            setSelectedRows={setSelectedRows}
            formatters={{
              actions: value => (
                <div className="text-center" style={{ minWidth: '70px' }}>
                  {((value.user_id !== userId && permission.editOthers) || (value.user_id === userId && permission.editMine)) && (
                    <>
                      <button
                        className="fa fa-edit space-left-right action-button"
                        onClick={() => {
                          setOpenSetAddress(true);
                          setSelectedRow(value);
                        }}
                      />
                      <button
                        className="fa fa-trash delete-warehouse action-button"
                        onClick={() => {
                          setOpenDelete(true);
                          setSelectedRow(value);
                        }}
                      />
                    </>
                  )}
                </div>
              ),
              customer_name: value => (
                <div className="text-center" style={{ minWidth: '70px' }}>
                  {value.customer ? `${value.customer.first_name} ${value.customer.last_name}` : '-'}
                </div>
              ),
              user: user => <span>{user ? `${user.first_name} ${user.last_name}` : ''}</span>,
            }}
            rowsProps={ADDRESS_TABLE_FIELDS[mode]}
            rightCheckbox
          />
          {openSetAddress && (
            <SetAddress
              mode={mode}
              onClose={() => setOpenSetAddress(false)}
              row={selectedRow}
              states={states}
              companies={companies}
              open={openSetAddress}
              update={handleFilter}
              countryId={countryId}
              userId={userId}
              limits={limits}
              serverTimezone={serverTimezone}
            />
          )}
          <ConfirmationDialog
            onConfirm={handleDelete}
            onClose={() => setOpenDelete(false)}
            message={`Are you sure you want to delete this ${mode}?`}
            open={openDelete}
          />
          <UploadModal
            mode={mode}
            sampleHref={SAMPLE_HREF[mode]}
            handleClose={() => setOpenUpload(false)}
            companies={companies}
            callback={() => {
              handleFilter({ forceMode: mode });
            }}
            open={openUpload}
          />
        </div>
      </div>
    </div>
  );
};

Addresses.propTypes = {
  countryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  companies: PropTypes.instanceOf(Array).isRequired,
  filters: PropTypes.bool.isRequired,
  requestPath: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
  limits: PropTypes.instanceOf(Object).isRequired,
  timezoneFromServer: PropTypes.instanceOf(Object).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

export default Addresses;
