import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import api from '../../services/api';
import { isAddressValid } from '../../services/helpers';

const EditCarrierModal = ({
  row, onClose, open, update,
}) => {
  const [errors, setErrors] = useState({});
  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRecord(row);
  }, [row]);

  const validate = useCallback(() => {
    Object.keys(record).forEach((key) => {
      if (['name', 'slug'].includes(key) && !record[key]) {
        errors[key] = `Please enter ${key.replace('_', ' ')}`;
      }
    });
    setErrors({ ...errors });
    return errors;
  }, [record]);

  const handleChange = (e) => {
    const { id: field, value, checked } = e.target;

    setRecord({
      ...record,
      [field]: ['status', 'is_outbound', 'is_return'].includes(field) ? checked : value,
    });
    setErrors({ ...errors, [field]: '' });
  };

  const handleSubmit = () => {
    if (isAddressValid(validate())) {
      setLoading(true);
      api.carrierServices.updateService({ carrier_service: record }, record.id).then(() => {
        onClose();
        setLoading(false);
        update();
      });
    }
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Edit Service
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="name">
              Name
              <span className="required">*</span>
            </label>
            <input
              className="form-control"
              id="name"
              value={record.name || ''}
              onChange={e => handleChange(e)}
            />
            {errors.name && (
              <label className="error">
                {errors.name}
              </label>
            )}
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="slug">
              Slug
              <span className="required">*</span>
            </label>
            <input
              className="form-control"
              id="slug"
              value={record.slug || ''}
              onChange={e => handleChange(e)}
            />
            {errors.slug && (
              <label className="error">
                {errors.slug}
              </label>
            )}
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="dim_divisor">
              Dim Divisor
            </label>
            <input
              className="form-control"
              id="dim_divisor"
              value={record.dim_divisor || ''}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="dim_divisor">
              ShipEngine code
            </label>
            <input
              className="form-control"
              id="shipengine_code"
              value={record.shipengine_code || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="status">
              Status
            </label>
            <input
              type="checkbox"
              id="status"
              style={{ marginLeft: '5px' }}
              checked={record.status}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="is_outbound">
              Is Outbound
            </label>
            <input
              type="checkbox"
              id="is_outbound"
              style={{ marginLeft: '5px' }}
              checked={record.is_outbound}
              onChange={e => handleChange(e)}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="is_return">
              Is Return
            </label>
            <input
              type="checkbox"
              id="is_return"
              style={{ marginLeft: '5px' }}
              checked={record.is_return}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="modal-bottom-btn text-right">
          <button disabled={loading} className="btn bg-olive" onClick={handleSubmit}>Update Carrier</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={onClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

EditCarrierModal.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  update: PropTypes.func.isRequired,
};

EditCarrierModal.defaultProps = {
  open: false,
};

export default EditCarrierModal;
