import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { actionCreators } from '../../../stores';
import { UNEXPECTED_ERROR_MESSAGE } from '../../../constants';
import { getTypingMessage, unexpectedErrorNotification } from '../../../helpers';
import MessageInput from './MessageInput';
import {
  getSdkConversationObject,
} from '../../../conversationObject';

const MessageInputField = ({
  convo,
  typingData,
}) => {
  const [message, setMessage] = useState('');
  const typingInfo = getTypingMessage(typingData);

  const dispatch = useDispatch();
  const { addNotifications } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    setMessage('');
  }, [convo]);

  const sdkConvo = useMemo(
    () => getSdkConversationObject(convo),
    [convo.sid],
  );

  const onMessageSend = async () => {
    if (message.trim().length === 0) {
      return;
    }

    const newMessageBuilder = sdkConvo.prepareMessage().setBody(message);
    setMessage('');
    const messageIndex = await newMessageBuilder.build().send();

    try {
      await sdkConvo.advanceLastReadMessageIndex(messageIndex ?? 0);
    } catch (e) {
      unexpectedErrorNotification(addNotifications);
      // eslint-disable-next-line consistent-return
      return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
  };

  return (
    <Box className="messageInputFieldContainer">
      <Box className="messageInputFieldTyping" hidden={!typingData.length}>
        <p>
          {typingInfo}
        </p>
      </Box>
      <Box className="messageInputContainer">
        <div className="messageInputWrapper">
          <MessageInput
            message={message}
            onChange={(e) => {
              sdkConvo.typing();
              setMessage(e);
            }}
            onKeyPress={async (e) => {
              if (e.key === 'Enter') {
                await onMessageSend();
              }
            }}
          />
        </div>
        <button disabled={!message.length} className=" messageButton btn btn-primary pull-right" onClick={onMessageSend}>Send</button>
      </Box>
    </Box>
  );
};

MessageInputField.propTypes = {
  convo: PropTypes.instanceOf(Object).isRequired,
  typingData: PropTypes.instanceOf(Array),
};

MessageInputField.defaultProps = {
  typingData: [],
};

export default MessageInputField;
