import React from 'react';
import PropTypes from 'prop-types';
import { StarBorderOutlined, Star, Check } from '@material-ui/icons';

const CertaintyIcon = ({
  certainty, onClick, size, renderCheck, className,
}) => {
  if (renderCheck) return <Check className={className} style={{ color: '#42AE8F', fontSize: size }} />;

  return certainty ? <Star data-testid="cerainty-icon" className={className} onClick={onClick} style={{ color: '#fdd835', fontSize: size }} /> : <StarBorderOutlined data-testid="cerainty-icon" className={className} onClick={onClick} style={{ fontSize: size }} />;
};

CertaintyIcon.propTypes = {
  className: PropTypes.string,
  certainty: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.number,
  renderCheck: PropTypes.bool,
};

CertaintyIcon.defaultProps = {
  className: '',
  certainty: false,
  onClick: () => {},
  size: 40,
  renderCheck: false,
};

export default CertaintyIcon;
