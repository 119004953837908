import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import {
  MessageStatus,
} from '../../stores/reducers/messageListReducer';
import SendingIcon from './icons/Sending';
import DeliveredIcon from './icons/Delivered';
import ReadIcon from './icons/Read';
import FailedIcon from './icons/Failed';

import twilioApi from '../../services/twilioApi';
import api from '../../services/api';

function getLastMessageTime(messages) {
  const lastMessageDate = _.last(messages)?.dateCreated;
  if (!lastMessageDate) {
    return '';
  }

  return moment(lastMessageDate).format('DD.MM.YYYY hh:mm a z');
}

const ConversationView = (props) => {
  const {
    convoId,
    lastMessage,
    myMessage,
    unreadMessagesCount,
    convo,
    onClick,
    participants,
    messages,
    typingInfo,
  } = props;
  const email = useSelector(state => state.email);
  const [lastMsgStatus, setLastMsgStatus] = useState('');
  const [convoTitle, setConvoTitle] = useState('');
  const time = getLastMessageTime(messages);
  const bgColor = unreadMessagesCount > 0
    ? 'rgb(244, 244, 246)'
    : 'white';

  const getStatusIcon = () => {
    let icon;
    if (!myMessage) return null;

    if (lastMsgStatus === MessageStatus.Sending) icon = <SendingIcon />;
    if (lastMsgStatus === MessageStatus.Delivered) icon = <DeliveredIcon />;
    if (lastMsgStatus === MessageStatus.Failed) icon = <FailedIcon />;
    if (lastMsgStatus === MessageStatus.Read) icon = <ReadIcon />;

    return (
      <div style={{ paddingRight: 6 }}>
        {icon}
      </div>
    );
  };

  useEffect(() => {
    if (myMessage && !typingInfo.length) {
      twilioApi.getMessageStatus(myMessage, participants, email).then((statuses) => {
        if (statuses[MessageStatus.Read]) {
          setLastMsgStatus(MessageStatus.Read);
          return;
        }
        if (statuses[MessageStatus.Delivered]) {
          setLastMsgStatus(MessageStatus.Delivered);
          return;
        }
        if (statuses[MessageStatus.Failed]) {
          setLastMsgStatus(MessageStatus.Failed);
          return;
        }
        if (statuses[MessageStatus.Sending]) {
          setLastMsgStatus(MessageStatus.Sending);
        }
      });
    }
  }, [convo, myMessage, lastMessage, participants, typingInfo]);

  useEffect(() => {
    const fetchRouteData = async () => {
      if (!convo.uniqueName) {
        setConvoTitle(convo.friendlyName);
        return;
      }
      const routeId = parseInt(convo.uniqueName.replace('route-', ''), 10);
      const res = await api.routesDashboard.routeInfo(routeId);
      if (!res.ok) {
        setConvoTitle(convo.friendlyName);
        return;
      }
      setConvoTitle(`${res.route_manifest.package_cluster.region_id_lvl1}@${res.route_manifest.package_cluster.airport_code}: ${res.route_manifest.driver.user.first_name} ${res.route_manifest.driver.user.last_name}`);
    };
    if (!convo) return;
    fetchRouteData();
  }, [convo]);

  return (
    <Box
      style={{
        backgroundColor: bgColor,
      }}
      id={convoId}
      className="conversationView"
      onClick={onClick}
    >
      <div>
        <div className="conversationHeader">
          <div className="conversationTitle">
            <span>{convoTitle}</span>
          </div>
          <div className="conversationTime">{time}</div>
        </div>
        <div className="conversationTextContainer">
          <div className="conversationTextWrapper">
            <div className="conversationText">
              {lastMessage}
            </div>
          </div>
          <div>
            {!typingInfo.length ? getStatusIcon() : null}
            {unreadMessagesCount > 0 && (
              <div className="messageCountWrapper">
                <div className="messageCount">
                  {unreadMessagesCount}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};

ConversationView.propTypes = {
  convoId: PropTypes.string.isRequired,
  lastMessage: PropTypes.string.isRequired,
  myMessage: PropTypes.instanceOf(Object),
  unreadMessagesCount: PropTypes.number,
  convo: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  participants: PropTypes.instanceOf(Array),
  messages: PropTypes.instanceOf(Array),
  typingInfo: PropTypes.instanceOf(Array),
};

ConversationView.defaultProps = {
  myMessage: null,
  unreadMessagesCount: 0,
  participants: [],
  messages: [],
  typingInfo: [],
};

export default ConversationView;
