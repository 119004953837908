import React from 'react';
import PropTypes from 'prop-types';

const ShipmentPackageDetails = ({
  data, prefix,
}) => (data ? data.map((detail, index) => (
  // eslint-disable-next-line react/no-array-index-key
  <table className="table table-bordered table-hover data-view-table fields" key={`detail-${index}-${detail.package_type?.name}`}>
    <tbody>
      <tr>
        <td>
          <label htmlFor="quantity">Quantity</label>
          <div>
            <span>{detail.quantity || '-'}</span>
          </div>
        </td>
        <td>
          <label htmlFor="package_type_id">Package Type</label>
          <div>
            <span>{detail.package_type?.name || '-'}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div className="row form-group">
            <div className="col-sm-12">
              <label htmlFor="weight">Weight</label>
              <div>
                <span>{detail.weight || '-'}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-4">
              <label className="radio-label">
                <input
                  type="radio"
                  name={`shipment[shipment_details][${index}][weight_calculated_in][${prefix}]`}
                  disabled
                  checked={detail.weight_calculated_in === 1}
                />
                Lbs
                <span className="checkmark" />
              </label>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-4">
              <label className="radio-label">
                <input
                  type="radio"
                  name={`shipment[shipment_details][${index}][weight_calculated_in][${prefix}]`}
                  disabled
                  checked={detail.weight_calculated_in === 2}
                />
                Kgs
                <span className="checkmark" />
              </label>
            </div>
            <label
              id={`shipment[shipment_details_attributes][0][weight_calculated_in]-error hide_class-[${prefix}]`}
              className="error"
              htmlFor="shipment[shipment_details_attributes][0][weight_calculated_in]"
            />
          </div>
        </td>
        <td>
          <div className="row form-group">
            <div className="col-xs-4">
              <label htmlFor="width">Width</label>
              <div>
                <span>{detail.width || '-'}</span>
              </div>
            </div>
            <div className="col-xs-4">
              <label htmlFor="length">Length</label>
              <div>
                <span>{detail.length || '-'}</span>
              </div>
            </div>
            <div className="col-xs-4">
              <label htmlFor="height">Height</label>
              <div>
                <span>{detail.height || '-'}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4">
              <label className="radio-label">
                <input
                  type="radio"
                  name={`shipment[shipment_details][${index}][dimension_calculated_in][${prefix}]`}
                  disabled
                  checked={detail.dimension_calculated_in === 1}
                />
                Ins
                <span className="checkmark" />
              </label>
            </div>
            <div className="col-xs-4">
              <label className="radio-label">
                <input
                  type="radio"
                  name={`shipment[shipment_details][${index}][dimension_calculated_in][${prefix}]`}
                  disabled
                  checked={detail.dimension_calculated_in === 2}
                />
                Cms
                <span className="checkmark" />
              </label>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan="2">
          <label className="checkbox-label">
            <input
              type="checkbox"
              name={`shipment[shipment_details][${index}][is_special_day_delivery][${prefix}]`}
              disabled
              checked={detail.is_special_day_delivery}
            />
            Saturday Delivery
            <span className="checkmark" />
          </label>
        </td>
      </tr>
    </tbody>
  </table>
)) : '');

ShipmentPackageDetails.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  prefix: PropTypes.string,
};

ShipmentPackageDetails.defaultProps = {
  prefix: '',
};

export default ShipmentPackageDetails;
