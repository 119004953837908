import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle,
} from '@material-ui/core';
import api from '../../services/api';

const AddTrackingIdModal = ({
  open, handleClose, pickupId, setNewPickups, newPickups,
}) => {
  const [trackingId, setTrackingId] = useState('');

  const handleCreate = async () => {
    api.pickupsDashboard.updateStatus({
      pickup_id: pickupId,
      package_code: trackingId,
    }).then(async (r) => {
      setNewPickups([...newPickups, r.code]);
      handleClose();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="dialog-sm"
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Add Tracking Id
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="modal-header">
          <div className="block">
            <div className="row">
              <input type="text" className="tracking-id-input" onChange={e => setTrackingId(e.target.value)} value={trackingId} />
            </div>
            <div className="modal-bottom-btn text-right" style={{ borderTop: 'none' }}>
              <button className="btn bg-olive" onClick={handleCreate}>Create</button>
              <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={handleClose}>Cancel</button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

AddTrackingIdModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  pickupId: PropTypes.number.isRequired,
  setNewPickups: PropTypes.func.isRequired,
  newPickups: PropTypes.func.isRequired,
};

AddTrackingIdModal.defaultProps = {
  open: false,
};

export default AddTrackingIdModal;
