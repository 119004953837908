import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle,
} from '@material-ui/core';
import api from '../../services/api';
import { errorToast, successToast } from '../../services/toast';

const AddPackageToBagModal = ({
  open, handleClose, bagCode, handleUpdate,
}) => {
  const [trackingCode, setTrackingCode] = useState('');

  const handleCreate = async () => {
    api.pickupsDashboard.addToConsolidationBag({
      bag_code: bagCode,
      package_code: trackingCode,
    }).then(async (res) => {
      if (res.status) {
        successToast(res.message);
        handleUpdate();
        handleClose();
      } else {
        errorToast(res.message);
      }
      // setNewPickups([...newPickups, res.code]);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="dialog-sm"
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Add Package Code
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="modal-header">
          <div className="block">
            <div className="row">
              <input type="text" className="tracking-id-input" onChange={e => setTrackingCode(e.target.value)} value={trackingCode} />
            </div>
            <div className="modal-bottom-btn text-right" style={{ borderTop: 'none' }}>
              <button className="btn bg-olive" onClick={handleCreate}>Create</button>
              <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={handleClose}>Cancel</button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

AddPackageToBagModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  bagCode: PropTypes.string.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

AddPackageToBagModal.defaultProps = {
  open: false,
};

export default AddPackageToBagModal;
