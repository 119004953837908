import * as React from 'react';
import PropTypes from 'prop-types';

const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const SelectDays = ({ value, onChange }) => (
  <div data-testid="day_picker">
    {DAYS_OF_WEEK.map(day => <button key={day} className={`btn ${value.includes(day) ? 'bg-olive' : 'btn-primary'}`} onClick={() => onChange(day)}>{day}</button>)}
  </div>
);

SelectDays.propTypes = {
  value: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectDays;
