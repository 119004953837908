/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import api from '../../services/api';
import Toast from '../common/Toast';

const ConsolidationBagDetailsInfo = ({ data }) => (
  <>
    <Toast />
    <table className="table table-bordered table-hover data-view-table">
      <tbody>
        <tr>
          <td>
            <label htmlFor="origin_airport">Origin Airport</label>
            <div><span>{ data.origin_airport?.code }</span></div>
          </td>

          <td>
            <label htmlFor="destination_airport">Destination airport</label>
            <div><span>{ data.destination_airport?.code }</span></div>
          </td>
        </tr>
        <tr>
          <td>
            <label htmlFor="date">Date</label>
            <div><span>{ data.date }</span></div>
          </td>
          <td>
            <label htmlFor="code">Code</label>
            <div><span>{ data.code }</span></div>
          </td>
        </tr>
        <tr>
          <td>
            <label htmlFor="is_load_master">Load Master</label>
            <div><span>{ data.is_load_master ? '+' : '-' }</span></div>
          </td>
          <td>
            <label htmlFor="company">Company</label>
            <div><span>{ data.company?.name ?? '-' }</span></div>
          </td>
        </tr>
        <tr>
          <td>
            <label htmlFor="status">Status</label>
            <div><span>{ data.status_text }</span></div>
          </td>
          <td>
            <label htmlFor="files">Files</label>
            <div>
              <span>
                { data.html_file_attached || data.pdf_file_attached || (
                  <button className="btn btn-primary" onClick={() => api.flightDashboard.generateLabel({ id: data.id })}>
                    Regenerate
                  </button>
                )}
                { data.html_file_attached && (
                  <a className="fa fa-file-picture-o pdf-icon" target="_blank" rel="noreferrer" style={{ marginLeft: 0 }} href={`/admin/consolidation_labels/${data.id}/show_html`} title="View HTML Label" />
                )}
                { data.pdf_file_attached && (
                  <a className="fa fa-file-pdf-o pdf-icon" target="_blank" rel="noreferrer" style={{ marginLeft: 0 }} href={`/admin/consolidation_labels/${data.id}/show_pdf`} title="View PDF Label" />
                )}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </>
);


ConsolidationBagDetailsInfo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default ConsolidationBagDetailsInfo;
