/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import api from '../../services/api';
import Toast from '../common/Toast';
import { MapWithFlexContainer } from '../common/Map';
import { useWindowDimensions } from '../../services/helpers';

const DriverLocationTrack = ({
  data, googleMapsKey, routeId, timezoneFromServer, route,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { height, width } = useWindowDimensions();
  const [driverTrackingData, setDriverTrackingData] = useState(data);
  // eslint-disable-next-line no-unused-vars
  const [renderMap, setRenderMap] = useState(true);
  const [markers, setMarkers] = useState([]);
  const [serverTimezone, setServerTimezone] = useState('America/Chicago');
  const [lines, setLines] = useState([]);
  const [routeData, setRouteData] = useState({});
  useEffect(() => setRouteData(JSON.parse(route)), [route]);
  useEffect(() => {
    const timer = setInterval(() => {
      api.routesDashboard.track(routeId).then((res) => {
        if (res.ok) {
          setDriverTrackingData(res.data);
        }
      });
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const tz = timezoneFromServer?.info?.identifier;
    if (tz) {
      setServerTimezone(tz);
    } else {
      setServerTimezone('America/Chicago');
    }
  }, [timezoneFromServer]);

  useEffect(() => {
    if (driverTrackingData.length > 0) {
      const points = [...driverTrackingData].filter(p => Boolean(p.geolocation) && Boolean(p.geolocation.latitude) && Boolean(p.geolocation.longitude));
      const firstPoint = points.shift();
      const lastPoint = points.length ? points.pop() : null;

      const processedMarkers = [
        {
          position: { lat: firstPoint?.geolocation?.latitude, lng: firstPoint?.geolocation?.longitude },
          tip: firstPoint?.time ? moment(firstPoint.time).tz(serverTimezone).format('hh:mm A z') : '',
          color: 'green',
        },
        ...points.map(point => ({
          position: { lat: point?.geolocation?.latitude, lng: point?.geolocation?.longitude },
          tip: point?.time ? moment(point.time).tz(serverTimezone).format('hh:mm A z') : '',
          color: 'blue',
        })),
      ];
      if (lastPoint) {
        processedMarkers.push({
          position: { lat: lastPoint?.geolocation?.latitude, lng: lastPoint?.geolocation?.longitude },
          tip: lastPoint?.time ? moment(lastPoint.time).tz(serverTimezone).format('hh:mm A z') : '',
          color: 'red',
        });
      }

      const routes = [];
      for (let i = 0; i < processedMarkers.length - 1; i += 1) {
        // eslint-disable-next-line no-shadow
        const route = [];
        route.push({ coords: `${processedMarkers[i].position.lat},${processedMarkers[i].position.lng}` });
        route.push({ coords: `${processedMarkers[i + 1].position.lat},${processedMarkers[i + 1].position.lng}` });
        routes.push(route);
      }
      setLines(routes);
      for (let i = 0; i < routeData.route_manifest_stops?.length || 0; i += 1) {
        if (Boolean(routeData.route_manifest_stops[i].pickup?.geolocation?.latitude) && Boolean(routeData.route_manifest_stops[i].pickup?.geolocation?.longitude)) {
          processedMarkers.push({
            position: { lat: routeData.route_manifest_stops[i].pickup?.geolocation?.latitude, lng: routeData.route_manifest_stops[i].pickup?.geolocation?.longitude },
            tip: 'Route Stop',
            color: 'yellow',
          });
        }
      }
      setMarkers(processedMarkers);
    } else {
      setMarkers([]);
    }
  }, [driverTrackingData, routeData]);

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Driver Location Track</h1>
            </div>
            <ul>
              <li>
                Driver:
                {routeData.driver?.user?.first_name}
                {' '}
                {routeData.driver?.user?.last_name}
              </li>
              <li>
                Mileage:
                {routeData.mileage}
              </li>
              <li>
                Cost:
                {routeData.estimated_cost}
              </li>
            </ul>
            <div className="box-body main_listing">
              <div style={{ height: height * 0.7 }}>
                { renderMap && (
                  <MapWithFlexContainer
                    markers={markers}
                    smallMarkers
                    routes={lines}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&v=3.exp&libraries=geometry,drawing,places`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

DriverLocationTrack.propTypes = {
  data: PropTypes.array.isRequired,
  googleMapsKey: PropTypes.string.isRequired,
  route: PropTypes.any.isRequired,
  timezoneFromServer: PropTypes.any.isRequired,
  routeId: PropTypes.any.isRequired,
};

export default DriverLocationTrack;
