import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import api from '../../services/api';
import { isAddressValid } from '../../services/helpers';

const EditCarrierModal = ({
  row, onClose, open, update, companies,
}) => {
  const [errors, setErrors] = useState({});
  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    row && setRecord(row);
  }, [row]);

  const validate = useCallback(() => {
    if (['FEDEX', 'UPS'].includes(record.carrier_type)) {
      if (!record?.carrier_api_credential?.account_name) errors.account_name = 'Please enter account number';
      if (!record?.carrier_api_credential?.meter_password) errors.meter_password = 'Please enter meter password';
      if (!record?.carrier_api_credential?.key) errors.key = 'Please enter key';
      if (record?.carrier_api_credential?.is_batch_shipping === '') errors.is_batch_shipping = 'Please enter value';
      if (record.carrier_type === 'FEDEX' && !record?.carrier_api_credential?.meter) errors.meter = 'Please enter meter';
      if (record?.carrier_api_credential?.mode === '') errors.mode = 'Please enter mode';
    }

    errors.carrier_api_fedex_smart_post_credential = {};
    if (record.carrier_type === 'FEDEX' && !record?.carrier_api_fedex_smart_post_credential?.account_number) errors.account_number = 'Please enter account number';
    if (record.carrier_type === 'FEDEX' && !record?.carrier_api_fedex_smart_post_credential?.meter) errors.carrier_api_fedex_smart_post_credential.meter = 'Please enter meter';
    setErrors({ ...errors });
    return errors;
  }, [record]);

  const handleChange = (e, subfield) => {
    const { id: field, value } = e.target;

    const setValue = () => {
      let res = '';
      if (value === 'true') res = true;
      if (value === 'false') res = false;
      return res;
    };

    const newValue = subfield ? {
      [subfield]: {
        ...record[subfield],
        [field]: ['is_batch_shipping', 'mode'].includes(field) ? setValue() : value,
      },
    } : {
      [field]: value,
    };

    setRecord({
      ...record,
      ...newValue,
    });
    setErrors({ ...errors, [field]: '' });
  };

  const handleSubmit = () => {
    if (isAddressValid(validate())) {
      setLoading(true);
      api.carriers.updateCarrier({
        carrier: {
          ...record,
          carrier_api_credential_attributes: record.carrier_api_credential,
          carrier_api_fedex_smart_post_credential_attributes: record.carrier_api_fedex_smart_post_credential,
        },
      }, record.id).then(() => {
        onClose();
        setLoading(false);
        update({});
      });
    }
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Edit Carrier
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <h2 className="modal-body-title">Carrier Fields</h2>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="fuel_surcharge">
              Fuel Surcharge
            </label>
            <input
              className="form-control"
              id="fuel_surcharge"
              value={record.fuel_surcharge || ''}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        {
          ['FEDEX', 'UPS'].includes(record.carrier_type) && (
            <>
              <h2 className="modal-body-title">{`${record.name} Carrier API Keys`}</h2>
              <div className="row">
                <div className="col-sm-6 form-group">
                  <label htmlFor="account_name">
                    Account Number
                    <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    id="account_name"
                    value={record?.carrier_api_credential?.account_name || ''}
                    onChange={e => handleChange(e, 'carrier_api_credential')}
                  />
                  {errors.account_name && (
                    <label className="error">
                      {errors.account_name}
                    </label>
                  )}
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="meter_password">
                    Password
                    <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    id="meter_password"
                    value={record?.carrier_api_credential?.meter_password || ''}
                    onChange={e => handleChange(e, 'carrier_api_credential')}
                  />
                  {errors.meter_password && (
                    <label className="error">
                      {errors.meter_password}
                    </label>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 form-group">
                  <label htmlFor="key">
                    Authentication Key
                    <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    id="key"
                    value={record?.carrier_api_credential?.key || ''}
                    onChange={e => handleChange(e, 'carrier_api_credential')}
                  />
                  {errors.key && (
                    <label className="error">
                      {errors.key}
                    </label>
                  )}
                </div>
                <div className="col-sm-6 form-group">
                  <label htmlFor="is_batch_shipping">
                    Is Batch Shipping
                    <span className="required">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="is_batch_shipping"
                    value={record?.carrier_api_credential?.is_batch_shipping.toString() || ''}
                    onChange={e => handleChange(e, 'carrier_api_credential')}
                  >
                    <option key="default" value="">Please Select</option>
                    <option key="yes" value="true">Yes</option>
                    <option key="no" value="false">No</option>
                  </select>
                  {errors.is_batch_shipping && (
                    <label className="error">
                      {errors.is_batch_shipping}
                    </label>
                  )}
                </div>
              </div>
              <div className="row">
                {record?.carrier_type === 'FEDEX' && (
                  <div className="col-sm-6 form-group">
                    <label htmlFor="meter">
                      Meter
                      <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      id="meter"
                      value={record?.carrier_api_credential?.meter || ''}
                      onChange={e => handleChange(e, 'carrier_api_credential')}
                    />
                    {errors.meter && (
                      <label className="error">
                        {errors.meter}
                      </label>
                    )}
                  </div>
                )}
                <div className="col-sm-6 form-group">
                  <label htmlFor="mode">
                    Mode
                    <span className="required">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="mode"
                    value={record?.carrier_api_credential?.mode.toString() || ''}
                    onChange={e => handleChange(e, 'carrier_api_credential')}
                  >
                    <option key="default" value="">Please Select</option>
                    <option key="live" value="true">Live</option>
                    <option key="sandbox" value="false">Sandbox</option>
                  </select>
                  {errors.mode && (
                    <label className="error">
                      {errors.mode}
                    </label>
                  )}
                </div>
              </div>
            </>
          )
        }
        {record?.carrier_type === 'FEDEX' && (
          <>
            <h2 className="modal-body-title">Fed-Ex Carrier Smart Post API Keys</h2>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label htmlFor="account_number">
                  Account Number
                  <span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  id="account_number"
                  value={record?.carrier_api_fedex_smart_post_credential?.account_number || ''}
                  onChange={e => handleChange(e, 'carrier_api_fedex_smart_post_credential')}
                />
                {errors?.account_number && (
                  <label className="error">
                    {errors?.account_number}
                  </label>
                )}
              </div>
              <div className="col-sm-6 form-group">
                <label htmlFor="meter">
                  Password
                  <span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  id="meter"
                  value={record?.carrier_api_fedex_smart_post_credential?.meter || ''}
                  onChange={e => handleChange(e, 'carrier_api_fedex_smart_post_credential')}
                />
                {errors?.carrier_api_fedex_smart_post_credential?.meter && (
                  <label className="error">
                    {errors?.carrier_api_fedex_smart_post_credential?.meter}
                  </label>
                )}
              </div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="parent_company_id">
              Parent company
            </label>
            <select
              name="parent_company_id"
              id="parent_company_id"
              className="form-control"
              value={record?.parent_company_id || ''}
              onChange={(e) => {
                handleChange(e);
                setErrors({ ...errors, parent_company_id: '' });
              }}
            >
              <option value="">Please select</option>
              {companies.map(item => <option key={item[1]} value={item[1]}>{item[0]}</option>)}
            </select>
            {errors?.parent_company_id && (
              <label className="error">
                {errors.parent_company_id}
              </label>
            )}
          </div>
        </div>
        <div className="modal-bottom-btn text-right">
          <button disabled={loading} className="btn bg-olive" onClick={handleSubmit}>Update Carrier</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={onClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

EditCarrierModal.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  update: PropTypes.func.isRequired,
  companies: PropTypes.instanceOf(Array),
};

EditCarrierModal.defaultProps = {
  open: false,
  companies: [],
};

export default EditCarrierModal;
