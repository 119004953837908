import React from 'react';
import PropTypes from 'prop-types';

const TableScrollable = ({ children, suffix }) => (
  <>
    <div id={`top-horizontal-scrollbar-${suffix}`} className="top-horizontal-scrollbar">
      <div id={`top-horizontal-scroll-${suffix}`} className="top-horizontal-scroll" />
    </div>
    {children}
  </>
);

TableScrollable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  suffix: PropTypes.string.isRequired,
};

export default TableScrollable;
