import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import api from '../../services/api';

/* eslint-disable no-extend-native, func-names */
Date.prototype.toDateInputValue = (function () {
  const local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
});
/* eslint-enable no-extend-native, func-names */

const CreateRoute = ({
  handleClose, open, type, onSubmit,
}) => {
  const [airports, setAirports] = useState([]);
  const [airportStops, setAirportStops] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [clusterId, setClusterId] = useState('');
  const [airportId, setAirportId] = useState('');
  const [date, setDate] = useState((new Date()).toDateInputValue());
  const [startingStop, setStartingStop] = useState();
  const [error, setError] = useState('');

  useEffect(() => {
    if (type === 'RL1') api.routesDashboard.getAirports().then(res => setAirports(res.airports));
  }, []);

  useEffect(() => {
    if (airportId) api.routesDashboard.getAirportClusters({ id: airportId }).then(res => setClusters(res.clusters));
    setError('');
  }, [airportId, date]);

  useEffect(() => {
    if (type === 'RL2') api.routesDashboard.getAirportStops(date).then(res => setAirportStops(res.stops));
    setError('');
  }, [date]);

  const renderForm = () => {
    switch (type) {
    case 'RL1':
      return (
        <>
          <div className="col-sm-12 form-group">
            <select
              className="form-control"
              value={airportId}
              onChange={(e) => {
                setAirportId(e.target.value);
                setError('');
              }}
            >
              <option key="default" value="">Please select</option>
              {airports.map(airport => (
                <option key={airport.id} value={airport.id}>
                  {airport.code}
                  {' '}
                  -
                  {' '}
                  {airport.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-12 form-group">
            <select className="form-control" value={clusterId} onChange={e => setClusterId(e.target.value)}>
              <option key="default" value="">Please select</option>
              {clusters.map(cluster => (
                <option key={cluster.id} value={cluster.id}>{`${cluster.id} @ ${cluster.airport_code}`}</option>
              ))}
            </select>
          </div>
        </>
      );
    case 'RL2':
      return (
        <div className="col-sm-12 form-group">
          <select className="form-control" value={startingStop} onChange={e => setStartingStop(e.target.value)}>
            <option key="default" value="">Please select</option>
            {airportStops.map(stop => (
              <option key={stop.id} value={stop.id}>{`${stop.route_manifest.id} - ${stop.pickup.string_origin_new}`}</option>
            ))}
          </select>
        </div>
      );
    default:
      return null;
    }
  };

  const handleSubmit = () => {
    if (type === 'RL1' && !clusterId) {
      setError('Cluster is required for the route!');
      return;
    }

    if (type === 'RL2' && !startingStop) {
      setError('Starting stop is required for the route!');
      return;
    }

    api.routesDashboard.createRoute({
      type,
      date,
      package_cluster_id: clusterId,
      route_manifest_type: type,
      status: 'unassigned',
      rl2_starting_pickup_id: airportStops.find(stop => stop.id === parseInt(startingStop, 10))?.pickup?.id,
    }).then((res) => {
      if (!res.ok) return;

      onSubmit();
    });
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Create Route
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="form-group">
          <div className="row">
            <div className="col-sm-12 form-group">
              <div>{`Route Type: ${type}`}</div>
            </div>
            <div className="col-sm-12 form-group">
              <div className="datepicker">
                <input
                  type="date"
                  className="form-control dimensions length"
                  id="date"
                  name="date"
                  value={date}
                  onChange={e => setDate(e.target.value)}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                />
              </div>
            </div>
            {renderForm()}
            {error && (
              <div className="col-sm-12 form-group">
                <label className="error">
                  {error}
                </label>
              </div>
            )}
          </div>
        </div>
        <div className="modal-bottom-btn text-right">
          <button className="btn bg-olive" onClick={handleSubmit}>Create</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={handleClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

CreateRoute.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  type: PropTypes.string,
};

CreateRoute.defaultProps = {
  open: false,
  type: 'RL1',
};

export default CreateRoute;
