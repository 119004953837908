import React from 'react';
import PropTypes from 'prop-types';
import GalleryItem from './GalleryItem';

const Gallery = ({
  photos, serverTimezone, deletedImages, deleteImage, canEdit,
}) => (
  <>
    {photos.map(photo => photo.photo_uri && deletedImages.indexOf(photo.photo_uri) === -1 && photo.is_deleted === false && (
      <GalleryItem serverTimezone={serverTimezone} deleteImage={deleteImage} photo={photo} canEdit={canEdit} />
    ))
    }
  </>
);

Gallery.propTypes = {
  serverTimezone: PropTypes.string,
  photos: PropTypes.instanceOf(Array),
  deletedImages: PropTypes.instanceOf(Array),
  deleteImage: PropTypes.func,
  canEdit: PropTypes.bool,
};

Gallery.defaultProps = {
  serverTimezone: 'America/Chicago',
  photos: [],
  deletedImages: [],
  deleteImage: () => {},
  canEdit: false,
};

export default Gallery;
