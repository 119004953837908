/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import qs from 'qs';
import CustomTable from '../common/Table';
import api from '../../services/api';
import {
  sortHelper,
  getPickupRowStyle,
  getDateForTimeComparison,
} from '../../services/helpers';
import CertaintyIcon from '../common/CertaintyIcon';
import PhotoPickupIcon from '../common/PhotoPickupIcon';
import PickupTestIcon from '../common/PickupTestIcon';
import StatusDropdown from '../common/StatusDropdown';
import { errorToast } from '../../services/toast';
import { updateChangeRowsPerPage } from '../../helpers';

const PickupsTable = ({
  defaultLimit,
  radiusFlexCarrierName, logoAsset, serverTimezone,
  permission, userId, isCustomerPickupRole,
  pickupByDay, isAssigned, selectedCompany, selectedCarrier, order, state, selectedFlag, selectedStatus,
  routeFilter, search, setOrder, selectedFields, forceUpdate, setForceUpdate,
  setOpenDelete, setOpenSwitch, setOpenDetails, setOpenLockBox, setOpenTest, setOpenCertainty,
  setOpenEdit, setSelectedRow, selectedRow, tab, visible, isCertain, suffix, paginationKey, isCompanyAdmin,
}) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page && urlSearchParams.tab === tab ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: urlSearchParams.limit && urlSearchParams.tab === tab ? parseInt(urlSearchParams.limit, 10) : (parseInt(window.localStorage.getItem(paginationKey), 10) || defaultLimit || 10),
  });

  const [photoPreview, setPhotoPreview] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterChanged, setFilterChanged] = useState(false);

  useEffect(() => {
    setFilterChanged(true);
  }, [pickupByDay, isCertain, isAssigned, selectedCompany, selectedCarrier, order, state, selectedFlag, selectedStatus]);

  useEffect(() => {
    if (!visible) return;
    if (!filterChanged && !forceUpdate) {
      updatePageAddress({
        pickup_by_day: pickupByDay,
        filter_company: selectedCompany,
        filter_carrier: selectedCarrier,
        filter_state: state,
        filter_flag: selectedFlag,
        filter_status: selectedStatus,
        is_assigned: isAssigned,
        search_field: search,
        route_filter: routeFilter,
        direction: order.type,
        sort: order.field,
        page: paginationOptions.page + 1,
        limit: paginationOptions.limit,
        pickup_certainty: isCertain,
      });
      return;
    }

    handleFilter({});
  }, [visible, filterChanged, forceUpdate]);

  const handleFilter = useCallback(({ page, limit, query }) => {
    const fixedPage = page !== undefined ? page + 1 : paginationOptions.page + 1;
    const data = {
      pickup_by_day: pickupByDay,
      filter_company: selectedCompany,
      filter_carrier: selectedCarrier,
      filter_state: state,
      filter_flag: selectedFlag,
      filter_status: selectedStatus,
      is_assigned: isAssigned,
      search_field: query !== undefined ? query : search,
      route_filter: routeFilter,
      direction: order.type,
      sort: order.field,
      page: fixedPage,
      limit: limit || paginationOptions.limit,
      pickup_certainty: isCertain,
    };
    api.pickupsDashboard.pickups(data)
      .then((res) => {
        setRows(res?.pickups);
        setPaginationOptions({
          count: res.all_pickups,
          page: fixedPage - 1,
          limit: limit || paginationOptions.limit,
        });
        updatePageAddress(data);
      })
      .catch(err => console.log(err))
      .finally(() => {
        setForceUpdate(false);
        setFilterChanged(false);
      });
  }, [pickupByDay, isCertain, isAssigned, selectedCompany, forceUpdate, selectedCarrier, paginationOptions, search, order, state, routeFilter, selectedFlag, selectedStatus]);

  const updatePageAddress = (data) => {
    const url = `/admin/pickup_dashboard/all_pickups?${qs.stringify({ ...data, tab })}`;
    window.history.replaceState(null, '', url);
  };

  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };

  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, paginationKey, val => handleFilter({ page: 0, limit: val }));
  };

  const handleSetApproved = useCallback(() => {
    api.pickupsDashboard.updatePickup(selectedRow.id, { approved: !selectedRow.approved }).then(() => {
      setSelectedRow({});
      handleFilter({});
    });
  }, [selectedRow]);

  const checkForCertaintyEdit = (row) => {
    if (!isCustomerPickupRole) return true;
    if (!row.actual_cutoff_time) return true;

    const time = moment().format('hh:mm:ss a');
    const now = getDateForTimeComparison(moment(new Date(Date.parse(`2000/01/01 ${time}`))).tz(serverTimezone));
    const cutoffTime = getDateForTimeComparison(moment.tz(row.actual_cutoff_time, serverTimezone));
    return now.diff(cutoffTime) <= 0;
  };

  const handleOpenCertainty = (row) => {
    if (checkForCertaintyEdit(row)) {
      setOpenCertainty(true);
    } else {
      errorToast('Confirmation out of schedule. Please call or text Support at  832-979-6255.');
    }
  };

  const handleSort = (field) => {
    const updatedOrder = { ...sortHelper(field, order) };
    setOrder(updatedOrder);
  };

  const handleUpdatePackageCount = (id, package_count) => {
    api.pickupsDashboard.updatePickup(id, { package_count }).then(() => {
      setSelectedRow({});
      handleFilter({});
    });
  };

  const handleUpdateStatus = (id, status) => {
    api.pickupsDashboard.updatePickup(id, { status }).then(() => {
      setSelectedRow({});
      handleFilter({});
    });
  };

  const getStyle = () => {
    if (visible) return ({ opacity: 1, zIndex: 1, position: 'relative' });

    return ({ opacity: 0, zIndex: 0, position: 'absolute' });
  };

  return (
    <>
      <div className="box-body main_listing" style={{ width: '100%', ...getStyle() }}>
        <CustomTable
          isPickupList
          radiusFlexCarrierName={radiusFlexCarrierName}
          logoAsset={logoAsset}
          rows={rows || []}
          formatters={{
            created_at: value => moment(value).tz(serverTimezone).format('DD MMM YYYY'),
            ready_time: value => moment(value).tz(serverTimezone).format('hh:mm A z'),
            close_time: value => moment(value).tz(serverTimezone).format('hh:mm A z'),
            actual_cutoff_time: value => (value ? moment(value).tz(serverTimezone).format('hh:mm A z') : ''),
            pickup_confirmation_number: value => <span>{value?.toUpperCase()}</span>,
            has_lockbox: value => value && <div className="text-center"><i className="fa fa-check" aria-hidden="true" /></div>,
            package_count: value => (
              <div style={{ minWidth: '50px' }}>
                {(permission.pickup?.editOthers || (permission.pickup?.editMine && value.user_id === userId)) ? (
                  <select className="form-control" value={value.package_count || 0} onChange={e => handleUpdatePackageCount(value.id, e.target.value)}>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => (
                      <option key={item} value={item}>{item}</option>
                    ))}
                  </select>
                ) : (<span>{value.package_count}</span>)}
              </div>
            ),
            photo_count: value => (
              <div
                onMouseOver={() => {
                  setPhotoPreview(value.photos);
                }}
                onMouseLeave={() => {
                  setPhotoPreview(null);
                }}
              >
                <span>{value.photos.length}</span>
                {Boolean(value.photos) && (
                  <div
                    style={{ display: 'inline' }}
                  >
                    {Boolean(photoPreview) && photoPreview.length > 0 && (
                      <div style={{
                        position: 'fixed',
                        left: 0,
                        top: 100,
                        height: 500,
                        backgroundColor: 'white',
                        overflow: 'hidden',
                        zIndex: 10000,
                      }}
                      >
                        {photoPreview.map(photo => (
                          <img
                            style={{
                              height: 500,
                              width: 'auto',
                              objectFit: 'contain',
                              marginLeft: 10,
                            }}
                            alt="img"
                            src={photo}
                          />
                        ))}
                      </div>
                    )}
                    <PhotoPickupIcon />
                  </div>
                )}
              </div>
            ),
            pickup_certainty: value => (
              <CertaintyIcon
                className="star-icon"
                renderCheck={isCustomerPickupRole && value.pickup_certainty}
                certainty={value.pickup_certainty}
                onClick={() => {
                  if (!permission.pickup?.editOthers && !(permission.pickup?.editMine && value.user_id === userId) && !isCustomerPickupRole) return;

                  setSelectedRow(value);
                  handleOpenCertainty(value);
                }}
              />
            ),
            approved_at: value => (value ? moment(value).tz(serverTimezone).format('hh:mm A z') : ''),
            approved_by: value => (Boolean(value.approved_by_user)
                  && (
                    <span>
                      {value.approved_by_user.first_name}
                      {' '}
                      {value.approved_by_user.last_name}
                    </span>
                  )
            ),
            approved: value => (
              <div>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    disabled={(!permission.pickup?.editOthers && !(permission.pickup?.editMine && value.user_id === userId))}
                    style={{ marginLeft: '5px' }}
                    checked={value.approved}
                    onChange={() => {
                      setSelectedRow(value);
                      handleSetApproved();
                    }}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            ),
            is_test: value => (
              <PickupTestIcon
                test={value.is_test}
                onClick={() => {
                  if (!permission.pickup?.editOthers && !(permission.pickup?.editMine && value.user_id === userId)) return;

                  setSelectedRow(value);
                  setOpenTest(true);
                }}
              />
            ),
            is_scheduled: value => value && <div className="text-center"><i className="fa fa-check" aria-hidden="true" /></div>,
            is_assigned: ({
              pickup_list1, pickup_list2, pickup_list3, pickup_list4,
            }) => (
              <div className="text-center" style={{ display: 'flex', flexDirection: 'column' }}>
                {pickup_list1 && <span title={`Phone number: ${pickup_list1?.driver?.user?.phone || '-'}`}>{`RL1: ${pickup_list1?.driver?.name || '-'}`}</span>}
                {pickup_list2 && <span title={`Phone number: ${pickup_list2?.driver?.user?.phone || '-'}`}>{`RL2: ${pickup_list2?.driver?.name || '-'}`}</span>}
                {pickup_list3 && <span title={`Phone number: ${pickup_list3?.driver?.user?.phone || '-'}`}>{`DL1: ${pickup_list3?.driver?.name || '-'}`}</span>}
                {pickup_list4 && <span title={`Phone number: ${pickup_list4?.driver?.user?.phone || '-'}`}>{`DL2: ${pickup_list4?.driver?.name || '-'}`}</span>}
              </div>
            ),
            status: ({ id, user_id, status }) => (
              (!isCompanyAdmin && (permission.pickup?.editOthers || (permission.pickup?.editMine && user_id === userId))) ? <StatusDropdown status={status} id={id} handleUpdateStatus={handleUpdateStatus} /> : (<div>{status}</div>)
            ),
            tracking_ids: value => (
              <div>
                { value?.scanned_tracking_ids?.map(item => (<div key={item.tracking_code}>{item.tracking_code}</div>)) }
                { value?.scanned_fedex_tracking_ids?.map(item => (<div key={item.tracking_code}>{item.tracking_code}</div>)) }
                { value?.scanned_ups_tracking_ids?.map(item => (<div key={item.tracking_code}>{item.tracking_code}</div>)) }
              </div>
            ),
            customer: value => <div className="text-center" style={{ minWidth: '70px' }}>{`${value?.first_name || ''} ${value?.last_name || ''}`}</div>,
            user: value => <div className="text-center" style={{ minWidth: '70px' }}>{`${value?.first_name} ${value?.last_name}`}</div>,
            pickup_list1: value => <div className="text-center">{`${value ? `${value?.package_cluster?.region_id_lvl1} @ ${value?.package_cluster?.airport_code}` : '-'}`}</div>,
            pickup_list2: value => <div className="text-center">{`${value ? `${value?.package_cluster?.region_id_lvl1} @ ${value?.package_cluster?.airport_code}` : '-'}`}</div>,
            pickup_list3: value => <div className="text-center">{`${value ? `${value?.package_cluster?.region_id_lvl1} @ ${value?.package_cluster?.airport_code}` : '-'}`}</div>,
            pickup_list4: value => <div className="text-center">{`${value ? `${value?.package_cluster?.region_id_lvl1} @ ${value?.package_cluster?.airport_code}` : '-'}`}</div>,
            driver: ({ driver }) => <div className="text-center" style={{ minWidth: '70px' }}>{driver ? `${driver?.first_name} ${driver?.last_name}` : '-'}</div>,
            is_on_call: value => <div className="text-center">{`${value ? '+' : '-'}`}</div>,
            origin_record_info: value => <span>{value ? value.record_id : '-'}</span>,
            packages_count: value => (value.pickup_details && value.pickup_details.length ? value.pickup_details[0].quantity : 0),
            actions: value => (
              <div className="text-center" style={{ minWidth: '70px' }}>
                {(permission.pickup?.editOthers || (permission.pickup?.editMine && value.user_id === userId)) && (
                  <>
                    <button
                      onClick={() => {
                        setSelectedRow(value);
                        setOpenDelete(true);
                      }}
                      data-testid={`delete-${value.id}`}
                      className="fa fa-times edit_user"
                      style={{ border: 'none', backgroundColor: 'transparent' }}
                    />
                    {
                      isCompanyAdmin || (
                        <>
                          <button
                            onClick={() => {
                              setSelectedRow(value);
                              setOpenSwitch(true);
                            }}
                            className="fa fa-exchange edit_user"
                            style={{ border: 'none', backgroundColor: 'transparent' }}
                            data-testid={`open-switch-${value.id}`}
                          />
                          <button
                            onClick={() => {
                              setSelectedRow(value);
                              setOpenLockBox(true);
                            }}
                            className={value.has_lockbox ? 'fa fa-lock edit_user' : 'fa fa-unlock-alt edit_user'}
                            style={{ border: 'none', backgroundColor: 'transparent' }}
                          />
                        </>
                      )
                    }
                    <button
                      className="fa fa-edit action-button"
                      onClick={() => {
                        setOpenEdit(true);
                        setSelectedRow(value);
                      }}
                    />
                  </>
                )}
              </div>
            ),
          }}
          rowsProps={selectedFields}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          paginationOptions={{
            ...paginationOptions,
            handleChangePage,
            handleChangeRowsPerPage,
          }}
          suffix={suffix}
          onRowClick={(row) => {
            setOpenDetails(true);
            setSelectedRow(row);
          }}
          rowStyle={row => getPickupRowStyle(row, serverTimezone, radiusFlexCarrierName)}
          handleSort={handleSort}
          sortOptions={order}
          blockRowPePage
        />
      </div>
    </>
  );
};

PickupsTable.propTypes = {
  defaultLimit: PropTypes.number.isRequired,
  radiusFlexCarrierName: PropTypes.string.isRequired,
  logoAsset: PropTypes.string.isRequired,
  isCustomerPickupRole: PropTypes.bool.isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  selectedFields: PropTypes.instanceOf(Object).isRequired,
  selectedRow: PropTypes.instanceOf(Object),
  serverTimezone: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  pickupByDay: PropTypes.string.isRequired,
  isAssigned: PropTypes.string.isRequired,
  selectedCompany: PropTypes.string.isRequired,
  selectedCarrier: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  selectedFlag: PropTypes.string.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  routeFilter: PropTypes.string,
  search: PropTypes.string,
  setOrder: PropTypes.func.isRequired,
  forceUpdate: PropTypes.bool,
  setForceUpdate: PropTypes.func.isRequired,
  setOpenDelete: PropTypes.func.isRequired,
  setOpenSwitch: PropTypes.func.isRequired,
  setOpenDetails: PropTypes.func.isRequired,
  setOpenLockBox: PropTypes.func.isRequired,
  setOpenTest: PropTypes.func.isRequired,
  setOpenCertainty: PropTypes.func.isRequired,
  setOpenEdit: PropTypes.func.isRequired,
  setSelectedRow: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  isCertain: PropTypes.string.isRequired,
  suffix: PropTypes.string.isRequired,
  paginationKey: PropTypes.string.isRequired,
  isCompanyAdmin: PropTypes.bool,
};

PickupsTable.defaultProps = {
  selectedRow: null,
  routeFilter: '',
  search: '',
  forceUpdate: false,
  visible: false,
  isCompanyAdmin: false,
};

export default PickupsTable;
