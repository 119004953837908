import React, {
  useCallback, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { omit, pick } from 'lodash';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import moment from 'moment-timezone';
import api from '../../services/api';
import { checkPickupFormForErrors, isAddressValid } from '../../services/helpers';
import AddressInputs from '../shipments_dashboard/AddressInputs';

const EditPickupModal = ({
  row, onClose, open, update, companyList, states, serverTimezone, limits, radiusFlexId, permission, isAdmin,
}) => {
  const [errors, setErrors] = useState({});
  const [record, setRecord] = useState({});
  const [originAddress, setOriginAddress] = useState({});
  const [originRecordInfo, setOriginRecordInfo] = useState({});
  const [carrierList, setCarrierList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [carrierServicesList, setCarrierServicesList] = useState([]);
  const [packageTypesList, setPackageTypesList] = useState([]);
  const [isUps, setIsUps] = useState(false);
  const [isFedex, setIsFedex] = useState(false);
  const [isOnCall, setIsOnCall] = useState(false);
  const [pack, setPack] = useState({
    weight_calculated_in: 1,
    package_type_id: '',
    quantity: '',
    weight: '',
  });
  const [loading, setLoading] = useState(false);
  const [nearestAirport, setNearestAirport] = useState({});
  const [originRecord, setOriginRecord] = useState(null);
  const pickupReadyTime = useRef();
  const pickupCloseTime = useRef();
  const overrideCutoffTimeRef = useRef();

  /* eslint-disable camelcase */
  useEffect(() => {
    if (row) {
      const { pickup_details, origin_record_info, ...rest } = row;
      setRecord(rest);
      setOriginAddress({
        company_name: row.origin_company_name,
        attention_to: row.origin_attention_to,
        street_address1: row.origin_street_address1,
        street_address2: row.origin_street_address2,
        city: row.origin_city,
        state: states.find(state => state.alpha2 === row.origin_state)?.id,
        zip: row.origin_zip,
        phone: row.origin_phone,
        account_id: row.account_id,
        customer_id: row.customer_id,
      });
      setIsUps(row.is_ups);
      setIsFedex(row.is_fedex);
      setIsOnCall(row.is_on_call);
      setOriginRecordInfo(origin_record_info);
      pickup_details && setPack({ ...pick(pickup_details[0], ['weight_calculated_in', 'package_type_id', 'quantity', 'weight', 'pickup_id', 'id']) });
    }
  }, [row]);
  /* eslint-enable camelcase */

  useLayoutEffect(() => {
    if (open) {
      setTimeout(() => {
        $(pickupReadyTime.current).datetimepicker({
          format: 'hh:mm a',
          ignoreReadonly: true,
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-arrow-up',
            down: 'fa fa-arrow-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-clock-o',
            clear: 'fa fa-trash-o',
          },
        });
        record.ready_time && $(pickupReadyTime.current).val(moment(record.ready_time).tz(serverTimezone).format('hh:mm a'));
      }, 10);
    }
  }, [pickupReadyTime.current, open, record.ready_time]);

  useLayoutEffect(() => {
    if (open) {
      setTimeout(() => {
        $(pickupCloseTime.current).datetimepicker({
          format: 'hh:mm a',
          ignoreReadonly: true,
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-arrow-up',
            down: 'fa fa-arrow-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-clock-o',
            clear: 'fa fa-trash-o',
          },
        });
        record.close_time && $(pickupCloseTime.current).val(moment(record.close_time).tz(serverTimezone).format('hh:mm a'));
      }, 10);
    }
  }, [pickupCloseTime.current, open, record.close_time]);

  useLayoutEffect(() => {
    if (open) {
      setTimeout(() => {
        $(overrideCutoffTimeRef.current).datetimepicker({
          format: 'hh:mm a',
          ignoreReadonly: true,
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-arrow-up',
            down: 'fa fa-arrow-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-clock-o',
            clear: 'fa fa-trash-o',
          },
        });
        record.override_cutoff && $(overrideCutoffTimeRef.current).val(moment(record.override_cutoff).tz(serverTimezone).format('hh:mm a'));
      }, 10);
    }
  }, [overrideCutoffTimeRef.current, open, record.override_cutoff]);


  useEffect(async () => {
    if (!record.company_id) {
      setCarrierList([]);
      setCarrierServicesList([]);
      setPackageTypesList([]);
    }

    if (record.company_id) {
      api.addressBook.getUserAccounts({ company_id: record.company_id }).then((res) => {
        setAccountList(res.accounts);
      });
      api.addressBook.getCustomers({ company_id: record.company_id }).then((res) => {
        setCustomerList(res.customers);
      });
      api.shipmentDashboard.getCarriers({ company_id: record.company_id }).then((res) => {
        setCarrierList(res.data.return_carriers_r);
        setCarrierServicesList([]);
        setPackageTypesList([]);
      });
    }
  }, [record.company_id]);

  useEffect(() => {
    if (!record.carrier_id) {
      setCarrierServicesList([]);
      setPackageTypesList([]);
      return;
    }
    record.carrier_id && api.shipmentDashboard.getCarrierServicesAndPackageTypes({ company_id: record.company_id, carrier_id: record.carrier_id, pickup_only: true }).then((res) => {
      setCarrierServicesList(res.return_carrier_services_r);
      setPackageTypesList(res.package_types_r);
    }).catch(error => console.log(error));
  }, [record.carrier_id, record.company_id]);

  useEffect(() => {
    if (!Object.keys(record).length) return;

    if (!record.carrier_service_id) {
      setPack({ ...pack, package_type_id: '' });
    }
  }, [record.carrier_service_id]);

  const handleChangePackage = async (e, radioValue) => {
    const field = e.target.name.split('-')[0];

    pack[field] = radioValue || e.target.value;

    setPack(pack);
    setErrors({
      ...errors,
      pack: {
        ...errors.pack,
        [field]: '',
      },
    });
  };

  useEffect(async () => {
    if (originAddress.street_address1 && originAddress.city && originAddress.state && originAddress.zip) {
      const { airport } = await api.pickupsDashboard.nearestAirport({
        origin_street_address1: originAddress.street_address1,
        origin_city: originAddress.city,
        origin_state: states.find(state => state.id === parseInt(originAddress.state, 10))?.alpha2,
        origin_zip: originAddress.zip,
      });
      setNearestAirport(airport);
    }
  }, [originAddress]);

  useEffect(async () => {
    if (!originRecordInfo || !originRecordInfo.record_id || !originRecordInfo.type) {
      setOriginRecord(null);
      return;
    }

    switch (originRecordInfo.type) {
    case 'warehouse':
      // eslint-disable-next-line no-case-declarations
      const { data } = await api.addressBook.warehouseShow(originRecordInfo.record_id);
      setOriginRecord(data);
      break;
    case 'address':
      // eslint-disable-next-line no-case-declarations
      const { address } = await api.addressBook.addressShow(originRecordInfo.record_id);
      setOriginRecord(address);
      break;
    default:
      setOriginRecord(null);
      break;
    }
  }, [originRecordInfo]);


  const validate = useCallback(() => {
    const checkResults = checkPickupFormForErrors({
      company_id: record.company_id,
      carrier_id: record.carrier_id,
      carrier_service_id: record.carrier_service_id,
      selectedDays: [],
    }, originAddress, {
      pickupDate: record.pickup_date,
      readyTime: pickupReadyTime.current.value,
      closeTime: pickupCloseTime.current.value,
      addressCutoffTime: originRecord?.cutoff_time,
      overrideCutoffTime: overrideCutoffTimeRef.current?.value,
      serverTimezone,
    }, pack, radiusFlexId, false, nearestAirport);

    setErrors({ ...checkResults });
    return checkResults;
  }, [record, originAddress, nearestAirport, serverTimezone, errors, originRecord]);

  const handleRecordChange = (e, subfield) => {
    const { id: field, value } = e.target;
    const fieldName = subfield ? `${subfield}_${field}` : field;

    setRecord({
      ...record,
      [fieldName]: value,
    });
    setErrors({ ...errors, [field]: '' });
  };

  const handleAddressChange = (e) => {
    const { id: field, value } = e.target;

    setOriginAddress({
      ...originAddress,
      [field]: value,
    });
    setOriginRecordInfo({});
    setErrors({ ...errors, origin: { ...errors.origin, [field]: '' } });
  };

  const handleSubmit = () => {
    const valida = validate();
    const valid = isAddressValid(valida);

    if (valid) {
      setLoading(true);
      api.pickupsDashboard.updatePickup(record.id, {
        pickup: {
          ...omit(record, ['id', 'discarded_at', 'created_at', 'updated_at', 'carrier', 'company']),
          origin_company_name: originAddress.company_name,
          origin_attention_to: originAddress.attention_to,
          origin_street_address1: originAddress.street_address1,
          origin_street_address2: originAddress.street_address2,
          origin_city: originAddress.city,
          origin_state: states.find(state => state.id === parseInt(originAddress.state, 10))?.alpha2,
          origin_zip: originAddress.zip.trim(),
          origin_phone: originAddress.phone,
          account_id: originAddress.account_id,
          customer_id: originAddress.customer_id,
          ready_time: pickupReadyTime.current.value,
          close_time: pickupCloseTime.current.value,
          override_cutoff: overrideCutoffTimeRef.current.value,
          origin_record_info: originRecordInfo,
          is_ups: isUps,
          is_fedex: isFedex,
          is_on_call: isOnCall,
          pickup_details_attributes: {
            0: pack,
          },
        },
      }, record.id).then(() => {
        onClose();
        setLoading(false);
        update({});
      });
    }
  };

  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Edit Pickup
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">

        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="company_id">
              Company
              <span className="required">*</span>
            </label>
            <select
              name="company_id"
              id="company_id"
              className="form-control get_company_id get_carriers"
              value={record.company_id}
              onChange={(e) => {
                handleRecordChange(e);
                setErrors({ ...errors, company_id: '' });
              }}
            >
              <option value="">Please select</option>
              {companyList.map(item => <option key={item[1]} value={item[1]}>{item[0]}</option>)}
            </select>
            {errors?.company_id && (
              <label className="error">
                {errors.company_id}
              </label>
            )}
          </div>
        </div>
        <AddressInputs
          errors={errors?.origin || {}}
          buttonTitle="Choose Origin"
          onChange={handleAddressChange}
          onButtonClick={() => {}}
          prefix="origin"
          values={originAddress}
          states={states || []}
          accounts={accountList}
          customers={customerList}
          limits={limits}
          embed
          mode="pickup"
        />

        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="carrier_id">
              Carrier
              <span className="required">*</span>
            </label>
            <select
              name="carrier_id"
              id="carrier_id"
              className="form-control fill_carriers"
              value={record.carrier_id}
              onChange={(e) => {
                handleRecordChange(e);
                setErrors({ ...errors, carrier_id: '' });
              }}
            >
              <option value="">Please select</option>
              {carrierList?.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
            </select>
            {errors?.carrier_id && (
              <label className="error">
                {errors.carrier_id}
              </label>
            )}
          </div>
          <div className="col-sm-6 form-group carrier_service">
            <label htmlFor="carrier_service_id">
              Carrier Service
              <span className="required">*</span>
            </label>
            <select
              name="carrier_service_id"
              id="carrier_service_id"
              className="form-control fill_carrier_services"
              value={record.carrier_service_id}
              onChange={(e) => {
                handleRecordChange(e);
                setErrors({ ...errors, carrier_service_id: '' });
              }}
            >
              <option value="">Please select</option>
              {carrierServicesList.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
            </select>
            {errors?.carrier_service_id && (
              <label className="error">
                {errors.carrier_service_id}
              </label>
            )}
          </div>

          <div className="col-sm-6 form-group">
            <label htmlFor="quantity">
              Quantity
              <span className="required">*</span>
            </label>
            <input
              name="quantity"
              id="quantity"
              className="form-control static_quantity"
              maxLength={15}
              value={pack.quantity || ''}
              onChange={e => handleChangePackage(e)}
            />
            {errors?.pack?.quantity && (
              <label className="error">
                {errors?.pack?.quantity}
              </label>
            )}
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="package_type_id">
              Package Type
              <span className="required">*</span>
            </label>
            <select
              name="package_type_id"
              id="package_type_id"
              className="form-control fill_carrier_services"
              value={pack.package_type_id || ''}
              onChange={e => handleChangePackage(e)}
            >
              <option value="">Please select</option>
              {packageTypesList.map(packageType => <option key={packageType.id} value={packageType.id}>{packageType.name}</option>)}
            </select>
            {errors?.pack?.package_type_id && (
              <label className="error">
                {errors?.pack?.package_type_id}
              </label>
            )}
          </div>
          <div className="col-sm-6 weight_block">
            <div className="row">
              <div className="col-md-6 form-group">
                <label htmlFor="weight">
                  Weight
                  <span className="required">*</span>
                </label>
                <input
                  name="weight"
                  id="weight"
                  className="form-control"
                  maxLength={15}
                  value={pack.weight || ''}
                  onChange={e => handleChangePackage(e)}
                />
                {errors?.pack?.weight && (
                  <label className="error">
                    {errors?.pack?.weight}
                  </label>
                )}
              </div>
              <div className="col-sm-6 media-radio">
                <div className="col-xs-4 col-md-5 padding-zero">
                  <label className="radio-label margin-top30">
                    <input
                      type="radio"
                      name="weight_calculated_in"
                      id="weight_calculated_in_lbs"
                      checked={pack.weight_calculated_in === 1}
                      onChange={e => handleChangePackage(e, 1)}
                    />
                    Lbs
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="col-xs-8 col-md-7">
                  <label className="radio-label margin-top30">
                    <input
                      type="radio"
                      name="weight_calculated_in"
                      id="weight_calculated_in_lbs"
                      checked={pack.weight_calculated_in === 2}
                      onChange={e => handleChangePackage(e, 2)}
                    />
                    Kgs
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-4 form-group">
            <label htmlFor="pickup_ready_time">
              Ready Time
              <span className="required">*</span>
            </label>
            <div className="admin-datepicker">
              <input
                ref={pickupReadyTime}
                name="ready_time"
                id="pickup_ready_time"
                className="form-control dimensions length"
                readOnly
              />
            </div>
            {errors?.readyTime && (
              <label className="error">
                {errors?.readyTime}
              </label>
            )}
          </div>
          <div className="col-sm-4 form-group">
            <label htmlFor="pickup_close_time">
              Close Time
              <span className="required">*</span>
            </label>
            <div className="admin-datepicker">
              <input
                ref={pickupCloseTime}
                name="close_time"
                id="pickup_close_time"
                className="form-control dimensions length"
                readOnly
              />
            </div>
            {errors?.closeTime && (
              <label className="error">
                {errors?.closeTime}
              </label>
            )}
          </div>

          <div className={`col-sm-4 form-group${!isAdmin ? ' hidden' : ''}`}>
            <label htmlFor="override_cutoff_time">
              Override Cutoff Time
            </label>
            <div className="admin-datepicker">
              <input
                ref={overrideCutoffTimeRef}
                name="override_cutoff_time"
                id="override_cutoff_time"
                className="form-control dimensions length"
                readOnly
              />
            </div>
          </div>

          <div className="col-sm-4 form-group">
            <label htmlFor="courier_instructions">
              Courier Instructions
            </label>
            <textarea
              name="courier_instructions"
              id="courier_instructions"
              className="form-control"
              style={{ height: '150px', padding: '5px' }}
              value={record.courier_instructions}
              onChange={handleRecordChange}
            />
          </div>
          <div className="row">
            <div className="col-sm-4 form-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  id="has_lockbox"
                  style={{ marginLeft: '5px' }}
                  checked={record.has_lockbox}
                  onChange={() => setRecord({ ...record, has_lockbox: !record.has_lockbox })}
                />
                Has Lockbox?
                <span className="checkmark" />
              </label>
            </div>
            <div className="col-sm-4 form-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  id="pickup_certainty"
                  style={{ marginLeft: '5px' }}
                  checked={record.pickup_certainty}
                  onChange={() => setRecord({ ...record, pickup_certainty: !record.pickup_certainty })}
                />
                Confirmed?
                <span className="checkmark" />
              </label>
            </div>
            <div className="col-sm-4 form-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  id="is_test"
                  style={{ marginLeft: '5px' }}
                  checked={record.is_test}
                  onChange={() => setRecord({ ...record, is_test: !record.is_test })}
                />
                Is tests?
                <span className="checkmark" />
              </label>
            </div>
          </div>
        </div>
        {permission.pickup?.flagsPermission
              && (
                <div className="row">
                  <div className="col-sm-4 form-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        id="is_ups"
                        style={{ marginLeft: '5px' }}
                        checked={isUps}
                        onChange={() => setIsUps(!isUps)}
                      />
                      Is UPS?
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-sm-4 form-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        id="is_fedex"
                        style={{ marginLeft: '5px' }}
                        checked={isFedex}
                        onChange={() => setIsFedex(!isFedex)}
                      />
                      Is FEDEX?
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-sm-4 form-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        id="is_on_call"
                        style={{ marginLeft: '5px' }}
                        checked={isOnCall}
                        onChange={() => setIsOnCall(!isOnCall)}
                      />
                      Is OnCall?
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
              )}
        <div className="modal-bottom-btn text-right">
          <button disabled={loading} className="btn bg-olive" onClick={handleSubmit}>Update</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={onClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

EditPickupModal.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  companyList: PropTypes.instanceOf(Array).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  update: PropTypes.func.isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  serverTimezone: PropTypes.string,
  limits: PropTypes.instanceOf(Object).isRequired,
  radiusFlexId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool,
  permission: PropTypes.instanceOf(Object).isRequired,
};

EditPickupModal.defaultProps = {
  open: false,
  serverTimezone: 'America/Chicago',
  isAdmin: false,
};

export default EditPickupModal;
