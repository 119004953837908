import { errorToast, successToast } from '../services/toast';

import {
  NOTIFICATION_TIMEOUT,
  UNEXPECTED_ERROR_MESSAGE,
} from '../constants';
// import api from '../services/api';

export const getTypingMessage = typingData => (typingData.length > 1
  ? `${`${typingData.length} participants are typing...`}`
  : `${`${typingData[0]} is typing...`}`);

export const pushNotification = (
  messages,
  func = null,
) => {
  if (func) {
    func(
      messages.map(({ variant, message }) => ({
        variant,
        message,
        id: new Date().getTime(),
        dismissAfter: NOTIFICATION_TIMEOUT,
      })),
    );
  }
};

export const successNotification = ({
  message,
  addNotifications = null,
}) => {
  if (!addNotifications) return;

  successToast(message);
  // pushNotification(
  //   [
  //     {
  //       message,
  //       variant: 'success',
  //     },
  //   ],
  //   addNotifications,
  // );
};

export const unexpectedErrorNotification = (
  addNotifications = null,
) => {
  if (!addNotifications) return;

  errorToast(UNEXPECTED_ERROR_MESSAGE);
  // pushNotification(
  //   [
  //     {
  //       message: UNEXPECTED_ERROR_MESSAGE,
  //       variant: 'error',
  //     },
  //   ],
  //   addNotifications,
  // );
};

export const handlePromiseRejection = async (
  func,
  addNotifications = null,
) => {
  if (!addNotifications) {
    return;
  }
  try {
    await func();
  } catch {
    unexpectedErrorNotification(addNotifications);
    // eslint-disable-next-line consistent-return
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
  }
};

export const getConvoTitle = (participants, email) => participants.map(p => p.identity).filter(el => el.trim() !== email).join(',');

export const updateChangeRowsPerPage = async (e, key, updateFunc) => {
  window.localStorage.setItem(key, e.target.value);
  const newVal = parseInt(e.target.value, 10);
  // await api.user.setRecordsPerPage({ records_per_page: newVal });
  updateFunc(newVal);
};
