/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, DialogActions,
} from '@material-ui/core';
import moment from 'moment-timezone';
import MyMapComponent from '../common/Map';
import AddTrackingIdModal from './AddTrackingIdModal';
import AddItemToGalleryModal from './AddItemToGalleryModal';
import Gallery from '../common/Gallery';
import DeletePhotoModal from './DeletePhotoModal';
import ConfirmationDialog from '../common/ConfirmationDialog';
import api from '../../services/api';
import { successToast, errorToast } from '../../services/toast';

const PickupDetailsModal = ({
  handleClose, open, data, googleMapsKey, serverTimezone, permission, userId,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalGallery, setShowModalGallery] = useState(false);
  const [newPickups, setNewPickups] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [showDeletePhotoModal, setShowDeletePhotoModal] = useState(false);
  const [selectedToDeletePhotoUrl, setSelectedToDeletePhotoUrl] = useState();
  const [deletedImages, setDeletedImages] = useState([]);
  const [deleteTrackingId, setDeleteTrackingId] = useState();
  const [deletedScannedTrackingIds, setDeletedScannedTrackingIds] = useState([]);
  const formatTracking = item => `✅  ${item.tracking_code} - ${item.consolidation_shipment?.length > 0 ? item.consolidation_shipment[0].tracking_comment : ''}`;
  const canEdit = (permission.pickup?.editOthers || (permission.pickup?.editMine && data.user_id === userId));

  const handleDeleteClose = () => {
    setSelectedToDeletePhotoUrl(null);
    setShowDeletePhotoModal(false);
  };

  useEffect(() => {
    if (selectedToDeletePhotoUrl) setShowDeletePhotoModal(true);
    else setShowDeletePhotoModal(false);
  }, [selectedToDeletePhotoUrl]);

  const removeImage = () => {
    const oldDeletedImages = deletedImages;
    oldDeletedImages.push(selectedToDeletePhotoUrl);
    setDeletedImages(oldDeletedImages);
  };

  const handleDeleteScannedTrackingId = useCallback(() => {
    api.pickupsDashboard.deleteScannedTrackingId({ id: deleteTrackingId }).then((res) => {
      if (res.ok) {
        successToast(res.message);
        setDeletedScannedTrackingIds([...deletedScannedTrackingIds, deleteTrackingId]);
      } else {
        errorToast(res.message);
      }
    });
    setDeleteTrackingId(null);
  },
  [deleteTrackingId]);

  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Pickup Details
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="modal-header">
          <h4>Main info</h4>
          <div className="block">
            <div className="row">
              <div className="label">ID:</div>
              <div className="value">{data.id}</div>
            </div>
            <div className="row">
              <div className="label">Pickup Confirmation Number:</div>
              <div className="value">{data.pickup_confirmation_number}</div>
            </div>
            <div className="row">
              <div className="label">Pickup Date:</div>
              <div className="value">{moment(data.pickup_date).format('DD-MM-YYYY')}</div>
            </div>
            <div className="row">
              <div className="label">Ready Time:</div>
              <div className="value">{moment(data.ready_time).tz(serverTimezone).format('hh:mm A z')}</div>
            </div>
            <div className="row">
              <div className="label">Close Time:</div>
              <div className="value">{moment(data.close_time).tz(serverTimezone).format('hh:mm A z')}</div>
            </div>
            <div className="row">
              <div className="label">Driver Arrival Time:</div>
              <div
                className="value"
              >
                {data.first_photo_time ? `${moment.tz(data.first_photo_time, serverTimezone).format('D MMM YYYY hh:mm A') } Local Time` : '-'}
              </div>
            </div>
            <div className="row">
              <div className="label">Company:</div>
              <div className="value">{data.company?.name || '-'}</div>
            </div>
            <div className="row">
              <div className="label">Carrier:</div>
              <div className="value">{data.carrier?.name || '-'}</div>
            </div>
            <div className="row">
              <div className="label">Origin:</div>
              <div className="value">{data.string_origin_new || '-'}</div>
            </div>
            <div className="row">
              <div className="label">Package Count:</div>
              <div className="value">{data.package_count || '-'}</div>
            </div>
            <div className="row">
              <div className="label">Is Scheduled:</div>
              <div className="value">{data.is_scheduled.toString()}</div>
            </div>
            <div className="column">
              <div className="label">Driver Notes:</div>
              <div className="column">
                {
                  data.pickup_driver_notes && data.pickup_driver_notes.map(note => (
                    <div
                      className="row tracking-id"
                      key={note.note}
                    >
                      {`${note.note} - ${note.local_time ? moment(note.local_time.replace('Z', '')).tz(serverTimezone).format('YYYY-MM-DD, hh:mm:ss A z') : moment(note.created_at).tz(serverTimezone).format('YYYY-MM-DD, hh:mm:ss A z')}`}
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="row">
              <div className="label">Courier Instructions:</div>
              <div className="value">{data.courier_instructions || '-'}</div>
            </div>
            <div className="row">
              <div className="label">Status:</div>
              <div className="value">{data.status_text || '-'}</div>
            </div>
            <div className="row">
              <div className="label">Created By:</div>
              <div
                className="value"
              >
                {`${data.user ? data.user.first_name : ''} ${data.user ? data.user.last_name : ''}`}
              </div>
            </div>
            <div className="column">
              <div className="row" style={{ marginLeft: 0 }}>
                <div className="label">
                  Tracking IDs:
                </div>
                {canEdit && (<button className="add-tracking-id" onClick={() => setShowModal(true)}>+</button>)}
              </div>
              <div className="column">
                {
                  data.scanned_fedex_tracking_ids && data.scanned_fedex_tracking_ids.map(s => (
                    <div className="row tracking-id">{ formatTracking(s) }</div>
                  ))
                }
                {
                  data.scanned_ups_tracking_ids && data.scanned_ups_tracking_ids.map(s => (
                    <div className="row tracking-id">{ formatTracking(s) }</div>
                  ))
                }
                {
                  data.scanned_tracking_ids && data.scanned_tracking_ids.map(s => !deletedScannedTrackingIds.includes(s.id) && (
                    <div className="row tracking-id">
                      { formatTracking(s) }
                      <span onClick={() => setDeleteTrackingId(s.id)} style={{ cursor: 'pointer' }}> ❌</span>
                    </div>
                  ))
                }
                {
                  newPickups.map(code => (
                    <div className="row tracking-id">{`✅  ${code}`}</div>
                  ))
                }
              </div>
            </div>
          </div>
          {(data?.packages || []).length > 0 && (
            <>
              <h4>Packages</h4>
              <div className="block" style={{ display: 'flex', flexWrap: 'wrap' }}>
                {data.packages.map(pack => (
                  <div key={pack.id} className="row" style={{ width: '30%' }}>
                    <div className="label">{pack?.shipment?.tracking_id || '-'}</div>
                    {pack.last_update && <i className="fa fa-check" aria-hidden="true" />}
                  </div>
                ))}
              </div>
            </>
          )}
          {data.geolocation && data.geolocation.latitude && data.geolocation.longitude && (
            <>
              <h4>Geolocation</h4>
              <MyMapComponent
                markers={[
                  {
                    position: { lat: data.geolocation.latitude, lng: data.geolocation.longitude },
                    tip: data.string_origin,
                    color: 'red',
                  },
                ]}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&v=3.exp&libraries=geometry,drawing,places`}
              />
            </>
          )}
          <h4>Gallery</h4>
          <div className="block">
            <div className="gallery">
              {data.pickup_photos?.length > 0 && <Gallery serverTimezone={serverTimezone} photos={data.pickup_photos} deleteImage={setSelectedToDeletePhotoUrl} deletedImages={deletedImages} canEdit={canEdit} />}
              {newImages.length > 0 && <Gallery serverTimezone={serverTimezone} photos={newImages} deleteImage={setSelectedToDeletePhotoUrl} deletedImages={deletedImages} canEdit={canEdit} />}
              {canEdit && (<button className="add-image-to-pickups" onClick={() => setShowModalGallery(true)}>+</button>)}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <button onClick={handleClose} className="btn btn-primary pull-right popup_cancel_btn">Cancel</button>
      </DialogActions>
      {
        showModal && (
          <AddTrackingIdModal
            open={showModal}
            handleClose={() => setShowModal(false)}
            pickupId={data.id}
            setNewPickups={setNewPickups}
            newPickups={newPickups}
          />
        )
      }
      {
        showModalGallery && (
          <AddItemToGalleryModal
            open={showModalGallery}
            handleClose={() => setShowModalGallery(false)}
            pickupId={data.id}
            setNewPhotos={setNewImages}
            newPhotos={newImages}
          />
        )
      }
      {
        showDeletePhotoModal && (
          <DeletePhotoModal
            open={showDeletePhotoModal}
            handleClose={() => handleDeleteClose()}
            pickupId={data.id}
            photoUrl={selectedToDeletePhotoUrl}
            deleteSuccess={() => removeImage()}
          />
        )
      }
      {
        Boolean(deleteTrackingId)
        && (
          <ConfirmationDialog
            onConfirm={handleDeleteScannedTrackingId}
            onClose={() => setDeleteTrackingId(null)}
            message="Are you sure you want to delete this scanned tracking id?"
            open
          />
        )
      }
    </Dialog>
  );
};

PickupDetailsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  googleMapsKey: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  permission: PropTypes.instanceOf(Object),
  open: PropTypes.bool,
  serverTimezone: PropTypes.string,
  userId: PropTypes.number.isRequired,
};

PickupDetailsModal.defaultProps = {
  open: false,
  serverTimezone: 'America/Chicago',
  permission: {},
};

export default PickupDetailsModal;
