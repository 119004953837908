import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import MyMapComponent from '../common/Map';
import AddTrackingIdModal from './AddTrackingIdModal';
import AddItemToGalleryModal from './AddItemToGalleryModal';
import Toast from '../common/Toast';
import Gallery from '../common/Gallery';
import DeletePhotoModal from './DeletePhotoModal';

const PickupDetails = ({
  data, googleMapsKey, serverTimezone, permission, userId, photos,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalGallery, setShowModalGallery] = useState(false);
  const [newPickups, setNewPickups] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [showDeletePhotoModal, setShowDeletePhotoModal] = useState(false);
  const [selectedToDeletePhotoUrl, setSelectedToDeletePhotoUrl] = useState();
  const [deletedImages, setDeletedImages] = useState([]);
  const formatTracking = item => `✅  ${item.tracking_code} - ${item.consolidation_shipment?.length > 0 ? item.consolidation_shipment[0].tracking_comment : ''}`;
  const canEdit = (permission.pickup?.editOthers || (permission.pickup?.editMine && data.user_id === userId));

  const handleDeleteClose = () => {
    setSelectedToDeletePhotoUrl(null);
    setShowDeletePhotoModal(false);
  };

  useEffect(() => {
    if (selectedToDeletePhotoUrl) setShowDeletePhotoModal(true);
    else setShowDeletePhotoModal(false);
  }, [selectedToDeletePhotoUrl]);

  const removeImage = () => {
    const oldDeletedImages = deletedImages;
    oldDeletedImages.push(selectedToDeletePhotoUrl);
    setDeletedImages(oldDeletedImages);
  };

  return (
    <div>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title"> Pickup Details</h1>
            </div>
            <div className="box-body">
              <div className="row">
                <div className="modal-header">
                  <h4>Main info</h4>
                  <div className="block">
                    <div className="row">
                      <div className="label">ID:</div>
                      <div className="value">{data.id}</div>
                    </div>
                    <div className="row">
                      <div className="label">Pickup Confirmation Number:</div>
                      <div className="value">{data.pickup_confirmation_number}</div>
                    </div>
                    <div className="row">
                      <div className="label">Pickup Date:</div>
                      <div className="value">{moment(data.pickup_date).format('DD-MM-YYYY')}</div>
                    </div>
                    <div className="row">
                      <div className="label">Ready Time:</div>
                      <div className="value">{moment(data.ready_time).tz(serverTimezone).format('hh:mm A z')}</div>
                    </div>
                    <div className="row">
                      <div className="label">Close Time:</div>
                      <div className="value">{moment(data.close_time).tz(serverTimezone).format('hh:mm A z')}</div>
                    </div>
                    <div className="row">
                      <div className="label">Driver Arrival Time:</div>
                      <div
                        className="value"
                      >
                        {data.first_photo_time ? `${moment.tz(data.first_photo_time, serverTimezone).format('D MMM YYYY hh:mm A') } Local Time` : '-'}
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">Company:</div>
                      <div className="value">{data.company?.name || '-'}</div>
                    </div>
                    <div className="row">
                      <div className="label">Carrier:</div>
                      <div className="value">{data.carrier?.name || '-'}</div>
                    </div>
                    <div className="row">
                      <div className="label">Origin:</div>
                      <div className="value">{data.string_origin_new || '-'}</div>
                    </div>
                    <div className="row">
                      <div className="label">Package Count:</div>
                      <div className="value">{data.package_count || '-'}</div>
                    </div>
                    <div className="row">
                      <div className="label">Is Scheduled:</div>
                      <div className="value">{data.is_scheduled.toString()}</div>
                    </div>
                    <div className="column">
                      <div className="label">Driver Notes:</div>
                      <div className="column">
                        {
                          data.pickup_driver_notes && data.pickup_driver_notes.map(note => (
                            <div
                              className="row tracking-id"
                              key={note.note}
                            >
                              {`${note.note} - ${note.local_time ? moment(note.local_time.replace('Z', '')).tz(serverTimezone).format('YYYY-MM-DD, hh:mm:ss A z') : moment(note.created_at).tz(serverTimezone).format('YYYY-MM-DD, hh:mm:ss A z')}`}
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">Courier Instructions:</div>
                      <div className="value">{data.courier_instructions || '-'}</div>
                    </div>
                    <div className="row">
                      <div className="label">Status:</div>
                      <div className="value">{data.status_text || '-'}</div>
                    </div>
                    <div className="row">
                      <div className="label">Created By:</div>
                      <div
                        className="value"
                      >
                        {`${data.user ? data.user.first_name : ''} ${data.user ? data.user.last_name : ''}`}
                      </div>
                    </div>
                    <div className="column">
                      <div className="row" style={{ marginLeft: 0 }}>
                        <div className="label">
                          Tracking IDs:
                        </div>
                        {canEdit && (<button className="add-tracking-id" onClick={() => setShowModal(true)}>+</button>)}
                      </div>
                      <div className="column">
                        {
                          data.scanned_fedex_tracking_ids && data.scanned_fedex_tracking_ids.map(s => (
                            <div className="row tracking-id">{ formatTracking(s) }</div>
                          ))
                        }
                        {
                          data.scanned_ups_tracking_ids && data.scanned_ups_tracking_ids.map(s => (
                            <div className="row tracking-id">{ formatTracking(s) }</div>
                          ))
                        }
                        {
                          data.scanned_tracking_ids && data.scanned_tracking_ids.map(s => (
                            <div className="row tracking-id">{ formatTracking(s) }</div>
                          ))
                        }
                        {
                          newPickups.map(code => (
                            <div className="row tracking-id">{`✅  ${code}`}</div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                  {(data?.packages || []).length > 0 && (
                    <>
                      <h4>Packages</h4>
                      <div className="block" style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {data.packages.map(pack => (
                          <div key={pack.id} className="row" style={{ width: '30%' }}>
                            <div className="label">{pack?.shipment?.tracking_id || '-'}</div>
                            {pack.last_update && <i className="fa fa-check" aria-hidden="true" />}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {data.geolocation && data.geolocation.latitude && data.geolocation.longitude && (
                    <>
                      <h4>Geolocation</h4>
                      <MyMapComponent
                        markers={[
                          {
                            position: { lat: data.geolocation.latitude, lng: data.geolocation.longitude },
                            tip: data.string_origin,
                            color: 'red',
                          },
                        ]}
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&v=3.exp&libraries=geometry,drawing,places`}
                      />
                    </>
                  )}
                  <h4>Gallery</h4>
                  <div className="block">
                    <div className="gallery">
                      {photos.length > 0 && <Gallery serverTimezone={serverTimezone} photos={photos} deleteImage={setSelectedToDeletePhotoUrl} deletedImages={deletedImages} canEdit={canEdit} />}
                      {newImages.length > 0 && <Gallery serverTimezone={serverTimezone} photos={newImages} deleteImage={setSelectedToDeletePhotoUrl} deletedImages={deletedImages} canEdit={canEdit} />}
                      {canEdit && (<button className="add-image-to-pickups" onClick={() => setShowModalGallery(true)}>+</button>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        showModal && (
          <AddTrackingIdModal
            open={showModal}
            handleClose={() => setShowModal(false)}
            pickupId={data.id}
            setNewPickups={setNewPickups}
            newPickups={newPickups}
          />
        )
      }
      {
        showModalGallery && (
          <AddItemToGalleryModal
            open={showModalGallery}
            handleClose={() => setShowModalGallery(false)}
            pickupId={data.id}
            setNewPhotos={setNewImages}
            newPhotos={newImages}
          />
        )
      }
      {
        showDeletePhotoModal && (
          <DeletePhotoModal
            open={showDeletePhotoModal}
            handleClose={() => handleDeleteClose()}
            pickupId={data.id}
            photoUrl={selectedToDeletePhotoUrl}
            deleteSuccess={() => removeImage()}
          />
        )
      }
    </div>
  );
};

PickupDetails.propTypes = {
  googleMapsKey: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  permission: PropTypes.instanceOf(Object),
  serverTimezone: PropTypes.string,
  photos: PropTypes.instanceOf(Array),
  userId: PropTypes.number.isRequired,
};

PickupDetails.defaultProps = {
  serverTimezone: 'America/Chicago',
  permission: {},
  photos: [],
};

export default PickupDetails;
