import { combineReducers } from 'redux';

import emailReducer from './emailReducer';
import tokenReducer from './tokenReducer';
import convoReducer from './convoReducer';
import sidReducer from './currentConvoReducer';
import messageReducer from './messageListReducer';
import loadingReducer from './loadingReducer';
import participantReducer from './participantsReducer';
import unreadMessagesReducer from './unreadMessagesReducer';
// import attachmentsReducer, { AttachmentsState } from "./attachmentsReducer";
import typingDataReducer from './typingDataReducer';
import lastReadIndexReducer from './lastReadIndexReducer';
import systemUsersReducer from './systemUsersReducer';
// import notificationsReducer, {
//     NotificationsType,
// } from "./notificationsReducer";

export const initialState = {
  email: '',
  token: '',
  sid: '',
  messages: {},
  // attachments: {},
  participants: {},
  convos: [],
  unreadMessages: {},
  loadingStatus: true,
  typingData: {},
  lastReadIndex: -1,
  // notifications: [],
  systemUsers: [],
};

const reducers = (
  state,
  action,
) => appReducer(state, action);

const appReducer = combineReducers({
  email: emailReducer,
  token: tokenReducer,
  convos: convoReducer,
  sid: sidReducer,
  lastReadIndex: lastReadIndexReducer,
  messages: messageReducer,
  loadingStatus: loadingReducer,
  participants: participantReducer,
  unreadMessages: unreadMessagesReducer,
  // attachments: attachmentsReducer,
  typingData: typingDataReducer,
  // notifications: notificationsReducer,
  systemUsers: systemUsersReducer,
});

export default reducers;
