import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import qs from 'qs';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import api from '../../services/api';
import { sortHelper } from '../../services/helpers';
import { updateChangeRowsPerPage } from '../../helpers';
import { PaginationKeys } from '../../constants';
import { errorToast, successToast } from '../../services/toast';

const Invoices = ({
  shipments, allLabelsCount,
  companyList, defaultLimit,
  carrierList, preferences,
}) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || parseInt(window.localStorage.getItem(PaginationKeys.invoices), 10) || preferences?.records_per_page || 10,
  });
  const [startDate, setStartDate] = useState(urlSearchParams.start_date || moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(urlSearchParams.end_date || moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
  const [selectedCompany, setCompany] = useState(urlSearchParams.filter_company || '');
  const [order, setOrder] = useState({ field: urlSearchParams.sort || 'id', type: urlSearchParams.direction || 'desc' });
  const [exportFormat, setExportFormat] = useState('xlsx');
  const [selectedCarrier, setCarrier] = useState(urlSearchParams.filter_carrier || '');
  const [includeUnused, setIncludeUnused] = useState(urlSearchParams.include_unused === 'true');
  const [datePeriod, setDatePeriod] = useState((urlSearchParams.start_date || urlSearchParams.end_date) ? 'custom' : 'lastMonth');

  useEffect(() => {
    setPaginationOptions({
      ...paginationOptions,
      count: allLabelsCount,
      limit: defaultLimit,
    });
  }, [shipments, allLabelsCount]);

  useEffect(() => {
    if (datePeriod === 'lastMonth') {
      setStartDate(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
    } else if (datePeriod === 'thisWeek') {
      setStartDate(moment().startOf('isoWeek').format('YYYY-MM-DD'));
      setEndDate(moment().endOf('isoWeek').format('YYYY-MM-DD'));
    } else if (datePeriod === 'thisMonth') {
      setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
      setEndDate(moment().endOf('month').format('YYYY-MM-DD'));
    } else if (datePeriod === 'lastWeek') {
      setStartDate(moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'));
    } else if (datePeriod === 'last2Weeks') {
      setStartDate(moment().subtract(2, 'week').startOf('isoWeek').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'));
    } else if (datePeriod === 'last4Weeks') {
      setStartDate(moment().subtract(4, 'week').startOf('isoWeek').format('YYYY-MM-DD'));
      setEndDate(moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'));
    }
  }, [datePeriod]);

  useEffect(() => {
    handleFilter({});
  }, [startDate, endDate, selectedCompany, selectedCarrier, order, includeUnused, datePeriod]);

  const handleFilter = useCallback(({ page, limit }) => {
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filters = {
      start_date: startDate,
      end_date: endDate,
      filter_company: selectedCompany,
      filter_carrier: selectedCarrier,
      direction: order.type,
      sort: order.field,
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
      include_unused: includeUnused,
    };
    api.shipmentDashboard.invoices(filters)
      .then((res) => {
        setRows(res.shipments);
        setPaginationOptions({
          count: res.all_labels,
          page: fixedPage || 0,
          limit: limit || paginationOptions.limit,
        });
        const url = `/admin/shipment_dashboard/invoices?${qs.stringify(filters)}`;
        window.history.replaceState(null, '', url);
      })
      .catch(err => console.log(err));
  }, [startDate, endDate, selectedCompany, selectedCarrier, includeUnused, paginationOptions, order, datePeriod]);

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setCompany('');
    setCarrier('');
  };

  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };

  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, PaginationKeys.invoices, val => handleFilter({ page: 0, limit: val }));
  };

  const handleSort = (field) => {
    const updatedOrder = { ...sortHelper(field, order) };
    setOrder(updatedOrder);
  };

  const handleExport = () => {
    api.shipmentDashboard.invoices({
      start_date: startDate,
      end_date: endDate,
      filter_company: selectedCompany,
      export: !!exportFormat,
      export_format: exportFormat,
      filter_carrier: selectedCarrier,
      include_unused: includeUnused,
    }).then((res) => {
      if (res.ok) {
        successToast(res.message);
      } else {
        errorToast(res.message);
      }
    })
      .catch(err => errorToast(err));
  };

  const getFields = () => [
    {
      header: '#',
      key: '#',
    },
    {
      header: 'Tracking Comment',
      key: 'tracking_comment',
      sortable: true,
    },
    {
      header: 'Destination Company Name',
      key: 'destination_company_name',
      sortable: true,
    },
    {
      header: 'Destination Attention To',
      key: 'destination_attention_to',
      sortable: true,
    },
    {
      header: 'Destination Street Address1',
      key: 'destination_street_address1',
      sortable: true,
    },
    {
      header: 'Destination Street Address2',
      key: 'destination_street_address2',
      sortable: true,
    },
    {
      header: 'Destination City',
      key: 'destination_city',
      sortable: true,
    },
    {
      header: 'Destination State',
      key: 'destination_state',
      sortable: true,
    },
    {
      header: 'Destination Zip',
      key: 'destination_zip',
      sortable: true,
    },
    {
      header: 'Destination Country',
      key: 'destination_country',
      sortable: true,
    },
    {
      header: 'Destination Phone',
      key: 'destination_phone',
      sortable: true,
    },
    {
      header: 'Status',
      key: 'status',
      sortable: false,
    },
    {
      header: 'Created At',
      key: 'created_at',
      sortable: true,
    },
    {
      header: 'Carrier Service',
      key: 'carrier_service.name',
      sortable: false,
    },
    {
      header: 'Tracking ID',
      key: 'tracking_id',
      sortable: true,
    },
    {
      header: 'Ship Date',
      key: 'ship_date',
      sortable: true,
    },
    {
      header: 'Price',
      key: 'price',
      sortable: true,
    },
    {
      header: 'Company',
      key: 'company.name',
      sortable: false,
    },
    {
      header: 'Origin Company Name',
      key: 'origin_company_name',
      sortable: true,
    },
    {
      header: 'Origin Attention To',
      key: 'origin_attention_to',
      sortable: true,
    },
    {
      header: 'Origin Street Address1',
      key: 'origin_street_address1',
      sortable: true,
    },
    {
      header: 'Origin Street Address2',
      key: 'origin_street_address2',
      sortable: true,
    },
    {
      header: 'Origin City',
      key: 'origin_city',
      sortable: true,
    },
    {
      header: 'Origin State',
      key: 'origin_state',
      sortable: true,
    },
    {
      header: 'Origin Zip',
      key: 'origin_zip',
      sortable: true,
    },
    {
      header: 'Origin Country',
      key: 'origin_country',
      sortable: true,
    },
    {
      header: 'Origin Phone',
      key: 'origin_phone',
      sortable: true,
    },
    {
      header: 'Status Updated At',
      key: 'status_updated_at',
      sortable: true,
    },
    {
      header: 'Carrier',
      key: 'carrier.name',
      sortable: false,
    },
    {
      header: 'Customer Base Price',
      key: 'customer_base_price',
      sortable: true,
    },
    {
      header: 'Zone',
      key: 'zone',
      sortable: true,
    },
    {
      header: 'Dim Weight',
      key: 'dim_weight',
      sortable: true,
    },
    {
      header: 'Billing Weight',
      key: 'billing_weight',
      sortable: true,
    },
    {
      header: 'Fuel Surcharge Percent',
      key: 'fuel_surcharge_percent',
      sortable: true,
    },
  ];

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Invoices</h1>
              <button
                className="export_csv_button btn-primary btn pull-right"
                title="Export"
                onClick={handleExport}
              >
                Export
              </button>
              <select
                value={exportFormat}
                onChange={e => setExportFormat(e.target.value)}
                className="pull-right export_format_select"
              >
                <option value="xlsx">XLSX</option>
                <option value="csv">CSV</option>
                {/* <option value="pdf">PDF</option> */}
              </select>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="status-sec">
                <div className="form-inline" style={{ display: 'flex' }}>
                  <select
                    name="date_period"
                    id="date_period"
                    className="form-control"
                    value={datePeriod}
                    onChange={e => setDatePeriod(e.target.value)}
                  >
                    <option value="thisWeek">This week</option>
                    <option value="thisMonth">This month</option>
                    <option value="lastWeek">Last week</option>
                    <option value="last2Weeks">Last 2 weeks</option>
                    <option value="last4Weeks">Last 4 weeks</option>
                    <option value="lastMonth">Last month</option>
                    <option value="custom">Custom</option>
                  </select>
                  <div className="datepicker">
                    <input
                      type="date"
                      className="form-control dimensions length"
                      id="startDate"
                      name="startDate"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                        setDatePeriod('custom');
                      }}
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    />
                  </div>
                  <div className="datepicker">
                    <input
                      type="date"
                      className="form-control dimensions length"
                      id="endDate"
                      name="endDate"
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                        setDatePeriod('custom');
                      }}
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    />
                  </div>
                  <select
                    name="bag_status"
                    id="bag_status"
                    className="form-control"
                    value={selectedCompany}
                    onChange={(e) => {
                      setCompany(e.target.value);
                    }}
                  >
                    <option value="">Select company</option>
                    {companyList.map(company => <option key={company[1]} value={company[1]}>{company[0]}</option>)}
                  </select>
                  <select
                    name="carrier"
                    id="carrier"
                    className="form-control"
                    value={selectedCarrier}
                    onChange={(e) => {
                      setCarrier(e.target.value);
                    }}
                  >
                    <option value="">Select carrier</option>
                    {carrierList.map(carrier => <option key={carrier[1]} value={carrier[1]}>{carrier[0]}</option>)}
                  </select>

                  <button style={{ marginLeft: '10px' }} className="btn-danger btn shipment_filters_reset" onClick={handleReset}>Reset</button>
                </div>
              </div>
              <div className="status-sec">
                <input
                  type="checkbox"
                  id="include-unused"
                  value={includeUnused}
                  checked={includeUnused}
                  onChange={() => setIncludeUnused(!includeUnused)}
                />
                <label htmlFor="include-unused" style={{ marginLeft: 5 }}>Include unused</label>
              </div>
            </div>
            <div className="box-body main_listing">
              <CustomTable
                rows={rows}
                formatters={{
                  created_at: value => (value ? moment(value).format('DD MMM YYYY') : '-'),
                }}
                paginationOptions={{
                  ...paginationOptions,
                  handleChangePage,
                  handleChangeRowsPerPage,
                }}
                rowsProps={getFields()}
                suffix="shipments"
                handleSort={handleSort}
                sortOptions={order}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Invoices.propTypes = {
  shipments: PropTypes.instanceOf(Array).isRequired,
  allLabelsCount: PropTypes.number.isRequired,
  companyList: PropTypes.instanceOf(Array).isRequired,
  defaultLimit: PropTypes.number.isRequired,
  carrierList: PropTypes.instanceOf(Array).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

export default Invoices;
