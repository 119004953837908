import * as React from 'react';
import PropTypes from 'prop-types';

const STATUSES = [
  { id: 'scheduled', text: 'Scheduled' },
  { id: 'completed', text: 'Package received' },
  { id: 'security', text: 'Security Check' },
  { id: 'exception', text: 'Exception' },
  { id: 'not_found', text: 'Package not found' },
  { id: 'test', text: 'Test' },
  { id: 'start', text: 'Start' },
  { id: 'cons', text: 'Cons' },
];

const StatusDropdown = ({
  status, id, handleUpdateStatus, addEmpty,
}) => (
  <div style={{ minWidth: '150px' }}>
    <select className="form-control" value={status || ''} onChange={e => handleUpdateStatus(id, e.target.value)}>
      {addEmpty && <option value="">Select status</option>}
      {STATUSES.map(item => (
        <option key={item.id} value={item.id}>{item.text}</option>
      ))}
    </select>
  </div>
);

StatusDropdown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  id: PropTypes.any,
  status: PropTypes.string.isRequired,
  handleUpdateStatus: PropTypes.func.isRequired,
  addEmpty: PropTypes.bool,
};

StatusDropdown.defaultProps = {
  id: null,
  addEmpty: false,
};

export default StatusDropdown;
