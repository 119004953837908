import React from 'react';
import Toast from '../common/Toast';

import GenerationToolCreate from './GenerationToolCreate';
import GeneratorToolCopy from './GenerationToolCopy';


const RouteGenerationTool = () => (
  <>
    <Toast />
    <GenerationToolCreate type="DL" />
    <GenerationToolCreate type="RL" />
    <GeneratorToolCopy />
  </>
);

export default RouteGenerationTool;
