import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import api from '../../services/api';

const ConversationTitleModal = ({
  onSubmit, handleClose, open, setIdentity, identity, error, convoTitle, setConvoTitle,
}) => {
  const [drivers, setDrivers] = useState([]);

  const handleSubmit = () => {
    if (!identity || !convoTitle) return;

    onSubmit();
    handleClose();
  };

  const fetchDrivers = async () => {
    api.drivers.getDrivers().then(data => setDrivers(data.drivers));
  };

  const selectDriver = (idx) => {
    if (!drivers[idx]) return;

    const driver = drivers[idx];
    setIdentity({
      email: driver.email,
      name: `${driver.first_name} ${driver.last_name}`,
    });
  };

  useEffect(() => {
    if (!open) return;

    fetchDrivers();
  }, [open]);

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Set new conversation title
        <IconButton onClick={handleClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="position">
              Select Driver
            </label>
            <select
              className="form-control"
              id="driver"
              onChange={e => selectDriver(parseInt(e.target.value, 10))}
            >
              {drivers.map((dr, idx) => (<option key={`${dr.first_name}-${dr.last_name}-${dr.id}`} value={idx}>{`${dr.first_name} ${dr.last_name} (${dr.email})`}</option>))}
            </select>
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="position">
              Set Conversation Title
            </label>
            <input
              className="form-control"
              id="convoTitle"
              name="convoTitle"
              type="text"
              value={convoTitle}
              onChange={e => setConvoTitle(e.target.value)}
              required
            />
          </div>
          {error && (<div>{error}</div>)}
        </div>
        <div className="modal-bottom-btn text-right">
          <button className="btn bg-olive" onClick={handleSubmit}>Confirm</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={handleClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ConversationTitleModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  setIdentity: PropTypes.func.isRequired,
  identity: PropTypes.instanceOf(Object),
  error: PropTypes.string,
  convoTitle: PropTypes.string.isRequired,
  setConvoTitle: PropTypes.func.isRequired,
};

ConversationTitleModal.defaultProps = {
  open: false,
  identity: {},
  error: '',
};

export default ConversationTitleModal;
