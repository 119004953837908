import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import api from '../../services/api';
import { errorToast } from '../../services/toast';

const FinancialReportModal = ({
  handleClose, open, selectedDate,
}) => {
  const [selectedRate, setSelectedRate] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const beforeClose = () => {
    if (!requestInProgress) {
      handleClose();
    } else {
      errorToast('Please, wait until all requests are completed');
    }
  };


  const handleSubmit = async () => {
    setRequestInProgress(true);
    await api.routesDashboard.generateFinancialReport({ rate: selectedRate, filter_date: selectedDate });
    setRequestInProgress(false);
    handleClose();
  };

  return (
    <Dialog
      className="dialog-sm"
      open={open}
      onClose={beforeClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Generate manifest
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row" style={{ marginLeft: 10, marginRight: 10 }}>
          <div className="col-sm-12">
            <div>Rate type</div>
            <select id="rate_type" className="form-control" onChange={e => setSelectedRate(e.target.value)} style={{ minWidth: 150 }}>
              <option value={0}>Driver rate</option>
              <option value={1}>Flat rate</option>
              <option value={2}>Max rate</option>
            </select>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="modal-bottom-btn text-right" style={{ borderTop: 'none', paddingRight: '15%' }}>
          <button disabled={requestInProgress} className="btn bg-olive" onClick={handleSubmit}>Confirm</button>
          <button disabled={requestInProgress} className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={beforeClose}>Close</button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

FinancialReportModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  selectedDate: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

FinancialReportModal.defaultProps = {
  open: false,
};

export default FinancialReportModal;
