import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { base64ToObjectURL } from '../../services/helpers';

const UploadDetailsModal = ({
  handleClose, open, data,
}) => (
  <Dialog
    className="dialog-md"
    open={open}
    onClose={handleClose}
    aria-labelledby="preview-modal-title"
  >
    <DialogTitle id="preview-modal-title">
      Upload Details
    </DialogTitle>
    <DialogContent className="preview-item-container" id="preview-modal-dialog">
      <div className="modal-header">
        <div className="imported_successfully">
          {`${data?.total_records} records were imported successfully`}
        </div>
        {data && data?.truncated_records && (
          <div className="truncated_data">
            {`${data?.truncated_records} records were truncated during import. You can review them in the attached file`}
          </div>
        )}
        {data && data?.truncated_file && (
          <div className="truncated_file">
            <a href={base64ToObjectURL(data?.truncated_file)} download="truncated.csv">Download</a>
          </div>
        )}
        {data && data?.problematic_file && (
          <div className="problematic_records">
            {`${data?.problematic_records} records raised an error during import. You can review them in the attached file`}
          </div>
        )}
        {data && data?.problematic_file && (
          <div className="problematic_file">
            <a href={base64ToObjectURL(data?.problematic_file)} download="problematic.csv">Download</a>
          </div>
        )}
        <button onClick={handleClose} className="btn btn-primary pull-right popup_cancel_btn">Close</button>
      </div>
    </DialogContent>
  </Dialog>
);

UploadDetailsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  open: PropTypes.bool,
};

UploadDetailsModal.defaultProps = {
  open: false,
};

export default UploadDetailsModal;
