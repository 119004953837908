import React from 'react';
import PropTypes from 'prop-types';
import { Info } from '@material-ui/icons';

const PickupTestIcon = ({ test, onClick, size }) => (test ? <Info data-testid="test-icon" onClick={onClick} style={{ color: 'red', fontSize: size }} /> : <Info data-testid="test-icon" onClick={onClick} style={{ color: 'blue', fontSize: size }} />);

PickupTestIcon.propTypes = {
  test: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.number,
};

PickupTestIcon.defaultProps = {
  test: false,
  onClick: () => {},
  size: 37,
};

export default PickupTestIcon;
