import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { get } from 'lodash';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import api from '../../services/api';
import { ROUTE_PICKUPS_FIELDS } from '../../services/helpers';
import CertaintyIcon from '../common/CertaintyIcon';
import RoutePickupsModal from './RoutePickupsModal';
import SetPositionModal from '../route_dashboard/SetPositionModal';
import PickupTestIcon from '../common/PickupTestIcon';
import FieldsModal from '../common/FieldsModal';
import EditScheduledPickupModal from '../pickups_dashboard/EditScheduledPickupModal';
import MyMapComponent from '../common/Map';
import SwitchPickupRouteModal from './SwitchPickupRouteModal';

const Route = ({
  route, network, timezoneFromServer, permissions, companyList, states, limits,
  radiusFlexId, googleMapsKey, countryId, userId, preferences,
}) => {
  const [rows, setRows] = useState([]);
  const [serverTimezone, setServerTimezone] = useState('America/Chicago');
  const [routePickupsModal, setRoutePickupsModal] = useState(false);
  const [filter, setFilter] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const [openSet, setOpenSet] = useState(false);
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [renderMap, setRenderMap] = useState(true);
  const [markers, setMarkers] = useState([]);
  const [addAirportStop, setAddAirportStop] = useState(route.add_airport_stop);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(route.driver_id);
  const [openSwitch, setOpenSwitch] = useState(false);

  useEffect(() => {
    updateData();
  }, [filter]);

  const updateData = () => {
    setRenderMap(false);
    api.rlNetwork.showRoute(route.id, filter).then((res) => {
      setRows(res.pickups.map((p) => {
        const { scheduled_pickup, ...rest } = p;
        scheduled_pickup.rlPickup = rest;
        return scheduled_pickup;
      }));
      setRenderMap(res.pickups.length > 0);
      setAddAirportStop(res.route.add_airport_stop);
      setDrivers(res.drivers);
    });
  };

  const handleSubmit = (data) => {
    api.rlNetwork.updateRoute(route.id, { rl_network_route: { add_airport_stop: addAirportStop, rl_network_route_pickups_attributes: data, driver_id: selectedDriver } }).then(updateData);
  };

  const handleChangePosition = useCallback(async (rlPickup, direction) => {
    const newPosition = direction === 'up' ? rlPickup.position - 1 : rlPickup.position + 1;
    updatePosition(rlPickup.id, newPosition);
  }, [rows, route]);

  const handleSetPosition = useCallback(async (newPosition) => {
    await updatePosition(selectedRow.rlPickup.id, newPosition);
    setSelectedRow({});
  }, [selectedRow, rows, route]);

  const updatePosition = async (rlPickupId, newPosition) => {
    const reorderedStops = rows.filter(row => row.rlPickup.id !== rlPickupId).map(row => row.rlPickup.id);
    reorderedStops.splice(newPosition - 1, 0, rlPickupId);

    await api.rlNetwork.updatePickupPositions({
      route_id: route.id,
      reordered_stops: reorderedStops,
    }).then(() => {
      updateData();
    });
  };

  const getFields = () => {
    const defaultArray = [
      { header: 'Company', key: 'company', format: true },
      { header: 'Carrier', key: 'carrier.name' },
      { header: 'Ready Time', key: 'ready_time' },
      { header: 'Close Time', key: 'close_time' },
      { header: 'Cutoff', key: 'actual_cutoff_time' },
      { header: 'Origin Company Name', key: 'origin_company_name', format: true },
      { header: 'Origin Attention To', key: 'origin_attention_to', format: true },
      { header: 'Origin Street Address1', key: 'origin_street_address1', format: true },
      { header: 'Origin Street Address2', key: 'origin_street_address2', format: true },
      { header: 'Origin City', key: 'origin_city', format: true },
      { header: 'Origin State', key: 'origin_state', format: true },
      { header: 'Origin Zip', key: 'origin_zip', format: true },
      { header: 'Origin Country', key: 'origin_country', format: true },
      { header: 'Origin Phone', key: 'origin_phone', format: true },
      { header: 'Courier Instructions', key: 'courier_instructions' },
      { header: 'Confirm', key: 'pickup_certainty' },
      { header: 'Test', key: 'is_test' },
      { header: 'Schedule Days', key: 'schedule_days' },
      { header: 'Position', key: 'position', format: true },
    ];
    if (permissions.rlNetwork?.edit) defaultArray.push({ header: 'Change Position', key: 'change_position', format: true });
    if (permissions.rlNetwork?.edit || permissions.scheduledPickup?.edit) defaultArray.push({ header: 'Actions', key: 'actions', format: true });

    return defaultArray;
  };

  const getRowStyle = (row) => {
    if (get(row, 'is_test') === true) return ({ backgroundColor: '#ff0000b3' });
    if (get(row, 'is_on_call')) {
      return ({ backgroundColor: '#ffce00' });
    }
    if (get(row, 'is_ups') || get(row, 'carrier.carrier_type') === 'UPS') return ({ backgroundColor: '#33000070' });
    if (get(row, 'is_fedex') || get(row, 'carrier.carrier_type') === 'FEDEX') return ({ backgroundColor: '#4d148cb3' });

    return {};
  };

  useEffect(() => {
    const tz = timezoneFromServer?.info?.identifier;
    if (tz) {
      setServerTimezone(tz);
    } else {
      setServerTimezone('America/Chicago');
    }
  }, [timezoneFromServer]);

  const handleUpdateFields = (updatedFields, save = true) => {
    setSelectedFields(getFields().filter(f => updatedFields.includes(f.key)));
    setShowFieldsModal(false);
    if (save) window.localStorage.setItem(ROUTE_PICKUPS_FIELDS, updatedFields.join(','));
  };

  const handleSwitch = useCallback(async (routeId) => {
    api.rlNetwork.switchPickupRoute({ pickup_id: selectedRow.rlPickup.id, route_id: routeId }).then(() => {
      setOpenSwitch(false);
      setSelectedRow({});
      updateData(route.id);
    });
  }, [selectedRow]);

  useEffect(() => {
    const savedFields = window.localStorage.getItem(ROUTE_PICKUPS_FIELDS);
    if (!savedFields) {
      setSelectedFields(getFields());
      return;
    }

    handleUpdateFields(savedFields.split(','), false);
  }, []);

  useEffect(() => {
    if (rows.length > 0) {
      const points = [...rows].filter(p => !!p.geolocation && !!p.geolocation.latitude && !!p.geolocation.longitude);
      const firstPoint = points.shift();
      const lastPoint = points.length ? points.pop() : null;

      const processedMarkers = [
        {
          position: { lat: firstPoint?.geolocation?.latitude, lng: firstPoint?.geolocation?.longitude },
          tip: firstPoint?.origin_company_name,
          color: 'green',
        },
        ...points.map(point => ({
          position: { lat: point?.geolocation?.latitude, lng: point?.geolocation?.longitude },
          tip: point?.origin_company_name,
          color: 'blue',
        })),
      ];
      if (lastPoint) {
        processedMarkers.push({
          position: { lat: lastPoint.geolocation?.latitude, lng: lastPoint.geolocation?.longitude },
          tip: lastPoint.origin_company_name,
          color: 'red',
        });
      }

      setMarkers(processedMarkers);
    } else {
      setMarkers([]);
    }
  }, [rows]);

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 1 }}>
                <h1 className="box-title">{`${network.name} - ${network.days.join(', ')}: Route #${route.id}`}</h1>
                <div className="pull-right">
                  <button className="btn btn-primary" onClick={() => setShowFieldsModal(true)}>Change selected fields</button>
                  {permissions.rlNetwork?.edit && (
                    <button
                      className="btn add-btn pull-right prevent_right_click add_address_class_new"
                      onClick={() => {
                        setRoutePickupsModal(true);
                      }}
                      data-testid="edit_pickups"
                      style={{ marginLeft: '5px' }}
                    >
                      Edit pickups
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="box-body main_listing">
              <CustomTable
                rows={rows}
                setFilter={setFilter}
                formatters={{
                  company: value => value.origin_record_json?.company?.name || value.company?.name || '-',
                  schedule_days: value => (value ? value.map(item => item.slice(0, 3)).join(', ') : '-'),
                  close_time: value => moment(value).tz(serverTimezone).format('hh:mm a z'),
                  ready_time: value => moment(value).tz(serverTimezone).format('hh:mm a z'),
                  actual_cutoff_time: value => (value ? moment(value).tz(serverTimezone).format('hh:mm A z') : ''),
                  pickup_certainty: value => <CertaintyIcon certainty={value} />,
                  is_test: value => <PickupTestIcon test={value} />,
                  position: value => value.rlPickup.position,
                  change_position: value => (
                    <div className="text-center" style={{ minWidth: '70px' }}>
                      {value.rlPickup.position > 1 && (
                        <button
                          type="button"
                          className="close"
                          onClick={() => handleChangePosition(value.rlPickup, 'up')}
                          data-testid={`position-up-${value.rlPickup.id}`}
                        >
                          &#x25B2;
                        </button>
                      )}
                      {value.rlPickup.position !== rows.length && (
                        <button
                          type="button"
                          className="close"
                          onClick={() => handleChangePosition(value.rlPickup, 'down')}
                          data-testid={`position-down-${value.rlPickup.id}`}
                        >
                          &#x25BC;
                        </button>
                      )}
                    </div>
                  ),
                  actions: value => (
                    <div className="text-center" style={{ minWidth: '100px' }}>
                      {permissions.rlNetwork?.edit && (
                        <>
                          <button
                            onClick={() => {
                              setSelectedRow(value);
                              setOpenSet(true);
                            }}
                            className="btn btn-primary"
                            disabled={rows.length < 2}
                          >
                            Set Position
                          </button>
                          <button
                            onClick={() => {
                              setSelectedRow(value);
                              setOpenSwitch(true);
                            }}
                            className="fa fa-exchange edit_user"
                            style={{ border: 'none', backgroundColor: 'transparent' }}
                          />
                        </>
                      )}
                      {permissions.scheduledPickup?.edit && (
                        <button
                          className="fa fa-edit action-button"
                          onClick={() => {
                            setOpenEdit(true);
                            setSelectedRow(value);
                          }}
                        />
                      )}
                    </div>
                  ),
                  origin_company_name: value => value.origin_record_json?.company_name || value.origin_company_name,
                  origin_attention_to: value => value.origin_record_json?.attention_to || value.origin_attention_to,
                  origin_street_address1: value => value.origin_record_json?.street_address1 || value.origin_street_address1,
                  origin_street_address2: value => value.origin_record_json?.street_address2 || value.origin_street_address2,
                  origin_city: value => value.origin_record_json?.city || value.origin_city,
                  origin_state: value => (value.origin_record_json ? states.find(state => state.id === parseInt(value.origin_record_json.state_id, 10))?.alpha2 : value.origin_state),
                  origin_zip: value => value.origin_record_json?.zip || value.origin_zip,
                  origin_country: value => value.origin_record_json?.country || value.origin_country,
                  origin_phone: value => value.origin_record_json?.phone || value.origin_phone,
                }}
                rowsProps={selectedFields}
                rowStyle={row => getRowStyle(row)}
                suffix="network_pickup"
              />
              {permissions.rlNetwork.edit && (
                <>
                  <div className="row" style={{ marginTop: '20px' }} />
                  <div style={{ marginTop: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="col-sm-4 form-group">
                        <div>
                          Reassign Driver
                          <span className="required">*</span>
                        </div>
                        <select className="form-control" value={selectedDriver} onChange={e => setSelectedDriver(e.target.value)}>
                          <option key="default" value="">Please select</option>
                          {drivers.map(driver => (
                            <option key={driver[1]} value={driver[1]}>{driver[0]}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-4">
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            id="add_airport_stop"
                            style={{ marginLeft: '5px' }}
                            checked={addAirportStop}
                            onChange={() => setAddAirportStop(!addAirportStop)}
                          />
                          Add airport stop
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="modal-bottom-btn text-right">
                    <button
                      onClick={() => handleSubmit(rows.map(row => ({ scheduled_pickup_id: row.id, position: row.rlPickup.position })))}
                      className="btn bg-olive"
                    >
                      Update Route Manifest
                    </button>
                  </div>
                </>
              )}
              <div style={{ marginTop: '20px' }} />
              { renderMap && (
                <MyMapComponent
                  markers={markers}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&v=3.exp&libraries=geometry,drawing,places`}
                />
              )}
            </div>
            {routePickupsModal && (
              <RoutePickupsModal
                onClose={() => setRoutePickupsModal(false)}
                selected={rows.map(p => p.id)}
                open={routePickupsModal}
                onSubmit={handleSubmit}
                serverTimezone={serverTimezone}
                route={route}
              />
            )}
          </div>
        </div>
      </div>
      {openSwitch && (
        <SwitchPickupRouteModal
          open={openSwitch}
          handleClose={() => setOpenSwitch(false)}
          handleConfirm={handleSwitch}
          network={network}
          currentRouteId={route.id}
        />
      )}
      {openSet && (
        <SetPositionModal
          handleClose={() => {
            setOpenSet(false);
            setSelectedRow({});
          }}
          open={openSet}
          onSubmit={handleSetPosition}
          maxValue={rows.length}
          currentValue={rows.indexOf(selectedRow) + 1}
        />
      )}
      {
        showFieldsModal && <FieldsModal open={showFieldsModal} fields={getFields()} selectedFields={selectedFields} updateFields={handleUpdateFields} handleClose={() => setShowFieldsModal(false)} />
      }
      {
        openEdit && (
          <EditScheduledPickupModal
            update={updateData}
            companyList={companyList}
            row={selectedRow}
            onClose={() => {
              setOpenEdit(false);
              setSelectedRow({});
            }}
            serverTimezone={serverTimezone}
            open={openEdit}
            states={states}
            limits={limits}
            radiusFlexId={radiusFlexId}
            permission={permissions}
            countryId={countryId}
            userId={userId}
            preferences={preferences}
          />
        )
      }
    </>
  );
};

Route.propTypes = {
  route: PropTypes.instanceOf(Object).isRequired,
  network: PropTypes.instanceOf(Object).isRequired,
  timezoneFromServer: PropTypes.instanceOf(Object).isRequired,
  permissions: PropTypes.instanceOf(Object).isRequired,
  companyList: PropTypes.instanceOf(Array).isRequired,
  states: PropTypes.instanceOf(Array).isRequired,
  limits: PropTypes.instanceOf(Object).isRequired,
  radiusFlexId: PropTypes.number.isRequired,
  googleMapsKey: PropTypes.string.isRequired,
  countryId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

export default Route;
