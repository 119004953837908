/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import { Clear, Done, MoreHoriz } from '@material-ui/icons';
import api from '../../services/api';
import { errorToast } from '../../services/toast';

const CopyRoutesModal = ({
  handleClose, open, routes,
}) => {
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const beforeClose = () => {
    if (!requestInProgress) {
      handleClose();
    } else {
      errorToast('Please, wait until all request are completed');
    }
  };
  useEffect(() => {
    const newSelectedRoutes = [];
    routes && routes.map(r => newSelectedRoutes.push({
      id: r.id,
      cluster_name: r.package_cluster_id ? `${r.package_cluster.region_id_lvl1} @ ${r.package_cluster.airport_code}` : '',
      checked: true,
      status: 0,
    }));
    setSelectedRoutes(newSelectedRoutes);
  }, [routes]);

  const handleChangeCheck = (id) => {
    const newSelectedRoutes = [...selectedRoutes];
    const element = newSelectedRoutes.find(r => r.id === id);
    if (element) {
      element.checked = !element.checked;
      setSelectedRoutes(newSelectedRoutes);
    }
  };

  const isRequestProcced = (routesQuery) => {
    if (routesQuery.findIndex(r => r.status === 1) === -1) {
      setRequestInProgress(false);
    }
  };

  const handleSubmit = async (data) => {
    for (const r of selectedRoutes) {
      if (data.includes(r)) {
        r.status = 1;
        setRequestInProgress(true);
        // eslint-disable-next-line no-await-in-loop
        await api.routeGenerationTool.copyRoute(r.id).then((response) => {
          const updateSectedRoutes = [...selectedRoutes];
          if (response.ok) {
            updateSectedRoutes.find(e => e.id === r.id).status = 2;
          } else {
            updateSectedRoutes.find(e => e.id === r.id).status = -1;
          }
          isRequestProcced(updateSectedRoutes);
          setSelectedRoutes(updateSectedRoutes);
        })
          .catch(() => {
            const updateSelectedRoutes = [...selectedRoutes];
            updateSelectedRoutes.find(e => e.id === r.id).status = -1;
            isRequestProcced(updateSelectedRoutes);
            setSelectedRoutes(updateSelectedRoutes);
          });
      }
    }
  };

  const getStatusElement = (element) => {
    let icon;
    switch (element) {
    case 1:
      icon = <MoreHoriz />;
      break;
    case 2:
      icon = <Done style={{ color: '#90ee90' }} />;
      break;
    case -1:
      icon = <Clear style={{ color: '#ff0000b3' }} />;
      break;
    case 0:
    default:
      icon = <></>;
      break;
    }
    return (
      <div style={{ width: '30%', overflow: 'hidden', textAlign: 'right' }}>{icon}</div>
    );
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={beforeClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Copy Routes
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div style={{ width: '75%', margin: 'auto' }}>
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid', paddingLeft: '20px ', paddingRight: '20px',
          }}
          >
            <h4>Name</h4>
            <h4>Should copy?</h4>
            <h4>Status</h4>
          </div>
          {selectedRoutes && selectedRoutes.map(route => (
            <div
              key={route.id}
              style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid', padding: '20px',
              }}
            >
              <div style={{ width: '30%', overflow: 'hidden', textAlign: 'left' }}>{route.cluster_name ? route.cluster_name : `Id ${route.id}`}</div>
              <div style={{ width: '30%', overflow: 'hidden', textAlign: 'center' }} onClick={() => handleChangeCheck(route.id)}>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={route.checked}
                    onChange={() => handleChangeCheck(route.id)}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              {getStatusElement(route.status)}
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="modal-bottom-btn text-right" style={{ borderTop: 'none', paddingRight: '15%' }}>
          <button disabled={requestInProgress} className="btn bg-olive" onClick={() => handleSubmit(selectedRoutes.filter(e => e.checked))}>Copy</button>
          <button disabled={requestInProgress} className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={beforeClose}>Close</button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

CopyRoutesModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  routes: PropTypes.instanceOf(Array).isRequired,
  open: PropTypes.bool,
};

CopyRoutesModal.defaultProps = {
  open: false,
};

export default CopyRoutesModal;
