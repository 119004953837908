import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import Toast from '../common/Toast';
import api from '../../services/api';
import CustomTable from '../common/Table';
import SetCustomerPriceConstants from './SetCustomerPriceConstants';
import { updateChangeRowsPerPage } from '../../helpers';

const CustomerPriceConstants = ({ companies, preferences }) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const paginationKey = 'customer-price-per-page';
  const [selectedRow, setSelectedRow] = useState({});
  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || parseInt(window.localStorage.getItem(paginationKey), 10) || preferences?.records_per_page || 10,
  });

  const selectFileRef = useRef(null);

  useEffect(() => {
    handleFilter({});
  }, []);

  const handleFilter = useCallback(({ page, limit }) => {
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filters = {
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
    };
    api.customerPriceConstants.getTables(filters)
      .then((res) => {
        setRows(res.tables);
        setPaginationOptions({
          count: res.all_tables,
          page: page || 0,
          limit: limit || paginationOptions.limit,
        });
        const url = `/admin/customer_price_constants_tables?${qs.stringify(filters)}`;
        window.history.replaceState(null, '', url);
      })
      .catch(err => console.log(err));
  }, [paginationOptions]);

  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };

  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, paginationKey, val => handleFilter({ page: 0, limit: val }));
  };

  const handleImport = () => {
    selectFileRef.current.click();
  };

  const handleSelectFile = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('csv', file);
    api.customerPriceConstants.importData(formData).then(() => {
      handleFilter({});
    });
  };

  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="box">
          <Toast />
          <div className="box-header">
            <h1 className="box-title">Customer Price Constants Tables</h1>
            <div className="pull-right">
              <button
                className="btn add-btn pull-right prevent_right_click add_address_class_new"
                onClick={() => {
                  setSelectedRow({});
                  setOpenModal(true);
                }}
              >
                Add Customer Price Constants Tables
              </button>
            </div>
          </div>
          <div className="import-addresses-container" style={{ paddingLeft: 0 }}>
            <input ref={selectFileRef} type="file" onChange={handleSelectFile} style={{ width: 0, height: 0 }} />
            <button
              style={{ marginLeft: 0 }}
              className="btn add-btn prevent_right_click add_warehouses_from_file"
              onClick={() => {
                handleImport();
              }}
            >
              Upload From File
            </button>
          </div>
          <CustomTable
            rows={rows}
            rowsProps={[
              { header: 'Actions', key: 'actions', format: true },
              { header: 'Company', key: 'company.name' },
              { header: 'Carrier', key: 'carrier.name' },
              { header: 'Base Discount', key: 'base_discount' },
              { header: 'Customer Discount', key: 'customer_discount' },
              { header: 'Customer Surcharge', key: 'customer_surcharge' },
              { header: 'Customer Minimum', key: 'customer_minimum' },
            ]}
            paginationOptions={{
              ...paginationOptions,
              handleChangePage,
              handleChangeRowsPerPage,
            }}
            suffix="zone"
            formatters={{
              actions: value => (
                <div className="text-center" style={{ maxWidth: '70px' }}>
                  <button
                    className="fa fa-edit space-left-right action-button"
                    onClick={() => {
                      setOpenModal(true);
                      setSelectedRow(value);
                    }}
                  />
                </div>
              ),
            }}
          />

          <SetCustomerPriceConstants
            onClose={() => setOpenModal(false)}
            row={selectedRow}
            companies={companies}
            open={openModal}
            update={handleFilter}
          />
        </div>
      </div>
    </div>
  );
};

CustomerPriceConstants.propTypes = {
  companies: PropTypes.instanceOf(Array).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

export default CustomerPriceConstants;
