import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import api from '../../services/api';
import CopyRoutesModal from './CopyRoutesModal';
import Toast from '../common/Toast';
import { errorToast } from '../../services/toast';


const GenerationToolCopy = () => {
  const [date, setDate] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'));
  const [openConfirm, setOpenConfirm] = useState(false);
  const [routesToCopy, setRoutesToCopy] = useState([]);

  useEffect(() => {
    if (openConfirm) {
      api.routeGenerationTool.get({
        copy_routes_date: date,
      }).then((res) => {
        setRoutesToCopy(res.routes);
        if (!res.ok) {
          errorToast(res.message);
        }
      });
    }
  }, [openConfirm]);

  return (
    <>
      <Toast />
      <div className=" route-generation-tools row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Create copy of RL Routes</h1>
            </div>
            <div className="form-inline">
              <div className="form-group">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control generate_date"
                  id="copy_routes_date"
                  tabIndex="-1"
                  placeholder="Date"
                  value={date}
                  onChange={e => setDate(e.target.value)}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                />
                <button className="btn-primary btn" onClick={() => setOpenConfirm(true)}>Create Copy</button>
              </div>
            </div>

          </div>
          {openConfirm
            && (
              <CopyRoutesModal
                open={openConfirm}
                routes={routesToCopy}
                handleClose={() => setOpenConfirm(false)}
              />
            )
          }
        </div>
      </div>
    </>
  );
};

export default GenerationToolCopy;
