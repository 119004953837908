import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const Dashboard = ({
  permission, shipmentCount, pickupCount,
}) => {
  const linkDate = moment().format('YYYY-MM-DD');
  const awaitingShipmentLink = `/admin/shipment_dashboard?filter_status=label_created&estimated_ship_day=${linkDate}`;
  const transitShipmentLink = `/admin/shipment_dashboard?filter_status=in_transit&estimated_ship_day=${linkDate}`;
  const deliveredShipmentLink = `/admin/shipment_dashboard?filter_status=delivered&estimated_ship_day=${linkDate}`;
  const errorShipmentLink = `/admin/shipment_dashboard?shipment_error=true&estimated_ship_day=${linkDate}`;
  const awaitingPickupLink = `/admin/pickup_dashboard/all_pickups?filter_status=scheduled&pickup_by_day=${linkDate}`;
  const completedPickupLink = `/admin/pickup_dashboard/all_pickups?filter_status=completed&pickup_by_day=${linkDate}`;
  const exceptionPickupLink = `/admin/pickup_dashboard/all_pickups?filter_status=exception&pickup_by_day=${linkDate}`;
  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="box">
          <div className="box-header">
            <h1 className="main-head">Dashboard</h1>
            <div className="pull-right">
              {permission.pickup?.add && (
                <a href="/admin/pickups/new" className="btn bg-olive margin add-btn" style={{ marginLeft: '5px' }}>
                  Add
                  Pickup
                </a>
              )}
              {permission.shipment?.add && (
                <a href="/admin/shipments/ship" className="btn bg-olive margin add-btn" style={{ marginLeft: '5px' }}>
                  Add
                  Shipment
                </a>
              )}
            </div>
          </div>
          <div className="box-body">
            <h2>Shipments</h2>
            <div className="row">
              <div className="col-lg-3 col-xs-6 dashboard-boxes">
                <div className="small-box bg-yellow">
                  <div className="inner">
                    <div className="counter-box">
                      <h3 className="number_count">{shipmentCount.total}</h3>
                      <a href={`${awaitingShipmentLink}&is_read=false`}>
                        <div className="notify-icon">
                          <span className="notifi-number">{shipmentCount.totalUnread}</span>
                        </div>
                      </a>
                    </div>
                    <p>Awaiting</p>
                  </div>
                  <div className="icon out-delivery-icon" />
                  <a
                    href={awaitingShipmentLink}
                    className="small-box-footer"
                    title="More Information"
                  >
                    More info
                    {' '}
                    <i className="fa fa-arrow-circle-right" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-xs-6 dashboard-boxes">
                <div className="small-box bg-green">
                  <div className="inner">
                    <div className="counter-box">
                      <h3 className="number_count">{shipmentCount.inTransit}</h3>
                      <a href={`${transitShipmentLink}&is_read=false`}>
                        <div className="notify-icon">
                          <span className="notifi-number">{shipmentCount.inTransitUnread}</span>
                        </div>
                      </a>
                    </div>
                    <p>In-Transit</p>
                  </div>
                  <div className="icon transition-icon" />
                  <a
                    href={transitShipmentLink}
                    className="small-box-footer"
                    title="More Information"
                  >
                    More info
                    {' '}
                    <i className="fa fa-arrow-circle-right" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-xs-6 dashboard-boxes">
                <div className="small-box bg-dark-orange">
                  <div className="inner">
                    <div className="counter-box">
                      <h3 className="number_count">{shipmentCount.delivered}</h3>
                      <a href={`${deliveredShipmentLink}&is_read=false`}>
                        <div className="notify-icon">
                          <span className="notifi-number">{shipmentCount.deliveredUnread}</span>
                        </div>
                      </a>
                    </div>
                    <p>Delivered</p>
                  </div>
                  <div className="icon delivred-icon" />
                  <a
                    href={deliveredShipmentLink}
                    className="small-box-footer"
                    title="More Information"
                  >
                    More info
                    {' '}
                    <i className="fa fa-arrow-circle-right" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-xs-6 dashboard-boxes">
                <div className="small-box bg-red">
                  <div className="inner">
                    <div className="counter-box">
                      <h3 className="number_count">{shipmentCount.error}</h3>
                      <a href={`${errorShipmentLink}&is_read=false`}>
                        <div className="notify-icon">
                          <span className="notifi-number">{shipmentCount.errorUnread}</span>
                        </div>
                      </a>
                    </div>
                    <p>Errors</p>
                  </div>
                  <div className="icon return-icon" />
                  <a
                    href={errorShipmentLink}
                    className="small-box-footer"
                    title="More Information"
                  >
                    More info
                    {' '}
                    <i className="fa fa-arrow-circle-right" />
                  </a>
                </div>
              </div>
            </div>
            <h2>Pickups</h2>
            <div className="row">
              <div className="col-lg-3 col-xs-6 dashboard-boxes">
                <div className="small-box bg-yellow">
                  <div className="inner">
                    <div className="counter-box">
                      <h3 className="number_count">{pickupCount.scheduled}</h3>
                    </div>
                    <p>Scheduled</p>
                  </div>
                  <div className="icon out-delivery-icon" />
                  <a
                    href={awaitingPickupLink}
                    className="small-box-footer"
                    title="More Information"
                  >
                    More info
                    {' '}
                    <i className="fa fa-arrow-circle-right" />
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-xs-6 dashboard-boxes">
                <div className="small-box bg-dark-orange">
                  <div className="inner">
                    <div className="counter-box">
                      <h3 className="number_count">{pickupCount.completed}</h3>
                    </div>
                    <p>Completed</p>
                  </div>
                  <div className="icon delivred-icon" />
                  <a
                    href={completedPickupLink}
                    className="small-box-footer"
                    title="More Information"
                  >
                    More info
                    {' '}
                    <i className="fa fa-arrow-circle-right" />
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-xs-6 dashboard-boxes">
                <div className="small-box bg-red">
                  <div className="inner">
                    <div className="counter-box">
                      <h3 className="number_count">{pickupCount.exceptions}</h3>
                    </div>
                    <p>Exceptions</p>
                  </div>
                  <div className="icon return-icon" />
                  <a
                    href={exceptionPickupLink}
                    className="small-box-footer"
                    title="More Information"
                  >
                    More info
                    {' '}
                    <i className="fa fa-arrow-circle-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  permission: PropTypes.instanceOf(Object).isRequired,
  shipmentCount: PropTypes.instanceOf(Object).isRequired,
  pickupCount: PropTypes.instanceOf(Object).isRequired,
};

export default Dashboard;
