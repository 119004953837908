import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import api from '../../services/api';
import CustomTable from '../common/Table';
import EditServiceModal from './EditServiceModal';

const ServicesModal = ({
  handleClose, open, carrierId,
}) => {
  const [rows, setRows] = useState([]);
  const [currentService, setCurrentService] = useState({});
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    if (carrierId) getServices();
  }, [carrierId]);

  const getServices = useCallback(() => {
    api.carriers.getServices({ id: carrierId }).then((res) => {
      setRows(res.services);
    });
  }, [carrierId]);

  const handleChangeStatus = (value) => {
    api.carriers.setServiceStatus({
      id: value.id,
      status: !value.status,
    }).then(() => getServices());
  };

  const handleOpenEdit = (carrier) => {
    setCurrentService(carrier);
    setOpenEdit(true);
  };

  return (
    <Dialog
      className="dialog-sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Carrier Services
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <CustomTable
          rows={rows}
          formatters={{
            status: value => (
              <button
                type="button"
                className={`btn btn-xs status-btn btn-toggle ${value.status ? 'active' : 'inactive'}`}
                data-toggle="button"
                title={`${value.status ? 'Click to inactive' : 'Click to active'}`}
                onClick={() => handleChangeStatus(value)}
              >
                <span className="handle" />
              </button>
            ),
            actions: value => (
              <div className="text-center" style={{ minWidth: '70px' }}>
                <button onClick={() => handleOpenEdit(value)} className="fa fa-edit pdf-icon" style={{ border: 'none', backgroundColor: 'transparent' }} />
              </div>
            ),
          }}
          rowsProps={[{ header: 'Name', key: 'name' }, { header: 'Status', key: 'status', format: true }, { header: 'Actions', key: 'actions', format: true }]}
          suffix="services"
        />
        <div className="modal-bottom-btn text-right">
          <button className="btn btn-primary" onClick={handleClose}>Cancel</button>
        </div>
      </DialogContent>
      {rows.length > 0 && <EditServiceModal update={getServices} onClose={() => setOpenEdit(false)} row={currentService} open={openEdit} />}
    </Dialog>
  );
};

ServicesModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  carrierId: PropTypes.number.isRequired,
};

ServicesModal.defaultProps = {
  open: false,
};

export default ServicesModal;
