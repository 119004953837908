/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Check } from '@material-ui/icons';
import api from '../../services/api';

const ConsolidationSummary = ({
  bagData, handleAdd, handleUpdate, permission,
}) => {
  const deleteTrackingId = async (packageCode) => {
    const res = await api.pickupsDashboard.removeFromConsolidationBag({
      package_code: packageCode,
      bag_code: bagData.code,
    });
    if (res.status) handleUpdate();
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <hr />
      {bagData.company ? (
        <div style={{ fontWeight: '500', marginBottom: 15 }}>
          Company:&nbsp;
          <div style={{ display: 'inline', fontWeight: '400' }}>
            {bagData.company.name}
          </div>
        </div>
      )
        : (
          <>
            <div style={{ fontWeight: '600', fontSize: 14 }}>
              Origin Airport
            </div>
            <div style={{ fontSize: 14, fontWeight: '400', marginBottom: 15 }}>
              {bagData?.origin_airport || ''}
            </div>
            <div style={{ fontWeight: '600', fontSize: 14 }}>Destination Airport</div>
            <div style={{
              fontSize: 14, fontWeight: '400', marginBottom: 15, borderBottomWidth: 1, borderBottomColor: '#D5D5D5', width: '100%',
            }}
            >
              {bagData?.destination_airport || ''}
            </div>
          </>
        )}

      <div style={{ marginBottom: 10, color: '#33333370' }}>
        Consolidation bag ID
      </div>
      <div style={{ fontWeight: '600', fontSize: 14, marginBottom: 15 }}>
        {bagData?.code || ' '}
        <div style={{ display: 'inline', fontWeight: '700' }}>
          {' -'}
          {bagData.consolidation_status === 1 ? ' Completed' : ` ${bagData.consolidation_status || 'Not Completed'}`}
        </div>
      </div>
      <div style={{ marginBottom: 10, color: '#33333370', display: 'flex' }}>
        <div>
          {`Assigned packages (${bagData?.packages.length || 0})`}
        </div>
        {permission.consolidationLabel?.edit && <button className="add-tracking-id" onClick={() => handleAdd()}>+</button>}
      </div>
      {bagData?.packages?.map((pack, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`id_${idx}`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ fontWeight: '600', fontSize: 14 }}>
            {pack.code}
          </div>
          {pack.scanned && (
            <Check
              style={{ color: '#42AE8F', marginLeft: 5 }}
            />
          )}
          {permission.consolidationLabel?.edit && <span onClick={() => deleteTrackingId(pack.code)} role="img" style={{ marginLeft: 5, cursor: 'pointer' }}> ❌</span>}
        </div>
      ))}
    </div>
  );
};

ConsolidationSummary.propTypes = {
  handleAdd: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  bagData: PropTypes.instanceOf(Object).isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
};


export default ConsolidationSummary;
