import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@material-ui/icons';

const PhotoPickupIcon = ({ onClick }) => (
  <div style={{ position: 'relative', display: 'inline', top: 6 }}>
    <Image onClick={onClick} style={{ fontSize: 24 }} />
  </div>
);

PhotoPickupIcon.propTypes = {
  onClick: PropTypes.func,
};

PhotoPickupIcon.defaultProps = {
  onClick: () => null,
};

export default PhotoPickupIcon;
