import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

// import { useSelector } from 'react-redux';
import MessageList from './MessageList';
import twilioApi from '../../../services/twilioApi';
import { CONVERSATION_PAGE_SIZE } from '../../../constants';
import { getSdkConversationObject } from '../../../conversationObject';

export async function loadMessages(
  conversation,
  upsertMessage,
  currentMessages,
) {
  const convoSid = conversation.sid;
  const sidExists = !!currentMessages.filter(({ sid }) => sid === convoSid)
    .length;
  if (!sidExists) {
    const paginator = await twilioApi.getMessages(getSdkConversationObject(conversation));
    const messages = paginator.items;
    // save to redux
    upsertMessage(convoSid, messages);
  }
}

const MessagesBox = ({
  messages, convo, loadingState, lastReadIndex, upsertMessage, participants, members, email,
}) => {
  const [hasMore, setHasMore] = useState(
    messages?.length === CONVERSATION_PAGE_SIZE,
  );
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(0);
  const [paginator, setPaginator] = useState(null);
  const listRef = useRef(null);
  const sdkConvo = useMemo(() => getSdkConversationObject(convo), [convo.sid]);

  useEffect(() => {
    if (!messages && convo && !loadingState) {
      loadMessages(convo, upsertMessage);
    }
  }, []);

  useLayoutEffect(() => {
    const currentHeight = listRef.current?.clientHeight;
    if (currentHeight && currentHeight > height && loading) {
      // for preventing immediate downloading of the next messages page
      setTimeout(() => {
        setHeight(currentHeight ?? 0);
        setLoading(false);
      }, 2000);
    }
  }, [listRef.current?.clientHeight]);

  useEffect(() => {
    twilioApi.getMessages(sdkConvo).then((pg) => {
      setHasMore(pg.hasPrevPage);
      setPaginator(pg);
    });
  }, [convo]);

  useEffect(async () => {
    if (messages?.length && messages[messages.length - 1].index !== -1) {
      await sdkConvo.advanceLastReadMessageIndex(messages[messages.length - 1].index);
    }
  }, [messages, convo]);

  const lastConversationReadIndex = useMemo(
    () => (messages?.length
      && messages[messages.length - 1].author !== email
      ? lastReadIndex
      : -1),
    [lastReadIndex, messages],
  );

  const fetchMore = async () => {
    if (!paginator) {
      return;
    }

    const result = await paginator?.prevPage();
    if (!result) {
      return;
    }
    const moreMessages = result.items;

    setLoading(true);
    setPaginator(result);
    setHasMore(result.hasPrevPage);
    upsertMessage(convo.sid, moreMessages);
  };

  return (
    <Box
      className="messageBox"
      key={convo.sid}
      id="scrollable"
    >
      <InfiniteScroll
        className="messageBoxScroll"
        dataLength={messages?.length ?? 0}
        next={fetchMore}
        hasMore={!loading && hasMore}
        loader={(
          <div className="loadingIndicator">
            Loading...
          </div>
        )}
        scrollableTarget="scrollable"
        inverse
        scrollThreshold="20px"
      >
        <div ref={listRef} className="messageBoxScrollInner">
          <MessageList
            messages={messages ?? []}
            conversation={convo}
            participants={participants}
            lastReadIndex={lastConversationReadIndex}
            manifestMembers={members}
          />
        </div>
      </InfiniteScroll>
    </Box>
  );
};

MessagesBox.propTypes = {
  messages: PropTypes.instanceOf(Array),
  convo: PropTypes.instanceOf(Object).isRequired,
  loadingState: PropTypes.bool.isRequired,
  lastReadIndex: PropTypes.number.isRequired,
  upsertMessage: PropTypes.func.isRequired,
  participants: PropTypes.instanceOf(Array),
  members: PropTypes.instanceOf(Array),
  email: PropTypes.string,
};

MessagesBox.defaultProps = {
  messages: [],
  participants: [],
  members: [],
  email: '',
};

export default MessagesBox;
