import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Check } from '@material-ui/icons';
import moment from 'moment-timezone';
import CustomTable from '../common/Table';
import Toast from '../common/Toast';
import api from '../../services/api';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { errorToast } from '../../services/toast';
import { getDateForTimeComparison } from '../../services/helpers';

const ConfirmPickups = ({
  pickups, permission, userId, radiusFlexCarrierName, logoAsset, isCustomerPickupRole, timezoneFromServer,
}) => {
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [openCertainty, setOpenCertainty] = useState(false);
  const [serverTimezone, setServerTimezone] = useState('America/Chicago');
  const [query, setQuery] = useState('');

  useEffect(() => {
    const tz = timezoneFromServer?.info?.identifier;
    if (tz) {
      setServerTimezone(tz);
    } else {
      setServerTimezone('America/Chicago');
    }
  }, [timezoneFromServer]);

  useEffect(() => {
    setRows(pickups);
  }, [pickups]);


  const handleFilter = () => {
    api.pickupsDashboard.customerPickups({ search_field: query })
      .then((res) => {
        setRows(res?.pickups);
      })
      .catch(err => console.log(err));
  };

  const handleSetCertainty = useCallback(() => {
    api.pickupsDashboard.updatePickup(selectedRow.id, { pickup_certainty: !selectedRow.pickup_certainty }).then(() => {
      setOpenCertainty(false);
      setSelectedRow({});
      handleFilter();
    });
  }, [selectedRow]);

  const getFields = () => [
    {
      header: 'Origin',
      key: 'string_origin_new',
    },
    {
      header: 'Confirmed',
      key: 'pickup_certainty',
      sortable: true,
      format: true,
    },
  ];

  const checkForCertaintyEdit = (row) => {
    if (!isCustomerPickupRole) return true;
    if (!row.actual_cutoff_time) return true;

    const time = moment().format('hh:mm:ss a');
    const now = getDateForTimeComparison(moment(new Date(Date.parse(`2000/01/01 ${time}`))).tz(serverTimezone));
    const cutoffTime = getDateForTimeComparison(moment.tz(row.actual_cutoff_time, serverTimezone));
    return now.diff(cutoffTime) <= 0;
  };

  const handleOpenCertainty = (row) => {
    if (checkForCertaintyEdit(row)) {
      setOpenCertainty(true);
    } else {
      errorToast('Confirmation out of schedule. Please call or text Support at  832-979-6255.', { style: { fontSize: '10px', lineHeight: '10px' } });
    }
  };

  useEffect(() => {
    if (query === '') handleFilter();
  }, [query]);

  return (
    <>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Confirm Pickup</h1>
            </div>
            <div style={{ display: 'flex' }}>
              <div className="status-sec">
                <div className="form-inline" style={{ display: 'flex' }}>
                  <div className="input-group">
                    <input
                      className="form-control"
                      placeholder="Search"
                      value={query}
                      onKeyPress={e => (e.key === 'Enter' && handleFilter())}
                      onChange={e => setQuery(e.target.value)}
                    />
                    {/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <span
                      className="search-clear fa fa-times"
                      onClick={() => { setQuery(''); }}
                    />
                    {/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  </div>
                  <button type="submit" style={{ marginLeft: 10 }} className="btn btn-primary" onClick={() => handleFilter()}>
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
            </div>
            <div className="box-body main_listing">
              <CustomTable
                isPickupList
                radiusFlexCarrierName={radiusFlexCarrierName}
                logoAsset={logoAsset}
                rows={rows || []}
                formatters={{
                  pickup_certainty: value => (
                    value.pickup_certainty
                      ? (<Check style={{ color: '#42AE8F', fontSize: 40 }} />)
                      : (
                        <button
                          onClick={() => {
                            if (!permission.pickup?.editOthers && !(permission.pickup?.editMine && value.user_id === userId) && !isCustomerPickupRole) return;

                            setSelectedRow(value);
                            handleOpenCertainty(value);
                            // setOpenCertainty(true);
                          }}
                          title="Confirm"
                          style={!checkForCertaintyEdit(value) ? { backgroundColor: '#dd4b39', borderColor: '#d73925' } : {}}
                          className="btn btn-primary"
                        >
                          Confirm
                        </button>
                      )
                  ),
                }}
                rowsProps={getFields()}
                suffix="pickups"
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        onConfirm={handleSetCertainty}
        onClose={() => setOpenCertainty(false)}
        message="Are you sure you want to confirm this pickup?"
        open={openCertainty}
      />
    </>
  );
};

ConfirmPickups.propTypes = {
  pickups: PropTypes.instanceOf(Array).isRequired,
  radiusFlexCarrierName: PropTypes.string.isRequired,
  logoAsset: PropTypes.string.isRequired,
  isCustomerPickupRole: PropTypes.bool.isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
  userId: PropTypes.number.isRequired,
  timezoneFromServer: PropTypes.instanceOf(Object).isRequired,
};

export default ConfirmPickups;
