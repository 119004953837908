import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import qs from 'qs';
import CustomTable from '../common/Table';
import api from '../../services/api';
import SendEmailModal from './SendEmailModal';
import { printZplLabels } from '../../services/helpers';
import { updateChangeRowsPerPage } from '../../helpers';
import { PaginationKeys } from '../../constants';

const Manifests = ({
  date, shipments, allLabelsCount, defaultLimit, isAdmin, preferences,
}) => {
  const urlSearchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const [rows, setRows] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    page: urlSearchParams.page ? parseInt(urlSearchParams.page, 10) - 1 : 0,
    count: 0,
    limit: parseInt(urlSearchParams.limit, 10) || parseInt(window.localStorage.getItem(PaginationKeys.shipmentManifests), 10) || preferences?.records_per_page || 10,
  });
  const [shipmentByDay, setShipmentByDay] = useState(urlSearchParams.filter_day || '');
  const [shipmentId, setShipmentId] = useState();
  const [showEmailModal, setShowEmailModal] = useState(false);

  useEffect(() => {
    setRows(shipments);
    setPaginationOptions({
      ...paginationOptions,
      count: allLabelsCount,
      limit: defaultLimit,
    });
    date && setShipmentByDay(date);
  }, [shipments, date, allLabelsCount]);

  useEffect(() => {
    handleFilter({});
  }, [shipmentByDay]);

  const handlePrintZPL = async (row) => {
    const res = await api.shipmentDashboard.bulkActions({
      bulk_action: 'print_zpl',
      ids: [row],
      controller_name: 'shipments',
    });

    printZplLabels(res);
  };

  const handleFilter = useCallback(({ page, limit, query }) => {
    const fixedPage = page !== undefined ? page : paginationOptions.page;
    const filter = {
      filter_day: shipmentByDay,
      search_field: query,
      page: fixedPage + 1,
      limit: limit || paginationOptions.limit,
    };
    api.shipmentDashboard.manifests(filter)
      .then((res) => {
        setRows(res?.shipments);
        setPaginationOptions({
          count: res.all_labels,
          page: fixedPage || 0,
          limit: limit || paginationOptions.limit,
        });
        const url = `/admin/shipment_dashboard/manifests?${qs.stringify(filter)}`;
        window.history.replaceState(null, '', url);
      })
      .catch(err => console.log(err));
  }, [shipmentByDay, paginationOptions]);

  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };

  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, PaginationKeys.shipmentManifests, val => handleFilter({ page: 0, limit: val }));
  };

  const getFields = () => [
    { header: 'Tracking Comment', key: 'tracking_comment' },
    { header: 'Origin Company Name', key: 'origin_company_name' },
    { header: 'Origin Attention To', key: 'origin_attention_to' },
    { header: 'Origin Street Address', key: 'origin_street_address1' },
    { header: 'Origin City', key: 'origin_city' },
    { header: 'Origin State', key: 'origin_state' },
    { header: 'Destination Company Name', key: 'destination_company_name' },
    { header: 'Destination Attention To', key: 'destination_attention_to' },
    { header: 'Destination Street Address', key: 'destination_street_address1' },
    { header: 'Destination City', key: 'destination_city' },
    { header: 'Destination State', key: 'destination_state' },
    { header: 'Status', key: 'status_text' },
    { header: 'Created At', key: 'created_at' },
    { header: 'Carrier Service', key: 'carrier_service.name' },
    { header: 'Tracking ID', key: 'tracking_id' },
    { header: 'Pickup ID', key: 'pickup_id' },
    { header: 'Actions', key: 'actions', format: true },
  ];

  return (
    <>
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">Manifests</h1>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="status-sec">
                <div className="form-inline" style={{ display: 'flex' }}>
                  <div className="datepicker">
                    <input
                      type="date"
                      className="form-control dimensions length"
                      id="date"
                      name="date"
                      value={shipmentByDay}
                      onChange={e => setShipmentByDay(e.target.value)}
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    />
                  </div>
                  <button style={{ marginLeft: '10px' }} className="btn-danger btn shipment_filters_reset" onClick={() => setShipmentByDay('')}>Reset</button>
                </div>
              </div>
            </div>
            <div className="box-body main_listing">
              <CustomTable
                rows={rows}
                formatters={{
                  created_at: value => moment(value).format('DD MMM YYYY'),
                  actions: value => (
                    <div className="text-center" style={{ minWidth: '70px' }}>
                      {value?.zpl_file_attached && <button onClick={() => handlePrintZPL(value?.id)} className="fa fa-print pdf-icon" style={{ border: 'none', backgroundColor: 'transparent' }} />}
                      {value?.pdf_file_attached && <a href={`/admin/shipments/${value?.id}/show_html_label`} className="fa fa-file-picture-o pdf-icon" target="_blank" rel="noreferrer" />}
                      {value?.html_file_attached && <a href={`/admin/shipments/${value?.id}/show_pdf_label`} className="fa fa-file-pdf-o pdf-icon" target="_blank" rel="noreferrer" />}
                      <a href={value.is_pickup ? `/admin/pickups/${value?.id}` : `/admin/shipments/${value?.id}`} className="fa fa-eye edit_user" />
                      { isAdmin && !value.is_pickup && (
                        <i
                          className="fa fa-paper-plane"
                          aria-hidden="true"
                          onClick={() => {
                            setShowEmailModal(true);
                            setShipmentId(value?.id);
                          }}
                        />
                      )
                      }
                    </div>
                  ),
                }}
                rowsProps={getFields()}
                paginationOptions={{
                  ...paginationOptions,
                  handleChangePage,
                  handleChangeRowsPerPage,
                }}
                suffix="shipments"
              />
            </div>
          </div>
        </div>
        {
          showEmailModal && <SendEmailModal shipmentId={shipmentId} handleClose={() => setShowEmailModal(false)} />
        }
      </div>
    </>
  );
};

Manifests.propTypes = {
  date: PropTypes.string,
  isAdmin: PropTypes.bool,
  shipments: PropTypes.instanceOf(Array).isRequired,
  allLabelsCount: PropTypes.number.isRequired,
  defaultLimit: PropTypes.number.isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

Manifests.defaultProps = {
  date: '',
  isAdmin: false,
};

export default Manifests;
