import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

const AddRL2StopModal = ({
  handleClose, open, stops, handleSubmit,
}) => {
  const [stopId, setStopId] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const options = stops.map(s => ({
    value: s.pickup.id,
    label: `${s.route_manifest.id} - ${s.pickup.string_origin_new}`,
  }));

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Add RL2 Stop
      </DialogTitle>
      <DialogContent className={`preview-item-container ${isFocused ? 'container-height' : ''}`} id="preview-modal-dialog">
        <div className="select-airport">Select Stop</div>
        <Select
          options={options}
          multi={false}
          onChange={s => setStopId(s.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          blurInputOnSelect
          filterOption={createFilter({
            matchFrom: 'any',
            stringify: option => option.label,
          })}
        />
        <div className="modal-bottom-btn text-right" style={{ borderTop: 'none' }}>
          <button className="btn bg-olive" onClick={() => handleSubmit(stopId)}>Create</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={handleClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

AddRL2StopModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  stops: PropTypes.instanceOf(Array).isRequired,
  open: PropTypes.bool,
};

AddRL2StopModal.defaultProps = {
  open: false,
};

export default AddRL2StopModal;
