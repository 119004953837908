import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import api from '../../services/api';

const NetworkRouteModal = ({
  onClose, open, onSubmit, network, drivers,
}) => {
  const [route, setRoute] = useState({ rl_network_id: network.id });
  const [errors, setErrors] = useState({});
  const [airports, setAirports] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [pickupCount, setPickupCount] = useState({});
  const [showEmptyClusters, setShowEmptyClusters] = useState(false);

  const handleChange = (data) => {
    const { id: field, value } = data;
    setRoute({
      ...route,
      [field]: value,
    });
    setErrors({ ...errors, [field]: '' });
    if (field === 'airport_id' && value) {
      api.rlNetwork.getPackageClusters(value, network.id).then((res) => {
        setClusters(res.clusters);
        setPickupCount(res.pickup_count);
      });
    }
  };

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!route.airport_id) newErrors.airport_id = 'Please select airport';
    if (!route.package_cluster_id) newErrors.package_cluster_id = 'Please select cluster';
    setErrors(newErrors);
    return !Object.keys(newErrors).some(key => !isEmpty(newErrors[key]));
  }, [route]);

  const handleSubmit = () => {
    if (!validateForm()) return;

    onSubmit(route);
    onClose();
  };

  useEffect(() => {
    api.routesDashboard.getAirports().then(res => setAirports(res.airports));
  }, []);

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={onClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Create route
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="airport_id">
              Airport
              <span className="required">*</span>
            </label>
            <select
              name="airport_id"
              id="airport_id"
              className="form-control"
              value={route.airport_id}
              onChange={(e) => {
                handleChange(e.target);
                setErrors({ ...errors, airport_id: '' });
              }}
            >
              <option value="">Please select</option>
              {airports.map(item => <option key={item.id} value={item.id}>{`${item.code} - ${item.name}`}</option>)}
            </select>
            {errors?.airport_id && (
              <label className="error" data-testid="airport_error">
                {errors.airport_id}
              </label>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <label className="checkbox-label" onChange={() => setShowEmptyClusters(p => !p)}>
              <input type="checkbox" />
              Show empty clusters
              <span className="checkmark" />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="airport_id">
              Cluster
              <span className="required">*</span>
            </label>
            <select
              name="package_cluster_id"
              id="package_cluster_id"
              className="form-control"
              value={route.package_cluster_id}
              onChange={(e) => {
                handleChange(e.target);
                setErrors({ ...errors, package_cluster_id: '' });
              }}
            >
              <option value="">Please select</option>
              {
                clusters
                  .filter(item => showEmptyClusters || pickupCount[item.id])
                  .map(item => <option key={item.id} value={item.id}>{`${item.airport_code}@${item.id} - ${pickupCount[item.id] || 0} pickups`}</option>)
              }
            </select>
            {errors?.package_cluster_id && (
              <label className="error" data-testid="cluster_error">
                {errors.package_cluster_id}
              </label>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="airport_id">
              Driver
            </label>
            <select
              name="driver_id"
              id="driver_id"
              className="form-control"
              value={route.driver_id}
              onChange={(e) => {
                handleChange(e.target);
                setErrors({ ...errors, driver_id: '' });
              }}
            >
              <option value="">Please select</option>
              {drivers.map(driver => (
                <option key={driver[1]} value={driver[1]}>{driver[0]}</option>
              ))}
            </select>
            {errors?.driver_id && (
              <label className="error" data-testid="driver_error">
                {errors.driver_id}
              </label>
            )}
          </div>
        </div>
        <div className="modal-bottom-btn text-right">
          <button
            className="btn bg-olive"
            onClick={handleSubmit}
            data-testid="create_route"
          >
            Confirm
          </button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={onClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

NetworkRouteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  network: PropTypes.instanceOf(Object).isRequired,
  drivers: PropTypes.instanceOf(Array),
};

NetworkRouteModal.defaultProps = {
  open: false,
  drivers: [],
};

export default NetworkRouteModal;
