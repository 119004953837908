import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const ConversationDetails = ({
  convoName, participants, onBack, members, email,
}) => {
  const participantNumber = () => {
    if (!members.includes(email)) return participants.length;

    return participants.filter(p => members.includes(p.identity)).length;
  };

  return (
    <Box className="conversationDetails">
      <Box className="conversationDetailsInner">
        <div className="conversationDetailsItem left">
          <button className="btn btn-primary pull-right" onClick={onBack}>Go back</button>
        </div>
        <div className="conversationDetailsTitle">
          {convoName}
        </div>
        <div className="conversationDetailsItem right">
          {`${participantNumber()} ${participantNumber() > 1 ? ' participants' : ' participant'}`}
        </div>
      </Box>
    </Box>
  );
};

ConversationDetails.propTypes = {
  participants: PropTypes.instanceOf(Array),
  members: PropTypes.instanceOf(Array),
  onBack: PropTypes.func.isRequired,
  convoName: PropTypes.string,
  email: PropTypes.string,
};

ConversationDetails.defaultProps = {
  participants: [],
  members: [],
  convoName: '',
  email: '',
};

export default ConversationDetails;
