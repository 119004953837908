/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useEffect, useRef, useState, useLayoutEffect,
} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  makeStyles,
  TableSortLabel,
  TableFooter,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import TableScrollable from './TableScrollable';

const useStyles = makeStyles({
  tableCell: {
    fontSize: '1.5rem',
  },
  paper: { fontSize: '1.5rem' },
  root: {
    border: 0,
  },
});

function CustomTable(props) {
  const {
    rows, rowsProps, paginationOptions, sortOptions, setSelectedRows, selectedRows,
    handleSort, formatters, onRowClick, suffix, rightCheckbox, rowStyle, isPickupList,
    radiusFlexCarrierName, logoAsset, setFilter, filter, blockRowPePage,
  } = props;

  const [clickedHeader, setClickedHeader] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const classes = useStyles();
  const timepickerRef = useRef();
  const activeFilterRef = useRef();
  const filterComponentRef = useRef();
  const [activeFilterValue, setActiveFilterValue] = useState();
  const datepickerRef = useRef();
  const setupScrolls = () => {
    const scrollWidth = $(`#${suffix}-head`).width();
    $(`#top-horizontal-scroll-${suffix}`).width(scrollWidth);

    $(() => {
      $(`#${suffix}`).scroll(() => {
        $(`#top-horizontal-scrollbar-${suffix}`).scrollLeft($(`#${suffix}`).scrollLeft());
      });
      $(`#top-horizontal-scrollbar-${suffix}`).scroll(() => {
        $(`#${suffix}`).scrollLeft($(`#top-horizontal-scrollbar-${suffix}`).scrollLeft());
      });
    });
  };

  useEffect(() => {
    const onClick = e => !e.target?.classList?.contains('fa-filter') && !filterComponentRef?.current?.contains(e.target) && setShowFilter(false);
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [filterComponentRef, showFilter]);

  useEffect(() => {
    setupScrolls();

    $(document).on('shown.bs.modal', () => {
      setupScrolls();
    });

    $(window).on('resize', () => {
      setupScrolls();
    });

    const scroll = document.querySelector(`#${suffix}-head`);
    new ResizeObserver(setupScrolls).observe(scroll);
  }, []);

  const handleSelectRow = (row) => {
    const idx = selectedRows.findIndex(item => item.id === row.id);
    if (idx >= 0) {
      setSelectedRows(selectedRows.filter(selectedRow => selectedRow.id !== row.id));
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleSelectAll = () => {
    if (selectedRows.length > 0) {
      setSelectedRows([]);
    } else {
      setSelectedRows([...rows]);
    }
  };

  useLayoutEffect(() => {
    if (showFilter && timepickerRef.current) {
      setTimeout(() => {
        $(timepickerRef.current).datetimepicker({
          format: 'hh:mm a',
          ignoreReadonly: true,
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-arrow-up',
            down: 'fa fa-arrow-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-clock-o',
            clear: 'fa fa-trash-o',
          },
        });
      }, 10);
    }
  }, [timepickerRef.current, showFilter]);

  const renderCheckbox = row => selectedRows && (
    <TableCell align="left">
      <input
        type="checkbox"
        id={`ship-${row.id}`}
        checked={!!selectedRows.find(item => item.id === row.id)}
        onChange={() => handleSelectRow(row)}
      />
    </TableCell>
  );

  const renderHeaderCheckbox = () => selectedRows && (
    <TableCell style={{ position: 'sticky', top: 0, left: 0 }}>
      <input
        type="checkbox"
        id="ship-all"
        checked={selectedRows.length === rows.length}
        onChange={() => handleSelectAll()}
      />
    </TableCell>
  );

  const renderTableRow = (row, index) => (
    <TableRow key={row.id} style={rowStyle(row)} className={isPickupList && get(row, 'carrier.name') === radiusFlexCarrierName ? 'radius-flex-row' : ''}>
      {!rightCheckbox && renderCheckbox(row)}
      {rowsProps.map(({ key, clickSkip, format }) => {
        if (key === '#') {
          return (
            <TableCell
              key={key}
              align="left"
            >
              <div className="textContainer">
                {index + 1}
              </div>
            </TableCell>
          );
        }
        if (key === 'carrier.name' && get(row, key) === radiusFlexCarrierName && isPickupList) {
          return (
            <TableCell
              key={key}
              align="left"
              className="radius-flex-cell"
            >
              <div className="textContainer">
                <img height={50} width={100} alt="Radius Flex" src={logoAsset} />
              </div>
            </TableCell>
          );
        }
        return (
          <TableCell
            key={key}
            align="left"
            onClick={() => {
              if (onRowClick && !clickSkip) onRowClick(row);
            }}
          >
            <div className="textContainer" style={key === 'actions' ? { overflow: 'visible' } : {}}>
              {formatters[key] ? formatters[key](format ? row : get(row, key)) : get(row, key) || '-'}
            </div>
          </TableCell>
        );
      })}
      {rightCheckbox && renderCheckbox(row)}
    </TableRow>
  );

  const renderTableHeader = () => (
    <TableRow key="header">
      {!rightCheckbox && renderHeaderCheckbox()}
      {rowsProps.map(({
        header, sortKey, key, sortable, filtering, timeFilter, dateFilter, filterKey,
      }) => {
        const sortingKey = sortKey || key;
        const filteringKey = filterKey || key;
        return (
          <TableCell key={sortingKey} style={{ position: 'sticky', top: 0, left: 0 }} className={classes.tableCell} sortDirection={sortOptions.field === sortingKey ? sortOptions.type : false}>
            {sortable
              ? (
                <TableSortLabel
                  active={sortOptions.field === sortingKey}
                  direction={sortOptions.field === sortingKey ? sortOptions.type : 'asc'}
                  onClick={() => handleSort(sortingKey)}
                >
                  {header}
                </TableSortLabel>
              ) : (<span className="header-text">{header}</span>) }
            {timeFilter && (
              <>
                <i
                  className="fa fa-filter"
                  onClick={() => {
                    setClickedHeader(header);
                    setShowFilter(!showFilter);
                  }}
                />
                {clickedHeader === header && showFilter && (
                  <div className="header-search" ref={filterComponentRef}>
                    <input
                      ref={timepickerRef}
                      name="time_picker"
                      id="time_picker"
                      className="form-control dimensions length"
                      readOnly
                    />
                    <button
                      name="button"
                      type="submit"
                      style={{ marginRight: '10px', backgroundColor: '#285b8d', borderColor: '#285b8d' }}
                      title="Ok"
                      onClick={() => setFilter({ ...filter, [filteringKey]: timepickerRef.current.value })}
                    >
                      Ok
                    </button>
                    <button
                      name="button"
                      type="submit"
                      className="reset_driver_btn common_reset_btn"
                      title="Reset"
                      onClick={() => setFilter({ ...filter, [filteringKey]: '' })}
                    >
                      Reset
                    </button>
                  </div>
                )}
              </>
            )}
            {dateFilter && (
              <>
                <i
                  className="fa fa-filter"
                  onClick={() => {
                    setClickedHeader(header);
                    setShowFilter(!showFilter);
                  }}
                />
                {clickedHeader === header && showFilter && (
                  <div className="header-search" ref={filterComponentRef}>
                    <input
                      ref={datepickerRef}
                      type="date"
                      className="form-control dimensions length"
                      id="date_filter"
                      name="date_filter"
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    />
                    <button
                      name="button"
                      type="submit"
                      style={{ marginRight: '10px', backgroundColor: '#285b8d', borderColor: '#285b8d' }}
                      title="Ok"
                      onClick={() => setFilter({
                        ...filter,
                        [filteringKey]: new Date(datepickerRef.current.value).toLocaleDateString('en-GB', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        }),
                      })}
                    >
                      Ok
                    </button>
                    <button
                      name="button"
                      type="submit"
                      className="reset_driver_btn common_reset_btn"
                      title="Reset"
                      onClick={() => setFilter({ ...filter, [filteringKey]: '' })}
                    >
                      Reset
                    </button>
                  </div>
                )}
              </>
            )}
            {filtering && (
              <>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <i
                  className="fa fa-filter"
                  onClick={() => {
                    setClickedHeader(header);
                    setShowFilter(!showFilter);
                    setActiveFilterValue(filter[filteringKey]);
                  }}
                />
                { clickedHeader === header && showFilter && (
                  <div className="header-search" ref={filterComponentRef}>
                    <input
                      type="text"
                      name="header-input"
                      id="header-input"
                      placeholder="Input"
                      value={activeFilterValue}
                      ref={activeFilterRef}
                      onChange={e => setActiveFilterValue(e.target.value)}
                    />
                    <button
                      name="button"
                      type="submit"
                      style={{ marginRight: '10px', backgroundColor: '#285b8d', borderColor: '#285b8d' }}
                      title="Ok"
                      onClick={() => setFilter({ ...filter, [filteringKey]: activeFilterRef.current?.value || '' })}
                    >
                      Search
                    </button>
                    <button
                      name="button"
                      type="submit"
                      className="reset_driver_btn common_reset_btn"
                      title="Reset"
                      onClick={() => {
                        setActiveFilterValue('');
                        setFilter({ ...filter, [filteringKey]: '' });
                      }}
                    >
                      Reset
                    </button>
                  </div>
                )}
              </>
            )}
          </TableCell>
        );
      })}
      {rightCheckbox && renderHeaderCheckbox()}
    </TableRow>
  );

  const renderTableFooter = () => (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              id="table-pagination"
              className={classes.root}
              rowsPerPageOptions={blockRowPePage ? [] : [10, 20, 30, 40, 50, 100]}
              count={paginationOptions.count}
              rowsPerPage={paginationOptions.limit}
              page={paginationOptions.page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
                id: 'select-creative-count',
                labelId: 'select-creative-count-label',
                style: {
                  fontSize: '1.5rem',
                },
              }}
              onPageChange={paginationOptions.handleChangePage}
              onRowsPerPageChange={paginationOptions.handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );

  return (
    <div className="table-block">
      <TableScrollable suffix={suffix}>
        <TableContainer component={Paper} id={suffix} style={{ maxHeight: '90vh', minHeight: '500px' }}>
          <Table stickyHeader className="table" style={{ width: '100%' }}>
            <TableHead id={`${suffix}-head`}>
              {rowsProps && renderTableHeader()}
            </TableHead>
            <TableBody>
              {rows.length > 0 ? rows.map((row, index) => renderTableRow(row, index)) : <TableRow><TableCell align="center" colSpan={rowsProps?.length || 1}>No records found</TableCell></TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
      </TableScrollable>
      {paginationOptions && renderTableFooter()}
    </div>
  );
}

CustomTable.propTypes = {
  rows: PropTypes.instanceOf(Array).isRequired,
  paginationOptions: PropTypes.instanceOf(Object),
  formatters: PropTypes.instanceOf(Object),
  sortOptions: PropTypes.instanceOf(Object),
  rowStyle: PropTypes.instanceOf(Object),
  setSelectedRows: PropTypes.func,
  handleSort: PropTypes.func,
  onRowClick: PropTypes.func,
  selectedRows: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.bool]),
  rightCheckbox: PropTypes.bool,
  suffix: PropTypes.string.isRequired,
  isPickupList: PropTypes.bool,
  radiusFlexCarrierName: PropTypes.string,
  logoAsset: PropTypes.string,
  setFilter: PropTypes.func,
  filter: PropTypes.instanceOf(Object),
  blockRowPePage: PropTypes.bool,
  rowsProps: PropTypes.instanceOf(Array).isRequired,
};

CustomTable.defaultProps = {
  paginationOptions: null,
  formatters: {},
  sortOptions: {},
  rowStyle: () => {},
  setSelectedRows: () => {},
  handleSort: () => {},
  onRowClick: null,
  selectedRows: null,
  rightCheckbox: false,
  isPickupList: false,
  radiusFlexCarrierName: '',
  logoAsset: '',
  // isFilteringActive: false,
  setFilter: () => {},
  filter: {},
  blockRowPePage: false,
};

export default CustomTable;
