/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Client } from '@twilio/conversations';
import twilioApi from '../../services/twilioApi';
import { PaginationKeys } from '../../constants';

function include(permissions, includeValue) {
  // eslint-disable-next-line guard-for-in
  for (const itemIndex in permissions) {
    const item = permissions[itemIndex];
    if (item[0] === includeValue[0] && item[1] === includeValue[1]) return true;
  }
  return false;
}

function getUnreadMessages(token, setUnreadMessages) {
  const localClient = new Client(token);
  localClient.getSubscribedConversations()
    .then(async (conversations) => {
      let totalUnread = 0;
      const arrayOfPromises = await Promise.all(conversations.items.map(async (convo) => {
        const unread = (await convo.getUnreadMessagesCount()) ?? (await convo.getMessagesCount());
        if ($(`#${convo.uniqueName}`)) {
          if (unread > 0) {
            $(`#${convo.uniqueName}`).html(`<span style="position: relative;
                display: inline-block;
                top: -10px;
                left: -10px;
                border-radius: 50%;
                background-color: #285b8d;
                color: white;
                width: 20px;">${unread}</span>`);
          } else $(`#${convo.uniqueName}`).html('');
        }
        return unread;
      }));
      for (const resolvedPromise of arrayOfPromises) {
        totalUnread += resolvedPromise ?? 0;
      }
      setUnreadMessages(totalUnread);
    });
}

const Sidebar = ({
  permissions, currentUser, controller, userTypes, currentRoute, preferences,
}) => {
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [token, setToken] = useState();
  const records_per_page = preferences?.records_per_page ?? 10;

  useEffect(() => {
    twilioApi.getToken(currentUser.email).then(data => setToken(data.token));
  }, [currentUser]);


  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (token) {
      getUnreadMessages(token, setUnreadMessages);
      const interval = setInterval(() => getUnreadMessages(token, setUnreadMessages), 10000);
      return () => clearInterval(interval);
    }
  }, [token, controller]);
  // useEffect(()=>{

  // },[]);
  return (
    <aside className="main-sidebar">
      <section className="sidebar">
        <ul className="sidebar-menu" data-widget="tree">
          {currentUser.user_type_id !== userTypes.DRIVER_TYPE && (
            <li className={controller.controller_name === 'home' || controller.controller_name === 'total_shipments' ? 'active' : ''}>
              <a title="Dashboard" className="dashboard-icon" href="/admin">
                <span>Dashboard</span>
              </a>
            </li>
          )}
          {currentUser.user_type_id !== userTypes.SALES_ADMIN_TYPE
          && (include(permissions, ['UserType', 'view']) || include(permissions, ['Admin', 'view']) || include(permissions, ['Company', 'view']) || include(permissions, ['Warehouse', 'view mine'])
          || include(permissions, ['Warehouse', 'view others']) || include(permissions, ['Driver', 'view']) || include(permissions, ['Carrier', 'view']) || include(permissions, ['Airports', 'view'])
          || include(permissions, ['AddressBook', 'view mine']) || include(permissions, ['AddressBook', 'view others']) || include(permissions, ['DriverCoverage', 'view'])) && (
            <li className={(controller.controller_name === 'user_types' || controller.controller_name === 'users'
                || controller.controller_name === 'companies' || controller.controller_name === 'warehouses'
                || controller.controller_name === 'drivers' || controller.controller_name === 'carriers'
                || controller.controller_name === 'airports' || controller.controller_name === 'driver_coverage'
                || controller.controller_name === 'regions' || controller.controller_name === 'region_price_tables'
                || controller.controller_name === 'notifications' || controller.controller_name === 'driver_rates'
                || controller.controller_name === 'address_book') && controller.controller_name !== 'edit_profile'
                && controller.action_name !== 'change_password' ? 'treeview active menu-open' : 'treeview'}
            >
              <a href="#" className="sidebar-dropdown">
                <span title="Admin Dashboard">Admin Dashboard</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </a>
              <ul className="treeview-menu">
                {(currentUser.user_type_id === userTypes.SUPER_ADMIN_TYPE || currentUser.user_type_id === userTypes.OWNER_TYPE) && (
                  <li className={controller.controller_name === 'accounts' ? 'active' : ''}>
                    <a title="Accounts" className="dropdown-circle" href="/admin/accounts">
                      <span>Accounts</span>
                    </a>
                  </li>
                )}
                {include(permissions, ['UserType', 'view']) && (
                  <li className={controller.controller_name === 'user_types' ? 'active' : ''}>
                    <a title="User Types" className="dropdown-circle" href={`/admin/user_types?limit=${localStorage.getItem(PaginationKeys.userTypes) || records_per_page}`}>
                      <span>User Types</span>
                    </a>
                  </li>
                )}
                {include(permissions, ['Admin', 'view']) && (
                  <li className={(controller.controller_name === 'users' && controller.action_name !== 'edit_profile' && controller.action_name !== 'change_password') ? 'active' : ''}>
                    <a title="Users" className="dropdown-circle" href={`/admin/users?limit=${localStorage.getItem(PaginationKeys.users) || records_per_page}`}>
                      <span>Users</span>
                    </a>
                  </li>
                )}
                {include(permissions, ['Company', 'view']) && (
                  <li className={controller.controller_name === 'companies' ? 'active' : ''}>
                    <a title="Companies" className="dropdown-circle" href={`/admin/companies?limit=${localStorage.getItem(PaginationKeys.companies) || records_per_page}`}>
                      <span>Companies</span>
                    </a>
                  </li>
                )}
                {(include(permissions, ['AddressBook', 'view mine']) || include(permissions, ['AddressBook', 'view others'])) && (
                  <li className={controller.controller_name === 'address_book' ? 'active' : ''}>
                    <a title="Address Book" className="dropdown-circle" href="/admin/address_book">
                      <span>Address Book</span>
                    </a>
                  </li>
                )}
                {(include(permissions, ['Warehouse', 'view mine']) || include(permissions, ['Warehouse', 'view others'])) && (
                  <li className={controller.controller_name === 'warehouses' ? 'active' : ''}>
                    <a title="Warehouses" className="dropdown-circle" href="/admin/warehouses">
                      <span>Warehouses</span>
                    </a>
                  </li>
                )}
                {include(permissions, ['Driver', 'view']) && (
                  <li className={controller.controller_name === 'drivers' ? 'active' : ''}>
                    <a title="Drivers" className="dropdown-circle" href={`/admin/drivers?limit=${localStorage.getItem(PaginationKeys.drivers) || records_per_page}`}>
                      <span>Drivers</span>
                    </a>
                  </li>
                )}
                {include(permissions, ['Carrier', 'view']) && (
                  <li className={controller.controller_name === 'carriers' ? 'active' : ''}>
                    <a title="Carriers" className="dropdown-circle" href="/admin/carriers">
                      <span>Carriers</span>
                    </a>
                  </li>
                )}
                {include(permissions, ['Airports', 'view']) && (
                  <li className={controller.controller_name === 'airports' ? 'active' : ''}>
                    <a title="Airports" className="dropdown-circle" href={`/admin/airports?limit=${localStorage.getItem(PaginationKeys.airports) || records_per_page}`}>
                      <span>Airports</span>
                    </a>
                  </li>
                )}
                {include(permissions, ['DriverCoverage', 'view']) && (
                  <li className={controller.controller_name === 'driver_coverage' ? 'active' : ''}>
                    <a title="Driver Coverage" className="dropdown-circle" href={`/admin/driver_coverage?limit=${localStorage.getItem(PaginationKeys.driverCoverage) || records_per_page}`}>
                      <span>Driver Coverage</span>
                    </a>
                  </li>
                )}
                {(currentUser.user_type_id === userTypes.SUPER_ADMIN_TYPE || currentUser.user_type_id === userTypes.OWNER_TYPE) && (
                  <>
                    <li hidden className={controller.controller_name === 'regions' ? 'active' : ''}>
                      <a title="Regions" className="dropdown-circle" href="/admin/regions">
                        <span>Regions</span>
                      </a>
                    </li>
                    <li hidden className={controller.controller_name === 'region_price_tables' ? 'active' : ''}>
                      <a title="Region Price Tables" className="dropdown-circle" href="/admin/region_price_tables">
                        <span>Region Price Tables</span>
                      </a>
                    </li>
                    <li hidden className={controller.controller_name === 'zone_price_tables' ? 'active' : ''}>
                      <a title="Zone Price Tables" className="dropdown-circle" href="/admin/zone_price_tables">
                        <span>Zone Price Tables</span>
                      </a>
                    </li>
                    <li className={controller.controller_name === 'zone_price_tables' ? 'active' : ''}>
                      <a title="Shipment Pricing Constants Tables" className="dropdown-circle" href="/admin/customer_price_constants_tables">
                        <span>Shipment Pricing</span>
                      </a>
                    </li>
                    <li className={controller.controller_name === 'notifications' ? 'active' : ''}>
                      <a title="Notifications" className="dropdown-circle" href="/admin/notifications">
                        <span>Notifications</span>
                      </a>
                    </li>
                    <li className={controller.controller_name === 'driver_rates' ? 'active' : ''}>
                      <a title="Driver Rates" className="dropdown-circle" href={`/admin/driver_rates?limit=${localStorage.getItem(PaginationKeys.driverRates) || records_per_page}`}>
                        <span>Driver Rates</span>
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </li>
          )}
          {(include(permissions, ['ScheduledPickup', 'view mine']) || include(permissions, ['ScheduledPikcup', 'view others'])) && (
            <li className={['scheduled_pickups', 'rl_networks'].includes(controller.controller_name) ? 'active menu-open treeview' : 'treeview'}>
              <a href="#" className="sidebar-dropdown">
                <span title="Pickup Dashboard">
                  RL Dashboard
                  <br />
                  {' '}
                  Network
                </span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </a>
              <ul className="treeview-menu">
                {include(permissions, ['RLNetwork', 'view']) && (
                  <li className={controller.controller_name === 'rl_networks' && controller.action_name !== 'new' ? 'active' : ''}>
                    <a title="RL Networks" className="dropdown-circle" href="/admin/rl_networks">
                      <span>RL Networks</span>
                    </a>
                  </li>
                )}
                {include(permissions, ['ScheduledPickup', 'add']) && (
                  <li className={controller.controller_name === 'scheduled_pickups' && controller.action_name === 'new' ? 'active' : ''}>
                    <a title="Schedule Pickup" className="dropdown-circle" href="/admin/scheduled_pickups/new">
                      <span>Schedule Pickup</span>
                    </a>
                  </li>
                )}
                {(include(permissions, ['ScheduledPickup', 'view mine']) || include(permissions, ['ScheduledPickup', 'view others'])) && (
                  <li className={controller.controller_name === 'scheduled_pickups' && controller.action_name !== 'new' ? 'active' : ''}>
                    <a title="Scheduled Pickups" className="dropdown-circle" href="/admin/scheduled_pickups">
                      <span>Scheduled Pickups</span>
                    </a>
                  </li>
                )}
              </ul>
            </li>
          )}
          {(include(permissions, ['Pickup', 'view mine']) || include(permissions, ['Pickup', 'view view others']) || include(permissions, ['MyPickupDashboard', 'view'])
          || include(permissions, ['RouteManifest', 'view']) || include(permissions, ['PickupsScan', 'view']) || include(permissions, ['Labels', 'view'])) && (
            <li className={controller.controller_name === 'my_pickup_dashboard' || controller.controller_name === 'pickups' || controller.controller_name === 'pickup_dashboard' || controller.controller_name === 'pickups_scan' ? 'treeview active menu-open' : 'treeview'}>
              <a href="#" className="sidebar-dropdown">
                <span title="Pickup Dashboard">Pickup Dashboard</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </a>
              <ul className="treeview-menu">
                {include(permissions, ['Pickup', 'add']) && (
                  <li className={controller.controller_name === 'pickups' && controller.action_name === 'new' ? 'active' : ''}>
                    <a title="Add Pickup" className="dropdown-circle" href="/admin/pickups/new">
                      <span>Add Pickup</span>
                    </a>
                  </li>
                )}
                {(include(permissions, ['Pickup', 'view mine']) || include(permissions, ['Pickup', 'view others'])) && (
                  <li className={(controller.controller_name === 'pickups' || controller.controller_name === 'pickup_dashboard') && !['new', 'analytics', 'confirm_pickups'].includes(controller.action_name) ? 'active' : ''}>
                    <a title="Pickups" className="dropdown-circle" href="/admin/pickup_dashboard">
                      <span>Pickups</span>
                    </a>
                  </li>
                )}
                {(currentUser.user_type_id === userTypes.SALES_ADMIN_TYPE) && (
                  <li className={(controller.controller_name === 'pickups' || controller.controller_name === 'pickup_dashboard') && ['confirm_pickups'].includes(controller.action_name) ? 'active' : ''}>
                    <a title="Pickups" className="dropdown-circle" href="/admin/pickup_dashboard/confirm_pickups">
                      <span>Approvals</span>
                    </a>
                  </li>
                )}
                {include(permissions, ['PickupsScan', 'view']) && (
                  <li className={controller.controller_name === 'pickups_scan' ? 'active' : ''}>
                    <a title="Pickups Scan" className="dropdown-circle" href={`/admin/pickups_scan?limit=${localStorage.getItem(PaginationKeys.pickupScans) || records_per_page}`}>
                      <span>Pickups Scan</span>
                    </a>
                  </li>
                )}
                {include(permissions, ['MyPickupDashboard', 'view']) && (
                  <li className={controller.controller_name === 'my_pickup_dashboard' ? 'active' : ''}>
                    <a title="My Pickup Dashboard" className="dropdown-circle" href={`/admin/my_pickup_dashboard?limit=${localStorage.getItem(PaginationKeys.myPickupDashboard) || records_per_page}`}>
                      <span className="small-font">My Pickup Dashboard</span>
                    </a>
                  </li>
                )}
                {(include(permissions, ['Pickup', 'view mine']) || include(permissions, ['Pickup', 'view others'])) && (
                  <li className={controller.controller_name === 'pickup_dashboard' && controller.action_name === 'analytics' ? 'active' : ''}>
                    <a title="Pickup Analytics" className="dropdown-circle" href="/admin/pickup_dashboard/analytics">
                      <span>Analytics</span>
                    </a>
                  </li>
                )}
              </ul>
            </li>
          )}
          {(include(permissions, ['Shipment', 'view mine']) || include(permissions, ['Shipment', 'view view others']) || include(permissions, ['BatchShipment', 'view'])) && (
            <li className={controller.controller_name === 'shipments' || controller.controller_name === 'shipment_dashboard'
                || controller.controller_name === 'fedex_package_scans' || controller.controller_name === 'batch_shipments' ? 'active menu-open treeview' : 'treeview'}
            >
              <a href="#" className="sidebar-dropdown">
                <span title="Shipment Dashboard">Shipment Dashboard</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </a>
              <ul className="treeview-menu">
                {include(permissions, ['Shipment', 'add']) && (
                  <li className={controller.controller_name === 'shipments' && controller.action_name === 'ship' ? 'active' : ''}>
                    <a title="Add Shipment" className="dropdown-circle" href="/admin/shipments/ship">
                      <span>Add Shipment</span>
                    </a>
                  </li>
                )}
                {(include(permissions, ['Shipment', 'view mine']) || include(permissions, ['Shipment', 'view others'])) && (
                  <>
                    <li className={(controller.controller_name === 'shipment_dashboard' && controller.action_name === 'parent_shipments') ? 'active' : ''}>
                      <a title="Shipments" className="dropdown-circle" href="/admin/shipment_dashboard/parent_shipments">
                        <span>Parent Shipments</span>
                      </a>
                    </li>
                    <li className={(controller.controller_name === 'shipments' && controller.action_name !== 'total_shipments' && controller.action_name !== 'track'
                      && controller.action_name !== 'ship' && controller.action_name !== 'invoices') || (controller.controller_name === 'shipment_dashboard' && !['invoices', 'analytics', 'parent_shipments'].includes(controller.action_name)) ? 'active' : ''}
                    >
                      <a title="Shipments" className="dropdown-circle" href="/admin/shipment_dashboard">
                        <span>Shipments</span>
                      </a>
                    </li>
                  </>
                )}
                {include(permissions, ['BatchShipment', 'view']) && (
                  <li className={controller.controller_name === 'batch_shipments' ? 'active' : ''}>
                    <a title="Batch Shipments" className="dropdown-circle" href={`/admin/batch_shipments?limit=${localStorage.getItem(PaginationKeys.batchShipments) || records_per_page}`}>
                      <span>Batch Shipments</span>
                    </a>
                  </li>
                )}
                <li className={controller.controller_name === 'shipments' && controller.action_name === 'track' ? 'active' : ''}>
                  <a title="Track Shipments" className="dropdown-circle" href="/admin/shipments/track">
                    <span>Track Shipments</span>
                  </a>
                </li>
                {(currentUser.user_type_id === userTypes.SUPER_ADMIN_TYPE || currentUser.user_type_id === userTypes.OWNER_TYPE) && (
                  <>
                    <li className={controller.controller_name === 'fedex_package_scans' ? 'active' : ''}>
                      <a title="Fedex Package Scans" className="dropdown-circle" href={`/admin/fedex_package_scans?limit=${localStorage.getItem(PaginationKeys.fedexPackageScans) || records_per_page}`}>
                        <span className="small-font">Fedex Package Scans</span>
                      </a>
                    </li>
                    <li className={controller.action_name === 'invoices' ? 'active' : ''}>
                      <a title="Invoices" className="dropdown-circle" href="/admin/shipment_dashboard/invoices">
                        <span>Invoices</span>
                      </a>
                    </li>
                    <li className={controller.action_name === 'manifests' ? 'active' : ''}>
                      <a title="Manifests" className="dropdown-circle" href="/admin/shipment_dashboard/manifests">
                        <span>Manifests</span>
                      </a>
                    </li>
                  </>
                )}
                {(include(permissions, ['Shipment', 'view mine']) || include(permissions, ['Shipment', 'view others'])) && (
                  <li className={controller.controller_name === 'shipment_dashboard' && controller.action_name === 'analytics' ? 'active' : ''}>
                    <a title="Shipment Analytics" className="dropdown-circle" href="/admin/shipment_dashboard/analytics">
                      <span>Analytics</span>
                    </a>
                  </li>
                )}
              </ul>
            </li>
          )}
          {(include(permissions, ['Flight', 'view'])) && (
            <li className={controller.controller_name === 'flights' ? 'active menu-open treeview' : 'treeview'}>
              <a href="#" className="sidebar-dropdown">
                <span title="Flights">Flights</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </a>
              <ul className="treeview-menu">
                <li className={controller.controller_name === 'flights' ? 'active' : ''}>
                  <a title="Flights" className="dropdown-circle" href="/admin/flights/operation_tracker">
                    <span className="small-font">Flight Operation tracker</span>
                  </a>
                </li>
              </ul>
            </li>
          )}
          {(include(permissions, ['FlightRoutes', 'view']) || include(permissions, ['ClusterFlightRoutes', 'view'])) && (
            <li className={controller.controller_name === 'consolidation_labels' || controller.controller_name === 'flight_routes'
                          || controller.controller_name === 'cluster_flight_routes' ? 'active menu-open treeview' : 'treeview'}
            >
              <a href="#" className="sidebar-dropdown">
                <span title="Flights Dashboard">Flights Dashboard</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </a>
              <ul className="treeview-menu">
                {include(permissions, ['FlightRoutes', 'view']) && (
                  <li className={controller.controller_name === 'flight_routes' ? 'active' : ''}>
                    <a title="Flight Routes" className="dropdown-circle" href={`/admin/flight_routes?limit=${localStorage.getItem(PaginationKeys.flightRoutes) || records_per_page}`}>
                      <span>Flight Routes</span>
                    </a>
                  </li>
                )}
                {include(permissions, ['ClusterFlightRoutes', 'view']) && (
                  <li className={controller.controller_name === 'cluster_flight_routes' ? 'active' : ''}>
                    <a title="Flight Route Cluster" className="dropdown-circle" href={`/admin/cluster_flight_routes?limit=${localStorage.getItem(PaginationKeys.flightRouteClusters) || records_per_page}`}>
                      <span>Flight Route Cluster</span>
                    </a>
                  </li>
                )}
                {include(permissions, ['ConsolidationLabels', 'view']) && (
                  <li className={controller.controller_name === 'consolidation_labels' ? 'active' : ''}>
                    <a title="Consolidation Bags" className="dropdown-circle" href="/admin/consolidation_labels">
                      <span>Consolidation Bags</span>
                    </a>
                  </li>
                )}
              </ul>
            </li>
          )}
          <li className={controller.controller_name === 'route_manifests' || controller.controller_name === 'dl_routes' ? 'active menu-open treeview' : 'treeview'}>
            <a href="#" className="sidebar-dropdown">
              <span title="Routes Dashboard">Routes Dashboard</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-left pull-right" />
              </span>
            </a>
            <ul className="treeview-menu">
              {include(permissions, ['RouteManifest', 'view']) && (
                <>
                  <li className={controller.controller_name === 'route_manifests' && !['generate_rl2_manifest_index', 'rl2_routes'].includes(controller.action_name) && currentRoute.route_manifest_type !== 'RL2' ? 'active' : ''}>
                    <a title="RL Routes" className="dropdown-circle" href={`/admin/route_manifests?limit=${localStorage.getItem(PaginationKeys.rl1Routes) || records_per_page}`}>
                      <span>RL Routes</span>
                    </a>
                  </li>
                  <li className={controller.controller_name === 'route_manifests' && (controller.action_name === 'rl2_routes' || (controller.action_name === 'show' && currentRoute.route_manifest_type === 'RL2')) ? 'active' : ''}>
                    <a title="RL2 Routes" className="dropdown-circle" href="/admin/route_manifests/rl2_routes">
                      <span>RL2 Routes</span>
                    </a>
                  </li>
                  <li className={controller.controller_name === 'dl_routes' ? 'active' : ''}>
                    <a title="DL Routes" className="dropdown-circle" href={`/admin/dl_routes?limit=${localStorage.getItem(PaginationKeys.dlRoutes) || records_per_page}`}>
                      <span>DL Routes</span>
                    </a>
                  </li>
                </>
              )}
              {(currentUser.user_type_id === userTypes.SUPER_ADMIN_TYPE || currentUser.user_type_id === userTypes.OWNER_TYPE) && (
                <>
                  <li className={controller.controller_name === 'route_generation_tools' ? 'active' : ''}>
                    <a title="Route generation tools" className="dropdown-circle" href="/admin/route_generation_tools">
                      <span className="small-font">Route generation tools</span>
                    </a>
                  </li>
                  <li className={controller.controller_name === 'route_manifests' && controller.action_name === 'generate_rl2_manifest_index' ? 'active' : ''}>
                    <a title="Generate RL2 Route" className="dropdown-circle" href="/admin/route_manifests/generate_rl2_manifest_index">
                      <span>Generate RL2 Route</span>
                    </a>
                  </li>
                </>
              )}
            </ul>
          </li>
          {currentUser.user_type_id === userTypes.SALES_ADMIN_TYPE && (
            <>
              {(include(permissions, ['Warehouse', 'view mine']) || include(permissions, ['Warehouse', 'view others'])) && (
                <li className={controller.controller_name === 'warehouses' ? 'active' : ''}>
                  <a title="Warehouses" className="dropdown-circle" href="/admin/warehouses">
                    <span>Warehouses</span>
                  </a>
                </li>
              )}
              {(include(permissions, ['AddressBook', 'view mine']) || include(permissions, ['AddressBook', 'view others'])) && (
                <li className={controller.controller_name === 'address_book' ? 'active' : ''}>
                  <a title="Address Book" className="dropdown-circle" href="/admin/address_book">
                    <span>Address Book</span>
                  </a>
                </li>
              )}
            </>
          )}
          {include(permissions, ['Report', 'view']) && (
            <li className={controller.controller_name === 'reports' ? 'active menu-open treeview' : 'treeview'}>
              <a href="#" className="sidebar-dropdown">
                <span title="Reports">Reports</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </a>
              <ul className="treeview-menu">
                <li className={controller.action_name === 'index' ? 'active' : ''}>
                  <a title="Reports" className="dropdown-circle" href="/admin/reports">
                    <span>Reports</span>
                  </a>
                </li>
                <li className={controller.action_name === 'routes_report' ? 'active' : ''}>
                  <a title="Routes Report" className="dropdown-circle" href="/admin/reports/routes_report">
                    <span>Routes Report</span>
                  </a>
                </li>
              </ul>
            </li>
          )}
          {(currentUser.user_type_id === userTypes.SUPER_ADMIN_TYPE || currentUser.user_type_id === userTypes.OWNER_TYPE) && (
            <li className={controller.controller_name === 'payments' ? 'active' : ''}>
              <a title="Payments" className="payment-icon" href={`/admin/payments?limit=${localStorage.getItem(PaginationKeys.payments) || records_per_page}`}>
                <span>Payments</span>
              </a>
            </li>
          )}
          {(currentUser.user_type_id === userTypes.SUPER_ADMIN_TYPE || currentUser.user_type_id === userTypes.OWNER_TYPE) && (
            <li className={controller.controller_name === 'twilio_chat' ? 'active' : ''}>
              <a title="Drivers Communication" className="letter-icon" href="/admin/twilio_chat">
                <span className="small-font">
                  Drivers Communication
                  {Boolean(unreadMessages > 0 && controller.controller_name !== 'twilio_chat') && (
                    <span style={{
                      display: 'inline-block',
                      color: 'white',
                      textAlign: 'center',
                      borderRadius: '50%',
                      width: '20px',
                      backgroundColor: '#204b81',
                    }}
                    >
                      {' '}
                      {unreadMessages}
                    </span>
                  )}
                </span>
              </a>
            </li>
          )}
          {(currentUser.user_type_id === userTypes.SUPER_ADMIN_TYPE || currentUser.user_type_id === userTypes.OWNER_TYPE) && (
            <li className={controller.controller_name === 'stripe_settings' ? 'active' : ''}>
              <a title="App Settings" className="stripe-icon" href={`/admin/stripe_settings?limit=${localStorage.getItem(PaginationKeys.settings) || records_per_page}`}>
                <span>App Settings</span>
              </a>
            </li>
          )}
          {include(permissions, ['USerPrinter', 'view']) && (
            <li className={controller.controller_name === 'user_printers' ? 'active' : ''}>
              <a title="Printer Settings" className="print-icon" href="/admin/user_printers">
                <span>Printer Settings</span>
              </a>
            </li>
          )}
          {include(permissions, ['CmsPage', 'view']) && (
            <li className={controller.controller_name === 'cms_pages' ? 'active' : ''}>
              <a title="Cms Pages" className="ship-icon" href={`/admin/cms_pages?limit=${localStorage.getItem(PaginationKeys.cmpPages) || records_per_page}`}>
                <span>Cms Pages</span>
              </a>
            </li>
          )}
        </ul>
      </section>
    </aside>
  );
};

Sidebar.propTypes = {
  currentUser: PropTypes.instanceOf(Object).isRequired,
  controller: PropTypes.instanceOf(Object).isRequired,
  permissions: PropTypes.instanceOf(Object).isRequired,
  userTypes: PropTypes.instanceOf(Object).isRequired,
  currentRoute: PropTypes.instanceOf(Object).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

export default Sidebar;
