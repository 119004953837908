import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import api from '../../services/api';

const SendEmailModal = ({
  handleClose, shipmentId,
}) => {
  const [attachPDF, setAttachPDF] = useState(true);
  const [to, setTo] = useState('');
  const [subject, setSubject] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleEditorStateChange = (state) => {
    setEditorState(state);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      to,
      subject,
      shipment_id: shipmentId,
      body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      attach_pdf: attachPDF,
    };
    const response = await api.shipmentDashboard.sendEmail(payload);

    if (response.ok) {
      handleClose();
    }
  };

  return (
    <Dialog
      open
      className="dialog"
      aria-labelledby="preview-modal-title"
      onClose={handleClose}
    >
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-12 form-group">
              <label htmlFor="to">To</label>
              <input
                className="form-control"
                id="to"
                name="to"
                type="text"
                value={to}
                onChange={e => setTo(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <label htmlFor="subject">Subject</label>
              <input
                className="form-control"
                id="subject"
                name="subject"
                type="text"
                value={subject}
                onChange={e => setSubject(e.target.value)}
                required
              />
            </div>
          </div>
          <label htmlFor="mail-body">Mail Body</label>
          <div id="mail-body">
            <Editor
              editorState={editorState}
              editorClassName="editor-class"
              onEditorStateChange={handleEditorStateChange}
            />
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <label htmlFor="attach-pdf">Attach PDF?</label>
              <input id="attach-pdf" className="form-group" type="checkbox" onChange={() => setAttachPDF(!attachPDF)} checked={attachPDF} />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">Send</button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

SendEmailModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  shipmentId: PropTypes.string.isRequired,
};

export default SendEmailModal;
