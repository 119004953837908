import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Toast from '../common/Toast';
import api from '../../services/api';
import { CHILD_SHIPMENT_FIELDS } from '../../services/helpers';
import ChildShipmentModal from './ChildShipmentModal';
import ShipmentDetailsInner from './ShipmentDetailsInner';
import FieldsModal from '../common/FieldsModal';
import ActualShipmentsTable from './ActualShipmentsTable';
import { PaginationKeys } from '../../constants';

const ShipmentDetails = ({
  data, isAdmin, returnData, outboundData, permission, timezoneFromServer, userId, countryId, preferences,
}) => {
  const getFields = () => [
    { header: 'Tracking Comment', key: 'tracking_comment' },
    { header: 'Created by', key: 'user' },
    { header: 'Ship date', key: 'ship_date' },
    { header: 'Origin Company Name', key: 'origin_company_name' },
    { header: 'Origin Attention To', key: 'origin_attention_to' },
    { header: 'Origin Street Address', key: 'origin_street_address1' },
    { header: 'Origin City', key: 'origin_city' },
    { header: 'Origin State', key: 'origin_state' },
    { header: 'Destination Company Name', key: 'destination_company_name' },
    { header: 'Destination Attention To', key: 'destination_attention_to' },
    { header: 'Destination Street Address', key: 'destination_street_address1' },
    { header: 'Destination City', key: 'destination_city' },
    { header: 'Destination State', key: 'destination_state' },
    { header: 'Status', key: 'status_text' },
    { header: 'Created At', key: 'created_at' },
    { header: 'Carrier Service', key: 'carrier_service.name' },
    { header: 'Tracking ID', key: 'tracking_id' },
    { header: 'Pickup ID', key: 'pickup_id' },
    { header: 'Actions', key: 'actions', format: true },
  ];
  const [children, setChildren] = useState([]);
  const [shipChildModal, setShipChildModal] = useState(false);
  const [shipmentByDay, setShipmentByDay] = useState('');
  const [shipmentCreatedDay, setShipmentCreatedDay] = useState('');
  const [search, setSearch] = useState('');
  const [statusResult, setStatusResult] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    page: 0,
    count: 0,
    limit: parseInt(window.localStorage.getItem(PaginationKeys.childrenShipments), 10) || preferences?.records_per_page || 10,
  });
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState(getFields());
  const [serverTimezone, setServerTimezone] = useState('America/Chicago');

  useEffect(() => {
    const tz = timezoneFromServer?.info?.identifier;
    if (tz) {
      setServerTimezone(tz);
    } else {
      setServerTimezone('America/Chicago');
    }
  }, [timezoneFromServer]);

  const handleFilter = useCallback(({ page, limit, query }) => {
    if (!data.is_parent) return;

    api.shipmentDashboard.getChildren({
      filter_day: shipmentByDay,
      created_day: shipmentCreatedDay,
      filter_status: statusResult,
      search_field: query !== undefined ? query : search,
      id: data.id,
      page: page !== undefined ? page : paginationOptions.page,
      limit: limit || paginationOptions.limit,
    })
      .then((res) => {
        setChildren(res?.shipments);
        setPaginationOptions({
          count: res.all_records,
          page: page || 0,
          limit: limit || paginationOptions.limit,
        });
      })
      .catch(err => console.log(err));
  }, [paginationOptions, data, shipmentByDay, shipmentCreatedDay, statusResult, search]);

  const handleReset = () => {
    setShipmentByDay('');
    setStatusResult('');
    setShipmentCreatedDay('');
  };

  const handleUpdateFields = (updatedFields, save = true) => {
    setSelectedFields(getFields().filter(f => updatedFields.includes(f.key)));
    setShowFieldsModal(false);
    if (save) window.localStorage.setItem(CHILD_SHIPMENT_FIELDS, updatedFields.join(','));
  };

  useEffect(() => {
    const savedFields = window.localStorage.getItem(CHILD_SHIPMENT_FIELDS);
    if (!savedFields) return;

    handleUpdateFields(savedFields.split(','), false);
  }, []);

  useEffect(() => {
    handleFilter({ page: 0 });
  }, [shipmentByDay, shipmentCreatedDay, statusResult]);

  return (
    <div>
      <Toast />
      <div className="row">
        <div className="col-xs-12">
          <div className="box">
            <div className="box-header">
              <h1 className="box-title">
                Shipment Details
              </h1>
              <div className="pull-right">
                <button className="btn btn-primary" onClick={() => setShowFieldsModal(true)}>Change selected fields</button>
                <a className="btn btn-primary" style={{ marginLeft: 5 }} href="/admin/shipment_dashboard">All Shipments</a>
                <a className="btn btn-primary" style={{ marginLeft: 5 }} href="/admin/shipment_dashboard/summary">Shipments Summary</a>
                {permission.shipment?.add && <a className="btn  margin add-btn" style={{ marginLeft: 5 }} href="/admin/shipments/ship">Add Shipment</a>}
                {data.is_parent && permission.shipment?.add && <button className="btn bg-olive btn-primary" style={{ marginLeft: 5 }} onClick={() => setShipChildModal(true)} data-testid="add-child-ship">Add Child Shipment</button>}
              </div>
            </div>
            {outboundData && <div className="box-header"><h1 className="box-title">Return Details</h1></div>}
            {returnData && <div className="box-header"><h1 className="box-title">Outbound Details</h1></div>}
            <ShipmentDetailsInner data={data} prefix="main" />
            {outboundData && (
              <>
                <div className="box-header">
                  <h1 className="box-title">Outbound Details</h1>
                </div>
                <ShipmentDetailsInner data={outboundData} prefix="sub" />
              </>
            )}
            {returnData && (
              <>
                <div className="box-header">
                  <h1 className="box-title">Return Details</h1>
                </div>
                <ShipmentDetailsInner data={returnData} prefix="sub" />
              </>
            )}
            {data.is_parent && (
              <div className="box-body">
                <div style={{ marginTop: 15, marginBottom: 10 }}>
                  <div className="form-inline" style={{ display: 'flex' }}>
                    <div className="datepicker" style={{ marginRight: 15 }}>
                      <label style={{ marginRight: 5 }}>Ship date:</label>
                      <input
                        type="date"
                        className="form-control dimensions length"
                        id="date"
                        name="date"
                        value={shipmentByDay}
                        onChange={(e) => {
                          setShipmentByDay(e.target.value);
                        }}
                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                      />
                    </div>
                    <div className="datepicker">
                      <label style={{ marginRight: 5 }}>Created at:</label>
                      <input
                        type="date"
                        className="form-control dimensions length"
                        id="ship_created_at"
                        name="ship_created_at"
                        value={shipmentCreatedDay}
                        onChange={(e) => {
                          setShipmentCreatedDay(e.target.value);
                        }}
                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                      />
                    </div>
                    <select
                      name="bag_status"
                      id="bag_status"
                      className="form-control"
                      value={statusResult}
                      onChange={(e) => {
                        setStatusResult(e.target.value);
                      }}
                    >
                      <option value="">Tracking status results</option>
                      <option value="label_created">Label Created</option>
                      <option value="rl1_driver">RL1 Driver</option>
                      <option value="rl2_driver">RL2 Driver</option>
                      <option value="origin_airport">Departure Airport</option>
                      <option value="destination_airport">Destination Airport</option>
                      <option value="delivered">Delivered</option>
                      <option value="dl2_driver">DL2 Driver</option>
                      <option value="dl1_driver">DL1 Driver</option>
                      <option value="exception">Exception</option>
                      <option value="not_found">Not found</option>
                    </select>
                    <button style={{ marginLeft: '10px' }} className="btn-danger btn shipment_filters_reset" onClick={handleReset}>Reset</button>
                  </div>
                </div>
                <div style={{ marginBottom: 10 }}>
                  <div className="form-inline">
                    <div className="input-group">
                      <input
                        className="form-control shipment_search"
                        placeholder="Search"
                        value={search}
                        onKeyUp={e => (e.key === 'Enter' && handleFilter({ page: 0 }))}
                        onChange={e => setSearch(e.target.value)}
                      />
                      {/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                      <span
                        className="search-clear fa fa-times"
                        onClick={() => { setSearch(''); handleFilter({ query: '', page: 0 }); }}
                      />
                      {/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    </div>
                    <button type="submit" style={{ marginLeft: 10 }} className="btn btn-primary shipment_search_button" onClick={() => handleFilter({ page: 0 })}>
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
                <ActualShipmentsTable
                  rows={children}
                  selectedFields={selectedFields}
                  serverTimezone={serverTimezone}
                  isAdmin={isAdmin}
                  handleFilter={handleFilter}
                  paginationOptions={paginationOptions}
                  permission={permission}
                  userId={userId}
                  countryId={countryId}
                  suffix="childShipments"
                  paginationKey={PaginationKeys.childrenShipments}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {
        showFieldsModal && <FieldsModal open={showFieldsModal} fields={getFields()} selectedFields={selectedFields} updateFields={handleUpdateFields} handleClose={() => setShowFieldsModal(false)} />
      }
      {
        shipChildModal && (
          <ChildShipmentModal
            open={shipChildModal}
            refreshData={() => handleFilter({})}
            parentShipment={data}
            handleClose={() => setShipChildModal(false)}
            outboundData={outboundData}
            returnData={returnData}
          />
        )
      }
    </div>
  );
};

ShipmentDetails.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  returnData: PropTypes.instanceOf(Object),
  outboundData: PropTypes.instanceOf(Object),
  permission: PropTypes.instanceOf(Object).isRequired,
  timezoneFromServer: PropTypes.instanceOf(Object).isRequired,
  userId: PropTypes.number.isRequired,
  countryId: PropTypes.number.isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

ShipmentDetails.defaultProps = {
  returnData: null,
  outboundData: null,
};

export default ShipmentDetails;
