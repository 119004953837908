import ActionType from '../action-types';

// eslint-disable-next-line import/prefer-default-export
export const login = token => ({
  type: ActionType.LOGIN,
  payload: token,
});

export const upsertConversation = convo => ({
  type: ActionType.UPSERT_CONVERSATION,
  payload: convo,
});

export const removeConversation = sid => ({
  type: ActionType.REMOVE_CONVERSATION,
  payload: sid,
});

export const updateCurrentConversation = sid => ({
  type: ActionType.UPDATE_CURRENT_CONVERSATION,
  payload: sid,
});

export const setLastReadIndex = index => ({
  type: ActionType.CONVERSATION_LAST_READ_INDEX,
  payload: index,
});

export const upsertMessages = (
  channelSid,
  messages,
) => ({
  type: ActionType.ADD_MESSAGES,
  payload: { channelSid, messages },
});

export const pushMessages = (channelSid, messages) => ({
  type: ActionType.PUSH_MESSAGES,
  payload: { channelSid, messages },
});

export const removeMessages = (channelSid, messages) => ({
  type: ActionType.REMOVE_MESSAGES,
  payload: { channelSid, messages },
});

export const updateLoadingState = loadingStatus => ({
  type: ActionType.UPDATE_LOADING_STATE,
  payload: loadingStatus,
});

export const updateParticipants = (
  participants,
  sid,
) => ({
  type: ActionType.UPDATE_PARTICIPANTS,
  payload: { participants, sid },
});

export const updateUnreadMessages = (
  channelSid,
  unreadCount,
) => ({
  type: ActionType.UPDATE_UNREAD_MESSAGES,
  payload: { channelSid, unreadCount },
});

export const updateConversation = (
  channelSid,
  parameters,
) => ({
  type: ActionType.UPDATE_CONVERSATION,
  payload: { channelSid, parameters },
});

export const addAttachment = (
  channelSid,
  messageSid,
  mediaSid,
  attachment,
) => ({
  type: ActionType.ADD_ATTACHMENT,
  payload: {
    channelSid, messageSid, mediaSid, attachment,
  },
});

export const clearAttachments = (channelSid, messageSid) => ({
  type: ActionType.CLEAR_ATTACHMENTS,
  payload: { channelSid, messageSid },
});

export const startTyping = (channelSid, participant) => ({
  type: ActionType.TYPING_STARTED,
  payload: { channelSid, participant },
});

export const endTyping = (channelSid, participant) => ({
  type: ActionType.TYPING_ENDED,
  payload: { channelSid, participant },
});

export const addNotifications = notifications => ({
  type: ActionType.ADD_NOTIFICATIONS,
  payload: notifications,
});

export const removeNotifications = toIndex => ({
  type: ActionType.REMOVE_NOTIFICATIONS,
  payload: toIndex,
});

export const setEmail = email => ({
  type: ActionType.SET_EMAIL,
  payload: email,
});

export const setSystemUsers = users => ({
  type: ActionType.SET_SYSTEM_USERS,
  payload: users,
});
