import React from 'react';
import PropTypes from 'prop-types';

const error = (errors, type, id) => ((errors?.packages || {})[type] || {})[id];

const Packages = ({
  errors, handleChangePack, packageTypesList, setErrors,
  type, packages, setCarrier, carrier, carriersList,
  setCarrierService, carrierService, carrierServicesList,
  disabled,
}) => (
  <>
    <div className="row outbound_carrier_container" style={{ marginTop: type === 'return' ? '30px' : '0' }}>
      <h3 className="inner-title" style={{ marginLeft: '15px' }}>{`${type === 'outbound' ? 'Outbound' : 'Return'} Shipment`}</h3>
      <div className="col-sm-6 form-group">
        <label htmlFor="carrier_id">
          Carrier
          <span className="required">*</span>
        </label>
        <select
          name="carrier_id"
          id="carrier_id"
          className="form-control fill_carriers"
          value={carrier}
          disabled={disabled}
          onChange={(e) => {
            setCarrier(e.target.value);
            setErrors(e);
          }}
        >
          <option value="">Please select</option>
          {carriersList.map(item => <option key={item.id} value={JSON.stringify({ carrier_details: { carrier_type: item.carrier_type, carrier_id: item.id } })}>{item.name}</option>)}
        </select>
        {(errors?.carrier_id || {})[type] && (
          <label className="error">
            {errors.carrier_id[type]}
          </label>
        )}
      </div>
      <div className="col-sm-6 form-group carrier_service">
        <label htmlFor="carrier_service_id">
          Carrier Service
          <span className="required">*</span>
        </label>
        <select
          name="carrier_service_id"
          id="carrier_service_id"
          className="form-control fill_carrier_services"
          value={carrierService}
          disabled={disabled}
          onChange={(e) => {
            setCarrierService(e.target.value);
            setErrors(e);
          }}
        >
          <option value="">Please select</option>
          {carrierServicesList.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
        </select>
        {(errors?.carrier_service_id || {})[type] && (
          <label className="error">
            {errors.carrier_service_id[type]}
          </label>
        )}
      </div>
    </div>
    {packages.map((pack, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className="shipment_first_block_clss" key={index.toString()}>
        <div
          className="row"
          style={{
            border: '1px solid #dedede', backgroundColor: '#edeff3', padding: '5px', borderRadius: '4px',
          }}
        >
          <div className="col-sm-6 form-group">
            <label htmlFor="quantity">
              Quantity
              <span className="required">*</span>
            </label>
            <input
              name="quantity"
              id="quantity"
              className="form-control static_quantity"
              maxLength={15}
              value={pack.quantity}
              disabled={disabled}
              onChange={e => handleChangePack(e, index)}
            />
            {error(errors, type, index)?.quantity && (
              <label className="error">
                {error(errors, type, index).quantity}
              </label>
            )}
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="package_type_id">
              Package Type
              <span className="required">*</span>
            </label>
            <select
              name="package_type_id"
              id="package_type_id"
              className="form-control fill_carrier_services"
              value={pack.package_type_id || ''}
              disabled={disabled}
              onChange={e => handleChangePack(e, index)}
            >
              <option value="">Please select</option>
              {packageTypesList.map(packageType => <option key={packageType.id} value={packageType.id}>{packageType.name}</option>)}
            </select>
            {error(errors, type, index)?.package_type_id && (
              <label className="error">
                {error(errors, type, index).package_type_id}
              </label>
            )}
          </div>
          <div className="col-sm-6 form-group">
            <div className="row">
              <div className="col-md-6 form-group">
                <label htmlFor="weight">
                  Weight
                  <span className="required">*</span>
                </label>
                <input
                  name="weight"
                  id="weight"
                  className="form-control"
                  maxLength={15}
                  value={pack.weight}
                  disabled={disabled}
                  onChange={e => handleChangePack(e, index)}
                />
                {error(errors, type, index)?.weight && (
                  <label className="error">
                    {error(errors, type, index).weight}
                  </label>
                )}
              </div>
              <div className="col-sm-6 media-radio">
                <div className="col-xs-4 col-md-5 padding-zero">
                  <label className="radio-label margin-top30">
                    <input
                      type="radio"
                      name={`weight_calculated_in-${type}`}
                      id={`weight_calculated_in_lbs-${type}`}
                      checked={pack.weight_calculated_in === 1}
                      disabled={disabled}
                      onChange={e => handleChangePack(e, index, 1)}
                    />
                    Lbs
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="col-xs-8 col-md-7">
                  <label className="radio-label margin-top30">
                    <input
                      type="radio"
                      name={`weight_calculated_in-${type}`}
                      id={`weight_calculated_in_lbs-${type}`}
                      checked={pack.weight_calculated_in === 2}
                      disabled={disabled}
                      onChange={e => handleChangePack(e, index, 2)}
                    />
                    Kgs
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 form-group">
            <div className="row form-group">
              <div className="col-xs-4">
                <label htmlFor="width">
                  Width
                  <span className="required">*</span>
                </label>
                <input
                  name="width"
                  id="width"
                  className="form-control dimensions width"
                  maxLength={15}
                  value={pack.width}
                  disabled={disabled}
                  onChange={e => handleChangePack(e, index)}
                />
                {error(errors, type, index)?.width && (
                  <label className="error">
                    {error(errors, type, index).width}
                  </label>
                )}
              </div>
              <div className="col-xs-4">
                <label htmlFor="length">
                  Length
                  <span className="required">*</span>
                </label>
                <input
                  name="length"
                  id="length"
                  className="form-control dimensions length"
                  maxLength={15}
                  value={pack.length}
                  disabled={disabled}
                  onChange={e => handleChangePack(e, index)}
                />
                {error(errors, type, index)?.length && (
                  <label className="error">
                    {error(errors, type, index).length}
                  </label>
                )}
              </div>
              <div className="col-xs-4">
                <label htmlFor="height">
                  Height
                  <span className="required">*</span>
                </label>
                <input
                  name="height"
                  id="height"
                  className="form-control dimensions height"
                  maxLength={15}
                  value={pack.height}
                  disabled={disabled}
                  onChange={e => handleChangePack(e, index)}
                />
                {error(errors, type, index)?.height && (
                  <label className="error">
                    {error(errors, type, index).height}
                  </label>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-2 media-radio-half">
                <label className="radio-label">
                  <input
                    type="radio"
                    name={`dimension_calculated_in-${type}`}
                    id={`dimension_calculated_in_ins-${type}`}
                    checked={pack.dimension_calculated_in === 1}
                    disabled={disabled}
                    onChange={e => handleChangePack(e, index, 1)}
                  />
                  Ins
                  <span className="checkmark" />
                </label>
              </div>
              <div className="col-xs-2 media-radio-half">
                <label className="radio-label">
                  <input
                    type="radio"
                    name={`dimension_calculated_in-${type}`}
                    id={`dimension_calculated_in_ins-${type}`}
                    checked={pack.dimension_calculated_in === 2}
                    disabled={disabled}
                    onChange={e => handleChangePack(e, index, 2)}
                  />
                  Cms
                  <span className="checkmark" />
                </label>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <label className="checkbox-label">
              <input
                type="checkbox"
                name="is_special_day_delivery"
                id="is_special_day_delivery"
                checked={pack.is_special_day_delivery}
                disabled={disabled}
                onChange={e => handleChangePack(e, index)}
              />
              Saturday Delivery
              <span className="checkmark" />
            </label>
          </div>
        </div>
      </div>
    ))}
  </>
);

Packages.propTypes = {
  errors: PropTypes.instanceOf(Object).isRequired,
  handleChangePack: PropTypes.func.isRequired,
  packageTypesList: PropTypes.instanceOf(Array).isRequired,
  setErrors: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  packages: PropTypes.instanceOf(Array).isRequired,
  setCarrier: PropTypes.func.isRequired,
  carrier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  carriersList: PropTypes.instanceOf(Array).isRequired,
  setCarrierService: PropTypes.func.isRequired,
  carrierService: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  carrierServicesList: PropTypes.instanceOf(Array).isRequired,
  disabled: PropTypes.bool,
};

Packages.defaultProps = {
  disabled: false,
};

export default Packages;
