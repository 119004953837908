import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import moment from 'moment-timezone';
import api from '../../services/api';
import { printZplLabels } from '../../services/helpers';

const ChildShipmentModal = ({
  parentShipment, open, handleClose, refreshData, outboundData, returnData,
}) => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(false);
  const [fedexFormula, setFedexFormula] = useState(false);
  const [finalPrice, setFinalPrice] = useState(null);
  const [returnShipmentRateId, setReturnShipmentRateId] = useState('');
  const [outboundShipmentRateId, setOutboundShipmentRateId] = useState('');
  const [outboundShipment, setOutboundShipment] = useState();
  const [returnShipment, setReturnShipment] = useState();
  const [isExternal, setIsExternal] = useState(false);
  const routeDate = useRef();

  useEffect(() => {
    if (parentShipment.is_return === 1) {
      setOutboundShipment(parentShipment);
    } else if (parentShipment.is_return === 2) {
      if (outboundData) {
        setReturnShipment(parentShipment);
        setOutboundShipment(outboundData);
      }
      if (returnData) {
        setReturnShipment(returnData);
        setOutboundShipment(parentShipment);
      }
    } else if (parentShipment.is_return === 3) {
      setReturnShipment(parentShipment);
    }
  }, [parentShipment]);

  const getParams = ({ outboundQuantity, returnQuantity }) => ({
    shipment: {
      origin_company_name: parentShipment.origin_company_name,
      origin_attention_to: parentShipment.origin_attention_to,
      origin_street_address1: parentShipment.origin_street_address1,
      origin_street_address2: parentShipment.origin_street_address2,
      origin_city: parentShipment.origin_city,
      origin_state: parentShipment.origin_state,
      origin_zip: parentShipment.origin_zip,
      origin_country: 'US',
      origin_phone: parentShipment.origin_phone,
      account_id: parentShipment.account_id,
      destination_company_name: parentShipment.destination_company_name,
      destination_attention_to: parentShipment.destination_attention_to,
      destination_street_address1: parentShipment.destination_street_address1,
      destination_street_address2: parentShipment.destination_street_address2,
      destination_city: parentShipment.destination_city,
      destination_state: parentShipment.destination_state,
      destination_zip: parentShipment.destination_zip,
      destination_country: parentShipment.destination_country,
      destination_phone: parentShipment.destination_phone,
      carrier_type: outboundShipment?.carrier?.carrier_type,
      carrier_id: outboundShipment?.carrier_id,
      carrier_service_id: outboundShipment?.carrier_service_id,
      estimated_ship_date: date,
      is_return: parentShipment.is_return,
      record_id: null,
      shipment_details_attributes: outboundShipment?.shipment_details?.map(item => ({
        quantity: outboundQuantity || item.quantity,
        weight: item.weight,
        weight_calculated_in: item.weight_calculated_in,
        width: item.width,
        length: item.length,
        height: item.height,
        dimension_calculated_in: item.dimension_calculated_in,
        package_type_id: item.package_type_id,
        is_special_day_delivery: item.is_special_day_delivery,
      })) || {},
      company_id: parentShipment.company_id,
      is_parent: false,
      parent_id: outboundShipment?.id,
    },
    fedex_formula: fedexFormula,
    outbound_shipment_rate_id: outboundShipmentRateId,
    return_shipment_rate_id: returnShipmentRateId,
    shipment_return: {
      parent_id: returnShipment?.id,
      carrier_type: returnShipment?.carrier?.carrier_type,
      carrier_id: returnShipment?.carrier_id,
      carrier_service_id: returnShipment?.carrier_service_id,
      shipment_details_attributes: returnShipment?.shipment_details ? {
        quantity: returnQuantity || returnShipment.shipment_details[0].quantity,
        weight: returnShipment.shipment_details[0].weight,
        weight_calculated_in: returnShipment.shipment_details[0].weight_calculated_in,
        width: returnShipment.shipment_details[0].width,
        length: returnShipment.shipment_details[0].length,
        height: returnShipment.shipment_details[0].height,
        dimension_calculated_in: returnShipment.shipment_details[0].dimension_calculated_in,
        package_type_id: returnShipment.shipment_details[0].package_type_id,
        is_special_day_delivery: returnShipment.shipment_details[0].is_special_day_delivery,
      } : {},
    },
    use_shipengine: isExternal,
  });

  const handleRate = () => {
    setLoading(true);
    api.shipmentDashboard.getRate(getParams({}))
      .then(({ final_price, return_shipment_rate_id, outbound_shipment_rate_id }) => {
        setFinalPrice(final_price);
        setOutboundShipmentRateId(outbound_shipment_rate_id);
        setReturnShipmentRateId(return_shipment_rate_id);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const currentQuantity = outboundShipment?.shipment_details[0]?.quantity || 1;
    const currentReturnQuantity = returnShipment?.shipment_details[0]?.quantity || 1;
    const clustersCount = Math.floor(currentQuantity / 5);
    const clustersReturnCount = Math.floor(currentReturnQuantity / 5);
    const currentQuantityMod = currentQuantity % 5;
    const currentReturnQuantityMod = currentReturnQuantity % 5;
    const quantities = (new Array(clustersCount)).fill(5);
    const quantitiesReturn = (new Array(clustersReturnCount)).fill(5);
    if (currentQuantityMod !== 0) quantities.push(currentQuantityMod);
    if (currentReturnQuantityMod !== 0) quantitiesReturn.push(currentReturnQuantityMod);
    const data = [];

    const values = quantities.length >= quantitiesReturn.length ? quantities : quantitiesReturn;
    const values2 = quantities.length < quantitiesReturn.length ? quantities : quantitiesReturn;
    const type = quantities.length >= quantitiesReturn.length ? 'outbound' : 'return';
    let idx = 0;
    try {
      for await (const item of values) {
        const params = type === 'outbound' ? { outboundQuantity: item, returnQuantity: values2[idx] } : { outboundQuantity: values2[idx], returnQuantity: item };
        const {
          // eslint-disable-next-line no-unused-vars
          fedex_ups_print_zpl, rfl_print_zpl, fedex_ups_printer_ips, rfl_printer_ips, model_data,
        } = await api.shipmentDashboard.shipSubmit(getParams(params));
        data.push(...model_data);

        const res = await api.shipmentDashboard.bulkActions({
          bulk_action: 'print_zpl',
          ids: model_data.map(row => row.id),
          controller_name: 'shipments',
        });

        printZplLabels(res);
        idx += 1;
      }

      setLoading(false);
      refreshData();
      handleClose();
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Create child shipment
        <IconButton onClick={handleClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="pickup_date">
              Date
              <span className="required">*</span>
            </label>
            <div>
              <input
                ref={routeDate}
                type="date"
                name="date"
                id="date"
                className="form-control"
                value={date}
                onChange={e => setDate(e.target.value)}
              />
            </div>
          </div>
          {outboundShipment?.carrier?.name === 'Fed-Ex' && (
            <div hidden className="col-sm-12">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  name="fedex_formula"
                  id="fedex_formula"
                  checked={fedexFormula}
                  disabled={Boolean(finalPrice)}
                  onChange={() => setFedexFormula(!fedexFormula)}
                />
                FedEx Formula Pricing
                <span className="checkmark" />
              </label>
            </div>
          )}
        </div>
        <div className="row radio-mobile">
          <div className="col-xs-4 col-lg-2 full-width">
            <label className="radio-label margin-top10">
              <input
                type="radio"
                name="direct"
                id="direct"
                className="is_return_class"
                value={1}
                checked={!isExternal}
                disabled={Boolean(finalPrice)}
                onChange={() => setIsExternal(false)}
              />
              Direct
              <span className="checkmark" />
            </label>
          </div>
          <div className="col-xs-4 col-lg-2 full-width">
            <label className="radio-label margin-top10">
              <input
                type="radio"
                name="external"
                id="external"
                className="is_return_class"
                checked={isExternal}
                disabled={Boolean(finalPrice)}
                onChange={() => setIsExternal(true)}
              />
              External
              <span className="checkmark" />
            </label>
          </div>
        </div>
        <div className="form-group final-price-col" style={{ marginTop: '20px' }}>
          {finalPrice && (
            <div style={{ width: '100%' }}>
              <div>Final Price:</div>
              <div className="final_price">{finalPrice}</div>
            </div>
          )}
          {finalPrice ? (
            <>
              <button disabled={loading} id="submit-button" type="button" className="btn bg-olive ship_submit_btn" title="Continue" style={{ marginRight: '10px' }} onClick={handleSubmit}>Ship Now</button>
              <button className="btn btn-primary ship_cancel" title="Cancel" onClick={handleClose}>Cancel</button>
            </>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button disabled={loading} id="rate-button" type="button" className="btn bg-olive get_rate_btn" title="Continue" style={{ marginRight: '10px' }} onClick={handleRate}>Continue</button>
              <button className="btn btn-primary continue_cancel" title="Cancel" onClick={handleClose}>Cancel</button>
              {outboundShipment?.carrier?.name === 'Fed-Ex' && (
                <div hidden className="col-sm-12">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      name="fedex_formula"
                      id="fedex_formula"
                      checked={fedexFormula}
                      disabled={Boolean(finalPrice)}
                      onChange={() => setFedexFormula(!fedexFormula)}
                    />
                    FedEx Formula Pricing
                    <span className="checkmark" />
                  </label>
                </div>
              )}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

ChildShipmentModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  parentShipment: PropTypes.instanceOf(Object).isRequired,
  refreshData: PropTypes.func.isRequired,
  outboundData: PropTypes.instanceOf(Object),
  returnData: PropTypes.instanceOf(Object),
};

ChildShipmentModal.defaultProps = {
  open: false,
  outboundData: null,
  returnData: null,
};

export default ChildShipmentModal;
