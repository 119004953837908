import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import api from '../../services/api';

const SwitchPickupRouteModal = ({
  handleClose, open, handleConfirm, currentRouteId, network,
}) => {
  const [selectedRouteId, setSelectedRouteId] = useState(currentRouteId);
  const [routes, setRoutes] = useState([]);
  const [unassignedPickups, setUnassignedPickups] = useState({});

  useEffect(() => {
    api.rlNetwork.show(network.id).then((res) => {
      setRoutes(res.routes);
      setUnassignedPickups(res.unassigned);
    });
  }, [network]);

  return (
    <Dialog
      className="dialog-sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Switch Pickup
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row">
          <div className="col-sm-12 form-group">
            <div>New Route</div>
            <select
              name="route_id"
              id="route_id"
              className="form-control"
              value={selectedRouteId}
              onChange={(e) => {
                setSelectedRouteId(parseInt(e.target.value, 10));
              }}
            >
              <option value="">Select route</option>
              {routes.map(route => <option key={route.id} value={route.id}>{`${route.package_cluster.region_id_lvl1} @ ${route.package_cluster.airport_code} ${route.driver ? `${route.driver.user.first_name} ${route.driver.user.last_name} [${route.driver.user.email}]` : ''} - ${route.pickup_count_for_user_role}/${unassignedPickups[route.package_cluster_id] || 0}`}</option>)}
            </select>
          </div>
        </div>
        <button onClick={() => handleConfirm(selectedRouteId)} disabled={selectedRouteId === currentRouteId} className="btn btn-primary popup_cancel_btn">Confirm</button>
        <button onClick={handleClose} className="btn btn-primary pull-right popup_cancel_btn">Cancel</button>
      </DialogContent>
    </Dialog>
  );
};

SwitchPickupRouteModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  currentRouteId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  network: PropTypes.instanceOf(Object).isRequired,
};

SwitchPickupRouteModal.defaultProps = {
  open: false,
};

export default SwitchPickupRouteModal;
