import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { errorToast, successToast } from '../../services/toast';

const CreateConsolidationBagModal = ({
  handleClose, open, onSubmit,
}) => {
  const [trackingCode, setTrackingCode] = useState('');
  const [pickupCodes, setPickupCodes] = useState('');

  const handleSubmit = () => {
    onSubmit(trackingCode, pickupCodes).then((response) => {
      if (response.ok) {
        successToast(response.message);
        handleClose();
      } else {
        errorToast(response.message);
      }
    });
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Create consolidation bag
        <IconButton onClick={handleClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="tracking_code">
              Consolidation bag tracking id
            </label>
            <input
              name="tracking_code"
              id="tracking_code"
              className="form-control"
              type="text"
              onChange={e => setTrackingCode(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="scanned_codes">
              Consolidation packages
            </label>
            <input
              name="scanned_codes"
              id="scanned_codes"
              className="form-control"
              type="text"
              onChange={e => setPickupCodes(e.target.value)}
            />
          </div>
        </div>
        <div className="modal-bottom-btn text-right">
          <button className="btn bg-olive" onClick={handleSubmit}>Confirm</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={handleClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

CreateConsolidationBagModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

CreateConsolidationBagModal.defaultProps = {
  open: false,
};

export default CreateConsolidationBagModal;
