import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  Dialog, DialogContent, DialogTitle,
} from '@material-ui/core';

const TrackingDetailsModal = ({
  open, handleClose, trackingDetails,
}) => {
  const [allStatuses, setAllStatuses] = useState([]);
  const [shipment, setShipment] = useState({});
  useEffect(() => {
    if (trackingDetails?.shipment) {
      // eslint-disable-next-line no-shadow
      const { shipment } = trackingDetails;
      setShipment(shipment);
      if (shipment.shipment_statuses?.length) {
        // eslint-disable-next-line no-nested-ternary
        setAllStatuses(shipment.shipment_statuses.sort((a, b) => (a.created_at > b.created_at ? 1 : (b.created_at > a.created_at ? -1 : 0))));
      }
    }
  }, [trackingDetails]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="dialog-sm"
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Tracking Id -
        {' '}
        {trackingDetails?.tracking_id}
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="box-body">
          {shipment && (
            <>
              <div className="top-horizontal-scrollbar">
                <div className="top-horizontal-scroll" />
              </div>
              <div className="media-table">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr className="pretty">
                      <th>Tracking Id</th>
                      <th>Origin</th>
                      <th>Destination</th>
                      <th>Status</th>
                      <th>ship Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{shipment.tracking_id ? shipment.tracking_id : ''}</td>
                      <td>{shipment.string_origin}</td>
                      <td>{shipment.string_destination}</td>
                      <td>{allStatuses.length ? allStatuses[allStatuses.length - 1].status : ''}</td>
                      <td>{shipment.ship_date ? moment(shipment.ship_date).format('DD MMM YYYY') : ''}</td>

                    </tr>
                  </tbody>
                </table>
              </div>
              {Boolean(allStatuses.length) && (
                <div className="track-accordion">
                  <div id="accordion" className="myaccordion">
                    <div className="card1">
                      <div className="card-header1 text-right" id="headingOne">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          View Shipment Details
                          <span className="accordion-icon" />
                        </button>
                      </div>
                      <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                          <div className="track-data">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="double-col">
                                  <label>Shipped Date</label>
                                  <span>{shipment.ship_date ? moment(shipment.ship_date).format('DD MMM YYYY') : ''}</span>
                                </div>
                                <div className="double-col">
                                  <label>Shipped From</label>
                                  <span>{shipment.string_origin}</span>
                                </div>
                                <div className="double-col">
                                  <label>Shipped To</label>
                                  <span>{shipment.string_destination}</span>
                                </div>
                                <div className="double-col">
                                  <label>Current Status</label>
                                  <span>{allStatuses.length ? allStatuses[allStatuses.length - 1].status : ''}</span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="track-detail">
                                  <label>Transit History</label>
                                  <ul>
                                    {allStatuses.map((item, index) => (
                                      // eslint-disable-next-line react/no-array-index-key
                                      <li key={index}>
                                        {item.status}
                                        {' '}
                                        |
                                        {' '}
                                        {item.description}
                                        {' '}
                                        |
                                        {' '}
                                        {moment(item.created_at).format('DD MMM YYYY hh:mm A')}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

TrackingDetailsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  trackingDetails: PropTypes.any.isRequired,
};

TrackingDetailsModal.defaultProps = {
  open: false,
};

export default TrackingDetailsModal;
