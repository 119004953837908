import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ConversationTitleModal from './ConversationTitleModal';
import { actionCreators } from '../../stores';
import twilioApi from '../../services/twilioApi';
import api from '../../services/api';
import { getSdkConversationObject } from '../../conversationObject';

const CreateConversationButton = ({ client }) => {
  const dispatch = useDispatch();
  const { updateCurrentConversation, updateParticipants, upsertMessages } = bindActionCreators(actionCreators, dispatch);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [identity, setIdentity] = useState();
  const [convoTitle, setConvoTitle] = useState('');
  const handleOpen = () => setIsModalOpen(true);

  const handleCreateConversation = async () => {
    if (!client) return;

    setError('');
    const userSid = await twilioApi.createUser(identity.email);
    if (userSid) {
      const convo = await twilioApi.addConversation(convoTitle, updateParticipants, client);
      const sdkConvo = getSdkConversationObject(convo);
      await twilioApi.addParticipant(identity.email, {}, sdkConvo);
      const { owners } = await api.user.getOwners();
      if (owners && owners.length) {
        for (let idx = 0; idx < owners.length; idx += 1) {
          // eslint-disable-next-line no-await-in-loop
          const ownerSID = await twilioApi.createUser(owners[idx].email);
          // eslint-disable-next-line no-await-in-loop
          if (ownerSID) await twilioApi.addParticipant(owners[idx].email, { isOwner: true }, sdkConvo);
        }
      }
      upsertMessages(convo.sid, []);
      updateCurrentConversation(convo.sid);
      setIsModalOpen(false);
    } else setError('Something went wrong, try again later');
  };

  return (
    <>
      <button className="btn btn-primary pull-right popup_cancel_btn" onClick={handleOpen}>
        Create New Conversation
      </button>
      <ConversationTitleModal
        error={error}
        title=""
        type="new"
        open={isModalOpen}
        identity={identity}
        convoTitle={convoTitle}
        setIdentity={setIdentity}
        setConvoTitle={setConvoTitle}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        onSubmit={handleCreateConversation}
      />
    </>
  );
};

CreateConversationButton.propTypes = {
  client: PropTypes.instanceOf(Object),
};

CreateConversationButton.defaultProps = {
  client: null,
};

export default CreateConversationButton;
