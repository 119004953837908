import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import Toast from '../common/Toast';
import api from '../../services/api';
import CustomTable from '../common/Table';
import SetZonePriceTable from './SetZonePriceTable';
import { updateChangeRowsPerPage } from '../../helpers';
import { PaginationKeys } from '../../constants';

const ZonePricing = ({ companies, preferences }) => {
  const [carrierServices, setCarrierServices] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [carrierService, setCarrierService] = useState('');
  const [carriers, setCarriers] = useState([]);
  const [carrier, setCarrier] = useState('');
  const [company, setCompany] = useState('');
  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState({
    page: 0,
    count: 0,
    limit: parseInt(window.localStorage.getItem(PaginationKeys.shipmentPricing), 10) || preferences?.records_per_page || 10,
  });

  const selectFileRef = useRef(null);

  useEffect(() => {
    handleFilter({});
  }, []);

  useEffect(() => {
    api.zonePriceTable.getCarriers({ company }).then((data) => {
      setCarriers(data.carriers);
    });
  }, [company]);

  useEffect(() => {
    api.zonePriceTable.getCarrierServices({ carrier }).then(({ services }) => {
      setCarrierServices(services);
    });
  }, [carrier]);

  const handleFilter = useCallback(({ page, limit }) => {
    api.zonePriceTable.getTables({
      page: page !== undefined ? page + 1 : paginationOptions.page + 1,
      limit: limit || paginationOptions.limit,
    })
      .then((res) => {
        setRows(res.tables);
        setPaginationOptions({
          count: res.all_tables,
          page: page || 0,
          limit: limit || paginationOptions.limit,
        });
      })
      .catch(err => console.log(err));
  }, [paginationOptions]);

  const handleChangePage = (e, newPage) => {
    handleFilter({ page: newPage });
  };

  const handleChangeRowsPerPage = async (e) => {
    await updateChangeRowsPerPage(e, PaginationKeys.shipmentPricing, val => handleFilter({ page: 0, limit: val }));
  };

  const handleImport = () => {
    selectFileRef.current.click();
  };

  const handleSelectFile = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('csv', file);
    formData.append('company_id', company);
    formData.append('carrier_id', carrier);
    formData.append('carrier_service_id', carrierService);
    api.zonePriceTable.importData(formData).then(() => {
      handleFilter({});
    });
  };

  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="box">
          <Toast />
          <div className="box-header">
            <h1 className="box-title">Zone Price Tables</h1>
            <div className="pull-right">
              <button
                className="btn add-btn pull-right prevent_right_click add_address_class_new"
                onClick={() => {
                  setSelectedRow({});
                  setOpenModal(true);
                }}
              >
                Add Zone Price Tables
              </button>
            </div>
          </div>
          <div className="import-addresses-container">
            <input ref={selectFileRef} type="file" onChange={handleSelectFile} style={{ width: 0, height: 0 }} />
            <select
              className="form-control import_address_company_id"
              id="company_id"
              value={company}
              onChange={e => setCompany(e.target.value)}
            >
              <option key="default" value="">Select Company</option>
              {companies.map(item => <option key={item[1]} value={item[1]}>{item[0]}</option>)}
            </select>
            <select
              className="form-control import_address_company_id"
              id="carrier_id"
              value={carrier}
              onChange={e => setCarrier(e.target.value)}
            >
              <option key="default" value="">Select Carrier</option>
              {carriers.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
            </select>
            <select
              className="form-control import_address_company_id"
              id="carrier_service_id"
              value={carrierService}
              onChange={e => setCarrierService(e.target.value)}
            >
              <option key="default" value="">Select Carrier Service</option>
              {carrierServices.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
            </select>
            <button
              className="btn add-btn prevent_right_click add_warehouses_from_file"
              onClick={() => {
                handleImport();
              }}
            >
              Upload From File
            </button>
          </div>
          <CustomTable
            rows={rows}
            rowsProps={[
              { header: 'Actions', key: 'actions', format: true },
              { header: 'Carrier', key: 'carrier.name' },
              { header: 'Carrier Service', key: 'carrier_service.name' },
              { header: 'Zone', key: 'zone' },
              { header: 'Weight', key: 'weight' },
              { header: 'Price($)', key: 'price' },
            ]}
            paginationOptions={{
              ...paginationOptions,
              handleChangePage,
              handleChangeRowsPerPage,
            }}
            suffix="zone"
            formatters={{
              actions: value => (
                <div className="text-center" style={{ maxWidth: '70px' }}>
                  <button
                    className="fa fa-edit space-left-right action-button"
                    onClick={() => {
                      setOpenModal(true);
                      setSelectedRow(value);
                    }}
                  />
                </div>
              ),
            }}
          />

          <SetZonePriceTable
            onClose={() => setOpenModal(false)}
            row={selectedRow}
            companies={companies}
            open={openModal}
            update={handleFilter}
          />
        </div>
      </div>
    </div>
  );
};

ZonePricing.propTypes = {
  companies: PropTypes.instanceOf(Array).isRequired,
  preferences: PropTypes.instanceOf(Object).isRequired,
};

export default ZonePricing;
