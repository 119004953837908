import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const SetPositionModal = ({
  handleClose, open, maxValue, currentValue, onSubmit,
}) => {
  const [newPosition, setNewPosition] = useState(currentValue);

  const handleChangeNewPosition = (event) => {
    if (event.target.value > 0 && event.target.value <= maxValue) setNewPosition(event.target.value);
  };

  const handleSubmit = () => {
    if (currentValue !== newPosition) onSubmit(newPosition);
    handleClose();
  };

  return (
    <Dialog
      className="dialog-md"
      open={open}
      onClose={handleClose}
      aria-labelledby="preview-modal-title"
    >
      <DialogTitle id="preview-modal-title">
        Set new position
        <IconButton onClick={handleClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent className="preview-item-container" id="preview-modal-dialog">
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="position">
              New Position
            </label>
            <input
              name="position"
              id="position"
              className="form-control"
              type="number"
              min={1}
              max={maxValue}
              value={newPosition}
              onChange={e => handleChangeNewPosition(e)}
            />
          </div>
        </div>
        <div className="modal-bottom-btn text-right">
          <button className="btn bg-olive" onClick={handleSubmit}>Confirm</button>
          <button className="btn btn-primary" style={{ marginLeft: '5px' }} onClick={handleClose}>Cancel</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

SetPositionModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  maxValue: PropTypes.number.isRequired,
  currentValue: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,

};

SetPositionModal.defaultProps = {
  open: false,
};

export default SetPositionModal;
